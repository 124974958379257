//:::::::::::::::::::::::::
import { SummaryItem, ModelPaperwork } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function SummaryEmployerRequest() {
  return (
    <>
      <SummaryItem
        name='Employer'
        value={ModelPaperwork.paperwork.responses.employer}
      />
      <SummaryItem
        name='Employer Contact Name'
        value={ModelPaperwork.paperwork.responses.workInjuryEmployerContactName}
      />
      <SummaryItem
        name='Employer Contact Phone Number'
        value={
          ModelPaperwork.paperwork.responses
            .workInjuryEmployerContactPhoneNumber
        }
      />
      <SummaryItem
        name='Reason for Request'
        value={ModelPaperwork.paperwork.responses.whatAreYouHereFor}
      />
    </>
  )
}
