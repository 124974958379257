//:::::::::::::::::::::::::
//Reusable abstraction of the email field so we don't have to repeat the  error handling in each instance
import { Input, useState, Helper } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function FieldEmail(props: {
  value: string
  change: (value: string) => void
  disabled?: boolean
}) {
  const [ready, readySet] = useState(true)

  return (
    <Input
      type='text'
      label='Email Address'
      value={props.value}
      disabled={props.disabled || false}
      change={(event) => {
        props.change(event.target.value)
        readySet(
          Helper.validate(Helper.InputValidator.Email, event.target.value),
        )
      }}
      error='Please enter a valid email address'
      valid={ready}
    />
  )
}
