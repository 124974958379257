//:::::::::::::::::::::::::
import {
  ModelClinic,
  Path,
  Button,
  observer,
  useState,
  Nav,
  ModelBooking,
  useCallback,
  ModelAccount,
  Types,
  ModelMap,
} from 'src/_Shared/global'
import css from './clinicDetails.module.css'
import ClinicInfo from './ClinicInfo/ClinicInfo'
import ClinicSelectVisitTime from './ClinicSelectVisitTime'
import ClinicServices from './ClinicServices/ClinicServices'
import ClinicHours from './ClinicHours/ClinicHours'
import ClinicInsurance from './ClinicInsurance/ClinicInsurance'
import { useAccountStore } from 'src/_Shared/stores/account'
//:::::::::::::::::::::::::

function ClinicDetails() {
  const [full, fullSet] = useState(false)
  const [account] = useAccountStore((state) => [state.account])
  const clickPatientPortal = useCallback(() => {
    window.open(
      'https://mycw58.eclinicalweb.com/portal7094/jsp/100mp/login_otp.jsp',
    )
  }, [])

  const isFavorited = ModelClinic.selected
    ? ModelAccount.checkIfIsFavorited(ModelClinic.selected, account)
    : false

  const handleFavorite = async (clinic: Types.Clinic) => {
    await ModelAccount.setFavorite(clinic, isFavorited, account)
  }

  //Hide clinic card in map view
  const clickVeil = useCallback((event: React.MouseEvent<HTMLElement>) => {
    ModelClinic.setSelected(undefined)
  }, [])

  return (
    <div
      className={`${css.clinicDetails} 
      ${ModelClinic.selected ? css.show : ''}`}
    >
      {/* === Full View === */}
      {full && ModelClinic.selected ? (
        <div className={css.detailsFull}>
          <Nav
            back={Path.BookStart}
            title='Clinic Details'
            clicked={() => fullSet(false)}
          />
          <div className={css.detailsFullWrap}>
            <ClinicInfo
              clinic={ModelClinic.selected}
              isFavorited={isFavorited}
              handleFavorite={handleFavorite}
            />
            <ClinicServices clinic={ModelClinic.selected} />
            <ClinicHours clinic={ModelClinic.selected} />
            {ModelBooking.primaryCare && (
              <ClinicInsurance clinic={ModelClinic.selected} />
            )}
          </div>
          <div className={css.buttons}>
            {ModelMap.favoriteSearch ? (
              <Button
                text={
                  isFavorited ? 'Remove from Favorites' : 'Add to Favorites'
                }
                clicked={() =>
                  ModelClinic.selected
                    ? handleFavorite(ModelClinic.selected)
                    : {}
                }
                primary={true}
              />
            ) : ModelBooking.primaryCare ? (
              <Button
                text='Patient Portal'
                clicked={clickPatientPortal}
                primary={true}
              />
            ) : (
              <ClinicSelectVisitTime clinic={ModelClinic.selected} />
            )}
          </div>
        </div>
      ) : null}
      {/* === Invisible veil the user can click to close the selected clinic card === */}
      <div
        className={ModelClinic.selected ? css.cardVeil : css.cardVeilHide}
        onClick={clickVeil}
      ></div>
      <div className={css.cardWrap}>
        <div className={css.card}>
          {ModelClinic.selected ? (
            <>
              {/* === Card View === */}
              <ClinicInfo
                clinic={ModelClinic.selected}
                isFavorited={isFavorited}
                handleFavorite={handleFavorite}
              />

              {/* === Buttons === */}
              <div className={css.buttons}>
                {ModelMap.favoriteSearch ? (
                  <Button
                    text={
                      isFavorited ? 'Remove from Favorites' : 'Add to Favorites'
                    }
                    clicked={() =>
                      ModelClinic.selected
                        ? handleFavorite(ModelClinic.selected)
                        : {}
                    }
                    primary={true}
                  />
                ) : (
                  !ModelBooking.primaryCare && (
                    <ClinicSelectVisitTime clinic={ModelClinic.selected} />
                  )
                )}
                <Button
                  text='View Details'
                  clicked={() => fullSet(true)}
                  secondary={true}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default observer(ClinicDetails)
