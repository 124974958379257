//:::::::::::::::::::::::::
import { PatientDetailBO } from 'src/BOs/PatientDetailBO'
import { PaymentSaleBodyBO } from 'src/BOs/PaymentSaleBodyBO'
import { PaymentSaleResponseBO } from 'src/BOs/PaymentSaleResponseBO'
import { TransactionDetailBO } from 'src/BOs/TransactionDetailBO'
import {
  HttpClient,
  PersonBalanceResponseBO,
  makeObservable,
  Types,
  PaymentDetailBO,
} from 'src/_Shared/global'
import accountStore from '../stores/account'
//:::::::::::::::::::::::::

class ModelPay {
  private static _instance: ModelPay
  //Properties
  userAgent: string = ''
  totalBalance: number = 0
  paymentDetails: PaymentDetailBO[] = []
  patientDetails: PatientDetailBO = {
    isSelfPay: false,
    isDependent: false,
    isAdvantageProgram: false,
  }
  // properties for payment in progress
  amount: number = 0
  memo: string = ''
  practiceId: string = ''
  nextGenId: string = ''

  constructor() {
    //Set props to true to make reactive
    makeObservable(this, {
      //Methods
      //show: action,

      //Enable properties
      userAgent: true,
      totalBalance: true,
      paymentDetails: true,
      setTotalBalance: true,
      setPaymentDetails: true,
      patientDetails: true,
      makeAPayment: true,
      amount: true,
      memo: true,
      setAmount: true,
      setMemo: true,
      practiceId: true,
      nextGenId: true,
      setPracticeId: true,
      setNextGenId: true,
    })
  }

  //Initialize our singleton
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  //==============================
  //#region Get Balance
  //==============================
  async getBalance() {
    //Grab NextGen user ID
    const { account, idToken } = accountStore.getState()
    if (!account) return

    try {
      //This doesn't work yet...
      const httpClient = new HttpClient<PersonBalanceResponseBO[], any>()
      const response = await httpClient.post('v2/nextgen/balance', null, {
        headers: {
          personid: account.nextGenPersonId ?? '',
          ...(idToken && { idtoken: idToken }),
        },
      })

      //Go through all practices to find total balance owed
      const clinics = response.data
      let paymentDetails: PaymentDetailBO[] = []
      let totalBalance = 0
      if (clinics) {
        for (const clinic of clinics) {
          let currentClinicBalance = 0

          for (const balance of clinic.balances) {
            currentClinicBalance = balance.patientBalance
            totalBalance += currentClinicBalance
          }
          paymentDetails.push({
            practiceId: clinic.practiceId,
            name: clinic.name,
            balance: currentClinicBalance,
          })
        }
      }

      this.setTotalBalance(totalBalance)
      this.setPaymentDetails(paymentDetails)
      const ready: Types.Due = {
        totalBalance,
        paymentDetails,
      }
      return ready
    } catch (error) {
      //--- ! ---
      console.log(error)
    }
  }

  //==============================
  //#region Setters
  //==============================
  setTotalBalance(value: number) {
    this.totalBalance = value
  }
  setPaymentDetails(value: PaymentDetailBO[]) {
    this.paymentDetails = value
  }
  setPatientDetails(key: keyof PaymentDetailBO, value: boolean | number) {
    this.patientDetails = { ...this.patientDetails, [key]: value }
  }
  setAmount(value: number) {
    this.amount = value
  }
  setMemo(value: string) {
    this.memo = value
  }
  setPracticeId(value: string) {
    this.practiceId = value
  }
  setNextGenId(value: string) {
    this.nextGenId = value
  }

  //==============================
  //#region Make a Payment
  //==============================
  async makeAPayment(
    body: PaymentSaleBodyBO,
    practiceId: string,
    patientId: string,
  ) {
    const state = accountStore.getState()
    const httpClient = new HttpClient<PaymentSaleResponseBO, any>()
    const response = await httpClient.post('v2/instamed/sale', body, {
      headers: {
        idtoken: state.idToken ?? '',
        practiceid: practiceId,
        patientid: patientId,
      },
    })
    return response
  }

  //==============================
  //#region Get Payment Receipt
  //==============================
  async getPaymentReceipt(transactionId: string) {
    const httpClient = new HttpClient<TransactionDetailBO, any>()
    const response = await httpClient.post('v2/instamed/receipt', '', {
      headers: {
        transid: transactionId,
      },
    })
    return response
  }
}

export default ModelPay.Instance
