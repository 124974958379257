//:::::::::::::::::::::::::
import css from './nav.module.css'
import Progress from './Progress'
import { Button, Icon } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function Nav(props: {
  title?: string
  progress?: number
  back?: string
  forward?: string
  children?: React.ReactNode
  clicked?: () => void
  hideBackButton?: boolean
}) {
  //Center content
  var content
  if (props.children) {
    content = props.children
  } else {
    content = <h5>{props.title}</h5>
  }

  return (
    <nav className={`${css.nav} ${props.progress ? css.navWithProgress : ''}`}>
      <div className={css.bar}>
        {/* === Back Button === */}
        {props.hideBackButton ? (
          <></>
        ) : (
          <div
            className={`${css.back} ${
              props.back ? css.visible : css.invisible
            }`}
          >
            <Button link={props.back} clicked={props.clicked}>
              <Icon name='back' width={16} />
            </Button>
          </div>
        )}

        {/* === Title or Custom Content === */}
        {content}

        {/* === Skip Button === */}

        <div
          className={`${css.skip} ${
            props.forward ? css.visible : css.invisible
          }`}
        >
          <Button link={props.forward}>Skip</Button>
        </div>
      </div>
      {props.progress && <Progress percentage={props.progress} />}
    </nav>
  )
}
