//:::::::::::::::::::::::::
import { AccountConstants } from 'src/_Shared/stores/account'
import css from '../paperwork.module.css'
import { FieldText, Types, Label, Selector, Select } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function PaperworkGuarantorAddress(props: {
  values: Types.Paperwork
  change: (values: Types.Paperwork) => void
}) {
  return (
    <div>
      {/* === Street Address === */}
      <FieldText
        value={
          props.values.responses[
            'GuarantorAddress_48e1f640-744f-11ed-bd09-51c5be6d07c3'
          ]
        }
        label='Street Address'
        error='Address is required'
        change={(value) =>
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              'GuarantorAddress_48e1f640-744f-11ed-bd09-51c5be6d07c3': value,
            },
          })
        }
      />

      {/* === City === */}
      <FieldText
        label='City'
        value={props.values.responses.GuarantorCity}
        error='City is required'
        change={(value) =>
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              GuarantorCity: value,
            },
          })
        }
      />

      {/* === Country === */}
      <div className={css.fieldWrap}>
        <Label text='Country' />
        <Selector
          items={['USA', 'Canada']}
          selected={props.values.responses.GuarantorCountry || ''}
          clicked={(value) =>
            props.change({
              ...props.values,
              responses: {
                ...props.values.responses,
                GuarantorCountry: value,
              },
            })
          }
        />
      </div>

      {/* === State === */}
      <div className={css.split}>
        <div className={css.wrap}>
          <Select
            label={
              props.values.responses.GuarantorCountry === 'USA'
                ? 'State'
                : 'Province'
            }
            value={props.values.responses.GuarantorState}
            items={
              props.values.responses.GuarantorCountry === 'USA'
                ? AccountConstants.STATES
                : AccountConstants.PROVINCES
            }
            change={(value) =>
              props.change({
                ...props.values,
                responses: {
                  ...props.values.responses,
                  GuarantorState: value,
                },
              })
            }
          />
        </div>

        {/* === ZIP === */}
        <FieldText
          value={props.values.responses.GuarantorZIP}
          label={
            props.values.responses.GuarantorCountry === 'USA'
              ? 'ZIP Code'
              : 'Postal Code'
          }
          error={
            props.values.responses.GuarantorCountry === 'USA'
              ? 'ZIP code is required'
              : 'Postal code is required'
          }
          change={(value) =>
            props.change({
              ...props.values,
              responses: {
                ...props.values.responses,
                GuarantorZIP: value,
              },
            })
          }
          mask={
            props.values.responses.GuarantorCountry === 'USA'
              ? '99999'
              : '*** ***'
          }
        />
      </div>
    </div>
  )
}
