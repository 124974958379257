//:::::::::::::::::::::::::
import css from './clinicPinDistance.module.css'
import { Icon } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function ClinicPinDistance(props: { distance: number }) {
  return (
    <div className={css.clinicPinDistance}>
      <span>
        <Icon name='locationPin' width={20} color='red' />
      </span>

      <small>{props.distance} mi</small>
    </div>
  )
}
