//:::::::::::::::::::::::::
import css from './home.module.css'
import { Icon, Button } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function HomeBigLink(props: {
  icon: string
  title: string
  subtitle: string
  link: string
}) {
  return (
    <section className={css.homeBigLink}>
      <Button link={props.link} title={props.title}>
        <span>
          <Icon name={props.icon} width={60} />
        </span>
        <h2>{props.title}</h2>
        <p>{props.subtitle}</p>
      </Button>
    </section>
  )
}
