//:::::::::::::::::::::::::
import LogOut from './LogOut/LogOut'
import SocialLinks from './SocialLinks/SocialLinks'
import css from './account.module.css'
import { List, ListLink, Path, Nav } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function Account() {
  return (
    <div className={css.account}>
      <Nav back={Path.Home} title='Account' />
      <div className='middle'>
        <List>
          <ListLink
            text='Personal Information'
            link={Path.PersonalInformation}
          />
          <ListLink text='Insurance' link={Path.Insurance} />
          <ListLink text='Dependents' link={Path.Dependents} />
          <ListLink text='Favorite Clinics' link={Path.FavoriteClinics} />
          <ListLink text='Legal' link={Path.Legal} />
          <ListLink
            text='Contact Us'
            icon='squarrow'
            href='https://nextcare.com/contact-us/'
          />
        </List>
      </div>
      <SocialLinks />
      <LogOut />
    </div>
  )
}
