//:::::::::::::::::::::::::
import { set } from 'store'
import css from '../paperwork.module.css'
import PaperworkWrap from '../PaperworkWrap'
import {
  Path,
  Nav,
  useState,
  ModelPaperwork,
  Button,
  useCallback,
  useNavigate,
  Selector,
  observer,
  PickList,
  Select,
  Divider,
  FieldText,
  FieldPhone,
  useEffect,
  ModelModal,
  ModelBooking,
  useSearchParams,
  BookingInformationBO,
  Helper,
  Types,
} from 'src/_Shared/global'
import accountStore from 'src/_Shared/stores/account'
import insuranceStore from 'src/_Shared/stores/insurance'
import moment from 'moment'
//:::::::::::::::::::::::::

function PaperworkEmployer() {
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const [isDrugScreen, setIsDrugScreen] = useState(false)
  const [isPhysical, setIsPhysical] = useState(false)
  const [isWorkInjury, setIsWorkInjury] = useState(false)
  const [requestReason, setRequestReason] = useState<string | null>(null)
  const fetchInsurances = insuranceStore.getState().fetchInsurances

  //==============================
  //#region Loading info from Booking
  //==============================

  useEffect(() => {
    //This function is used by the native apps since they can only send us query parameters to get booking and patient info
    async function getBookingAndPaperwork(bookingId: string) {
      ModelModal.showLoader('Please Wait', 'Getting patient info...')
      //Get booking info
      const booking = await ModelBooking.getBookingDetails(bookingId)
      if (!booking) return

      const booker = ModelBooking.getBooker()

      if (!booker) {
        //Set booker (used in PaperworkGuarantor screen)
        ModelBooking.setBooker({
          firstName: params.get('bookerFirstName') ?? '',
          lastName: params.get('bookerLastName') ?? '',
          dateOfBirth: params.get('bookerDateOfBirth') ?? '',
          phone: params.get('bookerPhone') ?? '',
          email: params.get('bookerEmail') ?? '',
          sex: params.get('bookerSex') ?? '',
          ethnicity: params.get('bookerEthnicity') ?? '',
          race: params.get('bookerRace') ?? '',
          address: params.get('bookerAddress') ?? '',
          apartment: params.get('bookerApartment') ?? '',
          city: params.get('bookerCity') ?? '',
          country: params.get('bookerCountry') ?? '',
          state: params.get('bookerState') ?? '',
          zip: params.get('bookerZip') ?? '',
        })
      }

      await fetchInsurances()
      console.info('*** Fetched insurances')

      //---
      let paperworkAccountValues = getPaperworkAccountValues(booking)

      //Get prior paperwork
      const priorPaperwork = await ModelPaperwork.getPriorPaperwork(bookingId)

      //---
      ModelPaperwork.setPaperwork({
        ...ModelPaperwork.paperwork,
        //Pull in the account info via the URL from the native apps
        account: {
          ...ModelPaperwork.paperwork.account,
          ...paperworkAccountValues,
        },
        //Some of these values need to check for existing ones in case they edit their paperwork values
        responses: {
          ...ModelPaperwork.paperwork.responses,
          ...priorPaperwork.responses,
        },
      })
      ModelModal.hide()
    }

    const bookingId = localStorage.getItem('bookingId')
    //Look up the patient info with the booking ID (the native apps use this)
    if (bookingId) getBookingAndPaperwork(bookingId)
  }, [params])

  const getPaperworkAccountValues = (
    booking: BookingInformationBO,
  ): Partial<Types.Account> => {
    const { account } = accountStore.getState()
    const booker = ModelBooking.getBooker()
    const values: Partial<Types.Account> = {
      firstName: booking.first_name,
      lastName: booking.last_name,
      dateOfBirth: Helper.convertDateOfBirthSimpleFromAPI(booking.birth_date),
      phone: Helper.convertPhoneFromAPI(booking.phone),
      email: booking.email ?? '',
      country: 'USA',
    }
    let patient: Types.Patient | undefined
    if (localStorage.getItem('patient')) {
      patient = JSON.parse(localStorage.getItem('patient')!) as Types.Patient
    }

    // Native: (no patient in localStorage. booker is set in getBookingAndPaperwork)
    if (!patient && booker) {
      const isDependent =
        moment(booking.birth_date).format('MM/DD/YYYY') !== booker.dateOfBirth
      return isDependent
        ? {
            ...values,
            city: booking.address_city,
            address: booking.address_street,
            apartment: booking.address_street_secondary,
            state: booking.address_state,
            zip: booking.address_zip,
            ...(booking.birth_sex ? { birthSex: booking.birth_sex } : {}),
          }
        : {
            ...values,
            ethnicity: booker.ethnicity,
            race: booker.race,
            city: booker.city,
            address: booker.address,
            apartment: booker.apartment,
            country: booker.country || 'USA',
            state: booker.state,
            zip: booker.zip,
            ...(booker.sex ? { birthSex: booker.sex } : {}),
          }
    }

    // Web: logged out (no account)
    if (!account && patient) {
      return {
        ...values,
        // so users don't have to set sex twice (also set on BookInfoForm)
        // Does not apply to appointment for dependent (since sex is not on BookPatientForm)
        ...(patient?.sex ? { birthSex: patient.sex } : {}),
      }
    }

    // Web: logged in
    if (patient?.nextGenId && account) {
      const patientIsDependent = patient.nextGenId !== account?.nextGenPersonId

      if (patientIsDependent) {
        const dependent = account.dependents?.find(
          (d) => d.nextGenPersonId === patient?.nextGenId,
        )
        return dependent
          ? {
              ...values,
              ethnicity: dependent.ethnicityDescription,
              race: dependent.raceDescription,
              address: dependent.address1,
              apartment: dependent.address2,
              city: dependent.city,
              state: dependent.state,
              zip: dependent.zipCode,
              birthSex: dependent.sex,
            }
          : values
      } else {
        return {
          ...values,
          ethnicity: account.ethnicityDescription,
          race: account.raceDescription,
          address: account.address1,
          apartment: account.address2,
          city: account.city,
          state: account.state,
          zip: account.zipCode,
          birthSex: account.sex,
        }
      }
    }
    return values
  }

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(() => {
    ModelPaperwork.setBackToSummary(false)
    // TODO: move this logic to the 4th step (right before Payments/Insurance)
    // if (ModelPaperwork.paperwork.workInjury) {
    //   //Skip insurance
    //   //>>>>>
    //   navigate(Path.Paperwork5)
    // } else {
    //   //>>>>>
    //   navigate(Path.Paperwork4)
    // }
    navigate(Path.PaperworkVirtual2)
  }, [navigate])

  const { responses, workInjury } = ModelPaperwork.paperwork
  const setResponseValue = (key: string, value: string) => {
    ModelPaperwork.setPaperwork({
      ...ModelPaperwork.paperwork,
      responses: {
        ...responses,
        [key]: value,
      },
    })
  }

  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav
        back={Path.BookFinish}
        title='Additional Paperwork'
        progress={1 / 7}
      />
      <div className={css.paperwork}>
        <div className={css.paperworkInner}>
          <h2>Are you here at the request of your employer?</h2>
          <div className={css.fieldWrapExtra}>
            <Selector
              buttonStyle
              items={['Yes', 'No']}
              selected={ModelPaperwork.paperwork.workInjury ? 'Yes' : 'No'}
              clicked={(value) => {
                ModelPaperwork.paperwork.workInjury = value === 'Yes'
                ModelPaperwork.setPaperwork(ModelPaperwork.paperwork)
              }}
            />
          </div>

          <div className={`${workInjury ? '' : css.invisible}`}>
            <div>
              <Divider />
              <h2>Employer Information</h2>
              {/* === Employer === */}
              <FieldText
                value={responses.employer}
                label='Employer'
                change={(value) => {
                  setResponseValue('employer', value)
                }}
              />

              {/* === Employer Contact === */}
              <FieldText
                value={responses.workInjuryEmployerContactName}
                label='Employer Contact Name'
                change={(value) => {
                  setResponseValue('workInjuryEmployerContactName', value)
                }}
              />

              {/* === Employer Contact Phone === */}
              <FieldPhone
                value={responses.workInjuryEmployerContactPhoneNumber}
                label='Employer Contact Phone Number'
                change={(value) => {
                  setResponseValue(
                    'workInjuryEmployerContactPhoneNumber',
                    value,
                  )
                }}
              />
            </div>

            <h2>Reason for Request</h2>
            <PickList
              items={[
                'Work Injury',
                'Physical',
                'Drug Screening / Alcohol Test',
              ]}
              selected={requestReason ?? ''}
              clicked={(value: string) => {
                setRequestReason(value)
                if (value === 'Work Injury') {
                  setIsWorkInjury(true)
                  setIsPhysical(false)
                  setIsDrugScreen(false)
                } else if (value === 'Physical') {
                  setIsWorkInjury(false)
                  setIsPhysical(true)
                  setIsDrugScreen(false)
                } else if (value === 'Drug Screening / Alcohol Test') {
                  setIsWorkInjury(false)
                  setIsPhysical(false)
                  setIsDrugScreen(true)
                }
              }}
            />
          </div>
          {isDrugScreen && (
            <>
              <h2>What type of drug screen and/or alcohol test?</h2>
              <Select
                items={[
                  'Pre-Employment',
                  'Post-Accident',
                  'Random',
                  'Reasonable Suspicion',
                  'Other',
                ]}
                value={workInjury ? 'Yes' : 'No'}
                change={(value: string) => {
                  setResponseValue('workInjury', value)
                }}
              />
            </>
          )}
          {isPhysical && (
            <div className={css.fieldWrapExtra}>
              <h2>What type of physical?</h2>
              <Select
                items={[
                  'DOT',
                  'Pre-Employment',
                  'Wellness',
                  'Fit for Duty',
                  'other',
                ]}
                value={responses.whatTypeOfPhysical}
                change={(value) => {
                  setResponseValue('whatTypeOfPhysical', value)
                }}
              />
            </div>
          )}
          {isWorkInjury && (
            <div>
              <Divider />
              <h2>Injury Details</h2>
              {/* === Employer === */}
              <FieldText
                value={responses.howDidYourInjuryOccur}
                label='How did your injury occur?'
                change={(value) => {
                  setResponseValue('howDidYourInjuryOccur', value)
                }}
              />
              <FieldText
                value={responses.whenDidYourInjuryOccur}
                label='When did your injury occur?'
                change={(value) => {
                  setResponseValue('whenDidYourInjuryOccur', value)
                }}
              />
              <FieldText
                value={responses.toWhomReportedYourInjury}
                label='To whom did you report your injury, if anyone?'
                change={(value) => {
                  setResponseValue('toWhomReportedYourInjury', value)
                }}
              />
            </div>
          )}
        </div>
      </div>
      {/* === Continue === */}
      <div className={css.buttonWrap}>
        <Button primary clicked={clickContinue} text={'Continue'} />
      </div>
    </PaperworkWrap>
  )
}

export default observer(PaperworkEmployer)
