//:::::::::::::::::::::::::
import css from './label.module.css'
//:::::::::::::::::::::::::

export default function Label(props: { text: string; big?: boolean }) {
  return (
    <label className={`${css.formLabel} ${props.big ? css.big : ''}`}>
      {props.text}
    </label>
  )
}
