//:::::::::::::::::::::::::
import css from './checkIn.module.css'
import {
  ModelClinic,
  ModelBooking,
  useEffect,
  useLocation,
  useNavigate,
  Helper,
  Input,
  Button,
  useState,
  useCallback,
  ModelModal,
  observer,
  moment,
  Path,
  FirebaseAnalytics,
  Types,
} from 'src/_Shared/global'
import ImageHero from 'src/_Shared/images/ImageHero'
import { useAccountStore } from 'src/_Shared/stores/account'
import { useBookingStore } from 'src/_Shared/stores/booking'
//:::::::::::::::::::::::::

function CheckIn() {
  const navigate = useNavigate()

  //==============================
  //#region State
  //==============================
  const [phone, setPhone] = useState('')
  const [readyPhone, setReadyPhone] = useState<boolean | undefined>()

  //==============================
  //#region Find Clinic
  //==============================
  // Test clinic IDs:  0PRWLp | 7gNmLp
  const location = useLocation()
  const account = useAccountStore((state) => state.account)
  const setIsOnDemand = useBookingStore((state) => state.setIsOnDemand)

  useEffect(() => {
    async function start(clinicId: string) {
      console.log('Clinic ID: ', clinicId)
      //Clear any booking or clinic stuff first
      ModelBooking.clear()

      //Find the clinic
      const clinic = await ModelClinic.getClinic(clinicId)
      //console.log(clinic)
      //%%%%% Analytics
      FirebaseAnalytics.trigger('QR - Appointment Lookup', {
        virtual_screen_name: 'QR - Appointment Lookup',
        clinic_name: clinic?.details.title,
        visit_type: 'UC',
      })

      if (clinic) {
        console.log(clinic)
        //Set the clinic details & get next available appointment
        await ModelClinic.setSelected(clinic)

        //Pull appointments for signed in user, if applicable
        if (account) {
          //For testing
          //const phone = '1112223333'
          //setPhone(phone)
          setPhone(account.phoneNumber ?? '')

          //Look up using phone and clinic ID
          const booking = await ModelBooking.find(phone, clinicId)
          if (booking) {
            //Set appointment
            const nextAvailable = moment(booking.appointment_date).toDate()
            ModelBooking.setAppointment(nextAvailable)

            //>>>>>
            navigate(Path.BookFinish)
          }
        }
        ModelModal.hide()
      }
    }

    //Get the last path of the URL to acquire the practiceId
    var id = ''
    if (location) {
      id = location.pathname.split('/').pop() ?? ''
    }

    //If not a 6-character clinic ID, send them to the home page
    if (id.length === 6) {
      //setClinicId(id)
      start(id)
    } else {
      navigate(Path.Home)
    }
  }, [navigate, location]) //Do NOT include phone here or it will keep firing the clinic loader over and over

  //==============================
  //#region Initial Settings
  //==============================
  useEffect(() => {
    // This is a walk-in so we set isOnDemand to true
    localStorage.setItem('visitType', 'WI')
    setIsOnDemand(true)
    // eslint-disable-next-line
  }, [])

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(async () => {
    //Look up their booking
    const booking = await ModelBooking.find(
      phone,
      ModelClinic.selected?.id ?? '',
    )

    //Prep the next available appointment in case there's no match, or if they click "Not Me" if there is a match.
    // const nextAvailable = moment(
    //   ModelBooking.earliestAppointmentDate,
    // ).toISOString()

    //ModelBooking.setAppointment(nextAvailable)

    if (booking) {
      //Booking found; prepare the message
      const time = Helper.convertAppointmentToday(booking.appointment_date)
      const message = `<b>${Helper.abbreviateName(
        booking.first_name,
        booking.last_name,
      )}. at ${time} today</b>. Let's get you checked in.`

      //Save booking summary for confirmation screen
      const summary: Types.BookingSummary = {
        firstName: booking.first_name,
        lastName: booking.last_name,
        appointment: booking.appointment_date,
        bookingId: booking.id,
        clinicName: ModelClinic.selected?.details.title ?? 'No Clinic Name',
        clinicId: ModelClinic.selected?.id ?? '',
        isWalkIn: booking.is_walk_in,
      }
      ModelBooking.setBookingSummary(summary)

      //Show modal
      ModelModal.showBookingFound(message)

      //%%%%% Analytics
      FirebaseAnalytics.trigger('QR - Found Your Appointment', {
        virtual_screen_name: 'QR - Found Your Appointment',
        clinic_name: ModelClinic.selected?.details.title,
        visit_type: 'WI',
      })
    } else {
      //No appointment found; check if there are any open slots for today
      if (!ModelClinic.selected?.id) return
      const clinicTimes = await ModelBooking.getTimeSlotsForClinic(
        ModelClinic.selected.id!,
      )

      if (
        clinicTimes?.timeSlots.length === 0 ||
        !clinicTimes?.nextAvailable ||
        clinicTimes?.nextAvailable.includes('Tomorrow')
      ) {
        //Show no appointments modal if no available slot, or if the next available is tomorrow
        ModelModal.showNoAppointments()
      } else {
        //Earliest available appointment
        ModelBooking.setAppointment(clinicTimes!.earliestAvailable)

        ModelModal.hide()

        //Go to Book Info flow with the phone they used
        //>>>>>
        navigate(`${Path.BookInfo}?phone=${phone}`)
      }
    }
  }, [phone, navigate])

  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.checkIn}>
      <ImageHero />
      <div className={css.checkInWrap}>
        <h1>Welcome to NextCare Urgent Care</h1>
        <h2>{ModelClinic.selected?.details.title ?? 'Loading...'}</h2>
        <h3>Let’s Get You Checked In</h3>

        {/* === Mobile Number === */}
        <Input
          type='text'
          value={phone}
          mask={'999-999-9999'}
          change={(event) => {
            setPhone(event.target.value)
            setReadyPhone(Helper.validatePhone(event.target.value))
          }}
          label='Mobile Number'
          error='Please enter a valid phone number'
          valid={readyPhone}
        />

        {/* === Continue Button === */}
        <div className={css.buttonWrap}>
          <Button
            text='Continue'
            disabled={!readyPhone}
            clicked={clickContinue}
            primary={true}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(CheckIn)
