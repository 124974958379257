//:::::::::::::::::::::::::
import css from './socialLinks.module.css'
import { Button, Icon } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function SocialLinks() {
  return (
    <div className={css.social}>
      <p>Connect with us!</p>
      <section>
        {/* === Facebook === */}
        <Button
          clicked={() => {
            window.open('https://www.facebook.com/NextCareUrgentCare/')
          }}
        >
          <Icon name='facebook' width={24} />
        </Button>
        {/* === Instagram === */}
        <Button
          clicked={() => {
            window.open('https://www.instagram.com/nextcare_urgentcare/')
          }}
        >
          <Icon name='instagram' width={24} />
        </Button>
        <Button
          clicked={() => {
            window.open('https://twitter.com/NextCare')
          }}
        >
          <Icon name='x' width={24} />
        </Button>
        <Button
          clicked={() => {
            window.open('https://www.linkedin.com/company/nextcare-urgent-care')
          }}
        >
          <Icon name='linkedin' width={24} />
        </Button>
      </section>
    </div>
  )
}
