//:::::::::::::::::::::::::
import { observer } from 'mobx-react-lite'
import { PracticeDetailsBO } from 'src/_Shared/global'
import css from './virtualCarePracticeInfo.module.css'
//:::::::::::::::::::::::::

function VirtualCarePracticeInfo({
  practice,
}: {
  practice: PracticeDetailsBO
}) {
  return (
    <div className={css.virtualCarePractice}>
      <h1>{practice.name}</h1>
      <h3>{practice.group_name}</h3>
    </div>
  )
}

export default observer(VirtualCarePracticeInfo)
