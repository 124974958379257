//:::::::::::::::::::::::::
import { OverlayViewF, OVERLAY_MOUSE_TARGET } from '@react-google-maps/api'
//:::::::::::::::::::::::::

export default function MarkerCentered(props: {
  latitude: number
  longitude: number
  children: React.ReactNode
  offset?: number
}) {
  return (
    <OverlayViewF
      position={{
        lat: props.latitude,
        lng: props.longitude,
      }}
      mapPaneName={OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width: number, height: number) => {
        return { x: -(width / 2), y: -(height / 2) - (props.offset || 34) }
      }}
    >
      {props.children}
    </OverlayViewF>
  )
}
