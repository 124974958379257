//:::::::::::::::::::::::::
import moment from 'moment'
import css from './notifications.module.css'
import { useEffect, useState } from 'react'
import {
  Icon,
  ModelModal,
  ModelNotifications,
  observer,
  useNavigate,
} from 'src/_Shared/global'
import noNotificationsBell from 'src/_Shared/images/no-notifications-bell.png'
import alertNotification from 'src/_Shared/images/alert-notification.svg'
import schedule from 'src/_Shared/images/Schedule.png'
import commonNotification from 'src/_Shared/images/common-notification.svg'
import NavEdit from 'src/_Shared/components/NavEdit/NavEdit'
import { useAuth0 } from '@auth0/auth0-react'
//:::::::::::::::::::::::::

function Notifications() {
  const navigate = useNavigate()
  const { user } = useAuth0()
  const [selectedIndex, setSelectedIndex] = useState<number>()

  useEffect(() => {
    const getNotifications = async () => {
      if (user && user.sub) await ModelNotifications.get(user.sub)
    }
    getNotifications()
  }, [user])

  const dismissClicked = async (notificationId: string) => {
    ModelModal.showLoader()
    if (!user?.sub) return
    try {
      await ModelNotifications.removeNotification(notificationId, user.sub)
      ModelModal.hide()
    } catch (e) {
      ModelModal.showError()
    }
  }

  const timezoneFunctionality = (response: any) => {
    let utcMoment = moment.utc()

    let utcDateOnly = utcMoment.format('YYYY-MM-DD')
    let utcHourOnly = utcMoment.format('HH')
    let utcHourMinutesOnly = utcMoment.format('HH:mm')

    const responseDate = response.slice(0, 10)
    const responseHour = response.slice(11, 13)
    const responseMinutes = response.slice(11, 16)
    const momentResponse = moment(response)

    let localTimeObject = moment.utc(response).local()
    let showLocalDate = localTimeObject.format('DD-MMM-YYYY')
    let localTime = localTimeObject.format('HH:mm:ss')

    const addMin = moment(momentResponse).add(5, 'minutes').format('HH:mm')

    const yesterday = moment(utcDateOnly)
      .subtract(1, 'day')
      .endOf('day')
      .format('YYYY-MM-DD')

    if (responseDate === utcDateOnly) {
      if (responseHour === utcHourOnly) {
        return (
          <span className={css.time}>
            {responseMinutes === utcHourMinutesOnly
              ? 'Few seconds ago'
              : utcHourMinutesOnly < addMin
              ? 'Few Minutes ago'
              : timeTo12HourFormat(localTime)}
          </span>
        )
      } else if (responseHour !== utcHourOnly) {
        return <span className={css.time}>{timeTo12HourFormat(localTime)}</span>
      }
    } else if (responseDate === yesterday) {
      return <span className={css.time}>Yesterday</span>
    } else {
      return <span className={css.time}>{`${showLocalDate}`}</span>
    }
  }

  const timeTo12HourFormat = (time: any) => {
    return moment(time, ['H:mm']).format('h:mm A')
  }

  return (
    <>
      <NavEdit
        leftBtn={{
          children: <Icon name='back' width={16} />,
          onClick: () => navigate(-1),
        }}
        title='Notifications'
      />
      <div className={css.notificationsBody}>
        {ModelNotifications.notifications?.length > 0 ? (
          ModelNotifications.notifications.map((data, index) => {
            var parsedMessage = JSON.parse(data.message)
            return (
              <div className={css.notificationsCard}>
                <div className={css.notificationsCardBody}>
                  <div>
                    {parsedMessage.type === 'ALERT' ? (
                      <img
                        className={css.notificationsIcon}
                        src={alertNotification}
                        alt='alert notification icon'
                      />
                    ) : parsedMessage.type === 'SCHEDULE' ? (
                      <img
                        className={css.notificationsIcon}
                        src={schedule}
                        alt='schedule notification icon'
                      />
                    ) : (
                      <img
                        className={css.notificationsIcon}
                        src={commonNotification}
                        alt='common notification icon'
                      />
                    )}
                    <div>
                      <h5 className={css.notificationsTitle}>
                        {parsedMessage.title}
                      </h5>
                      {timezoneFunctionality(parsedMessage.createDate)}
                    </div>
                  </div>
                  <div>
                    <p
                      className={`${css.notificationsMessage} ${
                        selectedIndex === index && css.showMore
                      }`}
                    >
                      {parsedMessage.message}
                    </p>
                  </div>
                </div>

                <div className={css.notificationsCardDismiss}>
                  {parsedMessage.message.length > 150 && (
                    <span
                      className={`${css.notificationsToggle} ${
                        selectedIndex === index ? css.down : ''
                      }`}
                      onClick={() => setSelectedIndex(index)}
                    />
                  )}
                  <span
                    role='button'
                    className={css.notificationsDismiss}
                    onClick={() => dismissClicked(data.ID)}
                  >
                    Dismiss
                  </span>
                </div>
              </div>
            )
          })
        ) : (
          <div className={css.nullState}>
            <img
              src={noNotificationsBell}
              alt=''
              className={css.nullStateIcon}
            />
            <p>No Notifications, yet!</p>
          </div>
        )}
      </div>
    </>
  )
}

export default observer(Notifications)
