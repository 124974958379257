//:::::::::::::::::::::::::
import { useAuth0 } from '@auth0/auth0-react'
import { Foundation, useEffect } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function AccountCreate() {
  const { loginWithRedirect } = useAuth0()
  const createAccountLink =
    `${Foundation.auth0BaseURL}/authorize` +
    `?client_id=${Foundation.auth0ClientId}` +
    `&response_type=code` +
    `&redirect_uri=${encodeURIComponent(Foundation.auth0Redirect)}` +
    `&scope=${encodeURIComponent(
      'authenticators offline_access openid profile email',
    )}` +
    `&loginSource=web` +
    `&loginType=create`

  console.info('createAccountLink', createAccountLink)

  // Function to generate a random state string
  function generateRandomState() {
    return Math.random().toString(36).substring(7)
  }

  const state = generateRandomState()

  const dynamicCreateAccountLink = `${createAccountLink}&state=${state}`

  //Redirect to Auth0 account creation
  useEffect(() => {
    window.location.href = dynamicCreateAccountLink
  }, [loginWithRedirect])

  return <></>
}
