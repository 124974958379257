//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import {
  DocumentList,
  useEffect,
  ModelClinic,
  useSearchParams,
  Icon,
  Input,
  Spinner,
  DocumentListItem,
  ModelPaperwork,
  observer,
  Types,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

function SummaryForms() {
  const [params] = useSearchParams()

  //==============================
  //#region Load Forms
  //==============================
  useEffect(() => {
    async function start(clinicId: string) {
      console.log('[Paperwork][SummaryForms][start()] clinicId:', clinicId)
      const docs = await ModelClinic.getReviewAndSignFormsForClinic(clinicId)
      console.log('[Paperwork][SummaryForms][start()] docs:', docs)

      //:::
      if (docs) {
        ModelPaperwork.setDocuments(docs)
      }

      ModelPaperwork.resetAcceptanceFormFields()
    }
    let clinicId
    //Grab clinic ID and set it
    const bookingSummaryRaw = localStorage.getItem('bookingSummary')
    let bookingSummary
    if (bookingSummaryRaw) {
      bookingSummary = JSON.parse(bookingSummaryRaw) as Types.BookingSummary
    }
    const clinic = localStorage.getItem('clinic')
    const selectedVirtual = ModelClinic.getSelectedVirtual()
    console.log('[Paperwork][SummaryForms] clinic:', clinic)
    if (clinic) {
      const clinic = JSON.parse(localStorage.getItem('clinic')!) as Types.Clinic
      clinicId = clinic.id
      localStorage.setItem('clinicId', clinic.id)
      console.log('[Paperwork][SummaryForms][Physical] clinic:', clinic)
    } else if (selectedVirtual) {
      clinicId = selectedVirtual.practiceDetails?.id
      console.log(
        '[Paperwork][SummaryForms][Virtual] selectedVirtual:',
        selectedVirtual,
      )
      console.log('[Paperwork][SummaryForms][Virtual] clinicId:', clinicId)
    }
    console.log('[Paperwork][SummaryForms] clinicId:', clinicId)
    if (!clinicId && bookingSummary?.clinicId) {
      clinicId = bookingSummary.clinicId
    }
    if (clinicId) {
      localStorage.setItem('clinicId', clinicId)
    }
    //Get clinic from paperwork query param
    if (clinicId) start(clinicId)
  }, [params])

  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.summaryForms}>
      <h2>Review and Accept Forms</h2>
      <p>
        Please review the attached documents and add your digital signature
        below. 100% confidential and secure. <Icon name='lockMini' width={14} />
      </p>

      {/* === Form List === */}
      {ModelPaperwork.documents.length === 0 ? (
        <div className={css.loading}>
          <Spinner size={30} />
        </div>
      ) : (
        <DocumentList>
          {ModelPaperwork.documents.map((document) => (
            <DocumentListItem
              id={document.id}
              text={document.text}
              subtext={document.subtext}
              url={document.url}
              accepted={document.accepted}
              toggle={() => {
                ModelPaperwork.updateDocument(document, !document.accepted)
              }}
            />
          ))}
          {/* === Hard-coded Legal Items === */}
          <DocumentListItem
            accepted={ModelPaperwork.termsOfUse}
            toggle={() =>
              ModelPaperwork.setTermsOfUse(!ModelPaperwork.termsOfUse)
            }
          >
            I have read, understand, and agree to NextCare’s{' '}
            <a
              href='https://nextcare.com/app-terms-of-use/'
              title='NextCare Terms of Use'
              target='_blank'
              rel='noreferrer'
            >
              Terms of Use
            </a>
          </DocumentListItem>
          <DocumentListItem
            accepted={ModelPaperwork.privacyPolicy}
            toggle={() =>
              ModelPaperwork.setPrivacyPolicy(!ModelPaperwork.privacyPolicy)
            }
          >
            I have read, understand, and agree to NextCare’s{' '}
            <a
              href='https://nextcare.com/app-privacy-policy/'
              title='NextCare Privacy Policy'
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy
            </a>
          </DocumentListItem>
        </DocumentList>
      )}

      {/* === Signature Field === */}
      <div className={css.fieldWrap}>
        <Input
          type='text'
          label='Tap to add signature'
          value={ModelPaperwork.signature}
          icon='signHere'
          error='Your signature is required'
          signature={true}
          change={(event) => {
            ModelPaperwork.setSignature(event.target.value)
          }}
        />
      </div>
    </div>
  )
}
export default observer(SummaryForms)
