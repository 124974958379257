//:::::::::::::::::::::::::
import {
  DocumentList,
  DocumentListItem,
  ModelVirtualCare,
  observer,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

function VirtualInfoLegal() {
  return (
    <DocumentList noBorders>
      {/* === Hard-coded Legal Items === */}
      <DocumentListItem
        accepted={ModelVirtualCare.agreeTerms}
        toggle={() => {
          ModelVirtualCare.setAgreeTerms(!ModelVirtualCare.agreeTerms)
        }}
      >
        I have read, understand, and agree to NextCare’s{' '}
        <a
          href='https://nextcare.com/app-terms-of-use/'
          title='NextCare Terms of Use'
          target='_blank'
          rel='noreferrer'
        >
          Terms of Use
        </a>
      </DocumentListItem>
    </DocumentList>
  )
}

export default observer(VirtualInfoLegal)
