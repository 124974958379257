import HttpClient from 'src/_API/HttpHelper'
import {
  CreateDependentResponseBO,
  Foundation,
  HttpStatus,
  ModelModal,
  Path,
} from '../../global'
import { Helper } from '../../Helper'
import type { Account, Dependent } from './store'
import Utils from './utils'
import { USA_ID, Canada_ID } from './constants'

const asValue = (value?: string | null) => (value?.length ? value : null)
const convertDependent = (dep: any) => ({
  address1: dep.address_1,
  address2: dep.address_2,
  city: dep.city,
  country: dep.country,
  countryId: dep.country_id,
  dateOfBirth: dep.dob,
  email: dep.email,
  ethnicityId: dep.ethnicities_id,
  ethnicityDescription: dep.ethnicities_description,
  firstName: dep.first_name,
  lastName: dep.last_name,
  nextGenPersonId: dep.nxgen_person_id,
  phoneNumber: dep.phonenumber,
  practiceId: dep.practiceId,
  raceDescription: dep.race_description,
  raceId: dep.race_id,
  sex: dep.sex ? Helper.convertBirthSexFromAPI(dep.sex) : '',
  state: dep.state,
  zipCode: dep.zip,
})

const API = {
  async fetchUser(
    idToken: string,
    accessToken: string,
    mergeWith?: any,
  ): Promise<Account | null> {
    if (!idToken || !accessToken) return null

    try {
      const httpClient = new HttpClient<any, any>()

      const response = await httpClient.post('v2/authn/user/profile', null, {
        headers: {
          idToken: idToken,
          useraccesstoken: accessToken,
        },
      })

      const dateOfBirth = asValue(response.data?.dob)
        ? Helper.convertDateOfBirthFromAPI(response.data?.dob)
        : null
      const sex = asValue(response.data?.sex)
        ? Helper.convertBirthSexFromAPI(response.data?.sex)
        : null

      const responseObj = {
        dateOfBirth,
        sex,
        firstName: asValue(response.data?.first_name),
        lastName: asValue(response.data?.last_name),
        email: asValue(response.data?.email_id),
        nextGenPersonId: asValue(response.data?.nxgen_person_id),
        auth0Id: asValue(response.data?.user_id),
        address1: asValue(response.data?.address_1),
        address2: asValue(response.data?.address_2),
        ethnicityId: asValue(response.data?.ethnicities_id),
        ethnicityDescription: asValue(response.data?.ethnicities_description),
        raceId: asValue(response.data?.race_id),
        raceDescription: asValue(response.data?.race_description),
        city: asValue(response.data?.city),
        state: asValue(response.data?.state),
        zipCode: asValue(response.data?.zip),
        phoneNumber: asValue(response.data?.phonenumber),
        practiceId: asValue(response.data?.practiceId),
        favoriteClinics: asValue(response?.data?.fav_clinics),
        dependents:
          response?.data?.dependants
            ?.map((dep: any) => convertDependent(dep))
            .filter((dep: any) => dep.nextGenPersonId?.length) ?? [],
        insurances: asValue(response?.data?.insurances),
        country: asValue(response?.data?.country),
        countryId: asValue(response?.data?.country_id),
        serverTime: asValue(response?.data?.server_time),
      } as { [key: string]: string | null }

      const combined = Object.keys(responseObj).reduce((acc, key) => {
        return {
          ...acc,
          [key]: responseObj[key]?.length ? responseObj[key] : mergeWith?.[key],
        }
      }, {} as { [key: string]: string })
      return combined as Account
    } catch (error) {
      console.error('*** Error getting user:', error)
      return null
    }
  },
  async updateUser(
    account: Account,
    idToken: string,
    isMinor = false,
    guardianpersonid?: string,
  ) {
    const updateBody = {
      userprofile: {
        user_id: account.auth0Id ?? '',
        email_id: account.email ?? '',
        nxgen_person_id: account.nextGenPersonId ?? '',
        first_name: account.firstName ?? '',
        last_name: account.lastName ?? '',
        sex: account.sex ? Helper.convertBirthSexToAPI(account.sex) : '',
        dob: account.dateOfBirth ?? '',
        address_1: account.address1 ?? '',
        address_2: account.address2 ?? '',
        city: account.city ?? '',
        state: account.state ?? '',
        zip: account.zipCode ?? '',
        phonenumber: account.phoneNumber ?? '',
        country: account.country ?? '',
        countryId: account.country === 'USA' ? USA_ID : Canada_ID,
        dependants: account.dependents ?? [],
        fav_clinics: account.favoriteClinics ?? [],
        insurances: account.insurances ?? [],
        EthnicityId: account.ethnicityId ?? '',
        ethnicities_description: Utils.getEthnicityNameById(
          account.ethnicityId ?? '',
        ),
        practiceId: account.practiceId ?? '',
        RaceId: account.raceId,
        race_description: Utils.getRaceNameById(account.raceId ?? ''),
      },
      isProfileUpdate: true,
      isClinicUpdate: false,
    }

    try {
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/nextgen/person/update',
        updateBody,
        {
          headers: {
            ...(isMinor && { isminor: isMinor }),
            ...(guardianpersonid && { guardianpersonid: guardianpersonid }),
            ...(idToken && { idtoken: idToken }),
          },
        },
      )

      console.log('response', response)

      return response
    } catch (error) {
      Helper.handleError('Update Account', error)
    }
  },
  async createUser(account: Account, idToken: string) {
    const values = {
      ...account,
      addressLine1: account.address1,
      addressLine2: account.address2,
      dateOfBirth: account.dateOfBirth?.length
        ? new Date(account.dateOfBirth).toISOString()
        : '',
      sex: account.sex ? Helper.convertBirthSexToAPI(account.sex) : '',
      zip: account.zipCode,
      emailAddress: account.email,
      cellPhone: account.phoneNumber?.replace(/[^0-9]/g, '') ?? '',
      RaceId: Utils.getRaceIdByName(account.raceDescription ?? ''),
      EthnicityId: account.ethnicityId,
      countryId: account.countryId,
      dependents: [],
    }

    const isMinor = !Helper.isAtLeast18YearsOld(account.dateOfBirth ?? '')

    try {
      const httpClient = new HttpClient<CreateDependentResponseBO, any>()

      const response = await httpClient.post(
        'v2/nextgen/person/create',
        values,
        {
          headers: {
            isminor: isMinor,
            auth0id: account?.auth0Id ?? '',
            idtoken: idToken,
          },
        },
      )

      if (response.message === 'Conflict') {
        //User already exists, tell them to sign in
        return false
      }

      return response.data
    } catch (error) {
      //--- ! ---
      console.error('[AccountStore] API.createUser - error:', error)
      Helper.handleError('createNextGenAccount', error)
      return false
    }
  },
  // TODO: move model modal up a level
  async resetPassword(email: string) {
    ModelModal.showLoader()
    try {
      const httpClient = new HttpClient<string, any>()
      const response = await httpClient.post(
        'v2/authn/user/changepassword',
        {
          'client-id': Foundation.auth0ClientId,
          email: email,
          connection: 'Username-Password-Authentication',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      ModelModal.hide()

      if (response.status === HttpStatus.Success) {
        //Success
        ModelModal.showSuccess(
          'Email Sent',
          'You should receive an email with instructions to reset your password.',
          'Go to Login',
          Path.LogIn,
        )
      } else {
        //Error
        ModelModal.showError(
          'Oops',
          'There was an unexpected error. Please try again.',
          'Okay',
        )
      }
    } catch (error) {
      //--- ! ---
      Helper.handleError('resetPassword', error)
    }
  },
  async getAuthPrefillData() {
    try {
      const httpClient = new HttpClient<string, any>()
      const response = await httpClient.post('v2/authn/user/prefill', null, {
        headers: {
          'Content-Type': 'application/json',
          useraccesstoken: localStorage.getItem('accessToken') || '',
          idtoken: localStorage.getItem('idToken') || '',
        },
      })
      console.log('response', response)
      return response.data
    } catch (error) {
      console.error('*** Error getting auth prefill data:', error)
    }
  },
  async updateAuthUserWithPersonId(
    auth0UserId: string,
    nextGenPersonId: string,
  ) {
    // ModelModal.showLoader('Please Wait', 'Syncing accounts...')
    try {
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/authn/user/linkpersonid',
        {},
        {
          headers: {
            userid: auth0UserId,
            personid: nextGenPersonId,
          },
        },
      )

      if (!response.data || response.data.length === 0) {
        //No user found
        return null
      }

      return response.data
    } catch (error) {
      Helper.handleError('updateAuthUserWithPersonId', error)
    }
  },
  async lookupNextGenAccount(
    values: {
      firstName: string
      lastName: string
      sex: string
      dateOfBirth: string
    },
    auth: {
      auth0Id: string
      idToken: string
    },
  ) {
    try {
      const { firstName, lastName, dateOfBirth } = values
      const { auth0Id, idToken } = auth
      const sex = values.sex.charAt(0)
      if (
        !firstName.length ||
        !lastName.length ||
        !dateOfBirth.length ||
        !sex.length ||
        !idToken.length ||
        !auth0Id.length
      )
        return null
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/nextgen/person/lookup',
        {
          firstName,
          lastName,
          sex,
          dateOfBirth,
        },
        {
          headers: {
            auth0id: auth0Id,
            idtoken: idToken,
          },
        },
      )

      if (!response.data || response.data.length === 0) {
        //No user found
        return null
      }

      //:::
      return response.data[0]
    } catch (error) {
      //--- ! ---
      Helper.handleError('createDependant', error)
    }
  },
  // TODO: move model modal up a level
  async createDependent(
    dependent: Dependent,
    auth0Id: string,
    idToken: string,
  ) {
    ModelModal.showLoader(undefined, 'Adding dependent...')

    //Check if 18 or over
    const isMinor = !Helper.isAtLeast18YearsOld(dependent.dateOfBirth ?? '')

    try {
      const httpClient = new HttpClient<any, any>()

      const response = await httpClient.post(
        'v2/nextgen/person/create',
        {
          EthnicityId: dependent.ethnicityId,
          RaceId: dependent.raceId,
          addressLine1: dependent.address1 ?? '',
          addressLine2: dependent.address2 ?? '',
          cellPhone: dependent.phoneNumber ?? '',
          city: dependent.city ?? '',
          countryId: dependent.countryId,
          dateOfBirth: dependent.dateOfBirth ?? '',
          emailAddress: dependent.email ?? '',
          firstName: dependent.firstName ?? '',
          lastName: dependent.lastName ?? '',
          sex: dependent.sex ? Helper.convertBirthSexToAPI(dependent.sex) : '',
          state: dependent.state ?? '',
          zip: dependent.zipCode ?? '',
        },
        {
          headers: {
            isminor: isMinor,
            auth0id: auth0Id,
            idtoken: idToken,
          },
        },
      )

      ModelModal.hide()
      return response
    } catch (error) {
      //--- ! ---
      ModelModal.hide()
      Helper.handleError('createDependant', error)
    }
  },
}

export default API
