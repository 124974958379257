//:::::::::::::::::::::::::
import css from './components.module.css'
import {
  Button,
  Checklist,
  DatePicker,
  Divider,
  DocumentList,
  Input,
  Label,
  Select,
  Switch,
  Textarea,
  ValidItem,
  useState,
  ModelModal,
  Selector,
  Step,
  PickList,
  DocumentListItem,
  Carousel,
} from 'src/_Shared/global'
import DownloadBanner from './DownloadBanner/DownloadBanner'
import Checkbox from './Forms/Checkbox/Checkbox'

//:::::::::::::::::::::::::

export default function Components() {
  //Dates for date picker
  var minDate = new Date()
  var maxDate = new Date()
  minDate.setDate(minDate.getDate() - 3)
  maxDate.setDate(maxDate.getDate() + 3)

  //Switch state
  const [toggle, toggleSet] = useState(true)

  //Selector state
  const [selected, selectedSet] = useState('Alpha')

  //Pick list state
  const [selectedPickList, selectedPickListSet] = useState('Alpha')

  //Document list state
  const [document1, document1Set] = useState(false)
  const [document2, document2Set] = useState(false)

  return (
    <div className={css.components}>
      <h1>Components</h1>
      {/* === Buttons === */}
      <h3>Buttons</h3>
      <Button text='Primary' primary={true} />
      <Button text='Secondary' secondary={true} />
      <Button text='Tertiary' tertiary={true} />
      <Button text='Quaternary' quaternary={true} />
      {/* === Checklist === */}
      <h3>Checklist</h3>
      <Checklist
        items={[
          'Alpha',
          'Bravo',
          'Charlie',
          'This is a really long one so I can test what happens when the text wraps to a new line.',
        ]}
      />
      {/* === DatePicker === */}
      <h3>Date Picker</h3>
      <DatePicker
        selectedDate={new Date()}
        minDate={minDate}
        maxDate={maxDate}
        change={(date) => {}}
      />
      {/* === Divider === */}
      <h3>Divider</h3>
      <Divider />
      {/* === Document List === */}
      <h3>Document List</h3>
      <DocumentList>
        <DocumentListItem
          text='Document A'
          url=''
          accepted={document1}
          toggle={() => document1Set(!document1)}
        />
        <DocumentListItem
          text='Document B'
          url=''
          accepted={document2}
          toggle={() => document2Set(!document2)}
        />
      </DocumentList>

      {/* === Download Banner === */}
      <h3>Download Banner</h3>
      <DownloadBanner force={true} />

      {/* === Forms === */}
      <h3>Forms</h3>
      <div className={css.form}>
        {/* === Labels === */}
        <Label text='This is a Big Label' big={true} />
        <Label text='This is a Regular Label' />
        {/* === Inputs === */}
        <Input
          type='text'
          value=''
          label='This is an Input'
          valid={false}
          error='Error message goes here.'
        />
        <Input
          type='text'
          value=''
          label='This is Sticky Label'
          stickyLabel={true}
        />
        {/* === Select === */}
        <Select
          label='Select List'
          items={[
            'Alpha',
            'Bravo',
            'Charlie',
            'This is a really long one so I can test what happens when the text wraps to a new line.',
          ]}
          value=''
        />
        {/* === Switch === */}
        <Switch
          label='Toggle Switch'
          on={toggle}
          changed={(value) => toggleSet(value)}
        />
        {/* === Textarea === */}
        <br />

        <Label text='Textarea Field' />
        <Textarea placeholder='Text body...' />

        {/* === Textarea === */}
        <br />
        <Checkbox onClick={(e) => console.log(e)} label='Checkbox' />

        {/* === Password requirements === */}
        <br />
        <Label text='Requirements List' />
        <ValidItem text='Requirement A' valid={true} />
        <ValidItem text='Requirement B' valid={true} />
        <ValidItem text='Requirement C' valid={false} />

        {/* === Modals === */}
        <h3>Modals</h3>
        <div className={css.flow}>
          <Button
            text='Loading'
            tertiary={true}
            clicked={() =>
              ModelModal.showLoader(undefined, 'Creating account...')
            }
          />
          <Button
            text='Success'
            tertiary={true}
            clicked={() => ModelModal.showSuccess('Success', 'Message here...')}
          />
          <Button
            text='Error'
            tertiary={true}
            clicked={() => ModelModal.showError()}
          />
          <Button
            text='Timer'
            tertiary={true}
            clicked={() => ModelModal.showTime()}
          />
          <Button
            text='Cancel Booking'
            tertiary={true}
            clicked={() => ModelModal.showCancelBooking()}
          />
        </div>

        {/* === Selector === */}
        <h3>Selector</h3>
        <Selector
          items={['Alpha', 'Bravo', 'Charlie']}
          selected={selected}
          clicked={(value) => selectedSet(value)}
          itemWidth={80}
        />
        {/* === Step === */}
        <h3>Step</h3>
        <div className={css.stack}>
          <Step number='1' title='Step 1' text='Text goes here.' />
          <Step number='2' title='Step 2' text='Text goes here.' />
          <Step number='3' title='Step 3' text='Text goes here.' />
        </div>

        {/* === Pick List === */}
        <PickList
          items={['Alpha', 'Bravo', 'Charlie']}
          selected={selectedPickList}
          clicked={(value) => selectedPickListSet(value)}
        />

        {/* === Carousel === */}
        <Carousel
          slides={[<h1>One</h1>, <h1>Two</h1>, <h1>Three</h1>]}
        ></Carousel>
      </div>
    </div>
  )
}
