//:::::::::::::::::::::::::
import css from '../modal.module.css'
import {
  Icon,
  ModelModal,
  Button,
  useNavigate,
  Path,
  useCallback,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function ModalNoAppointments() {
  const navigate = useNavigate()

  //==============================
  //#region Continue
  //==============================
  const clickThanks = useCallback(() => {
    ModelModal.hide()
    navigate(Path.Home)
  }, [navigate])

  return (
    <div className={css.modalBookingFound}>
      <Icon name='medicalCalendarMultipleDays' width={92} />
      <h5>No Time Slots Available Today</h5>
      <p>See the front desk and we will do whatever we can to help you.</p>

      <Button text='Thanks' clicked={clickThanks} primary={true} />
    </div>
  )
}
