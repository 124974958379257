//:::::::::::::::::::::::::
import {
  ModelBooking,
  ModelPaperwork,
  ModelVirtualCare,
  Path,
  useEffect,
} from 'src/_Shared/global'
import HomeBigLink from './HomeBigLink'
import HomeLink from './HomeLink'
import css from './home.module.css'
import ImageHero from 'src/_Shared/images/ImageHero'

import HomeAccount from './HomeAccount'
import PrimaryNav from 'src/_Shared/components/PrimaryNav/PrimaryNav'
import UpcomingVisits from './UpcomingVisits'
//:::::::::::::::::::::::::

export default function Home() {
  useEffect(() => {
    ModelVirtualCare.clear()
    localStorage.removeItem('virtualBookingId')
    ModelBooking.clear()
    ModelPaperwork.clear()
  }, [])
  return (
    <>
      <div className={css.home}>
        <PrimaryNav />
        <ImageHero />
        <div className={css.homeWrap}>
          <UpcomingVisits />
          <h1>How can we help you today?</h1>
          <ul>
            {/* === Book Urgent Care === */}
            <li>
              <HomeBigLink
                icon='bookUrgent'
                title='Book an Urgent Care Visit'
                subtitle='Find a location and book a visit'
                link={Path.BookStart}
              />
            </li>
            {/* === Book Virtual Care === */}
            <li>
              <HomeBigLink
                icon='bookVirtual'
                title='Book a Virtual Care Visit'
                subtitle='See a provider virtually'
                link={Path.VirtualCareLocation}
              />
            </li>

            {/* === View Records (External) === */}
            <li>
              <HomeLink
                icon='viewRecords'
                title='View Medical Records'
                subtitle='Sign up to access medical results and visit summary'
                href='https://nextcarepatientportal.azurewebsites.net/'
              />
            </li>
            {/* === Pay Bill === */}
            <li>
              <HomeLink
                icon='payBill'
                title='Pay Your Bills'
                subtitle='Sign up to manage bills and payment history'
                link='/payment/make-a-payment'
              />
            </li>
            {/* === Book Primary === */}
            <li>
              <HomeLink
                icon='bookPrimary'
                title='Book Primary Care Visits'
                subtitle='Sign up to schedule annual visits with ease'
                link={`${Path.BookStart}?primaryCare=true`}
              />
            </li>
            {/* === Discover Services === */}
            <li>
              <HomeLink
                icon='discoverServices'
                title='Discover More Services'
                subtitle='Find out how we can help you'
                href='https://nextcare.com/what-we-treat'
              />
            </li>
            {/* === Account === */}
            <HomeAccount />
          </ul>
        </div>
      </div>
    </>
  )
}
