//:::::::::::::::::::::::::
import { SummaryItem, ModelPaperwork, observer } from 'src/_Shared/global'
//:::::::::::::::::::::::::

function SummaryInfoRelease() {
  return (
    <>
      <SummaryItem
        name='Name'
        value={
          ModelPaperwork.paperwork.authorizedIndividual1
            ? ModelPaperwork.paperwork.authorizedIndividual1
            : '-'
        }
      />
      {ModelPaperwork.paperwork.authorizedIndividual2 && (
        <SummaryItem
          name='Name'
          value={ModelPaperwork.paperwork.authorizedIndividual2}
        />
      )}
      {ModelPaperwork.paperwork.authorizedIndividual3 && (
        <SummaryItem
          name='Name'
          value={ModelPaperwork.paperwork.authorizedIndividual3}
        />
      )}
    </>
  )
}

export default observer(SummaryInfoRelease)
