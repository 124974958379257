//:::::::::::::::::::::::::
import css from './marker.module.css'
import { Types, Icon, ModelClinic, Button, observer } from 'src/_Shared/global'
//:::::::::::::::::::::::::

function Marker(props: {
  clinic: Types.Clinic
  clicked: (clinic: Types.Clinic) => void
}) {
  return (
    <div className={css.marker}>
      <Button clicked={() => props.clicked(props.clinic)}>
        <Icon
          name='locationPin'
          width={44}
          color={props.clinic.id === ModelClinic.selected?.id ? 'main' : 'red'}
        />
      </Button>
    </div>
  )
}

export default observer(Marker)
