//:::::::::::::::::::::::::
import { Modal, ModelClinic, useEffect } from 'src/_Shared/global'
import { Route, Routes, useLocation } from 'react-router-dom'
// import { Auth0Provider } from '@auth0/auth0-react'
import AuthProvider from 'src/Auth/AuthProvider'
import AppRouter from '../_AppRouter'
import DownloadBanner from '../_Shared/components/DownloadBanner/DownloadBanner'
import 'src/_Shared/styles/global.css'
import css from './start.module.css'
//:::::::::::::::::::::::::

function ClinicData() {
  useEffect(() => {
    // We need all clinics to start
    const getClinics = async () => {
      if (!ModelClinic.clinics.length) await ModelClinic.getAllClinics()
      const virtualClinics = ModelClinic.clinics.filter((clinic) => {
        const title = clinic.details.title
        const result = title.match(/Virtual/)
        return !!result
      })
      localStorage.setItem('clinics', JSON.stringify(ModelClinic.clinics))
      console.info('[ClinicData] virtualClinics:', virtualClinics)
    }
    getClinics()
  }, [])
  return <></>
}

export default function Start() {
  const { pathname } = useLocation()

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      {/* === Auth Setup === */}
      <AuthProvider>
        {/* === Global Elements === */}
        <Modal />
        <ClinicData />
        {/* === App Routes === */}
        <div className={css.app}>
          {/* === Banner to nag them to download the app === */}
          <DownloadBanner />
          {/* ===  === */}
          <Routes>
            <Route path='*' element={<AppRouter />} />
          </Routes>
        </div>
      </AuthProvider>
    </>
  )
}
