//:::::::::::::::::::::::::
import { SummaryItem, ModelPaperwork } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function SummaryGuarantorInfo() {
  return (
    <>
      <SummaryItem
        name='First Name'
        value={ModelPaperwork.paperwork.responses.GuarantorFirstName}
      />
      <SummaryItem
        name='Last Name'
        value={ModelPaperwork.paperwork.responses.GuarantorLastName}
      />
      <SummaryItem
        name='Date of Birth'
        value={
          ModelPaperwork.paperwork.responses[
            'GuarantorDateofBirth_2dc46f00-744f-11ed-bd09-51c5be6d07c3'
          ]
        }
      />
      <SummaryItem
        name='Email Address'
        value={
          ModelPaperwork.paperwork.responses[
            'GuarantorEmailAddress_96866ff0-ac65-11ec-bd28-a3d3bf59450f'
          ]
        }
      />
      <SummaryItem
        name='Mobile Number'
        value={
          ModelPaperwork.paperwork.responses[
            'GuarantorPhone_3cfac820-744f-11ed-bd09-51c5be6d07c3'
          ]
        }
      />
      <SummaryItem
        name='Relation to Patient'
        value={
          ModelPaperwork.paperwork.responses[
            'GuarantorRelationship_883a1660-744f-11ed-bd09-51c5be6d07c3'
          ]
        }
      />
    </>
  )
}
