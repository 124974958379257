//:::::::::::::::::::::::::
import { Input, useState, Helper } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function FieldDateOfBirth(props: {
  // value must be passed in as MM/DD/YYYY
  value: string
  label?: string
  mustBe18?: boolean
  change: (value: string) => void
}) {
  //==============================
  //#region State
  //==============================
  const [readyDateOfBirth, readyDateOfBirthSet] = useState<
    boolean | undefined
  >()

  return (
    <Input
      type='text'
      value={props.value}
      label={props.label || 'Date of Birth'}
      mask={'99/99/9999'}
      change={(event) => {
        props.change(event.target.value)
        readyDateOfBirthSet(
          Helper.isAValidBirthDate(event.target.value) &&
            props.mustBe18 &&
            Helper.isAtLeast18YearsOld(event.target.value),
        )
      }}
      error={
        props.mustBe18
          ? 'Please enter a date of birth (18 or older)'
          : 'Please enter a date of birth'
      }
      valid={readyDateOfBirth}
    />
  )
}
