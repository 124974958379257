//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import {
  FieldText,
  FieldDateOfBirth,
  FieldPhone,
  FieldEmail,
  Types,
  Select,
  observer,
  FieldSex,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::
import { AccountUtils } from 'src/_Shared/stores/account'

function PaperworkPatientInfo(props: {
  values: Types.Paperwork
  change: (values: Types.Paperwork) => void
}) {
  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.paperworkPatientInfo}>
      {/* === Patient First Name === */}
      <FieldText
        value={props.values.account.firstName}
        label='Patient First Name'
        error='First name is required'
        change={(value) => {
          props.change({
            ...props.values,
            account: {
              ...props.values.account,
              firstName: value,
            },
          })
        }}
      />
      {/* === Patient Last Name === */}
      <FieldText
        value={props.values.account.lastName}
        label='Patient Last Name'
        error='Last name is required'
        change={(value) => {
          props.change({
            ...props.values,
            account: {
              ...props.values.account,
              lastName: value,
            },
          })
        }}
      />
      {/* === Date of Birth === */}
      <FieldDateOfBirth
        value={props.values.account.dateOfBirth}
        change={(value) =>
          props.change({
            ...props.values,
            account: {
              ...props.values.account,
              dateOfBirth: value,
            },
          })
        }
      />
      {/* === Mobile Number === */}
      <FieldPhone
        value={props.values.account.phone}
        change={(value) =>
          props.change({
            ...props.values,
            account: {
              ...props.values.account,
              phone: value,
            },
          })
        }
      />
      {/* === Email Address === */}
      <FieldEmail
        value={props.values.account.email}
        change={(value) =>
          props.change({
            ...props.values,
            account: {
              ...props.values.account,
              email: value,
            },
          })
        }
      />

      {/* === Birth Sex === */}
      <div className={css.fixedField}>
        <FieldSex
          value={props.values.account.birthSex}
          change={(value) =>
            props.change({
              ...props.values,
              account: {
                ...props.values.account,
                birthSex: value,
              },
            })
          }
        />
      </div>
      {/* === Ethnicity === */}
      <div className={css.fixedField}>
        <Select
          label='Ethnicity'
          value={props.values.account.ethnicity}
          items={AccountUtils.getEthnicityNames()}
          change={(value) =>
            props.change({
              ...props.values,
              account: {
                ...props.values.account,
                ethnicity: value,
              },
            })
          }
        />
      </div>
      {/* === Race === */}
      <div className={css.fixedField}>
        <Select
          label='Race'
          value={props.values.account.race}
          items={AccountUtils.getRaceNames()}
          change={(value) =>
            props.change({
              ...props.values,
              account: {
                ...props.values.account,
                race: value,
              },
            })
          }
        />
      </div>
    </div>
  )
}

export default observer(PaperworkPatientInfo)
