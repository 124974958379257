//Required Format (YYYY/MM/DD)

import moment from 'moment'
import dateFollowedText from './dateFollowedText'

const dateToTextConvertor = (date: string, time?: string) => {
  /** This array consists data in this format
   * Monday (Today/Tomorrow), Month, Date, Year
   */
  const dayResult = []

  const today = moment().endOf('day').format('YYYY/MM/DD')
  const tomorrow = moment().add(1, 'day').endOf('day').format('YYYY/MM/DD')
  dayResult.push(moment(date, 'YYYY/MM/DD').format('dddd'))
  dayResult.push(moment(date, 'YYYY/MM/DD').format('MMMM'))
  dayResult.push(moment(date, 'YYYY/MM/DD').format('D'))

  if (time != undefined) {
    dayResult.push(moment(time, 'h:mm').format('h:mm a'))
  } else {
    dayResult.push(' ')
  }
  dayResult.push(moment(date, 'YYYY/MM/DD').format('YYYY'))
  return dayResult
}

export default dateToTextConvertor

export const displayableDateConvertor = (
  appointment_date: string,
  time?: string,
  serverTime?: string,
) => {
  //debugger;
  /** This array consists data in this format
   * Monday (Today/Tomorrow), Month, Date, Year
   */
  let displayable = {
    day: '',
    month: '',
    day_date: '',
    date_followed_text: '',
    year: '',
    time: '',
  }
  const date = moment(appointment_date).format('YYYY/MM/DD')
  const today = moment(serverTime).endOf('day').format('YYYY/MM/DD')
  const tomorrow = moment(today).add(1, 'day').endOf('day').format('YYYY/MM/DD')
  displayable.day =
    today === date
      ? 'Today'
      : tomorrow === date
        ? 'Tomorrow'
        : moment(date, 'YYYY/MM/DD').format('dddd')
  displayable.month = moment(date, 'YYYY/MM/DD').format('MMMM')
  displayable.day_date = moment(date, 'YYYY/MM/DD').format('D')
  displayable.date_followed_text = dateFollowedText(displayable.day_date)
  displayable.year = moment(date, 'YYYY/MM/DD').format('YYYY')
  displayable.time = time ? moment(time, 'h:mm').format('h:mm A') : ''

  return displayable
}
