//:::::::::::::::::::::::::
import { observer } from 'mobx-react-lite'
import css from './bookTime.module.css'
import { useState, moment, Button, Helper } from 'src/_Shared/global'
//:::::::::::::::::::::::::

function BookTimeSlots(props: {
  slots: string[]
  selected?: string
  clicked: (value: string) => void
}) {
  const [showMore, setShowMore] = useState(false)

  //Nothing yet
  if (props.slots.length === 0) {
    return (
      <div className='none-available'>
        <h2>No Slots Available</h2>
        <p>
          All online booking slots are full. You can still walk in to the
          clinic.
        </p>
      </div>
    )
  }

  return (
    <>
      <ul className={css.timeSlots}>
        {props.slots
          .slice(0, showMore ? props.slots.length : 10)
          .map((slot: string) => {
            return (
              <li
                className={`${props.selected === Helper.convertDateObjectToSlotFormat(new Date(slot)) ? css.selected : ''}`}
                key={slot}
              >
                <Button clicked={() => props.clicked(slot)}>
                  <span>{moment(slot).format('h:mma')}</span>
                </Button>
              </li>
            )
          })}
      </ul>
      {/* === More Times === */}
      <div className={css.moreTimes}>
        <Button clicked={() => setShowMore(!showMore)}>
          {showMore ? 'Fewer times' : 'More times'}
        </Button>
      </div>
    </>
  )
}

export default observer(BookTimeSlots)
