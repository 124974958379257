//:::::::::::::::::::::::::
import { Label, Selector, Types } from 'src/_Shared/global'
import css from './fields.module.css'
import { sexOptions } from 'src/_Shared/stores/account/constants'
//:::::::::::::::::::::::::

export default function FieldSex(props: {
  value: string
  label?: string
  change: (value: Types.Sex) => void
}) {
  return (
    <div className={css.fieldSexWrap}>
      <Label text={props.label || 'Birth Sex'} />
      <Selector
        items={sexOptions}
        selected={props.value}
        clicked={(value) => props.change(value as Types.Sex)}
      />
    </div>
  )
}
