//:::::::::::::::::::::::::
import { Icon } from 'src/_Shared/global'
import css from './clinicWalkins.module.css'
//:::::::::::::::::::::::::

export default function ClinicWalkins() {
  return (
    <div className={css.walkIns}>
      <span>
        <Icon name='walkIns' width={9} />
      </span>
      <div>
        <h6>Walk-ins Available </h6>
        <p>Wait times may be longer than scheduled appointments.</p>
      </div>
    </div>
  )
}
