import { ETHNICITIES, RACES } from './constants'

const Utils = {
  getEthnicityNames(): any {
    return ETHNICITIES.map(({ name }) => name)
  },
  getRaceNames(): any {
    return RACES.map(({ name }) => name)
  },
  getEthnicityNameById(id: string): any {
    const ethnicity = ETHNICITIES.find((race) => race.id === id)
    return ethnicity?.name
  },
  getRaceNameById(id: string) {
    const race = RACES.find((race) => race.id === id)
    return race?.name
  },
  getEthnicityIdByName(name: string): any {
    const ethnicity = ETHNICITIES.find((race) => race.name === name)
    return ethnicity?.id
  },
  getRaceIdByName(name: string): any {
    const race = RACES.find((race) => race.name === name)
    return race?.id
  },
}

export default Utils
