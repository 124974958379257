//:::::::::::::::::::::::::
import { Icon, ModelModal, Button } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function ModalError(props: { clicked: () => void }) {
  return (
    <>
      <Icon name='modalError' width={80} />
      <h5>{ModelModal.title}</h5>
      <p>{ModelModal.message}</p>
      <Button text={ModelModal.button} clicked={props.clicked} primary={true} />
    </>
  )
}
