//:::::::::::::::::::::::::
import {
  Nav,
  Path,
  Icon,
  Select,
  useEffect,
  Types,
  useState,
  Helper,
  observer,
  ModelVirtualCare,
  moment,
  Button,
  useCallback,
  useNavigate,
  ModelBooking,
} from 'src/_Shared/global'
import css from './virtualInfo.module.css'
import VirtualPatientInfo from './VirtualPatientInfo'
import { useAccountStore } from 'src/_Shared/stores/account'
import VirtualInfoPayment from './VirtualInfoPayment'
import VirtualInfoEmployee from './VirtualInfoEmployee'
import VirtualInfoLegal from './VirtualInfoLegal'
//:::::::::::::::::::::::::

function VirtualInfo() {
  const navigate = useNavigate()
  const [account] = useAccountStore((state) => [state.account])
  const [patients, patientsSet] = useState<Types.Patient[]>([])
  const [patientName, setPatientName] = useState('')

  const [appointment, setAppointment] = useState('Unavailable')
  useEffect(() => {
    const appt = ModelBooking.getAppointment()
    if (appt) {
      const ready = Helper.convertDateToDayAndTimeFull(moment(appt).toDate())
      setAppointment(ready)
    }
  }, [])

  useEffect(() => {
    var patientsReady: Types.Patient[] = []

    //:: 1 :: Set account holder as first person in the patient list
    const bookerPatient: Types.Patient = {
      firstName: account?.firstName ?? '',
      lastName: account?.lastName ?? '',
      dateOfBirth: account?.dateOfBirth ?? '',
      phone: account?.phoneNumber ?? '',
      email: account?.email ?? '',
      sex: account?.sex,
      nextGenId: account?.nextGenPersonId,
      ethnicity: account?.ethnicityDescription,
      race: account?.raceDescription,
      address: account?.address1,
      apartment: account?.address2,
      city: account?.city,
      country: account?.country,
      state: account?.state,
      zip: account?.zipCode,
    }
    //Persist for submitting booking
    localStorage.setItem('virtualBooker', JSON.stringify(bookerPatient))

    patientsReady.push(bookerPatient)

    //:: 2 :: Add dependents to the patient list
    for (const dependent of account?.dependents ?? []) {
      //Convert Dependent object to patient
      const patient: Types.Patient = {
        firstName: dependent.firstName ?? '',
        lastName: dependent.lastName ?? '',
        dateOfBirth: Helper.convertDateOfBirthFromAPI(
          dependent.dateOfBirth ?? '',
        ),
        sex: dependent.sex,
        phone: Helper.convertPhoneFromAPI(dependent?.phoneNumber ?? ''),
        email: dependent?.email ?? '',
        nextGenId: dependent.nextGenPersonId ?? '',
        ethnicity: dependent?.ethnicityDescription,
        race: dependent?.raceDescription,
        address: dependent?.address1,
        apartment: dependent?.address2,
        city: dependent?.city,
        country: dependent?.country,
        state: dependent?.state,
        zip: dependent?.zipCode,
      }
      //---
      patientsReady.push(patient)
    }
    //---
    patientsSet(patientsReady)
  }, [account])

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(() => {
    if (ModelVirtualCare.payInsurance) {
      navigate(Path.VirtualCareInsurance)
    } else {
      navigate(Path.VirtualCareSelfPay)
    }
  }, [navigate])

  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.virtualCareInfo}>
      <Nav title='Booking Information' back={Path.VirtualCareTime} />
      {/* === Appointment Info === */}
      <div className={css.info}>
        <Icon name='medicalCalendar' width={92} />
        <div>
          <p>Estimated Appointment Time</p>
          <h3>{appointment}</h3>
        </div>
      </div>
      <div className={css.formWrap}>
        {/* === Who it's for === */}
        <Select
          label='Who is this visit for?'
          value={patientName}
          items={patients.map((patient) =>
            ModelBooking.getPatientName(patient),
          )}
          change={(value) => {
            //Select patient name
            setPatientName(value)
            //Select patient object
            const selectedPatient = patients.find(
              (patient) => ModelBooking.getPatientName(patient) === value,
            )

            if (selectedPatient) {
              ModelVirtualCare.setPatient(selectedPatient)
            }
          }}
        />
        {/* === Account Edit Notice === */}
        <p className={css.notice}>
          To add or edit patient information, please go to your user profile.
        </p>
        {/* === Form === */}
        {ModelVirtualCare.patient && (
          <VirtualPatientInfo
            values={ModelVirtualCare.patient}
            change={(values) => {
              ModelVirtualCare.setPatient(values)
            }}
          />
        )}

        {/* === NextCare Employee Check === */}
        <VirtualInfoEmployee />

        {/* === Payment Option === */}
        <VirtualInfoPayment />

        {/* === Terms of Service === */}
        <div className={css.legalWrap}>
          <VirtualInfoLegal />
        </div>

        {/* === Continue Button === */}
        <Button
          text='Continue'
          clicked={clickContinue}
          primary
          disabled={
            ModelVirtualCare.patient === undefined ||
            !ModelVirtualCare.agreeTerms
          }
        />
      </div>
    </div>
  )
}

export default observer(VirtualInfo)
