//:::::::::::::::::::::::::
import {
  Button,
  FieldText,
  GetPracticeByZipResponseBO,
  Icon,
  ModelModal,
  Nav,
  Path,
  Select,
  Selector,
  useEffect,
  useMemo,
  useNavigate,
  useState,
} from 'src/_Shared/global'
import css from './virtualLocation.module.css'
import ModelClinic from 'src/_Shared/models/ModelClinic'
import { useAuth0 } from '@auth0/auth0-react'
import { AccountConstants, useAccountStore } from 'src/_Shared/stores/account'
//:::::::::::::::::::::::::

type SearchType = 'ZIP CODE' | 'CITY AND STATE'

export default function VirtualLocation() {
  const navigate = useNavigate()
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  //==============================
  //#region State
  //==============================
  const [zipcode, setZipcode] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [searchType, setSearchType] = useState<SearchType>('ZIP CODE')
  const formReady = useMemo(() => {
    const zipCodeMaskregex = /_/i
    return searchType === 'ZIP CODE'
      ? !!zipcode && !zipCodeMaskregex.test(zipcode)
      : !!city && !!state
  }, [zipcode, city, state, searchType])

  //==============================
  //#region Continue
  //==============================
  const handleContinue = async () => {
    ModelModal.showLoader()
    let practice: GetPracticeByZipResponseBO | null
    if (searchType === 'ZIP CODE') {
      practice = await ModelClinic.getPracticeByZip(zipcode)
    } else {
      const zip = await ModelClinic.getZipByCityAndState(city, state)
      practice = await ModelClinic.getPracticeByZip(zip)
    }
    if (!practice) return ModelModal.showError('Practice not found')

    ModelModal.hide()
    //>>>>>
    navigate(`${Path.VirtualCareTime}/${practice.solvQueueId}`)
  }

  //==============================
  //#region Account
  //==============================
  const account = useAccountStore((state) => state.account)

  useEffect(() => {
    if (account) {
      setZipcode(account.zipCode ?? '')
      setCity(account.city ?? '')
      setState(account.state ?? '')
    }
  }, [])

  if (!isAuthenticated)
    return (
      <>
        <Nav title='' back={Path.Home} />
        <div className={css.nullState}>
          <div>
            <Icon name='lock' width={32} />
            <p>Please Login to access this feature</p>
          </div>
          <Button text='Log In' clicked={() => loginWithRedirect()} primary />
        </div>
      </>
    )

  return (
    <>
      <Nav title='Provide your Location' back={Path.Home} />
      <div className={css.virtualCareLocation}>
        <div className={css.wrap}>
          <Selector
            items={['ZIP CODE', 'CITY AND STATE']}
            selected={searchType}
            clicked={(value) => {
              setSearchType(value as SearchType)
            }}
          />
        </div>

        {searchType === 'ZIP CODE' ? (
          <div className={css.wrap}>
            <FieldText
              value={zipcode}
              label='ZIP Code'
              change={(value) => setZipcode(value)}
              mask='99999'
            />
          </div>
        ) : (
          <>
            <FieldText
              label='City'
              value={city}
              change={(value) => setCity(value)}
            />
            <div className={css.wrap}>
              <Select
                label='State'
                value={state}
                items={AccountConstants.STATES}
                change={(value) => setState(value)}
              />
            </div>
          </>
        )}
        <Button
          text='Continue'
          disabled={!formReady}
          primary={true}
          clicked={handleContinue}
        />
      </div>
    </>
  )
}
