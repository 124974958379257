//:::::::::::::::::::::::::
import {
  HttpClient,
  ModelModal,
  UserLookUpDetailBO,
  HttpStatus,
  Helper,
  UserInformationBO,
  CreateDependantInformationBO,
  Types,
  Foundation,
  Path,
  UpdateUserInformationBO,
  makeObservable,
  action,
  CreateDependentResponseBO,
} from 'src/_Shared/global'
import { IdToken } from '@auth0/auth0-react'
import { Account } from '../stores/account/store'
import accountStore from '../stores/account'
//:::::::::::::::::::::::::

class ModelAccount {
  //==============================
  //#region Class Setup
  //==============================
  private static _instance: ModelAccount
  //Properties
  account: UserInformationBO | undefined
  firstName: string = ''

  constructor() {
    //Set props to true to make reactive
    makeObservable(this, {
      //Methods
      firstNameSet: action,
      setAccount: action,
      //Enable properties
      firstName: true,
      account: true,
    })
  }

  //==============================
  //#region Getters
  //==============================
  getAccount(): UserInformationBO | undefined {
    if (localStorage.getItem('account')) {
      return JSON.parse(localStorage.getItem('account')!) as UserInformationBO
    } else {
      return undefined
    }
  }

  getPaperwork(): Types.Paperwork | undefined {
    if (localStorage.getItem('paperwork')) {
      return JSON.parse(localStorage.getItem('paperwork')!) as Types.Paperwork
    } else {
      return undefined
    }
  }

  getLinkedAccounts(): any {
    if (localStorage.getItem('linkedAccounts')) {
      return JSON.parse(localStorage.getItem('linkedAccounts')!)
    } else {
      return undefined
    }
  }

  getSavedObj(key: string): { [key: string]: any } | undefined {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key)!)
    } else {
      return undefined
    }
  }

  //==============================
  //#region Setters
  //==============================
  firstNameSet(value: string) {
    console.log(`Set: ${value}`)
    this.firstName = value
  }
  async setAccount(
    accessToken: string,
    tokenClaims?: IdToken,
    onUpdate?: () => any,
  ) {
    ModelModal.showLoader('Please Wait', 'Logging in...')
    let idToken = tokenClaims?.__raw
    if (idToken) {
      localStorage.setItem('idToken', idToken)
    }
    localStorage.setItem('accessToken', accessToken)
    //Get account details
    ModelModal.showLoader('Please Wait', 'Getting account...')
    await this.get()
    onUpdate?.()
    ModelModal.hide()
  }

  //Initialize our singleton
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  //==============================
  //#region Check for User (Deprecated)
  //==============================
  async findNextGenAccount(email: string) {
    try {
      const httpClient = new HttpClient<UserLookUpDetailBO[], any>()
      const response = await httpClient.post(
        'v2/authn/user/lookup/byemail',
        { email: email },
        {
          headers: {
            isdetailsrequired: true,
          },
        },
      )

      if (!response.data || response.data.length === 0) {
        //No user found
        return null
      }

      //Found 'em
      const user = response.data[0] as UserLookUpDetailBO

      //Check if a NextGen account exists
      if (!user.app_metadata.NEXTGEN_PERSON_ID) {
        console.log('no account found; make it!')
        return null
      }

      //:::
      return user
    } catch (error) {
      //--- ! ---
      Helper.handleError('findUserWithEmail', error)
      return null
    }
  }

  async getAuthPrefillData() {
    try {
      const httpClient = new HttpClient<string, any>()
      const response = await httpClient.post('v2/authn/user/prefill', null, {
        headers: {
          'Content-Type': 'application/json',
          useraccesstoken: localStorage.getItem('accessToken') || '',
          idtoken: localStorage.getItem('idToken') || '',
        },
      })
      console.log('response', response)
      return response.data
    } catch (error) {
      console.error('*** Error getting auth prefill data:', error)
    }
  }

  //==============================
  //#region Reset Password
  //==============================
  async resetPassword(email: string) {
    ModelModal.showLoader()
    try {
      const httpClient = new HttpClient<string, any>()
      const response = await httpClient.post(
        'v2/authn/user/changepassword',
        {
          'client-id': Foundation.auth0ClientId,
          email: email,
          connection: 'Username-Password-Authentication',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      ModelModal.hide()

      if (response.status === HttpStatus.Success) {
        //Success
        ModelModal.showSuccess(
          'Email Sent',
          'You should receive an email with instructions to reset your password.',
          'Go to Login',
          Path.LogIn,
        )
      } else {
        //Error
        ModelModal.showError(
          'Oops',
          'There was an unexpected error. Please try again.',
          'Okay',
        )
      }
    } catch (error) {
      //--- ! ---
      Helper.handleError('resetPassword', error)
    }
  }

  async createUser(account: UserInformationBO) {
    try {
      const newUser = {
        addressLine1: account.address_1,
        addressLine2: account.address_2,
        city: account.city,
        dateOfBirth: account.dob?.length
          ? new Date(account.dob).toISOString()
          : '',
        firstName: account.first_name,
        lastName: account.last_name,
        sex: account.sex ? Helper.convertBirthSexToAPI(account.sex) : '',
        state: account.state,
        zip: account.zip,
        emailAddress: account.email_id,
        cellPhone: account.phonenumber.replace(/[^0-9]/g, ''),
        RaceId: this.getRaceIdByName(account.race_description),
        EthnicityId: account.ethnicities_id,
        countryId: account.countryId,
        dependents: [],
      }
      const personId = await this.createNextGenAccount(newUser)
      if (!personId) return
      return personId
    } catch (error) {
      console.error('*** Error creating NextGen account:', error)
      ModelModal.hide()
    }
  }

  //==============================
  //#region Create Account
  //==============================
  async createNextGenAccount(values: any) {
    const account = this.getAccount()
    console.info('[createNextGenAccount] account:', account)
    if (!account) return

    //Check if 18 or over
    const isMinor = !Helper.isAtLeast18YearsOld(values.dob)

    try {
      const httpClient = new HttpClient<CreateDependentResponseBO, any>()

      const response = await httpClient.post(
        'v2/nextgen/person/create',
        values,
        {
          headers: {
            isminor: isMinor,
            auth0id: account.user_id,
            idtoken: localStorage.getItem('idToken') || '',
          },
        },
      )

      if (response.message === 'Conflict') {
        //User already exists, tell them to sign in
        return false
      }

      //Success
      localStorage.setItem(
        'account',
        JSON.stringify({
          ...account,
          nxgen_person_id: response.data?.NGPersonId ?? '',
        }),
      )
      //:::
      return response.data?.NGPersonId
    } catch (error) {
      //--- ! ---
      ModelModal.hide()
      Helper.handleError('createNextGenAccount', error)
      return false
    }
  }

  //==============================
  //#region Update NextGen Account
  //==============================
  async update(
    updateBody: UpdateUserInformationBO,
    isMinor = false,
    guardianpersonid?: string,
  ) {
    // ModelModal.showLoader('Please Wait', 'Updating account...')
    const { idToken } = accountStore.getState()

    try {
      const httpClient = new HttpClient<UserInformationBO, any>()
      const response = await httpClient.post(
        'v2/nextgen/person/update',
        updateBody,
        {
          headers: {
            ...(isMinor && { isminor: isMinor }),
            ...(guardianpersonid && { guardianpersonid: guardianpersonid }),
            ...(idToken && { idtoken: idToken }),
          },
        },
      )

      ModelModal.hide()
      return response
    } catch (error) {
      ModelModal.hide()
      Helper.handleError('Update Account', error)
    }
  }

  //===========================================
  //#region Update Auth0 with NextGen PersonId
  //===========================================
  async updateAuthUserWithPersonId(
    auth0UserId: string,
    nextGenPersonId: string,
  ) {
    // ModelModal.showLoader('Please Wait', 'Syncing accounts...')
    try {
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/authn/user/linkpersonid',
        {},
        {
          headers: {
            userid: auth0UserId,
            personid: nextGenPersonId,
          },
        },
      )

      if (!response.data || response.data.length === 0) {
        //No user found
        return null
      }

      localStorage.setItem('linkedAccounts', response.data)

      return response.data
    } catch (error) {
      Helper.handleError('updateAuthUserWithPersonId', error)
      ModelModal.hide()
    }
  }

  //==============================
  //#region Get Account
  //==============================
  // XXX: This routinely returns `''` for nxgen_person_id so we should poll the API for the latest data
  async get() {
    ModelModal.showLoader('Please Wait', 'Getting account...')
    const idToken = localStorage.getItem('idToken')
    const accessToken = localStorage.getItem('accessToken')
    if (!idToken || !accessToken) return

    try {
      const httpClient = new HttpClient<UserInformationBO, any>()

      const response = await httpClient.post('v2/authn/user/profile', null, {
        headers: {
          idToken: idToken,
          useraccesstoken: accessToken,
        },
      })

      localStorage.setItem(
        'account',
        JSON.stringify({
          ...response.data,
          // HACK: Sometimes the API returns an empty dependent so ignore these.
          dependants:
            response.data?.dependants.filter(
              (dependent) => dependent.nxgen_person_id,
            ) ?? [],
        }),
      )
      ModelModal.hide()
    } catch (error) {
      //--- ! ---
      ModelModal.hide()
      Helper.handleError('getProfile', error)
    }
  }

  // XXX: This is a privacy leak risk because, at times, it returns multiple accounts with little or no PII provided in the
  // payload. This lookup should be done server-side and not client-side, and then when the Auth0 account id is retrieved
  // the link between the NextGen Person ID and the Auth0 ID should be made server-side.
  async lookupNextGenAccount(values?: any) {
    try {
      const account = this.getAccount()
      const firstName = values?.firstName
      const lastName = values?.lastName
      const dateOfBirth = values?.dateOfBirth
      const sex = values?.sex.charAt(0)
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/nextgen/person/lookup',
        {
          firstName,
          lastName,
          sex,
          dateOfBirth,
        },
        {
          headers: {
            auth0id: account?.user_id ?? '',
            idtoken: localStorage.getItem('idToken') || '',
          },
        },
      )

      if (!response.data || response.data.length === 0) {
        //No user found
        return null
      }

      //:::
      return response.data[0]
    } catch (error) {
      //--- ! ---
      ModelModal.hide()
      Helper.handleError('lookupUser', error)
    }
  }

  //==============================
  //#region Check if clinic favorited
  //==============================
  checkIfIsFavorited = (clinic: Types.Clinic, account?: Account | null) => {
    if (!account) return false
    return !!account.favoriteClinics?.find(({ ID }) => ID === clinic.details.ID)
  }
  //==============================
  //#region Set Favorite
  //==============================
  setFavorite = async (
    clinic: Types.Clinic,
    isFavorited: boolean,
    account?: Account | null,
  ) => {
    const isRemoving = isFavorited
    ModelModal.showLoader(
      undefined,
      isRemoving ? 'Removing clinic from Favorites...' : 'Favoriting clinic...',
    )
    const { setAccount } = accountStore.getState()
    if (!account) return
    let favoriteClinics = isRemoving
      ? account?.favoriteClinics?.filter((c) => c.ID !== clinic.details.ID)
      : [...(account?.favoriteClinics ?? []), clinic.details]
    let updateBody: UpdateUserInformationBO = {
      userprofile: {
        user_id: account.auth0Id,
        email_id: account.email ?? '',
        nxgen_person_id: account.nextGenPersonId ?? '',
        first_name: account.firstName ?? '',
        last_name: account.lastName ?? '',
        sex: account.sex ? Helper.convertBirthSexToAPI(account.sex) : '',
        dob: account.dateOfBirth ?? '',
        address_1: account.address1 ?? '',
        address_2: account.address2 ?? '',
        EthnicityId: account.ethnicityId ?? '',
        RaceId: account.raceId ?? '',
        city: account.city ?? '',
        state: account.state ?? '',
        zip: account.zipCode ?? '',
        phonenumber: account.phoneNumber ?? '',
        countryId: account.countryId ?? '',
      },
      fav_clinics: favoriteClinics?.map((clinic) => clinic.ID.toString()) ?? [],
      isProfileUpdate: true,
      isClinicUpdate: true,
      isRemove: isFavorited,
    }
    setAccount({
      favoriteClinics,
    })
    await this.update(updateBody)
    return !isFavorited
  }

  //==============================
  //#region Get Dependants
  //==============================
  getDependants() {
    var dependantNames: string[] = []

    const account = this.getAccount()
    if (account) {
      dependantNames = account.dependants.map((dependant) => {
        return `${dependant.first_name} ${dependant.last_name}`
      })
    }
    //:::
    return dependantNames
  }

  //==============================
  //#region Create Dependent
  //==============================

  async createDependent(dependent: CreateDependantInformationBO) {
    const account = this.getAccount()
    if (!account) return
    ModelModal.showLoader(undefined, 'Adding dependent...')

    //Check if 18 or over
    const isMinor = !Helper.isAtLeast18YearsOld(dependent.dateOfBirth)

    try {
      const httpClient = new HttpClient<any, any>()

      const response = await httpClient.post(
        'v2/nextgen/person/create',
        dependent,
        {
          headers: {
            isminor: isMinor,
            auth0id: account.user_id,
            idtoken: localStorage.getItem('idToken') || '',
          },
        },
      )

      ModelModal.hide()
      return response
    } catch (error) {
      //--- ! ---
      ModelModal.hide()
      Helper.handleError('createDependant', error)
    }
  }

  //==============================
  //#region Form Values
  //==============================
  states: string[] = [
    'AA',
    'AE',
    'AL',
    'AK',
    'AP',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WI',
    'WV',
    'WY',
  ]

  provinces: string[] = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NS',
    'NT',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
    'FC',
  ]

  races: Types.Race[] = [
    {
      id: 'fb8a0bef-605f-45bd-b61a-9c0b23ea8c5f',
      name: 'Undisclosed',
    },
    {
      id: 'd9173865-1fe0-46fb-af97-60b5b4197d3d',
      name: 'American Indian or Alaska Native',
    },
    {
      id: '1c300a6f-3f40-4bfd-bd2b-6667c21292d3',
      name: 'Asian',
    },
    {
      id: '200e7794-f979-416a-b24d-bb92a016af9e',
      name: 'Black or African American',
    },
    {
      id: 'd32ce189-b22c-4826-9399-2516dc884d43',
      name: 'Native Hawaiian or Other Pacific Islander',
    },
    {
      id: 'f4614c2d-13b9-49c6-8ee2-2b3bc92af8bc',
      name: 'White',
    },
  ]

  ethnicities: Types.Ethnicity[] = [
    {
      id: '05bf28f9-a600-445f-add2-ffccb0440a62',
      name: 'Undisclosed',
    },
    {
      id: '625625c5-328f-4b49-a87e-0236777a3eff',
      name: 'Hispanic or Latino',
    },
    {
      id: 'bf23ab7f-c9a6-4930-acb6-710cd6d1a077',
      name: 'Not Hispanic or Latino',
    },
  ]

  getEthnicityNames() {
    return this.ethnicities.map(({ name }) => name)
  }
  getRaceNames() {
    return this.races.map(({ name }) => name)
  }
  getEthnicityNameById(id: string) {
    const ethnicity = this.ethnicities.find((race) => race.id === id)
    return ethnicity?.name
  }
  getRaceNameById(id: string) {
    const race = this.races.find((race) => race.id === id)
    return race?.name
  }
  getEthnicityIdByName(name: string) {
    const ethnicity = this.ethnicities.find((race) => race.name === name)
    return ethnicity?.id
  }
  getRaceIdByName(name: string) {
    const race = this.races.find((race) => race.name === name)
    return race?.id
  }
}

export default ModelAccount.Instance
