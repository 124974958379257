//:::::::::::::::::::::::::
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Foundation, UserInformationBO } from 'src/_Shared/global'
import accountStore from 'src/_Shared/stores/account'
//:::::::::::::::::::::::::

class API {
  //==============================
  //#region Class Setup
  //==============================
  client: AxiosInstance
  private static _instance: API
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }
  constructor() {
    //User ID
    const { account, idToken } = accountStore.getState()
    const nextGenId = account?.nextGenPersonId

    //Session ID
    let sessionId = ''
    if (sessionStorage.getItem('sessionId')) {
      sessionId = sessionStorage.getItem('sessionId')!
    } else {
      sessionId = crypto.randomUUID()
    }

    this.client = axios.create({
      //baseURL: Foundation.middlewareUrl,
      baseURL: process.env.REACT_APP_MIDDLEWARE_URL!,
    })

    //Set headers for all requests
    this.client.interceptors.request.use(function (config) {
      config.headers = {
        Accept: '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        platform: 'web',
        version: '2.0',
        loggedinuserid: nextGenId ? nextGenId : 'guest',
        sessionid: sessionId,
        appchecktoken: localStorage.getItem('appCheckToken')!,
        apikey: process.env.REACT_APP_MIDDLEWARE_API_KEY!,
        idtoken: idToken ?? '',
      }

      return config
    })
  }

  //==============================
  //#region POST
  //==============================
  async post(url: string, body?: any, headers?: any) {
    console.log(`::: ${url} :::`)

    try {
      const response = await this.client.post(url, body, headers)
      console.log('-----')
      console.log(response)
      return response
    } catch (error) {
      console.log('--- ! ---')
      console.log(error)
    }
  }

  //==============================
  //#region GET
  //==============================
  async get(url: string, headers?: any) {
    console.log(`::: ${url} :::`)

    try {
      const response = await this.client.get(url, headers)
      console.log('-----')
      console.log(response)
      return response
    } catch (error) {
      console.log('--- ! ---')
      console.log(error)
    }
  }
}

export default API.Instance
