//:::::::::::::::::::::::::
import css from './pickList.module.css'
//:::::::::::::::::::::::::

export default function PickList(props: {
  items: string[]
  selected: string
  clicked: (item: string, index?: number) => void
}) {
  return (
    <ol className={css.pickList}>
      {props.items.map((item, index) => (
        <li
          key={item}
          className={`${item === props.selected ? css.selected : ''}`}
          onClick={() => props.clicked(item, index)}
        >
          {item}
        </li>
      ))}
    </ol>
  )
}
