//:::::::::::::::::::::::::
import { SummaryItem, ModelPaperwork, observer } from 'src/_Shared/global'
//:::::::::::::::::::::::::

function SummaryGuarantorAddress() {
  return (
    <>
      <SummaryItem
        name='Street Address'
        value={
          ModelPaperwork.paperwork.responses[
            'GuarantorAddress_48e1f640-744f-11ed-bd09-51c5be6d07c3'
          ]
        }
      />
      <SummaryItem
        name='City'
        value={ModelPaperwork.paperwork.responses.GuarantorCity}
      />
      <SummaryItem
        name='State'
        value={ModelPaperwork.paperwork.responses.GuarantorState}
      />
      <SummaryItem
        name='ZIP Code'
        value={ModelPaperwork.paperwork.responses.GuarantorZIP}
      />
    </>
  )
}

export default observer(SummaryGuarantorAddress)
