import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import API from './api'
import accountStore from '../account'

interface Booking {
  [key: string]: any
}

export interface Insurance {
  cardId: string
  cardNickName: string
  frontImage: string
  backImage: string
}

export interface InsuranceState {
  bookings: null | Booking[]
  setBookings: (bookings: Booking[]) => void
  frontImage: string | null
  backImage: string | null
  setFrontImage: (frontImage: string | null) => void
  setBackImage: (backImage: string | null) => void
  cardId: string | null
  setCardId: (cardId: string | null) => void
  cardName: string | 'Insurance Card'
  setCardName: (cardName: string) => void
  bookingId: string | null
  setBookingId: (bookingId: string | null) => void
  uploadDocuments: () => Promise<void>
  fetchInsurances: () => Promise<void>
  insurances: Insurance[] | null
  setInsurances: (insurances: Insurance[]) => void
  createInsurance: (
    frontImage: string,
    backImage: string,
    cardName: string,
    idToken: string,
  ) => Promise<any>
  updateInsurance: (
    cardId: string,
    frontImage: string,
    backImage: string,
    cardName: string,
    idToken: string,
  ) => Promise<any>
  fetchInsuranceDetails: (cardId: string) => Promise<void>
  deleteInsurance: (cardId: string) => Promise<void>
  resetInsurance: () => void
}

const useInsuranceStore = create<InsuranceState>()(
  persist(
    (set, get) => ({
      bookings: null,
      setBookings: (bookings: Booking[]) => {
        set(() => ({ bookings }))
      },
      frontImage: null,
      backImage: null,
      setFrontImage: (frontImage: string | null) => {
        set(() => ({ frontImage }))
      },
      setBackImage: (backImage: string | null) => {
        set(() => ({ backImage }))
      },
      uploadDocuments: async () => {
        // if we have a cardId, frontImage, and backImage, we can upsert the insurance, else return
        const { bookingId, frontImage, backImage } = get()
        const { idToken } = accountStore.getState()
        console.log(
          bookingId,
          frontImage?.slice(0, 10),
          backImage?.slice(0, 10),
          idToken,
        )
        if (idToken && bookingId && frontImage && backImage) {
          await API.uploadCards(frontImage, backImage, bookingId, idToken)
        }
      },
      cardId: null,
      setCardId: (cardId: string | null) => {
        set(() => ({ cardId }))
      },
      cardName: 'Insurance Card',
      setCardName: (cardName: string) => {
        set(() => ({ cardName }))
      },
      createInsurance: async (
        frontImage: string,
        backImage: string,
        cardName: string,
        idToken: string,
      ) => {
        const result = await API.createInsurance(
          frontImage,
          backImage,
          cardName,
          idToken,
        )
        return result
      },
      updateInsurance: async (
        cardId: string,
        frontImage: string,
        backImage: string,
        cardName: string,
        idToken: string,
      ) => {
        await API.deleteInsurance(cardId, idToken)
        await API.createInsurance(frontImage, backImage, cardName, idToken)
      },
      fetchInsurances: async () => {
        const { idToken } = accountStore.getState()
        if (!idToken) return
        const result = await API.fetchInsurances(idToken)
        if (result) {
          const insurances = await Promise.all(
            result.map(async (insurance) => {
              const details = insurance.cardId
                ? await API.fetchInsuranceDetails(insurance.cardId, idToken)
                : insurance
              return {
                cardId: insurance.cardId ?? '',
                cardNickName: insurance.cardNickName ?? '',
                frontImage: details?.insuranceFrontImage,
                backImage: details?.insuranceBackImage,
              }
            }),
          )
          set(() => ({ insurances }))
        }
      },
      bookingId: null,
      setBookingId: (bookingId: string | null) => {
        set(() => ({ bookingId }))
      },
      insurances: null,
      setInsurances: (insurances: Insurance[]) => {},
      fetchInsuranceDetails: async (cardId) => {
        const { idToken } = accountStore.getState()
        if (!idToken) return
        return await API.fetchInsuranceDetails(cardId, idToken)
      },
      deleteInsurance: async (cardId: string) => {
        const { idToken } = accountStore.getState()
        if (!idToken) return
        await API.deleteInsurance(cardId, idToken)
        await get().fetchInsurances()
      },
      resetInsurance: () => {
        set(() => ({
          bookings: null,
          frontImage: null,
          backImage: null,
          cardId: null,
          cardName: 'Insurance Card',
          bookingId: null,
          insurances: null,
        }))
      },
    }),
    {
      name: 'insurance-storage',
    },
  ),
)

export default useInsuranceStore
