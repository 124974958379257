//:::::::::::::::::::::::::
import { ModelMap, Icon, observer } from 'src/_Shared/global'
import css from './mapOptions.module.css'

//:::::::::::::::::::::::::

function MapOptions() {
  return (
    <div
      className={`${css.mapOptions} ${
        ModelMap.showMap ? css.clearBackground : ''
      }`}
    >
      <div>
        {/* === Filter Button === */}
        {/* <div className={css.filter}>
        <button onClick={() => ModelMap.setShowFilters(true)}>
          <Icon name='filter' width={20} />
          <p>Filter</p>
        </button>
      </div> */}

        {/* === Zoom to See === */}
        {ModelMap.mapZoom < ModelMap.mapZoomThreshold && ModelMap.showMap && (
          <div className={css.zoomToSee}>Zoom to See Availability</div>
        )}
      </div>
      <div className={css.toggleButtons}>
        {/* === Map Button === */}
        <button
          className={`${ModelMap.showMap ? css.selected : ''}`}
          onClick={() => ModelMap.setShowMap(true)}
        >
          <span>
            <Icon
              name='location'
              color={`${ModelMap.showMap ? 'white' : 'gray3'}`}
              width={20}
            />
          </span>
          <p>Map</p>
        </button>

        {/* === List Button === */}
        <button
          className={`${ModelMap.showMap ? '' : css.selected}`}
          onClick={() => {
            ModelMap.updateMapMarkers()
            ModelMap.setShowMap(false)
          }}
        >
          <span>
            <Icon
              name='list'
              color={`${ModelMap.showMap ? 'gray3' : 'white'}`}
              width={20}
            />
          </span>

          <p>List</p>
        </button>
      </div>
    </div>
  )
}

export default observer(MapOptions)
