//:::::::::::::::::::::::::
import {
  Button,
  Checklist,
  FieldText,
  ModelModal,
  Nav,
  Path,
  UploadCard,
  useState,
} from 'src/_Shared/global'
import css from './InsuranceUpload.module.css'
import { useAccountStore } from 'src/_Shared/stores/account'
import { useInsuranceStore } from 'src/_Shared/stores/insurance'
import type { Insurance } from 'src/_Shared/stores/insurance/store'
//:::::::::::::::::::::::::

export default function AccountInsuranceUpload({
  insurance,
  onUpload,
  onDelete,
}: {
  insurance?: Insurance | null
  onUpload?: () => void
  onDelete?: () => void
}) {
  //==============================
  //#region State
  //==============================
  const [cardName, createInsurance, deleteInsurance, fetchInsurances] =
    useInsuranceStore((state) => [
      state.cardName,
      state.createInsurance,
      state.deleteInsurance,
      state.fetchInsurances,
    ])
  const [idToken] = useAccountStore((state) => [state.idToken])
  const [frontImage, setFrontImage] = useState<string | null>(
    insurance?.frontImage ?? null,
  )
  const [backImage, setBackImage] = useState<string | null>(
    insurance?.backImage ?? null,
  )
  const [cardNickName, setCardNickName] = useState<string>('')

  const uploadInsuranceCards = async () => {
    if (frontImage && backImage && idToken && cardName) {
      try {
        ModelModal.showLoader('Saving Insurance Card')

        await createInsurance(frontImage, backImage, cardNickName, idToken)
        ModelModal.hide()
        ModelModal.showLoader(
          'Please Wait',
          'Retrieving insurance information ...',
        )
        onUpload?.()
        await fetchInsurances()
        ModelModal.hide()
      } catch (error) {
        console.error(
          '[InsuranceUpload] Error uploading insurance card:',
          error,
        )
        ModelModal.hide()
      }
    }
  }

  const deleteInsuranceCard = async () => {
    if (insurance?.cardId && idToken) {
      try {
        // TODO: Add confirmation modal
        // eslint-disable-next-line no-restricted-globals
        const isConfirmed = confirm(
          'Are you sure you want to delete this insurance card?',
        )
        if (!isConfirmed) return
        // Delete the insurance
        ModelModal.showLoader('Deleting Insurance Card')
        await deleteInsurance(insurance.cardId)
        onDelete?.()
        ModelModal.showLoader(
          'Please Wait',
          'Retrieving insurance information ...',
        )
        await fetchInsurances()
        ModelModal.hide()
      } catch (error) {
        console.error('[InsuranceUpload] Error deleting insurance card:', error)
        ModelModal.hide()
      }
    }
  }

  const addPreamble = (image: string) => {
    return image.startsWith('data:image')
      ? image
      : `data:image/jpeg;base64,${image}`
  }

  // console.info(frontImage, backImage, bookingId)
  //==============================
  //#region UI
  //==============================
  return (
    <>
      <Nav back={Path.Account} title='Insurance Information' />
      <div className={css.container}>
        {/* === Front of Insurance Card === */}
        {insurance ? (
          <>
            <h4>Card Name: {insurance.cardNickName ?? ''}</h4>
            <div className={css.dashedImageContainer}>
              <img
                src={frontImage ? addPreamble(frontImage) : undefined}
                alt='Front of Insurance Card'
              />
            </div>
            <div className={css.dashedImageContainer}>
              <img
                src={backImage ? addPreamble(backImage) : undefined}
                alt='Back of Insurance Card'
              />
            </div>
          </>
        ) : (
          <>
            <UploadCard
              title='Front of Insurance Card'
              id='Front'
              photo={frontImage ?? ''}
              ready={(file: string) => {
                setFrontImage(file)
              }}
            />
            <UploadCard
              title='Back of Insurance Card'
              id='Back'
              photo={backImage ?? ''}
              ready={(file: string) => {
                setBackImage(file)
              }}
            />
            <p>
              Add photos of your insurance card. We’ll send your insurance to
              the clinic to verify.
            </p>
            <p>
              <b>When taking a photo, make sure the card...</b>
            </p>
            <Checklist
              items={[
                'Is lying flat and centered in the camera window.',
                'Is well lit with minimal glare on the surface.',
              ]}
            />
            <FieldText
              value={cardNickName}
              label='Card Nickname'
              error='Card nickname is required'
              change={(value) => {
                setCardNickName(value)
              }}
            />
          </>
        )}

        {/* === Back of Insurance Card === */}
      </div>

      <div className={css.buttonWrapper}>
        {insurance ? (
          <Button block primary clicked={deleteInsuranceCard}>
            Delete Insurance
          </Button>
        ) : (
          <Button
            block
            primary
            clicked={uploadInsuranceCards}
            disabled={
              !frontImage?.length || !backImage?.length || !cardNickName?.length
            }
          >
            Save Insurance Card
          </Button>
        )}
      </div>
    </>
  )
}
