import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { PropsWithChildren } from 'react'
import { useCookies } from 'react-cookie'
import {
  Foundation,
  useEffect,
  useSearchParams,
  useNavigate,
  useLocation,
  Path,
  ModelModal,
} from 'src/_Shared/global'
import accountStore, { useAccountStore } from 'src/_Shared/stores/account'

// Update Auth0 user with NextGen Person ID
function AuthCheck() {
  const { isAuthenticated, getIdTokenClaims, getAccessTokenSilently, user } =
    useAuth0()
  const fetchAccount = accountStore.getState().fetchAccount
  const [
    account,
    setAccount,
    setIdToken,
    setAccessToken,
    setExpiration,
    isExpired,
  ] = useAccountStore((state) => [
    state.account,
    state.setAccount,
    state.setIdToken,
    state.setAccessToken,
    state.setExpiration,
    state.isExpired,
  ])

  const [cookies] = useCookies()
  useEffect(() => {
    if (cookies?.idToken) setIdToken(cookies.idToken)
    if (cookies?.accessToken) setAccessToken(cookies.accessToken)
  }, [cookies, setAccessToken, setIdToken])

  const getTokenAndClaims = async () => {
    const { access_token: token } = await getAccessTokenSilently({
      detailedResponse: true,
    })
    setAccessToken(token)
    const tokenClaims = await getIdTokenClaims()
    setIdToken(tokenClaims?.__raw)
    setExpiration(tokenClaims?.exp || null)
    if (user) {
      setAccount({
        email: user?.email,
        auth0Id: user?.sub,
      })
    }
    ModelModal.showLoader('Please Wait', 'Loading account...')
    await fetchAccount()
    ModelModal.hide()
  }
  const { pathname } = useLocation()
  console.info('[AuthProvider] isAuthenticated:', isAuthenticated)

  // Get Auth0 token and claims - should happen after logged in/account created via Auth0 but no account stored
  useEffect(() => {
    if (!isAuthenticated) return
    if (isAuthenticated && !account) {
      // This logs the person in from NextCare POV, and sets the web client to use the token for all requests
      if (pathname !== Path.PaperworkAccountCreated) {
        getTokenAndClaims()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    if (isExpired()) {
      getTokenAndClaims()
    }
  }, [isExpired()])

  return <></>
}

function destinationFor(destination: string | null) {
  switch (destination) {
    case 'bookInfo':
      //>>>>>
      return Path.BookInfo
    case 'paperwork':
      //>>>>>
      return Path.Paperwork1
    case 'paperworkAccountCreated':
      //>>>>>
      return Path.AccountCreated
    case 'homeAccountCreated':
      //>>>>>
      return Path.CreateAccountPatientInfo
    default:
      //>>>>>
      return Path.Home
  }
}

export default function AuthProvider({ children }: PropsWithChildren<{}>) {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const { pathname } = useLocation()
  const destination = destinationFor(params.get('destination'))

  const onRedirectCallback = (appState: any) => {
    console.info('onRedirectCallback - appState:', appState)
    navigate(destination || pathname, {
      replace: true,
    })
  }

  return (
    <Auth0Provider
      useRefreshTokens
      cacheLocation='localstorage'
      domain={Foundation.auth0BaseURL}
      clientId={Foundation.auth0ClientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: Foundation.auth0Redirect,
      }}
    >
      <AuthCheck />
      {children}
    </Auth0Provider>
  )
}
