//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import {
  Selector,
  Divider,
  Types,
  FieldText,
  FieldDateOfBirth,
  FieldRelationship,
  ModelPaperwork,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function PaperworkPaymentInsuranceSecondary(props: {
  values: Types.Paperwork
  change: (values: Types.Paperwork) => void
}) {
  //==============================
  //#region State
  //==============================

  return (
    <div className={css.insuranceSecondary}>
      <Divider margins={30} />
      <h2>Do you have secondary insurance to add?</h2>
      <div className={css.fieldWrapExtra}>
        <Selector
          items={['Yes', 'No']}
          selected={ModelPaperwork.paperwork.secondaryInsurance ? 'Yes' : 'No'}
          buttonStyle={true}
          clicked={(value) => {
            ModelPaperwork.setPaperwork({
              ...ModelPaperwork.paperwork,
              secondaryInsurance: value === 'Yes',
            })
          }}
        />
      </div>
      {ModelPaperwork.paperwork.secondaryInsurance && (
        <>
          <Divider />
          <h2>Secondary Insurance Information</h2>

          {/* === Insurance Company === */}
          <FieldText
            value={props.values.responses.secondaryInsuranceCompany}
            label='Insurance Company'
            change={(value) => {
              props.change({
                ...props.values,
                responses: {
                  ...props.values.responses,
                  secondaryInsuranceCompany: value,
                },
              })
            }}
          />

          {/* === Member ID === */}
          <FieldText
            value={props.values.responses.secondaryMemberId}
            label='Member ID'
            change={(value) => {
              props.change({
                ...props.values,
                responses: {
                  ...props.values.responses,
                  secondaryMemberId: value,
                },
              })
            }}
          />

          {/* === Group ID === */}
          <FieldText
            value={props.values.responses.secondaryGroupNumber}
            label='Group ID'
            change={(value) => {
              props.change({
                ...props.values,
                responses: {
                  ...props.values.responses,
                  secondaryGroupNumber: value,
                },
              })
            }}
          />

          {/* === Policy Holder First Name === */}
          <FieldText
            value={props.values.responses.secondaryPolicyHolderFirstName}
            label='Policy Holder First Name'
            change={(value) => {
              props.change({
                ...props.values,
                responses: {
                  ...props.values.responses,
                  secondaryPolicyHolderFirstName: value,
                },
              })
            }}
          />

          {/* === Policy Holder Last Name === */}
          <FieldText
            value={props.values.responses.secondaryPolicyHolderLastName}
            label='Policy Holder Last Name'
            change={(value) => {
              props.change({
                ...props.values,
                responses: {
                  ...props.values.responses,
                  secondaryPolicyHolderLastName: value,
                },
              })
            }}
          />

          {/* === Policy Holder Birth Date === */}
          <div className={css.fieldWrap}>
            <FieldDateOfBirth
              value={props.values.responses.secondaryPolicyHolderBirthDate}
              label='Policy Holder Birth Date'
              change={(value) => {
                props.change({
                  ...props.values,
                  responses: {
                    ...props.values.responses,
                    secondaryPolicyHolderBirthDate: value,
                  },
                })
              }}
            />
          </div>

          {/* === Patient Relationship to Policy Holder === */}
          <div className={css.fieldWrap}>
            <FieldRelationship
              value={
                props.values.responses.secondaryPatientsRelationshipToInsured
              }
              label='Patient Relationship to Policy Holder'
              change={(value) => {
                props.change({
                  ...props.values,
                  responses: {
                    ...props.values.responses,
                    secondaryPatientsRelationshipToInsured: value,
                  },
                })
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}
