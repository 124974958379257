//:::::::::::::::::::::::::
import css from './uploadCard.module.css'
import TakePhoto from './TakePhoto'
import UploadFile from './UploadFile'
//:::::::::::::::::::::::::

export default function UploadCard(props: {
  title: string
  id: string
  photo?: string
  ready: (file: string) => void
}) {
  return (
    <div className={css.uploadCard}>
      <h3>{props.title}</h3>

      {/* === Take Photo === */}
      <TakePhoto photo={props.photo} ready={props.ready} />
      {/* === File Upload === */}
      <UploadFile text='Upload photo...' ready={props.ready} id={props.id} />
    </div>
  )
}
