//:::::::::::::::::::::::::
import css from './datePicker.module.css'
import Calendar from 'react-calendar'
//:::::::::::::::::::::::::

export default function DatePicker(props: {
  selectedDate: Date
  minDate: Date
  maxDate: Date
  message?: string
  change: (value: Date) => void
}) {
  return (
    <div className={css.datePicker}>
      <Calendar
        calendarType='US'
        value={props.selectedDate ? new Date(props.selectedDate) : null}
        onChange={props.change}
        minDate={props.minDate ? new Date(props.minDate) : new Date()}
        maxDate={props.maxDate ? new Date(props.maxDate) : new Date()}
      />
      {/* === Optional Message === */}
      {props.message && <p>{props.message}</p>}
    </div>
  )
}
