//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import {
  useState,
  Nav,
  Path,
  useNavigate,
  useCallback,
  Button,
  ModelPaperwork,
  useEffect,
  Helper,
  observer,
  FieldText,
  Select,
} from 'src/_Shared/global'
import PaperworkWrap from '../PaperworkWrap'
//:::::::::::::::::::::::::

function PaperworkPatient() {
  const navigate = useNavigate()
  const { responses } = ModelPaperwork.paperwork

  //==============================
  //#region State
  //==============================
  const [formReady, setFormReady] = useState(false)

  //==============================
  //#region Form Validation
  //==============================
  useEffect(() => {
    const ready =
      //Patient Information
      Helper.isNotEmpty(responses.physician) &&
      Helper.isNotEmpty(responses.pharmacy) &&
      Helper.isNotEmpty(responses.preferredPharmacyCrossStreets) &&
      Helper.isNotEmpty(responses.howHearAboutUs)

    setFormReady(ready)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responses])

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(() => {
    ModelPaperwork.setBackToSummary(false)

    //>>>>>
    navigate(Path.PaperworkVirtual3)
  }, [navigate])

  const setResponseValue = (key: string, value: string) => {
    ModelPaperwork.setPaperwork({
      ...ModelPaperwork.paperwork,
      responses: {
        ...responses,
        [key]: value,
      },
    })
  }

  //==============================
  //#region UI
  //==============================
  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav
        back={Path.PaperworkVirtual1}
        title='Patient Information'
        progress={2 / 7}
      />

      <div className={css.paperwork}>
        <div className={css.paperworkInner}>
          <h2>Healthcare Contacts</h2>
          <>
            <FieldText
              value={responses.physician}
              label='Primary Care Physician'
              change={(value) => {
                setResponseValue('physician', value)
              }}
            />
            <FieldText
              value={responses.pharmacy}
              label='Preferred Pharmacy'
              change={(value) => {
                setResponseValue('pharmacy', value)
              }}
            />
            <FieldText
              value={responses.preferredPharmacyCrossStreets}
              label='Preferred Pharmacy Cross Streets'
              change={(value) => {
                setResponseValue('preferredPharmacyCrossStreets', value)
              }}
            />
            <Select
              items={[
                'Solv',
                'Community Event',
                'School Event/Promotion',
                'Pediatrician',
                'Flu Shot Clinic',
                'Billboard/Mailer',
                'Google/Internet',
                'Facebook',
                'Shopping Center',
                'Friend',
                'Other/Return',
              ]}
              value={responses.howHearAboutUs}
              change={(value: string) => {
                setResponseValue('howHearAboutUs', value)
              }}
            />
          </>
        </div>
      </div>
      {/* === Continue === */}
      <div className={css.buttonWrap}>
        <Button
          primary
          disabled={!formReady}
          clicked={clickContinue}
          text={ModelPaperwork.backToSummary ? 'Save Changes' : 'Continue'}
        />
      </div>
    </PaperworkWrap>
  )
}

export default observer(PaperworkPatient)
