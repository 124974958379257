//:::::::::::::::::::::::::
import css from './select.module.css'
import { Icon } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function Select(props: {
  label?: string
  value: string
  items: string[]
  disabled?: boolean
  change?: (value: string) => void
}) {
  return (
    <div
      className={`${css.select} ${props.disabled ? css.disabled : ''} ${
        props.value === '' ? css.noSelection : ''
      }`}
    >
      {props.value && <label>{props.label}</label>}
      <select
        disabled={props.disabled}
        onChange={(event) => {
          if (props.change) props.change(event.target.value)
        }}
        value={props.value}
        defaultValue={props.label || 'Select...'}
      >
        {/* Must have the blank value='' in order for it to be the default selection */}
        <option id={`item-select`} value='' disabled>
          {props.label || 'Select...'}
        </option>
        <>
          {props.items.map((item: string) => {
            return (
              <option key={item} id={item} value={item}>
                {item}
              </option>
            )
          })}
        </>
      </select>
      <span className={css.icon}>
        <Icon name='arrowDown' width={16} />
      </span>
    </div>
  )
}
