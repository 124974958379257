//:::::::::::::::::::::::::

import { Select } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function FieldRelationship(props: {
  value: string
  label?: string
  change: (value: string) => void
}) {
  return (
    <Select
      label={props.label || 'Relationship to Patient'}
      value={props.value || ''}
      items={[
        'Child',
        'Parent',
        'Mother',
        'Father',
        'Sibling',
        'Spouse',
        'Other',
      ]}
      change={(value) => {
        props.change(value)
      }}
    />
  )
}
