//:::::::::::::::::::::::::
import { SummaryItem, ModelPaperwork } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function SummaryPatientAddress() {
  return (
    <>
      <SummaryItem
        name='Street Address'
        value={ModelPaperwork.paperwork.account.address}
      />
      <SummaryItem
        name='Apt, Suite, etc.'
        value={ModelPaperwork.paperwork.account.apartment}
      />
      <SummaryItem name='City' value={ModelPaperwork.paperwork.account.city} />
      <SummaryItem
        name='State'
        value={ModelPaperwork.paperwork.account.state}
      />
      <SummaryItem
        name='ZIP Code'
        value={ModelPaperwork.paperwork.account.zip}
      />
    </>
  )
}
