//:::::::::::::::::::::::::
import css from './divider.module.css'
//:::::::::::::::::::::::::

export default function Divider(props: { margins?: number }) {
  return (
    <hr
      className={css.divider}
      style={{
        margin: props.margins ? `${props.margins}px 0px` : '20px 0px',
      }}
    />
  )
}
