//:::::::::::::::::::::::::
import moment from 'moment'
import css from '../modal.module.css'
import {
  Icon,
  ModelModal,
  Button,
  useNavigate,
  Path,
  useCallback,
  ModelClinic,
  useLocation,
  useEffect,
  ModelBooking,
} from 'src/_Shared/global'
import { useBookingStore } from 'src/_Shared/stores/booking'
//:::::::::::::::::::::::::

export default function ModalBookingFound(props: { clicked: () => void }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const clinicId = pathname.split('/').pop()
  const setIsOnDemand = useBookingStore((state) => state.setIsOnDemand)

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(() => {
    setIsOnDemand(false)
    ModelModal.hide()
    navigate(Path.BookFinish)
  }, [navigate])

  useEffect(() => {
    const setClinic = async () => {
      const clinic = await ModelClinic.getClinic(clinicId)
      await ModelClinic.setSelected(clinic)
    }
    setClinic()
  }, [clinicId])

  //==============================
  //#region Not Me
  //==============================
  const clickNotMe = useCallback(() => {
    setIsOnDemand(true)
    ModelModal.hide()
    const firstAvailableSlot = ModelBooking.timeSlotPackages[0]
    ModelBooking.setAppointment(moment(firstAvailableSlot.date).toDate())
    ModelModal.showLoader()
    ModelModal.hide()
    //>>>>>
    navigate(`${Path.BookInfo}`)
  }, [navigate])

  return (
    <div className={css.modalBookingFound}>
      <Icon name='medicalCalendar' width={92} />
      <h5>We Found Your Appointment</h5>
      <p dangerouslySetInnerHTML={{ __html: ModelModal.messageHtml }} />

      <Button text='Continue' clicked={clickContinue} primary />
      <div className='noTouchy'>
        <Button text='Not Me' tertiary clicked={clickNotMe} />
      </div>
    </div>
  )
}
