//:::::::::::::::::::::::::
//:::::::::::::::::::::::::

export default function SummaryItem(props: {
  name: string
  value?: string
  photo?: string
}) {
  return (
    <li>
      <span>{props.name}</span>
      {props.photo ? (
        <img src={props.photo} alt='Card' width={120} />
      ) : (
        <b>{props.value}</b>
      )}
    </li>
  )
}
