//:::::::::::::::::::::::::
import css from './summary.module.css'
import { Button, Icon } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function Summary(props: {
  title: string
  clicked: () => void
  children: React.ReactNode
}) {
  return (
    <Button block={true} clicked={props.clicked}>
      <div className={css.summary}>
        {/* === Heading === */}
        <div>
          <h2>{props.title}</h2>
          <span>
            <Icon name='pencil' width={18} />
          </span>
        </div>
        {/* === List === */}
        <ol>{props.children}</ol>
      </div>
    </Button>
  )
}
