//:::::::::::::::::::::::::
import css from './clinicInsurance.module.css'
import { Types, Icon } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function ClinicInsurance(props: { clinic: Types.Clinic }) {
  const insurances = props.clinic.details.insurance_list.map(
    (item) => item.name,
  )
  return (
    <div className={css.insurance}>
      <span>
        <Icon name='shield' width={12} />
      </span>
      <div>
        <h6>Accepted Insurance Plans</h6>
        <p>{insurances.join(', ')}</p>
      </div>
    </div>
  )
}
