//:::::::::::::::::::::::::
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, Foundation } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function AuthLogout() {
  const { logout } = useAuth0()

  //Logout and go back to the home page
  useEffect(() => {
    //Clear cache
    localStorage.clear()

    logout({ logoutParams: { returnTo: Foundation.baseUrl } })
  }, [logout])

  return <></>
}
