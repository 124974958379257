//:::::::::::::::::::::::::
import css from './bookInfo.module.css'
import {
  Nav,
  Path,
  ModelBooking,
  useEffect,
  useNavigate,
  ModelClinic,
  FirebaseAnalytics,
} from 'src/_Shared/global'
import BookInfoClinic from './BookInfoClinic'
import BookInfoForm from './BookInfoForm'
//:::::::::::::::::::::::::

export default function BookInfo() {
  //==============================
  //#region Analytics
  //==============================
  useEffect(() => {
    //Check visit type
    FirebaseAnalytics.trigger('Book Now - Your Information', {
      virtual_screen_name: 'Your Information',
      clinic_name: ModelClinic.selected?.details.title,
      visit_type: localStorage.getItem('visitType') === 'WI' ? 'WI' : 'UC',
    })
  }, [])

  //==============================
  //#region Load Cache
  //==============================
  const navigate = useNavigate()
  useEffect(() => {
    //Go back to the start if no data
    const noSelected = ModelBooking.virtualCare
      ? !ModelClinic.getSelectedVirtual()
      : !ModelClinic.getSelected()
    if (noSelected || !ModelBooking.getAppointment()) {
      //>>>>>
      navigate(Path.BookStart)
    }
  }, [navigate])

  return (
    <>
      <Nav title='Your Information' back={Path.BookTime} />
      <div className={css.bookInfo}>
        <BookInfoClinic />
        <BookInfoForm />
      </div>
    </>
  )
}
