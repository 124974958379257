//:::::::::::::::::::::::::
import { Selector, observer, ModelVirtualCare } from 'src/_Shared/global'
import css from './virtualInfo.module.css'
//:::::::::::::::::::::::::

function VirtualAdvantageMember() {
  return (
    <div className={css.advantageMember}>
      <p>Are you currently an advantage program member?</p>
      <Selector
        items={['Yes', 'No']}
        buttonStyle={true}
        selected={ModelVirtualCare.advantageMember ? 'Yes' : 'No' || ''}
        clicked={(value: string) => {
          ModelVirtualCare.setAdvantageMember(value === 'Yes')
        }}
      />
    </div>
  )
}

export default observer(VirtualAdvantageMember)
