//:::::::::::::::::::::::::
import css from './virtualBookPatient.module.css'
import {
  Selector,
  Input,
  useState,
  observer,
  useEffect,
  ModelVirtualCare,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

function VirtualInfoEmployee() {
  //State
  const [isEmployee, setIsEmployee] = useState<string | undefined>()
  const [employeeName, setEmployeeName] = useState('')

  useEffect(() => {
    if (isEmployee === 'No' || (isEmployee === 'Yes' && !employeeName.length)) {
      ModelVirtualCare.setEmployee(false)
    } else {
      ModelVirtualCare.setEmployee(true)
    }
  }, [isEmployee, employeeName]) //Don't include props

  return (
    <div className={css.bookPatientEmployee}>
      <p>
        Is the patient a NextCare Employee or eligible dependent of a NextCare
        Employee (legal spouse or legal child under the age of 26)?
      </p>
      <Selector
        buttonStyle
        items={['Yes', 'No']}
        selected={isEmployee || ''}
        clicked={(value: string) => {
          setIsEmployee(value)
        }}
      />
      {isEmployee === 'Yes' ? (
        <div className={css.bookPatientEmployeeYes}>
          <p>If yes, please also provide the name of the NextCare Employee</p>
          <Input
            type='text'
            label='NextCare Employee Name'
            value={employeeName}
            error='Employee name is required'
            valid={employeeName.length > 1}
            change={(event) => {
              setEmployeeName(event.target.value)
            }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default observer(VirtualInfoEmployee)
