//:::::::::::::::::::::::::
import {
  Nav,
  Path,
  Icon,
  Button,
  observer,
  ModelVirtualCare,
  ModelBooking,
  Helper,
  useEffect,
  moment,
  useState,
  ModelModal,
  useCallback,
  useNavigate,
} from 'src/_Shared/global'
import css from './virtualFinalize.module.css'
import useAccountStore from 'src/_Shared/stores/account'
//:::::::::::::::::::::::::

function VirtualFinalize() {
  const [appointment, setAppointment] = useState('Unavailable')
  const [account] = useAccountStore((state) => [state.account])
  const [bookingId, setBookingId] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const appt = ModelBooking.getAppointment()
    if (appt) {
      const ready = Helper.convertDateToDayAndTimeFull(moment(appt).toDate())
      setAppointment(ready)
    }
  }, [])

  //==============================
  //#region Book
  //==============================
  const clickBook = useCallback(async () => {
    ModelModal.showLoader('Please Wait', 'Booking visit...')
    //Submit booking
    const bookingIdReady = await ModelVirtualCare.create()
    if (bookingIdReady) {
      console.info('Booking ID:', bookingIdReady)
      ModelBooking.setBookingId(bookingIdReady)
      const bookingSummary = account?.phoneNumber
        ? (await ModelBooking.findAll(account?.phoneNumber)).find(
            (booking) => booking.bookingId === bookingIdReady,
          )
        : null
      if (bookingSummary) ModelBooking.setBookingSummary(bookingSummary)
      ModelVirtualCare.clear()
      navigate(Path.BookFinish)
      // setBookingId(bookingIdReady)
      // //Once booking completes, show the join button
      // setJoinCall(true)
    }

    ModelModal.hide()
  }, [])

  //==============================
  //#region UI
  //==============================
  return (
    <>
      <Nav title='Confirm & Book' back={Path.VirtualCareSignForms} />
      <div className={css.finalize}>
        <Icon name='medicalCalendar' width={92} />
        <h1>Finalize Your Visit</h1>
        <div className={css.fancyBar}>&mdash;</div>
        {ModelVirtualCare.patient && (
          <p>
            Patient Name:{' '}
            {ModelBooking.getPatientName(ModelVirtualCare.patient)}
          </p>
        )}
        <h2>{appointment}</h2>
      </div>
      <div className={css.button}>
        {/* === Finish booking and start virtual meeting === */}
        <Button primary text='Book Your Visit' clicked={clickBook} />
      </div>
    </>
  )
}

export default observer(VirtualFinalize)
