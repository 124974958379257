//:::::::::::::::::::::::::
import { observer } from 'mobx-react-lite'
import {
  ModelModal,
  ModalType,
  useNavigate,
  useCallback,
} from 'src/_Shared/global'
import css from './modal.module.css'

import ModalLoading from './Modals/ModalLoading'
import ModalSuccess from './Modals/ModalSuccess'
import ModalInfo from './Modals/ModalInfo'
import ModalError from './Modals/ModalError'
import ModalCancelBooking from './Modals/ModalCancelBooking'
import ModalBookingFound from './Modals/ModalBookingFound'
import ModalAllBills from './Modals/ModalAllBills'
import ModalInfoSaved from './Modals/ModalInfoSaved'
import ModalNoAppointments from './Modals/ModalNoAppointments'
import ModalAppointmentTaken from './Modals/ModalAppointmentTaken'
//:::::::::::::::::::::::::

function Modal() {
  const navigate = useNavigate()

  const clicked = useCallback(() => {
    ModelModal.hide()
    //If a link is specified, go to it
    if (ModelModal.link) {
      //>>>>>
      navigate(ModelModal.link)
    }
  }, [navigate])

  var content: React.ReactElement

  //Dynamically add a class for CSS animations
  let classVeil = `${css.modalVeil} ${
    ModelModal.showModal ? css.modalShow : ''
  }`

  let classBox = `${css.modalBox} ${ModelModal.showModal ? css.modalShow : ''}
  }`

  //Default width
  var width = 300

  switch (ModelModal.type) {
    //::::::::::::::::::::
    //Loader
    //::::::::::::::::::::
    case ModalType.Loading:
      width = 180
      content = <ModalLoading />
      break
    //::::::::::::::::::::
    //Info Saved
    //::::::::::::::::::::
    case ModalType.InfoSaved:
      content = <ModalInfoSaved />
      break
    //::::::::::::::::::::
    //Booking Found
    //::::::::::::::::::::
    case ModalType.BookingFound:
      content = <ModalBookingFound clicked={clicked} />
      break
    //::::::::::::::::::::
    //Appointment Taken
    //::::::::::::::::::::
    case ModalType.AppointmentTaken:
      content = <ModalAppointmentTaken />
      break
    //::::::::::::::::::::
    //No Appointments
    //::::::::::::::::::::
    case ModalType.NoAppointments:
      content = <ModalNoAppointments />
      break
    //::::::::::::::::::::
    //Cancel
    //::::::::::::::::::::
    case ModalType.CancelBooking:
      width = 330
      content = <ModalCancelBooking />
      break
    //::::::::::::::::::::
    //Cancel
    //::::::::::::::::::::
    case ModalType.AllBills:
      width = 330
      content = <ModalAllBills />
      break
    //::::::::::::::::::::
    //Success
    //::::::::::::::::::::
    case ModalType.Success:
      content = <ModalSuccess clicked={clicked} />
      break
    //::::::::::::::::::::
    //Info
    //::::::::::::::::::::
    case ModalType.Info:
      content = <ModalInfo clicked={clicked} />
      width = 246
      break
    //::::::::::::::::::::
    //Error
    //::::::::::::::::::::
    case ModalType.Error:
      content = <ModalError clicked={clicked} />
      break
    default:
      content = <>No modal type specified.</>
      break
  }

  //++++++++++
  return (
    <div className={classVeil} onClick={() => ModelModal.hide()}>
      {/* === Box === */}
      <div
        className={classBox}
        onClick={(event) => event.stopPropagation()}
        style={{ width: `${width}px` }}
      >
        {content}
      </div>
    </div>
  )
}

export default observer(Modal)
