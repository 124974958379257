//:::::::::::::::::::::::::
import { SummaryItem, ModelPaperwork } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function SummaryPatientEmergency() {
  return (
    <>
      <SummaryItem
        name='First Name'
        value={ModelPaperwork.paperwork.responses.emergencyContactFirstName}
      />
      <SummaryItem
        name='Last Name'
        value={ModelPaperwork.paperwork.responses.emergencyContactLastName}
      />
      <SummaryItem
        name='Mobile Number'
        value={ModelPaperwork.paperwork.responses.emergencyContactPhone}
      />
      <SummaryItem
        name='Relation to Patient'
        value={
          ModelPaperwork.paperwork.responses
            .emergencyContactRelationshipToPatient
        }
      />
    </>
  )
}
