//:::::::::::::::::::::::::
//Reusable abstraction of the phone field so we don't have to repeat the masking and error handling in each instance
import { Input, useState, Helper } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function FieldPhone(props: {
  value: string
  label?: string
  disabled?: boolean
  change: (value: string) => void
}) {
  const [ready, readySet] = useState(true)

  return (
    <Input
      type='text'
      label={props.label || 'Mobile Number'}
      value={props.value}
      disabled={props.disabled || false}
      change={(event) => {
        props.change(event.target.value)
        readySet(Helper.validatePhone(event.target.value))
      }}
      error='Please enter a valid phone number'
      valid={ready}
      mask={'999-999-9999'}
    />
  )
}
