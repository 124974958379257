//:::::::::::::::::::::::::
import Icon from 'src/_Shared/components/Icon/Icon'
import css from './documentList.module.css'
import { Switch } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function DocumentListItem(props: {
  id?: string
  text?: string
  subtext?: string
  url?: string
  accepted: boolean
  toggle?: () => void
  children?: React.ReactNode
  indent?: boolean
}) {
  return (
    <li key={props.id} className={props.indent ? css.indent : undefined}>
      {props.children ? (
        <div className={css.content}>
          <span className={css.pdf}>
            <Icon name='document' width={24} />
          </span>
          <p>{props.children}</p>
        </div>
      ) : (
        <a
          href={props.url}
          title={`View ${props.text}`}
          target='_blank'
          rel='noreferrer'
        >
          <span className={css.pdf}>
            <Icon name='document' width={24} />
          </span>
          <p>
            {props.text}
            {props.subtext && props.subtext.length > 0 && (
              <small>{props.subtext}</small>
            )}
          </p>
        </a>
      )}
      {props.toggle && (
        <div className={css.switchWrap}>
          <Switch on={props.accepted} changed={props.toggle} />
        </div>
      )}
    </li>
  )
}
