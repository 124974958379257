export namespace Foundation {
  //==============================
  //#region Env Variable Setup
  //==============================
  export const baseUrl = process.env.REACT_APP_BASE_URL!
  export const middlewareUrl = process.env.REACT_APP_MIDDLEWARE_URL!
  export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID!
  export const middleWareApiKey = process.env.REACT_APP_MIDDLEWARE_API_KEY!
  export const auth0BaseURL = process.env.REACT_APP_AUTH0_URL!
  export const auth0Redirect = process.env.REACT_APP_AUTH0_REDIRECT!
}
