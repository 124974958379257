import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import API from './api'
import { Helper, PracticeListBO, Types } from 'src/_Shared/global'

export interface Dependent {
  address1?: string
  address2?: string
  city?: string
  country?: string
  countryId?: string
  dateOfBirth?: string
  email?: string
  ethnicityId?: string
  ethnicityDescription?: string
  firstName?: string
  lastName?: string
  nextGenPersonId?: string
  phoneNumber?: string
  practiceId?: string
  raceDescription?: string
  raceId?: string
  sex?: string
  state?: string
  zipCode?: string
}

export interface Account {
  firstName?: string
  lastName?: string
  email?: string
  nextGenPersonId?: string
  auth0Id?: string
  sex?: string
  dateOfBirth?: string
  address1?: string
  address2?: string
  ethnicityId?: string
  ethnicityDescription?: string
  raceId?: string
  raceDescription?: string
  city?: string
  state?: string
  zipCode?: string
  phoneNumber?: string
  favoriteClinics?: PracticeListBO[]
  dependents?: Dependent[]
  insurances?: { id: string; cardNickName: string }[]
  country?: string
  countryId?: string
  serverTime?: string
  practiceId?: string
}

export interface AccountState {
  account: Partial<Account> | null
  idToken: string | null
  expiration: number | null
  setIdToken: (idToken?: string) => void
  accessToken: string | null
  setAccessToken: (accessToken?: string) => void
  setAccount: (account: Account) => void
  setExpiration: (expiration: number | null) => void
  resetAccount: () => void
  fetchAccount: () => Promise<Account | null>
  createUser: () => Promise<any>
  updateUser: () => Promise<any>
  linkAccounts: () => Promise<any>
  lookupUser: () => Promise<any>
  resetPassword: (email: string) => Promise<any>
  createDependent: (dependent: Dependent) => Promise<any>
  updateDependent: (dependent: Dependent) => Promise<any>
  isExpired: () => boolean
}

const useAccountStore = create<AccountState>()(
  persist(
    (set, get) => ({
      idToken: null,
      expiration: null,
      setIdToken: (idToken?: string) => set(() => ({ idToken })),
      accessToken: null,
      setAccessToken: (accessToken?: string) => set(() => ({ accessToken })),
      account: null,
      setAccount: (updates: Partial<Account>) => {
        const { account } = get()
        set(() => ({ account: { ...account, ...updates } }))
      },
      setExpiration: (expiration: number | null) => set(() => ({ expiration })),
      resetAccount: () => {
        set(() => ({
          account: null,
          idToken: null,
          accessToken: null,
          expiration: null,
        }))
      },
      fetchAccount: async () => {
        const { idToken, accessToken } = get()
        if (!idToken?.length || !accessToken?.length) return null
        const fetchedUser = await API.fetchUser(idToken, accessToken)
        fetchedUser &&
          set(() => ({
            ...get(),
            account: {
              ...get().account,
              ...fetchedUser,
            },
          }))
        return fetchedUser
      },
      createUser: async () => {
        const { account, idToken } = get()
        if (!account || !idToken?.length) return null
        return API.createUser(account, idToken)
      },
      updateUser: async () => {
        const { idToken, account } = get()
        if (!idToken?.length || !account || !account.dateOfBirth) return null
        const isMinor = !Helper.isAtLeast18YearsOld(account.dateOfBirth)
        return API.updateUser(account, idToken, isMinor)
      },
      linkAccounts: async () => {
        const account = get().account
        if (!account?.auth0Id?.length || !account?.nextGenPersonId?.length)
          return null
        return API.updateAuthUserWithPersonId(
          account.auth0Id,
          account.nextGenPersonId,
        )
      },
      lookupUser: async () => {
        const { idToken, account } = get()
        if (!idToken?.length || !account) return null
        const { firstName, lastName, dateOfBirth, sex, auth0Id } = account ?? {}
        if (
          !firstName?.length ||
          !lastName?.length ||
          !dateOfBirth?.length ||
          !sex?.length ||
          !auth0Id?.length
        )
          return null
        const result = await API.lookupNextGenAccount(
          {
            firstName,
            lastName,
            dateOfBirth,
            sex,
          },
          {
            auth0Id,
            idToken,
          },
        )

        console.info('******** nextGenPersonId:', result?.id)
        get().setAccount({ nextGenPersonId: result?.id })

        console.info('get():', get())

        return result?.id
      },
      resetPassword: async (email: string) => {
        return API.resetPassword(email ?? '')
      },
      createDependent: async (dependent: Dependent) => {
        const { account, idToken } = get()
        if (!account || !account.auth0Id?.length || !idToken) return null
        const result = await API.createDependent(
          dependent,
          account.auth0Id,
          idToken,
        )
        console.info('*** [createDependent] result:', result)
        return result
      },
      updateDependent: async (dependent: Dependent) => {
        const { account, idToken } = get()
        if (!account || !account.auth0Id?.length || !idToken) return null
        const isMinor = !Helper.isAtLeast18YearsOld(dependent.dateOfBirth ?? '')
        return API.updateUser(
          dependent,
          idToken,
          isMinor,
          account.nextGenPersonId,
        )
      },
      isExpired() {
        const { expiration } = get()
        if (!expiration) return false
        return expiration * 1000 < Date.now()
      },
    }),
    {
      name: 'account-storage',
    },
  ),
)

export default useAccountStore
