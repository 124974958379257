//:::::::::::::::::::::::::
import {
  Icon,
  ModelBooking,
  moment,
  Helper,
  ModelClinic,
  useEffect,
  observer,
  useState,
} from 'src/_Shared/global'
import css from './bookInfo.module.css'
import { useBookingStore } from 'src/_Shared/stores/booking'
//:::::::::::::::::::::::::
function BookInfoClinic() {
  const isOnDemand = useBookingStore((s) => s.isOnDemand)
  const [appointment, setAppointment] = useState('Unavailable')
  useEffect(() => {
    const appt = ModelBooking.getAppointment()
    if (appt) {
      setAppointment(Helper.convertDateToDayAndTime(moment(appt).toDate()))
    }
  }, [])

  //++++++++++++++++++++
  return (
    <>
      <div className={css.bookInfoClinic}>
        <Icon name='medicalCalendar' width={92} />
        <div>
          <p>
            {ModelClinic.selected?.details.brands.name ?? 'Name Unavailable'}
          </p>
          <h3>
            {ModelClinic.selected?.details.title ?? 'Clinic Title Unavailable'}
          </h3>
          {isOnDemand ? (
            <>
              <p>Visit Type</p>
              <h3>Walk-in</h3>
            </>
          ) : (
            <>
              <p>Selected Time</p>
              <h3>{appointment}</h3>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default observer(BookInfoClinic)
