//:::::::::::::::::::::::::
import css from './switch.module.css'
//:::::::::::::::::::::::::

export default function Switch(props: {
  on: boolean
  changed: (value: boolean) => void
  label?: string
  children?: React.ReactNode
}) {
  return (
    <div className={css.switchWrap}>
      <div>
        <label className={css.switch}>
          <input
            type='checkbox'
            checked={props.on}
            onChange={() => props.changed(!props.on)}
          />
          <span className={`${css.slider} ${css.round}`}></span>
        </label>
      </div>
      {props.children ? (
        <div className={css.switchChildren}>
          <p>{props.children}</p>
        </div>
      ) : (
        <p onClick={() => props.changed(!props.on)}>{props.label}</p>
      )}
    </div>
  )
}
