//:::::::::::::::::::::::::
import {
  List,
  ListLink,
  Path,
  Nav,
  Button,
  useState,
  useEffect,
  ModelModal,
} from 'src/_Shared/global'
// import DependentsAddOrUpdate from './AddOrUpdateDependent/DependentsAddOrUpdate'
import css from './insurances.module.css'
import { useAccountStore } from 'src/_Shared/stores/account'
import InsuranceUpload from '../Insurance/InsuranceUpload'
import useInsuranceStore from 'src/_Shared/stores/insurance'
import { Insurance } from 'src/_Shared/stores/insurance/store'
//:::::::::::::::::::::::::

export default function InsuranceList() {
  //==============================
  //#region State
  //==============================
  const [showForm, setShowForm] = useState(false)
  const [idToken] = useAccountStore((state) => [state.idToken])

  const [fetchInsurances, insurances, fetchInsuranceDetails] =
    useInsuranceStore((state) => [
      state.fetchInsurances,
      state.insurances,
      state.fetchInsuranceDetails,
    ])

  const [selectedInsurance, setSelectedInsurance] = useState<Insurance | null>(
    null,
  )

  useEffect(() => {
    const fetchAllInsurances = async () => {
      ModelModal.showLoader(
        'Please Wait',
        'Retrieving insurance information ...',
      )
      await fetchInsurances()
      ModelModal.hide()
    }
    fetchAllInsurances()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken])

  useEffect(() => {
    const fetchInsurance = async () => {
      if (insurances?.length) {
        const insurance = insurances[0]
        if (!insurance) return
        ModelModal.showLoader('Please Wait', 'Retrieving insurance details ...')
        await fetchInsuranceDetails(insurance.cardId)
        ModelModal.hide()
      }
    }
    fetchInsurance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurances])

  return (
    <div className={css.dependents}>
      {showForm ? (
        <InsuranceUpload
          insurance={selectedInsurance}
          onUpload={() => setShowForm(false)}
          onDelete={async () => {
            setShowForm(false)
          }}
        />
      ) : (
        <>
          <Nav back={Path.Account} title='Insurance' />
          <List>
            {insurances?.map((insurance, i) => (
              <ListLink
                key={i}
                text={insurance?.cardNickName ?? ''}
                clicked={async () => {
                  setSelectedInsurance(insurance)
                  setShowForm(true)
                }}
              />
            ))}
          </List>
          <div className={css.addButton}>
            <Button
              tertiary
              text='Add Insurance'
              clicked={() => {
                setSelectedInsurance(null)
                setShowForm(true)
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}
