//:::::::::::::::::::::::::
import { makeObservable, HttpClient, HttpResult, API } from 'src/_Shared/global'
//:::::::::::::::::::::::::

class ModelApp {
  //==============================
  //#region Class Setup
  //==============================
  private static _instance: ModelApp
  //Properties
  userAgent: string = ''

  constructor() {
    //Set props to true to make reactive
    makeObservable(this, {
      //Methods
      //show: action,

      //Enable properties
      userAgent: true,
    })
  }

  //Initialize our singleton
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  //==============================
  //#region User Agent
  //==============================
  getUserAgent() {
    const userAgent = navigator.userAgent

    if (/android/i.test(userAgent)) {
      return 'Android'
    }

    if (/iPad|iPhone|iPod|iPad Pro|iPad Air|iPad Mini/.test(userAgent)) {
      return 'Apple'
    }
    return ''
  }

  //==============================
  //#region Public Key
  //for Encrypting Document Uploads
  //==============================
  async getPublicKey(
    ConsentURL: string,
  ): Promise<HttpResult<{ publicKey: string }>> {
    const httpClient = new HttpClient<{ publicKey: string }, any>()
    const response = await httpClient.get(ConsentURL)
    //:::
    return response
  }

  //==============================
  //#region Middleware Logs
  //==============================
  async getLogs() {
    const response = await API.get('log/getdetails')
    //const httpClient = new HttpClient<any, any>()
    //const response = await httpClient.post('log/getdetails', null)
    //:::
    return response
  }
}

export default ModelApp.Instance
