import { NotificationDataBO } from 'src/BOs/NotificationDataBO'
import { Helper, HttpClient, action, makeObservable } from '../global'

class ModelNotifications {
  //==============================
  //#region Class Setup
  //==============================
  private static _instance: ModelNotifications
  //Properties
  notifications: NotificationDataBO[] = []

  constructor() {
    //Set props to true to make reactive
    makeObservable(this, {
      //Methods
      get: action,
      //Enable properties
      notifications: true,
    })
  }

  //Initialize our singleton
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  //==============================
  //#region Setters
  //==============================
  setNotifications(values: NotificationDataBO[]) {
    this.notifications = values
  }

  //==============================
  //#region Get Notifications
  //==============================
  async get(auth0Id: string) {
    try {
      const httpClient = new HttpClient<NotificationDataBO[], any>()
      const idToken = localStorage.getItem('idToken')

      const response = await httpClient.post(
        'v2/app/notification/getnotifications',
        null,
        {
          headers: {
            auth0id: auth0Id,
            ...(idToken && { idtoken: idToken }),
          },
        },
      )

      this.setNotifications(response.data || [])
    } catch (error) {
      Helper.handleError('getNotifications', error)
    }
  }

  //==============================
  //#region Remove Notifications
  //==============================
  async removeNotification(notificationId: string, auth0Id: string) {
    try {
      const httpClient = new HttpClient<any, any>()
      const test = localStorage.getItem('idToken')

      const response = await httpClient.post(
        'v2/app/notification/delete',
        null,
        {
          headers: {
            notificationId: notificationId,
            auth0id: auth0Id,
            ...(test && { idtoken: test }),
          },
        },
      )

      await this.get(auth0Id)
      return response
    } catch (error) {
      Helper.handleError('getNotifications', error)
    }
  }
}

export default ModelNotifications.Instance
