//:::::::::::::::::::::::::
import { Divider, FieldPhone, FieldText, Types } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function PaperworkEmployerInfo(props: {
  values: Types.Paperwork
  change: (values: Types.Paperwork) => void
}) {
  return (
    <div>
      <Divider />
      <h2>Employer Information</h2>
      {/* === Employer === */}
      <FieldText
        value={props.values.responses.employer}
        label='Employer'
        change={(value) => {
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              employer: value,
            },
          })
        }}
      />

      {/* === Employer Contact === */}
      <FieldText
        value={props.values.responses.workInjuryEmployerContactName}
        label='Employer Contact Name'
        change={(value) => {
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              workInjuryEmployerContactName: value,
            },
          })
        }}
      />

      {/* === Employer Contact Phone === */}
      <FieldPhone
        value={props.values.responses.workInjuryEmployerContactPhoneNumber}
        label='Employer Contact Phone Number'
        change={(value) => {
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              workInjuryEmployerContactPhoneNumber: value,
            },
          })
        }}
      />
    </div>
  )
}
