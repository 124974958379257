//:::::::::::::::::::::::::
import {
  HttpClient,
  ModelModal,
  PracticeListBO,
  moment,
  Helper,
  makeObservable,
  action,
  ConsentListResponseBO,
  Types,
  ModelBooking,
  ModelMap,
  runInAction,
  GetPracticeByZipResponseBO,
  PracticeDetailsResponseBO,
  PracticeDetailsBO,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

type SelectedVirtual = {
  times?: Types.ClinicTimes
  practiceDetails?: PracticeDetailsBO
}

class ModelClinic {
  //==============================
  //#region Class Setup
  //==============================
  private static _instance: ModelClinic
  //Properties
  clinics: Types.Clinic[] = [] //All clinics
  clinicsVisibleOnMap: Types.Clinic[] = [] //Visible clinics
  selected: Types.Clinic | undefined
  // virtual care cannot use selected, because getAllClinics does not return virtual clinics
  selectedVirtual?: SelectedVirtual

  constructor() {
    //Set props to true to make reactive
    makeObservable(this, {
      //Reactive Properties
      clinics: true,
      selected: true,
      clinicsVisibleOnMap: true,
      selectedVirtual: true,

      //Methods
      setClinics: action,
      setClinicsVisibleOnMap: action,
      setSelected: action,
      getNextAvailableTimeForClinic: action,
      update: action,
      setSelectedVirtual: action,
      setPracticeDetails: action,
      setVirtualTimes: action,
    })
  }

  //Initialize our singleton
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  //==============================
  //#region Getters
  //==============================
  getSelected(): Types.Clinic | undefined {
    if (localStorage.getItem('clinic')) {
      const clinic = JSON.parse(localStorage.getItem('clinic')!)
      this.setSelected(clinic)
      return clinic
    } else {
      return undefined
    }
  }

  getClinics(): Types.Clinic[] {
    if (this.clinics.length === 0 && localStorage.getItem('clinics')) {
      const clinics = JSON.parse(
        localStorage.getItem('clinics')!,
      ) as Types.Clinic[]
      this.setClinics(clinics)
      return clinics
    } else {
      return this.clinics
    }
  }

  //==============================
  //#region Virtual Care Getters
  //==============================
  async getPracticeByZip(zip: string) {
    const httpClient = new HttpClient<GetPracticeByZipResponseBO, any>()
    const response = await httpClient.post(
      'v2/nextgen/location/practicebyzip',
      '',
      {
        headers: {
          zip: zip,
        },
      },
    )
    return response.data
  }
  async getZipByCityAndState(city: string, state: string) {
    const httpClient = new HttpClient<any, any>()
    const response = await httpClient.post(
      'v2/nextgen/location/zipsearch',
      '',
      {
        headers: {
          city: city,
          state: state,
        },
      },
    )
    return response.data.Zipcode
  }
  async getPracticeDetailsById(locationId: string) {
    ModelModal.showLoader(undefined, 'Getting practice details...')
    const httpClient = new HttpClient<PracticeDetailsResponseBO, any>()
    const response = await httpClient.post('v2/solv/locations/details', '', {
      headers: {
        locationid: locationId,
      },
    })

    this.setPracticeDetails(response.data?.data)
    ModelModal.hide()
    return response.data?.data
  }
  async getNextAvailableTimeForPractice(practiceDetails: PracticeDetailsBO) {
    if (localStorage.getItem('selectedVirtual')) {
      const times = await ModelBooking.getTimeSlotsForClinic(practiceDetails.id)
      if (times) this.setVirtualTimes(times)
    }
  }
  getSelectedVirtual(): SelectedVirtual | undefined {
    if (localStorage.getItem('selectedVirtual')) {
      const selectedVirtual = JSON.parse(
        localStorage.getItem('selectedVirtual')!,
      )
      this.setSelectedVirtual(selectedVirtual)
      return selectedVirtual
    } else {
      return undefined
    }
  }

  //==============================
  //#region Virtual Care Setters
  //==============================
  setSelectedVirtual(value?: SelectedVirtual) {
    this.selectedVirtual = value
    if (!value) return localStorage.removeItem('selectedVirtual')
    localStorage.setItem('selectedVirtual', JSON.stringify(value))
  }

  async setPracticeDetails(value?: PracticeDetailsBO) {
    let newSelectedVirtual
    if (value) {
      newSelectedVirtual = { ...this.selectedVirtual, practiceDetails: value }
    } else {
      //Clear
      newSelectedVirtual = {
        ...this.selectedVirtual,
        practiceDetails: undefined,
      }
    }
    this.setSelectedVirtual(newSelectedVirtual)
  }

  async setVirtualTimes(value: Types.ClinicTimes) {
    let newSelectedVirtual
    if (value) {
      newSelectedVirtual = { ...this.selectedVirtual, times: value }
    } else {
      //Clear
      newSelectedVirtual = {
        ...this.selectedVirtual,
        times: undefined,
      }
    }
    this.setSelectedVirtual(newSelectedVirtual)
  }

  //==============================
  //#region Setters
  //==============================
  setClinics(values: Types.Clinic[]) {
    this.clinics = values
  }
  setClinicsVisibleOnMap(values: Types.Clinic[]) {
    this.clinicsVisibleOnMap = values
  }

  async setSelected(value?: Types.Clinic) {
    if (value) {
      this.selected = value

      //Get the time if it's not cached yet
      await this.getNextAvailableTimeForClinic(value)

      //Reset date tab when picking a time
      ModelBooking.setSelectedTab('Today')

      //***
      localStorage.setItem('clinic', JSON.stringify(value))
    } else {
      //Clear
      this.selected = undefined
      localStorage.removeItem('clinic')
    }
  }

  //==============================
  //#region Get Clinic Times
  //==============================
  async getNextAvailableTimeForClinic(clinic: Types.Clinic) {
    //Loading...
    runInAction(() => {
      clinic.loading = true
    })

    //Get time slots if not cached
    if (localStorage.getItem(clinic.id)) {
      //Get time slot from cache
      const clinicTemp = JSON.parse(
        localStorage.getItem(clinic.id)!,
      ) as Types.Clinic
      //---
      clinic.times = clinicTemp.times
    } else {
      //Query for time slots if not cached
      clinic.times = await ModelBooking.getTimeSlotsForClinic(clinic.id)

      //***
      localStorage.setItem(clinic.id, JSON.stringify(clinic))
    }
    if (clinic.times) ModelBooking.setTimeSlotPackages(clinic.times.timeSlots)

    //Done!
    runInAction(() => {
      clinic.loading = false
    })
  }

  async update(value: Types.Clinic) {
    //Update clinic loading state
    const clinicLoading: Types.Clinic = {
      ...value,
      loading: true,
    }

    //Update object in the list to show loading state
    runInAction(() => {
      const index = this.clinics.findIndex((clin) => clin.id === value.id)
      this.clinics[index] = clinicLoading
    })

    let times: Types.ClinicTimes | undefined
    if (localStorage.getItem(value.id)) {
      //Get time slot from cache
      const clinicTemp = JSON.parse(
        localStorage.getItem(value.id)!,
      ) as Types.Clinic
      times = clinicTemp.times
    } else {
      //Query for time slots if not cached
      times = await ModelBooking.getTimeSlotsForClinic(value.id)
      console.log(`=== ${value.details.title} - ${value.id} ===`)
    }

    //Update object
    const clinic: Types.Clinic = {
      ...value,
      times: times,
    }

    //Update object in the list with next available time
    runInAction(() => {
      const index = this.clinics.findIndex((clin) => clin.id === clinic.id)
      this.clinics[index] = clinic
    })

    //***
    localStorage.setItem(clinic.id, JSON.stringify(clinic))
    //:::
    return clinic
  }

  //==============================
  //#region Get Clinic by ID
  //==============================
  async getClinic(clinicId?: string, detailsId?: number) {
    // ModelModal.showLoader('Please Wait', 'Getting clinic...')

    //Need to get the full clinic info (like in maps) so we can set the selected clinic in case they click "Not Me" during the QR code check-in flow
    await this.getAllClinics()

    //Find the clinic with the specified ID
    const clinics = this.clinics.filter((clinic: Types.Clinic) => {
      return clinicId
        ? clinic.details.checkin.ID === clinicId
        : clinic.details.ID === detailsId
    })

    //:::
    if (clinics[0]) {
      console.log(`✅ Found the clinic`)
      // ModelModal.hide()
      return clinics[0]
    } else {
      // ModelModal.hide()
      return undefined
    }
  }

  //==============================
  //#region Get All Clinics
  //==============================
  async getAllClinics() {
    var clinicsReady: Types.Clinic[] = []

    console.log(`🔄 Finding all clinics...`)

    try {
      //Lookup booking with phone number
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.get('v2/locations', {
        headers: {
          isprimarycare: ModelBooking.primaryCare,
        },
      })

      const clinicList = response.data as PracticeListBO[]

      //Build the list of clinics
      for (const [index, clinic] of clinicList.entries()) {
        const clinicHours = this.getClinicHours(clinic)

        //Clear cached version of the clinic
        localStorage.removeItem(clinic.checkin.ID)

        //Break title apart into search terms; example: San Antonio (Terrell Plaza)
        var titlePieces = clinic.title.split(' (')

        //The first term is the main clinic title
        var titleTerms: string[] = [titlePieces[0]]

        //Add parentheses value (if applicable)
        var wordsInParentheses = ''
        if (titlePieces[1]) {
          wordsInParentheses = titlePieces[1].replaceAll(')', '')
        }

        if (wordsInParentheses.length > 0) {
          titleTerms.push(wordsInParentheses)
        }

        //Prep cross streets for search
        var crossStreets = clinic.cross_streets

        const stringsToRemove = [
          'n.',
          'e.',
          's.',
          'w.',
          'av',
          'ave',
          'blvd',
          'rd',
          '&',
          'st',
          'dr',
          '(',
          ')',
          'pkwy',
          '.',
        ]

        for (const target of stringsToRemove) {
          crossStreets = crossStreets.toLowerCase().replaceAll(target, '')
        }

        //Break into separate search terms
        var crossStreetsTerms = crossStreets.split(' ')
        crossStreetsTerms = crossStreetsTerms.map((term) =>
          term.replaceAll(' ', ''),
        )
        //Remove empty values
        crossStreetsTerms = crossStreetsTerms.filter((term) => term)

        const clinicReady: Types.Clinic = {
          id: clinic.checkin.ID,
          index: index,
          distance: 0,
          details: clinic,
          isOpen: clinicHours.isOpen,
          openHours: clinicHours.openHours,
          latitude: Number(clinic.geo.latitude),
          longitude: Number(clinic.geo.longitude),
          searchTerms: [
            ...titleTerms.map((term) => term.toLowerCase()),
            ...crossStreetsTerms,
            clinic.address.city.toLowerCase(),
            clinic.address.state.toLowerCase(),
            clinic.address.zipcode,
          ],
        }
        //console.log(clinicReady.searchTerms)

        clinicsReady.push(clinicReady)
      }

      console.log(`✅ Found ${clinicsReady.length} clinics`)

      //:::
      this.setClinics(clinicsReady)
    } catch (error) {
      //--- ! ---
      Helper.handleError('getAllClinics', error)
    }
  }

  sortClinicsByDistance(currentLatitude: number, currentLongitude: number) {
    //Calculate distance to location provided

    for (const clinic of this.clinics) {
      //Update distance value
      const distance = ModelMap.distanceBetweenCoordinates(
        currentLatitude || 0,
        currentLongitude || 0,
        clinic.details.geo.latitude,
        clinic.details.geo.longitude,
      )
      clinic.distance = Math.round(distance)
    }

    //Put in order of distance
    const clinicsReady = this.clinics.sort(
      (clinic1, clinic2) => clinic1.distance - clinic2.distance,
    )
    //:::
    this.setClinics(clinicsReady)
  }

  //==============================
  //#region Clinic Hours
  //==============================
  getClinicHours(clinic: PracticeListBO) {
    var isOpen: boolean = false
    var openHours: string = ''

    const today = moment()
    const tomorrow = moment().add(1, 'day')

    const dayOfTheWeek = today.format('dddd')
    const dayOfTheWeekTomorrow = tomorrow.format('dddd')

    const todayHours = clinic.open_hours.find(
      (hours) => hours.day_of_the_week === dayOfTheWeek,
    )
    if (todayHours) {
      //Calculate if open now based on open and close time for the current day
      const openTime = moment(todayHours.open_time, ['H:mm'])
      const closeTime = moment(todayHours.close_time, ['H:mm'])
      const now = moment()

      //Set if it's open or not by calculating if right now is between open and closing time
      isOpen = openTime.isBefore(now) && closeTime.isAfter(now)

      if (isOpen && todayHours) {
        //Open today until...
        openHours = `until ${closeTime.format('h:mma')}`
      } else {
        //Opens tomorrow at...
        const tomorrowHours = clinic.open_hours.find(
          (hours) => hours.day_of_the_week === dayOfTheWeekTomorrow,
        )
        if (tomorrowHours) {
          //Get tomorrow's open time and format it
          const tomorrowOpenTime = moment(tomorrowHours.open_time, ['H:mm'])
          openHours = `Opens at ${tomorrowOpenTime.format('h:mma')}`
        }
      }
    }
    //:::
    return { isOpen: isOpen, openHours: openHours }
  }

  //==============================
  //#region Waiting List
  //==============================
  async getWaitingList(clinicId: string) {
    console.log(`🔄 Getting Clinic Waiting List...`)

    try {
      //Lookup booking with phone number
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post('v2/solv/locations/waitlist', '', {
        headers: {
          locationid: clinicId,
        },
      })

      console.log(`✅`, response)

      //:::
    } catch (error) {
      //--- ! ---
      Helper.handleError('getWaitList', error)
    }
  }

  //==============================
  //#region Clinic Legal Docs
  // For Review and Sign Forms screen (BookSign.tsx)
  //==============================
  async getReviewAndSignFormsForClinic(clinicId: string) {
    console.log('🔄 Getting consent forms...')
    //const clinicId = 'p8RYD0'
    try {
      //Lookup booking with phone number
      const httpClient = new HttpClient<ConsentListResponseBO, any>()
      const response = await httpClient.post(
        'v2/solv/locations/consentsform',
        null,
        {
          headers: {
            locationid: clinicId,
          },
        },
      )

      //Gather up the consent IDs (for booking) and links (for UI)
      var formIdsReady: string[] = []
      var docsReady: Types.DocumentListItem[] = []

      if (response.data) {
        for (const form of response.data?.data.results) {
          formIdsReady.push(form.id)
          var subtext = ''
          if (
            form.display_name.includes(
              'Authorization, Acknowledgement and Consent Summary',
            )
          ) {
            subtext =
              'Specific consent statements within this document are not applicable to Department of Transportation (DOT) drug or alcohol testing services only.'
          }
          docsReady.push({
            id: form.id,
            text: form.display_name,
            subtext: subtext,
            url: `https://${form.aws_bucket_name}.s3.amazonaws.com/${form.aws_path}`,
            accepted: false,
          })
        }
      }
      //*****
      //Save consent form IDs locally
      localStorage.setItem('consent', JSON.stringify(formIdsReady))

      //:::
      return docsReady
    } catch (error) {
      //--- ! ---
      Helper.handleError('getReviewAndSignFormsForClinic', error)
    }
  }

  //==============================
  //#region Misc
  //==============================
  getLatLongFromPracticeDetails = (practiceDetails: PracticeDetailsBO) => {
    // remove parentheses and comme
    const practiceLatLong = practiceDetails.lat_long
      .replace(/[()]/g, '')
      .split(',')
    return { lat: practiceLatLong[0], long: practiceLatLong[1] }
  }

  parseInsuranceList() {}
}

export default ModelClinic.Instance
