//:::::::::::::::::::::::::
import css from './bookPatientCss.module.css'
import { Textarea } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function BookPatientReason(props: {
  valid?: boolean
  change: (value: string) => void
}) {
  return (
    <div className={css.bookPatientReason}>
      <h4>Reason for Visit</h4>
      <Textarea
        placeholder='Briefly describe why you are coming in...'
        error='Please enter a reason'
        valid={props.valid}
        change={(event) => props.change(event.target.value)}
      />
    </div>
  )
}
