//:::::::::::::::::::::::::
import { SummaryItem, ModelPaperwork } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function SummaryPaymentInsuranceSecondary() {
  return (
    <>
      <SummaryItem
        name='Insurance Company'
        value={ModelPaperwork.paperwork.responses.secondaryInsuranceCompany}
      />
      <SummaryItem
        name='Member ID'
        value={ModelPaperwork.paperwork.responses.secondaryMemberId}
      />
      <SummaryItem
        name='Group ID'
        value={ModelPaperwork.paperwork.responses.secondaryGroupNumber}
      />
      <SummaryItem
        name='Policy Holder First Name'
        value={
          ModelPaperwork.paperwork.responses.secondaryPolicyHolderFirstName
        }
      />
      <SummaryItem
        name='Policy Holder Last Name'
        value={ModelPaperwork.paperwork.responses.secondaryPolicyHolderLastName}
      />
      <SummaryItem
        name='Policy Holder Birth Date'
        value={
          ModelPaperwork.paperwork.responses.secondaryPolicyHolderBirthDate
        }
      />
      <SummaryItem
        name='Patient Relationship to Policy Holder'
        value={
          ModelPaperwork.paperwork.responses
            .secondaryPatientsRelationshipToInsured
        }
      />
    </>
  )
}
