//:::::::::::::::::::::::::
import { useRef, Icon, Button } from 'src/_Shared/global'
import css from './carousel.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperCore } from 'swiper/types'
import { Navigation, Pagination, Keyboard, Mousewheel } from 'swiper/modules'
// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/keyboard'
//:::::::::::::::::::::::::

export default function Carousel(props: { slides: React.ReactNode[] }) {
  const swiperRef = useRef<SwiperCore>()

  return (
    <div className={css.swiper}>
      {/* === Left/right arrows were not needed right away but may need to be added later on === */}
      {/* <button
        className={css.prev}
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <Icon name='arrowLeft' width={20} />
      </button> */}
      <Swiper
        onBeforeInit={(swiper: SwiperCore) => {
          swiperRef.current = swiper
        }}
        modules={[Pagination, Navigation, Keyboard, Mousewheel]}
        className={css.swiper}
        navigation={false}
        pagination={{ clickable: true }}
        keyboard={true}
        mousewheel={true}
        loop={true}
      >
        {props.slides.map((slide, index) => {
          return <SwiperSlide key={index}>{slide}</SwiperSlide>
        })}
      </Swiper>
      {/* <button
        className={css.next}
        onClick={() => swiperRef.current?.slideNext()}
      >
        <Icon name='arrowRight' width={20} />
      </button> */}
    </div>
  )
}
