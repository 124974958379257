//:::::::::::::::::::::::::
import { makeObservable, action } from 'mobx'
import {
  ClinicCenterBO,
  PracticeFiltersBO,
  PracticeServiceBO,
  ModelModal,
  Helper,
  ModelClinic,
  Types,
  HttpClient,
  GeocodeResponseBO,
} from 'src/_Shared/global'

//:::::::::::::::::::::::::

class ModelMap {
  //==============================
  //#region Class Setup
  //==============================
  private static _instance: ModelMap
  //Properties
  map: google.maps.Map | undefined
  defaultLocation: ClinicCenterBO = { lat: 39.81, lng: -98.56 } // center of the U.S.
  mapCenter: ClinicCenterBO = { lat: 39.81, lng: -98.56 }
  userLatitude: number = 39.81
  userLongitude: number = -98.56
  showFilters: boolean = false
  filters: PracticeFiltersBO = {
    isOpen: false,
    services: ['all'],
    waitTime: 'ALL',
  }
  filterServices: PracticeServiceBO[] = []
  showMap: boolean = true
  mapZoomThreshold: number = 12 //The zoom level where next available time pins start showing
  mapZoom: number = 11 //Default
  locationSearchRadius: number = 30 //Defined by the middleware
  southwestPoint: ClinicCenterBO = { lat: 0, lng: 0 }
  northeastPoint: ClinicCenterBO = { lat: 0, lng: 0 }
  favoriteSearch: boolean = false
  showMiles: boolean = false

  constructor() {
    //Set props to true to make reactive
    makeObservable(this, {
      //Methods
      setMap: action,
      setUserLocation: action,
      setShowMiles: action,
      setMapCenter: action,
      setUserLatitude: action,
      setUserLongitude: action,
      setShowFilters: action,
      setShowMap: action,
      setFavoriteSearch: action,
      setMapZoom: action,
      updateMapMarkers: action,

      //Properties
      map: true,
      mapCenter: true,
      mapZoom: true,
      userLatitude: true,
      userLongitude: true,
      showMiles: true,
      showMap: true,
      showFilters: true,
      filters: true,
      favoriteSearch: true,
      filterServices: true,
    })
  }

  //Initialize our singleton
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  //==============================
  //#region Get User Location
  //==============================
  options = {
    enableHighAccuracy: true,
    timeout: 10000, //Only let it find your location for 10 seconds
    maximumAge: 0,
  }

  async getUserLocation() {
    //Location not supported
    if (!navigator.geolocation) {
      ModelModal.showError(
        'Location services are not supported by your web browser.',
      )
      return
    }

    ModelModal.showLoader('Please Wait', 'Getting location...')

    //Location Supported; prompt the user for permission
    try {
      const result = await navigator.permissions.query({ name: 'geolocation' })

      const position: any = await new Promise((resolve, reject) => {
        //Check their response
        switch (result.state) {
          //::::::::::::::::
          case 'granted':
            navigator.geolocation.getCurrentPosition(
              (position) => {
                //:::
                resolve({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                })
              },
              (error: GeolocationPositionError) => {
                console.log(
                  `You granted permission to use your location, but there was an error:\n\n${error.message}`,
                )
                reject()
              },
              this.options,
            )
            break
          //::::::::::::::::
          case 'prompt':
            navigator.geolocation.getCurrentPosition(
              (position) => {
                //:::
                resolve({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                })
              },
              (error) => {
                console.log(
                  `There was an error after replying to the prompt to use your location:\n\n${JSON.stringify(
                    error,
                  )}`,
                )
                reject()
              },
              this.options,
            )
            return
          //::::::::::::::::
          default:
            ModelModal.showError(
              'Location Off',
              'Location services are disabled in your web browser.',
            )
            reject()
        }
      })
      //:::
      this.setUserLocation(position.latitude, position.longitude)
      return
    } catch (error) {
      //--- ! ---
      Helper.handleError('getUserLocation', error)
      return
    }
  }
  setUserLocation(latitude: number, longitude: number) {
    this.setUserLatitude(latitude)
    this.setUserLongitude(longitude)

    //Update clinic distances
    ModelClinic.sortClinicsByDistance(latitude, longitude)

    this.setMapCenter({
      lat: latitude,
      lng: longitude,
    })
    ModelModal.hide()
  }

  //==============================
  //#region Setters
  //==============================
  setMap(value: google.maps.Map) {
    this.map = value
  }
  setMapZoom(value: number) {
    this.mapZoom = value
  }
  setUserLatitude(value: number) {
    this.userLatitude = value
  }
  setUserLongitude(value: number) {
    this.userLongitude = value
  }
  setShowFilters(value: boolean) {
    this.showFilters = value
  }
  setFilters(values: PracticeFiltersBO) {
    this.filters = values
  }
  setFilterServices(values: PracticeServiceBO[]) {
    this.filterServices = values
  }
  setShowMap(value: boolean) {
    this.showMap = value
  }
  setFavoriteSearch(value: boolean) {
    this.favoriteSearch = value
  }
  setShowMiles(value: boolean) {
    this.showMiles = value
  }
  setMapCenter(center: ClinicCenterBO) {
    this.mapCenter = center
  }

  //==============================
  //#region Search Clinics
  //==============================
  async search(terms: string) {
    ModelModal.showLoader('Please Wait', 'Finding clinics...')

    //Search for their terms in the clinic list first
    const foundClinics = ModelClinic.clinics.filter((clinic) => {
      return clinic.searchTerms.includes(terms.toLowerCase())
    })
    if (foundClinics.length > 0 && foundClinics[0]) {
      //Zoom out a bit
      this.setMapZoom(10)
      this.setUserLocation(foundClinics[0].latitude, foundClinics[0].longitude)
      return
    }

    //If nothing is found, search using the API
    const httpClient = new HttpClient<GeocodeResponseBO, any>()
    const response = await httpClient.post('v2/nextgen/location/geocode', '', {
      headers: {
        address: terms,
      },
    })

    //Clear previously selected clinic
    ModelClinic.setSelected(undefined)
    if (response.data) {
      this.setUserLocation(
        response.data.geoLocation.lat,
        response.data.geoLocation.lng,
      )
    }

    //Update map after a search
    await this.updateMapMarkers()

    //Check if no clinics showed up; if nothing, show nearest
    if (ModelClinic.clinicsVisibleOnMap.length === 0) {
      if (ModelClinic.clinics[0]) {
        const nearestClinic = ModelClinic.clinics[0]
        //Set nearest clinic
        this.setUserLocation(nearestClinic.latitude, nearestClinic.longitude)
      }
    }

    ModelModal.hide()
  }

  //==============================
  //#region Update Map Markers
  //Happens on zoom or pan
  //==============================
  async updateMapMarkers() {
    //::: 1 ::: Update current zoom level
    if (this.map?.getZoom()) {
      this.setMapZoom(this.map.getZoom()!)
    }

    //::: 2 ::: Update what is visible on the map
    var clinicsOnMap: Types.Clinic[] = []
    for (const clinic of ModelClinic.clinics) {
      if (this.markerIsInside(clinic.latitude, clinic.longitude)) {
        clinicsOnMap.push(clinic)
      }
    }
    ModelClinic.setClinicsVisibleOnMap(clinicsOnMap)

    //::: 3 ::: Load times if zoomed in enough
    if (this.mapZoom < this.mapZoomThreshold) return

    for (const clinic of clinicsOnMap) {
      //Get clinics visible on the map
      ModelClinic.getNextAvailableTimeForClinic(clinic)
    }
  }

  //==============================
  //#region Check Markers Visible on Map
  //==============================
  markerIsInside(markerLatitude: number, markerLongitude: number) {
    const sw = this.map?.getBounds()?.getSouthWest()
    const ne = this.map?.getBounds()?.getNorthEast()

    if (!sw || !ne) return false
    if (
      sw.lat() < markerLatitude &&
      sw.lng() < markerLongitude &&
      ne.lat() > markerLatitude &&
      ne.lng() > markerLongitude
    ) {
      //Marker is inside geo box
      return true
    } else {
      //Marker is outside
      return false
    }
  }

  //==============================
  //#region Distance between Coordinates
  //==============================
  distanceBetweenCoordinates(
    latitude1: number,
    longitude1: number,
    lat2: string,
    long2: string,
  ) {
    const r = 6371 // km
    const p = Math.PI / 180

    //Convert to numbers
    const latitude2 = +lat2
    const longitude2 = +long2

    const a =
      0.5 -
      Math.cos((latitude2 - latitude1) * p) / 2 +
      (Math.cos(latitude1 * p) *
        Math.cos(latitude2 * p) *
        (1 - Math.cos((longitude2 - longitude1) * p))) /
        2
    //:::
    return Number(2 * r * Math.asin(Math.sqrt(a)))
  }
}

export default ModelMap.Instance
