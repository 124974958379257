//:::::::::::::::::::::::::
import css from './virtualPayment.module.css'
import {
  Foundation,
  useState,
  useEffect,
  Nav,
  Path,
  useNavigate,
  PaymentSaleBodyBO,
  ModelPay,
  ModelVirtualCare,
  ModelModal,
} from 'src/_Shared/global'
import { useAccountStore } from 'src/_Shared/stores/account'
//:::::::::::::::::::::::::

export default function VirtualSelfPay() {
  const navigate = useNavigate()
  const [url, setUrl] = useState('')
  const [account] = useAccountStore((state) => [state.account])

  //==============================
  //#region iFrame Setup
  //==============================
  useEffect(() => {
    //Load the credit card form locally or else use the environment
    //if (Foundation.baseUrl === 'http://localhost:3000/') {
    //setUrl('https://nextcaredev.wpengine.com/wp-content/instamedtrial.html')
    //setUrl('https://nextcarestg.wpengine.com/wp-content/instamedtrial.html')
    ////} else {
    //setUrl(`${Foundation.baseUrl}wp-content/instamedtrial.html`)
    //}
    setUrl(`${Foundation.baseUrl}/instamedtrial.html`)
  }, [])

  //==============================
  //#region Extract Credit Card
  //==============================
  useEffect(() => {
    async function pay(
      payment: PaymentSaleBodyBO,
      clinicId: string,
      patientId: string,
    ) {
      //:::
      ModelModal.showLoader('Please Wait', 'Submitting payment...')
      await ModelPay.makeAPayment(payment, clinicId, patientId)
      ModelModal.hide()
      //>>>>>
      navigate(Path.VirtualCareSignForms)
    }
    const url = document?.querySelector('#iframe_id')

    if (!url) return
    url.addEventListener('load', async () => {
      const iframe = document?.querySelector('#iframe_id') as HTMLIFrameElement
      if (!iframe) return

      //Get iframe URL
      const encodedUrl = iframe.contentWindow?.location.href
      if (!encodedUrl) return
      var decodedURI = decodeURI(encodedUrl)
      console.log('encodedUrl: ', encodedUrl)
      if (!encodedUrl.includes('cardDetails')) return

      //Parse credit card details off URL
      //Example: https://nextcare.com/wp-content/capture-query-params.html?cardDetails={%22cvn%22:%22821%22,%22expDate%22:%2212/24%22,%22token%22:%224111110955461111%22,%22tokenID%22:%2238c53afb-ec9c-4d88-9632-eef0eb72a42f%22}
      const pieces = decodedURI.split('cardDetails=')
      const jsonValues = JSON.parse(pieces[1])

      //Prep payment submission
      let payment: PaymentSaleBodyBO = {
        CardNumber: jsonValues.token,
        Expiration: jsonValues.expDate,
        Amount: ModelVirtualCare.price,
        Memo: 'Virtual Care Payment',
        FirstName: account?.firstName ?? '',
        LastName: account?.lastName ?? '',
      }
      const virtualClinicId = localStorage.getItem('virtualClinicId') ?? ''
      const patientId = account?.nextGenPersonId ?? ''
      //:::
      pay(payment, virtualClinicId, patientId)
    })
  }, [account, navigate])

  //==============================
  //#region UI
  //==============================
  return (
    <>
      <Nav title='Self-Pay' back={Path.VirtualCareInfo} />
      <div className={css.selfPay}>
        {/* === InstaMed Module === */}
        <iframe title='InstaMed Payment' id='iframe_id' src={url} />
      </div>
    </>
  )
}
