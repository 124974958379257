import {
  Dispatch,
  SetStateAction,
  useState,
  FieldDateOfBirth,
  FieldEmail,
  FieldPhone,
  FieldText,
  Icon,
  Label,
  ModelModal,
  Select,
  Selector,
  observer,
  FieldSex,
  NavEdit,
  Button,
  HttpStatus,
  Helper,
  useCallback,
} from 'src/_Shared/global'
import css from './dependentsAddOrUpdate.module.css'
import useAccountStore, {
  AccountConstants,
  AccountUtils,
} from 'src/_Shared/stores/account'
import { Dependent } from 'src/_Shared/stores/account/store'

const defaultDependentData = {
  nxgen_person_id: '',
  first_name: '',
  last_name: '',
  sex: '',
  dob: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zip: '',
  phonenumber: '',
  email_id: '',
  ethnicities_id: AccountConstants.ETHNICITIES[0].id,
  ethnicities_description: '',
  race_id: AccountConstants.RACES[0].id,
  race_description: '',
  country: 'USA',
  countryId: '',
}

const DependentsAddOrUpdate = (props: {
  dependent?: Dependent
  setShowForm: Dispatch<SetStateAction<boolean>>
  setSelectedDependent: Dispatch<SetStateAction<Dependent | undefined>>
}) => {
  //#region Initializers starts here
  const isUpdating = !!props.dependent
  const [account, setAccount, createDependent, updateDependent, fetchAccount] =
    useAccountStore((state) => [
      state.account,
      state.setAccount,
      state.createDependent,
      state.updateDependent,
      state.fetchAccount,
    ])
  const [values, setValues] = useState<Dependent>(
    props.dependent || defaultDependentData,
  )
  //#endregion Initializers ends here

  const handleChangeValue = (key: keyof Dependent, value: string) => {
    setValues({ ...values, [key]: value })
  }

  const formIsSubmittable = useCallback(() => {
    return (
      Helper.isNotEmpty(values.firstName) &&
      Helper.isNotEmpty(values.lastName) &&
      Helper.isAValidBirthDate(values.dateOfBirth ?? '')
    )
  }, [values])

  const handleAdd = async () => {
    if (!account) return

    const response = await createDependent(values)
    console.info('*** [DependentsAddOrUpdate] response:', response)
    console.info('*** [DependentsAddOrUpdate] values:', values)
    console.info('*** [DependentsAddOrUpdate] account.dependents:', response)
    if (response?.status === HttpStatus.Success) {
      setAccount({
        dependents: [...(account.dependents ?? []), values],
      })
      if (response?.data?.NGPersonId) {
        ModelModal.showLoader(undefined, 'Syncing Account ...')
        await fetchAccount()
        ModelModal.hide()
      }
      ModelModal.showSuccess(
        undefined,
        'Your dependent has been added successfully!',
      )
      props.setShowForm(false)
      props.setSelectedDependent(undefined)
    } else {
      ModelModal.hide()
    }
  }

  const handleUpdate = async () => {
    if (!account || !props.dependent) return
    ModelModal.showLoader(undefined, 'Updating dependent...')

    const response = await updateDependent(values)
    if (response?.status === HttpStatus.Success) {
      ModelModal.showLoader(undefined, 'Syncing Account ...')
      await fetchAccount()
      ModelModal.hide()
      ModelModal.showSuccess(
        undefined,
        'Your dependent has been updated successfully!',
      )
      props.setShowForm(false)
      props.setSelectedDependent(undefined)
    } else {
      ModelModal.hide()
    }
  }

  return (
    <>
      <NavEdit
        title='Dependent Information'
        leftBtn={{ onClick: () => props.setShowForm(false) }}
      />

      <div className={css.formContainer}>
        <p className={css.subtitle}>
          You may change your dependent information unless noted otherwise.{' '}
          <span>100% confidential and secure.</span>
          <Icon name='lock' width={16} />
        </p>

        <FieldText
          label='Legal First Name'
          value={values.firstName ?? ''}
          change={(value) => handleChangeValue('firstName', value)}
          error='Enter a valid first name'
        />
        <FieldText
          label='Legal Last Name'
          value={values.lastName ?? ''}
          change={(value) => handleChangeValue('lastName', value)}
          error='Enter a valid last name'
        />
        <FieldDateOfBirth
          value={values.dateOfBirth ?? ''}
          change={(value) => handleChangeValue('dateOfBirth', value)}
        />
        <FieldSex
          value={values.sex || ''}
          change={(value) => handleChangeValue('sex', value as string)}
        />
        <FieldPhone
          value={account?.phoneNumber || 'Mobile Number'}
          change={() => {}}
          disabled
        />
        <FieldEmail
          value={account?.email || 'Email Address'}
          change={(value) => handleChangeValue('email', value)}
          disabled
        />
        <div className={css.wrap}>
          <Select
            label='Race'
            value={AccountUtils.getRaceNameById(values.raceId ?? '') || ''}
            items={AccountUtils.getRaceNames()}
            change={(value) =>
              handleChangeValue(
                'raceId',
                AccountUtils.getRaceIdByName(value) || '',
              )
            }
          />
        </div>
        <div className={css.wrap}>
          <Select
            label='Ethnicity'
            value={
              AccountUtils.getEthnicityNameById(values.ethnicityId ?? '') || ''
            }
            items={AccountUtils.getEthnicityNames()}
            change={(value) =>
              handleChangeValue(
                'ethnicityId',
                AccountUtils.getEthnicityIdByName(value) || '',
              )
            }
          />
        </div>

        <div className={css.divider} />

        <FieldText
          label='Street Address'
          value={values.address1 ?? ''}
          change={(value) => handleChangeValue('address1', value)}
          error='street address is required'
        />
        <FieldText
          label='Apt, suite, etc. (optional)'
          value={values.address2 ?? ''}
          change={(value) => handleChangeValue('address2', value)}
        />
        <div className={css.wrap}>
          <Label text='Country' />
          <Selector
            items={['USA', 'Canada']}
            selected={values.country ?? ''}
            clicked={(value) => handleChangeValue('country', value)}
          />
        </div>
        <FieldText
          label='City'
          value={values.city ?? ''}
          error='City is required'
          change={(value) => handleChangeValue('city', value)}
        />
        <div className={css.wrap}>
          <Select
            label={values.country === 'USA' ? 'State' : 'Province'}
            value={values.state ?? ''}
            items={
              values.country === 'USA'
                ? AccountConstants.STATES
                : AccountConstants.PROVINCES
            }
            change={(value) => handleChangeValue('state', value)}
          />
        </div>
        <FieldText
          value={values.zipCode ?? ''}
          label={values.country === 'USA' ? 'ZIP Code' : 'Postal Code'}
          error={
            values.country === 'USA'
              ? 'ZIP code is required'
              : 'Postal code is required'
          }
          change={(value) => handleChangeValue('zipCode', value)}
          mask={values.country === 'USA' ? '99999' : '*** ***'}
        />
        <div
          className='row justify-content-end'
          style={{ marginTop: '10px', marginBottom: '20px' }}
        >
          <Button
            text={isUpdating ? 'Update' : 'Add'}
            primary
            clicked={isUpdating ? handleUpdate : handleAdd}
            disabled={!formIsSubmittable()}
          />
        </div>
      </div>
    </>
  )
}

export default observer(DependentsAddOrUpdate)
