import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Foundation,
  ModelModal,
  Nav,
  Path,
  PrimaryNav,
  observer,
} from 'src/_Shared/global'
import HeroDog from 'src/_Shared/images/HeroDog.svg'
import css from './paymentAdvantageProgram.module.css'

const PaymentAdvantageProgram = () => {
  const navigate = useNavigate()

  useEffect(() => {
    ModelModal.showLoader()
    var url = document
      ?.querySelector('#root')
      ?.shadowRoot?.querySelector('#advantage-program')
    if (url !== null && url !== undefined) {
      url.addEventListener('load', async () => {
        debugger
        ModelModal.hide()
      })
    }
  }, [])

  return (
    <div className={css.payBill}>
      <PrimaryNav backHome={true} />
      <img src={HeroDog} alt='Man on Phone' />
      <div className={css.payBillInner}>
        <h1>Pay Bill</h1>
        <Nav title='Advantage Program' clicked={() => navigate(Path.Home)} />
        <div className={css.iframeContainer}>
          <iframe
            id='advantage-program'
            title='advantage program'
            src={`${Foundation.baseUrl}/medical-discount-program/`}
          />
        </div>
        <div className={css.buttonContainer}>
          <Button
            text='Continue'
            primary
            clicked={() => navigate(Path.PaymentDetails)}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(PaymentAdvantageProgram)
