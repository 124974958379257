//:::::::::::::::::::::::::
import { observer } from 'mobx-react-lite'
import css from './clinicNextAvailable.module.css'
import { Icon } from 'src/_Shared/global'
//:::::::::::::::::::::::::

function ClinicNextAvailable(props: { time: string }) {
  return (
    <div className={css.nextAvailable}>
      <span>
        <Icon name='clock' width={13} />
      </span>
      <h6>Next Available Appointment is {props.time}</h6>
    </div>
  )
}

export default observer(ClinicNextAvailable)
