//:::::::::::::::::::::::::
import { useAuth0 } from '@auth0/auth0-react'
import {
  Path,
  useCallback,
  observer,
  useEffect,
  ModelModal,
  ModelClinic,
  useNavigate,
  ModelBooking,
  Types,
  HttpClient,
  API,
  ModelPaperwork,
  ModelInsurance,
  ModelApp,
  Helper,
  moment,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

function Dev() {
  const navigate = useNavigate()
  const {
    loginWithRedirect,
    isAuthenticated,
    user,
    logout,
    isLoading,
    getIdTokenClaims,
  } = useAuth0()

  const clickLogin = useCallback(() => {
    loginWithRedirect()
  }, [loginWithRedirect])

  const clickLogout = useCallback(() => {
    console.log(window.location.origin)
    logout({ logoutParams: { returnTo: '/' } })
  }, [logout])

  useEffect(() => {
    getAuth0Stuff()
  })
  async function getAuth0Stuff() {
    //if (!isLoading) {
    //console.log('🐯')
    //console.log(isAuthenticated)
    //console.log(user)
    //console.log('--')
    //}
    //console.log(user)
  }
  function confirm() {
    console.log('confirmer!')
  }
  function cancel() {
    console.log('Canceler!')
  }

  const clickSlots = useCallback(async () => {
    await ModelBooking.getNextAvailableTimeSlots()
  }, [])

  const selectVisitTime = async () => {
    //>>>>>
    console.log(Path.BookTime)
    navigate(Path.BookTime)
  }

  const clickSetPatient = useCallback(() => {
    const patient: Types.Patient = {
      firstName: 'Clifton',
      lastName: 'Labrum',
      dateOfBirth: '2001-01-02',
      phone: '+19282982000',
      email: 'clifton@labrum.org',
      relationship: 'Self',
    }
    localStorage.setItem('patient', JSON.stringify(patient))
  }, [])

  const clickGetClinics = useCallback(async () => {
    const httpClient = new HttpClient<any, any>()
    const response = await httpClient.post('v2/nextgen/location/geocode', '', {
      headers: {
        address: 'phoenix',
      },
    })
    console.log('=== Clinics ===')
    console.log(response)
  }, [])

  const clickGetRaces = useCallback(async () => {
    const response = await API.post('v2/nextgen/race', {})
    // const httpClient = new HttpClient<any, any>()
    // const response = await httpClient.post('v2/nextgen/race', '', {
    //   headers: {},
    // })
    console.log('=== Races ===')
    console.log(response)
  }, [])

  const clickMiddlewareTest = useCallback(() => {
    console.log(`process.env...`)
    console.log(process.env)
    console.log(`middleware URL...`)
    console.log(process.env.REACT_APP_MIDDLEWARE_URL!)
  }, [])

  const clickUploadId = useCallback(async () => {
    const front = localStorage.getItem('idCardFront')
    const back = localStorage.getItem('idCardBack')
    if (front && back) {
      await ModelPaperwork.uploadCards(front, back, 'JLbRx4', false)
    }
  }, [])

  const clickUploadInsurance = useCallback(async () => {
    const idToken = await getIdTokenClaims()
    console.log(idToken)
    const front = localStorage.getItem('insuranceCardFront')
    const back = localStorage.getItem('insuranceCardBack')
    if (front && back && idToken) {
      await ModelInsurance.uploadInsuranceCard(
        front,
        back,
        'testing',
        idToken.__raw,
      )
    }
  }, [])

  const clickiOSTest = useCallback(() => {
    const account = {
      firstName: 'Jacques',
      lastName: 'Millard',
    }
    //@ts-ignore
    window?.webkit?.messageHandlers?.AccountBridge?.postMessage({
      message: account,
    })
  }, [])

  const clickAndroidTest = useCallback(() => {
    const account = {
      firstName: 'Marsal',
      lastName: 'Silveira',
      email: 'marsal@drawbackwards.com',
      phone: '1234567890',
    }
    //@ts-ignore
    if (typeof AccountBridge !== 'undefined') {
      //@ts-ignore
      AccountBridge.setAccount(JSON.stringify(account))
    }
  }, [])

  const clickGetLogs = useCallback(async () => {
    await ModelApp.getLogs()
  }, [])

  const clickSubmitBookingChange = useCallback(() => {
    const appointment = localStorage.getItem('appointment')!
    const bookingId = localStorage.getItem('bookingId')!
    ModelBooking.update(bookingId, appointment)
  }, [])

  const clickGetPaperwork = useCallback(async () => {
    //9xJdN3
    const paperwork = await ModelPaperwork.getPriorPaperwork('9xJdN3')
    console.log(paperwork)
  }, [])

  const clickClinicForms = useCallback(async (id: string) => {
    //9xJdN3
    const result = await ModelClinic.getReviewAndSignFormsForClinic(id)
    console.log(result)
  }, [])

  return (
    <>
      <h2>Modal</h2>
      <button
        onClick={() => ModelModal.showLoader(undefined, 'Creating account...')}
      >
        Show Loader
      </button>
      <button
        onClick={() =>
          ModelModal.showSuccess(
            "You're back!",
            'Looks like you already have an account. Please log in.',
            'Log In',
            Path.LogIn,
          )
        }
      >
        Success
      </button>
      <button onClick={() => ModelModal.showError()}>Error</button>
      {/* === Test Cancel Booking === */}
      <button onClick={() => ModelModal.showCancelBooking()}>
        Cancel Booking
      </button>
      <button
        onClick={() =>
          ModelModal.showBookingFound(
            "<b>Corie H. at 2:30pm today</b>. Let's get you checked in.",
          )
        }
      >
        Booking Found
      </button>
      <button onClick={() => ModelModal.hide()}>Hide</button>
      <button onClick={() => ModelModal.showTime()}>Time's Up</button>
      <button onClick={() => ModelModal.showAllBills()}>All Bills</button>
      <button
        onClick={() =>
          ModelModal.showInfo(
            'Walk-ins accepted anytime.',
            'Don’t see a time that works for you? Walk-ins are always accepted at all clinics. Waiting time will vary depending on how many patients are in line. View clinic information page to see more information.',
          )
        }
      >
        Info
      </button>
      <h2>Clinic Forms</h2>
      <button onClick={() => clickClinicForms('gdeXxw')}>Price</button>
      <button onClick={() => clickClinicForms('7gNmLp')}>Thomas</button>
      <br />
      <br />
      <button
        onClick={() => {
          const appt = moment('2024-06-18T15:40:00-07:00')
          console.log(appt)
          const now = moment().toDate()
          const fifteenMinutesBefore = moment(
            appt.subtract(15, 'minutes'),
          ).toDate()
          if (now >= fifteenMinutesBefore) {
            console.log('Within 15!')
          } else {
            console.log('Not yet!')
          }
        }}
      >
        Compare Dates
      </button>

      <h2>Auth</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : user && isAuthenticated ? (
        <>
          <div>
            <img src={user?.picture} alt={user?.name} />
            <h4>{user?.name}</h4>
            <p>{user?.email}</p>
            <hr />
            <code>{JSON.stringify(user)}</code>
            <hr />
          </div>
          <button onClick={clickLogout}>Log Out</button>
        </>
      ) : (
        <button onClick={clickLogin}>Log In</button>
      )}
      <h2>Patient Info</h2>
      <button onClick={clickSetPatient}>Set Patient</button>
      <h2>NextGen API Tests</h2>
      <button onClick={clickGetClinics}>Get Clinics</button>
      <button onClick={clickGetRaces}>Get Races</button>
      <h2>Middleware Tests</h2>
      <button onClick={clickMiddlewareTest}>Get Clinics from Middleware</button>
      <h2>Upload ID Card</h2>
      <button onClick={clickUploadId}>Upload ID</button>
      <br />
      <br />
      <h2>Upload Insurance Card</h2>
      <button onClick={clickUploadInsurance}>Upload Insurance</button>
      <br />
      <br />
      <h2>Get Prior Paperwork</h2>
      <button onClick={clickGetPaperwork}>Get Paperwork</button>
      <br />
      <br />
      <h2>Middleware Logs</h2>
      <button onClick={clickGetLogs}>Get Logs</button>
      <br />
      <br />
      <h2>Booking Test</h2>
      <button onClick={clickSubmitBookingChange}>Submit Booking Change</button>
      <br />
      <br />
      <h2>Test iOS Data Hand-off</h2>
      <button onClick={clickiOSTest}>Send Data to iOS</button>
      <br />
      <br />
      <h2>Test Android Data Hand-off</h2>
      <button onClick={clickAndroidTest}>Send Data to Android</button>
      <br />
      <br />
      <br />
      <br />
    </>
  )
}

export default observer(Dev)
