import { Helper, Foundation } from 'src/_Shared/global'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import {
  ReCaptchaV3Provider,
  getToken,
  initializeAppCheck,
} from 'firebase/app-check'

export namespace FirebaseAnalytics {
  //==============================
  //#region Setup
  //==============================
  export async function getConfig() {
    if (window.location.host === 'app.nextcare.com') {
      //Production
      return {
        apiKey: 'AIzaSyDajVoW6lPpBzHwbSEI2eWAB-sE3XJbzbA',
        authDomain: 'nextcare-bb60d.firebaseapp.com',
        projectId: 'nextcare-bb60d',
        storageBucket: 'nextcare-bb60d.appspot.com',
        messagingSenderId: '1014217653685',
        appId: '1:1014217653685:web:273e097e216a9d282dedfc',
        measurementId: 'G-2J9GCTRG66',
      }
    } else {
      //Everywhere else
      return {
        apiKey: 'AIzaSyBmq2zdJnIAEmddD1UC8ahdh9YGcs6vOgk',
        authDomain: 'nextcare-dev.firebaseapp.com',
        projectId: 'nextcare-dev',
        storageBucket: 'nextcare-dev.appspot.com',
        messagingSenderId: '1063997613600',
        appId: '1:1063997613600:web:55330129e033325f673e45',
        measurementId: 'G-EQKVYLHSRK',
      }
    }
  }

  //==============================
  //#region Trigger Event
  //==============================
  export async function trigger(event: string, payload?: any) {
    // Initialize Firebase
    const config = await getConfig()
    const app = initializeApp(config)

    const analytics = getAnalytics(app)

    try {
      //Check if user is signed in
      var user = undefined
      if (localStorage.getItem('user')) {
        user = JSON.parse(localStorage.getItem('user')!)
      }

      logEvent(analytics, event, {
        virtual_screenviews: 1,
        login_status: user ? 'logged_in' : 'guest',
        user_id: user?.user_id || '',
        ...payload,
      })
    } catch (error) {
      //--- ! ---
      Helper.handleError(`Firebase Analytics Error: ${event}`, error)
    }
  }

  //==============================
  //#region Configure App Check
  //==============================
  export async function configureAppCheck() {
    try {
      const config = await getConfig()
      const app = initializeApp(config)

      //Initialize app check for API security
      const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(
          '6LcFe64nAAAAAMk7eLizEFsTdJTUABNHfCmUGjuR',
        ),
        isTokenAutoRefreshEnabled: true,
      })
      const appCheckTokenResponse = await getToken(appCheck, false)

      localStorage.setItem('appCheckToken', appCheckTokenResponse.token)
    } catch (error) {
      //Redirecting to home was the default behavior previously.
      window.location.href = ''
    }
  }
}
