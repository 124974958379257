//:::::::::::::::::::::::::
import { SummaryItem, ModelPaperwork } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function SummaryPatientInfo() {
  return (
    <>
      <SummaryItem
        name='Legal First Name'
        value={ModelPaperwork.paperwork.account.firstName}
      />
      <SummaryItem
        name='Legal Last Name'
        value={ModelPaperwork.paperwork.account.lastName}
      />
      <SummaryItem
        name='Date of Birth'
        value={ModelPaperwork.paperwork.account.dateOfBirth}
      />
      <SummaryItem
        name='Mobile Number'
        value={ModelPaperwork.paperwork.account.phone}
      />
      <SummaryItem
        name='Email Address'
        value={ModelPaperwork.paperwork.account.email}
      />
      <SummaryItem
        name='Birth Sex'
        value={ModelPaperwork.paperwork.account.birthSex}
      />
      <SummaryItem
        name='Ethnicity'
        value={ModelPaperwork.paperwork.account.ethnicity}
      />
      <SummaryItem name='Race' value={ModelPaperwork.paperwork.account.race} />
    </>
  )
}
