//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import {
  FieldText,
  FieldPhone,
  Types,
  FieldRelationship,
  FieldDateOfBirth,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function PaperworkGuarantorInfo(props: {
  values: Types.Paperwork
  change: (values: Types.Paperwork) => void
  sameAsAccountHolder: boolean
}) {
  return (
    <>
      {/* === First Name === */}
      <FieldText
        value={props.values.responses.GuarantorFirstName}
        label='First Name'
        change={(value) => {
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              GuarantorFirstName: value,
            },
          })
        }}
      />

      {/* === Last Name === */}
      <FieldText
        value={props.values.responses.GuarantorLastName}
        label='Last Name'
        change={(value) => {
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              GuarantorLastName: value,
            },
          })
        }}
      />

      {/* === Date of Birth === */}
      <FieldDateOfBirth
        mustBe18
        value={
          props.values.responses[
            'GuarantorDateofBirth_2dc46f00-744f-11ed-bd09-51c5be6d07c3'
          ]
        }
        change={(value) =>
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              'GuarantorDateofBirth_2dc46f00-744f-11ed-bd09-51c5be6d07c3':
                value,
            },
          })
        }
      />

      {/* === Email Address === */}
      <FieldText
        value={
          props.values.responses[
            'GuarantorEmailAddress_96866ff0-ac65-11ec-bd28-a3d3bf59450f'
          ]
        }
        label='Email Address'
        change={(value) => {
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              'GuarantorEmailAddress_96866ff0-ac65-11ec-bd28-a3d3bf59450f':
                value,
            },
          })
        }}
      />

      {/* === Mobile Number === */}
      <FieldPhone
        value={
          props.values.responses[
            'GuarantorPhone_3cfac820-744f-11ed-bd09-51c5be6d07c3'
          ]
        }
        change={(value) => {
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              'GuarantorPhone_3cfac820-744f-11ed-bd09-51c5be6d07c3': value,
            },
          })
        }}
      />
      {/* === Relationship to Patient === */}
      <div className={css.fieldWrapExtra}>
        <FieldRelationship
          value={
            props.values.responses[
              'GuarantorRelationship_883a1660-744f-11ed-bd09-51c5be6d07c3'
            ]
          }
          change={(value: string) => {
            props.change({
              ...props.values,
              responses: {
                ...props.values.responses,
                'GuarantorRelationship_883a1660-744f-11ed-bd09-51c5be6d07c3':
                  value,
              },
            })
          }}
        />
      </div>
    </>
  )
}
