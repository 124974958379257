/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
import { Foundation } from 'src/_Shared/global'

export const axiosClient = axios.create({
  baseURL: Foundation.middlewareUrl,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    apikey: Foundation.middleWareApiKey,
  },
})

export const axiosAuthClient = axios.create({
  baseURL: Foundation.auth0BaseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})
export const addBaseUrlToImage = (src: string) => {
  if (src.includes('data:image/png') || src.includes('data:image/jpeg')) {
    return src
  } else {
    //TODO fix these image paths
    var base_image_url = process.env.REACT_APP_BASE_URL + src
    return base_image_url
  }
}
export default {
  axiosClient,
  axiosAuthClient,
}
