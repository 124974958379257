//:::::::::::::::::::::::::
import css from './account.module.css'
import { List, ListLink, Path, Nav } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function AccountLegal() {
  return (
    <div className={css.account}>
      <Nav back={Path.Account} title='Legal' />
      <List>
        <ListLink
          text='Terms of Service'
          icon='squarrow'
          href='https://nextcare.com/app-terms-of-service/'
        />
        <ListLink
          text='Privacy Policy'
          icon='squarrow'
          href='https://nextcare.com/app-privacy-policy/'
        />
      </List>
    </div>
  )
}
