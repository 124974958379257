import { Foundation } from 'src/_Shared/global'
import { symmetricMessageDecryption } from './symmetricMessageDecryption'

export const decryptLocalStorage = (key: string) => {
  try {
    const encryptedMessage = localStorage.getItem(key)
    if (encryptedMessage) {
      const decryptedMessage = symmetricMessageDecryption(
        'T9zWgkJ2EhKMQHiVBvCFlj/SN3l1QQebrzh+6JkKZI0=',
        'FYOTEF+o4TG47K/Kqj+5+Q==',
        encryptedMessage,
      )
      return decryptedMessage
    }
    return encryptedMessage
  } catch {
    sessionStorage.setItem('error', 'true')
    return null
  }
}
