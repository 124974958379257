//:::::::::::::::::::::::::
import css from './clinicHours.module.css'
import { moment, Types } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function ClinicHours(props: { clinic: Types.Clinic }) {
  const presentDay = moment().format('dddd') //Day of the week today
  //TODO clean up this UI code
  return (
    <div className={css.clinicHours}>
      <h5>Daily Hours</h5>
      <ul>
        {props.clinic.details.open_hours.map(
          (
            data: {
              day_of_the_week: string
              open_time: string
              close_time: string
            },
            index: number,
          ) => (
            <li
              key={index}
              className={`${
                presentDay === data.day_of_the_week ? css.selected : ''
              }`}
            >
              <p
                className={
                  presentDay === data.day_of_the_week
                    ? data.open_time === '' ||
                      data.open_time === 'closed' ||
                      data.close_time === '' ||
                      data.close_time === 'closed'
                      ? 'Today red'
                      : 'Today green'
                    : 'weekday'
                }
              >
                {data.day_of_the_week}
              </p>

              <em
                className={
                  presentDay === data.day_of_the_week
                    ? data.open_time === '' ||
                      data.open_time === 'closed' ||
                      data.close_time === '' ||
                      data.close_time === 'closed'
                      ? 'Today red'
                      : 'Today green'
                    : 'weekday'
                }
              >
                {data.open_time === '' || data.open_time === 'closed'
                  ? 'Closed'
                  : moment(data.open_time, ['H:mm']).format('h:mmA')}{' '}
                {data.open_time === '' || data.open_time === 'closed'
                  ? ''
                  : '-'}{' '}
                {data.close_time === '' || data.close_time === 'closed'
                  ? ''
                  : moment(data.close_time, ['H:mm']).format('h:mmA')}
              </em>
            </li>
          ),
        )}
      </ul>
    </div>
  )
}
