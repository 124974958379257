//:::::::::::::::::::::::::
import css from './downloadAppCard.module.css'
import {
  Carousel,
  Button,
  ModelApp,
  useEffect,
  useCallback,
  useState,
  useSearchParams,
} from 'src/_Shared/global'
import DownloadAppCardItem from './DownloadAppCardItem'
//:::::::::::::::::::::::::

export default function DownloadAppCard(props: { hide: () => void }) {
  const [url, setUrl] = useState('')

  //Set the button URL according to the device's user agent
  useEffect(() => {
    //Set the appropriate link
    const agent = ModelApp.getUserAgent()

    switch (agent) {
      //:::::
      case 'Android':
        setUrl(
          'https://play.google.com/store/apps/details?id=com.nextcare.health&pcampaignid=web_share',
        )
        break
      //:::::
      case 'Apple':
        setUrl('https://apps.apple.com/us/app/nextcare-anywhere/id1629290318')
        break
      //:::::
      default:
        setUrl('https://nextcare.com/nextcare-app/')
        break
    }
  }, [])

  //==============================
  //#region Button Click
  //==============================
  const clickButton = useCallback(() => {
    window.open(url)
  }, [url])

  return (
    <div className={css.downloadAppCard}>
      <div className={css.cardWrap}>
        <div className={css.card}>
          {/* === Carousel === */}
          <Carousel
            slides={[
              <DownloadAppCardItem
                icon='logoApp'
                text='There’s no faster and easier way to get the care you need than through the NextCare Anywhere app.'
              />,
              <DownloadAppCardItem
                icon='bookUrgent'
                text='Quickly and Easily Book Urgent Care Visits at 170+ Locations with Short Wait Times'
              />,
              <DownloadAppCardItem
                icon='bookVirtual'
                text='Get Virtual Care from the Comfort of Your Home or Office with our HIPAA Secure App'
              />,
              <DownloadAppCardItem
                icon='viewRecords'
                text='Access Medical Documents, Test Results, Prescriptions, and Pay Your Bills Online in One Secure Location'
              />,
            ]}
          />
          {/* === Buttons === */}
          <div className={css.buttons}>
            <Button
              text='Download the App'
              primary={true}
              clicked={clickButton}
            />
            <Button
              text='Continue in Browser'
              tertiary={true}
              clicked={() => props.hide()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
