import { observer } from 'mobx-react-lite'
import css from '../modal.module.css'
import Button from '../../Button/Button'
import { Path } from 'src/_Shared/Paths'
import { Icon, useNavigate } from 'src/_Shared/global'
import { useAuth0 } from '@auth0/auth0-react'

const ModalInfoSaved = () => {
  const { user } = useAuth0()
  const navigate = useNavigate()
  const email = user?.email || '---'
  return (
    <>
      <Icon name='infoSavedCheck' width={100} />
      <div className={css.infoSavedContainer}>
        <h5>Your Information Has Been Saved</h5>
        <p>
          You're signed in with the email {email} and the password you just
          created.
        </p>
        <Button primary clicked={() => navigate(Path.BookFinish)}>
          OK
        </Button>
      </div>
    </>
  )
}

export default observer(ModalInfoSaved)
