import { Helper, ModelBooking, ModelPaperwork } from 'src/_Shared/global'
import { stringOrNull } from './utils'
import accountStore from '../account'

const API = {
  async fetchBookings(phoneNumber?: string) {
    const account = accountStore.getState().account
    const paperwork = ModelPaperwork.paperwork
    const booker = ModelBooking.getBooker()

    const phone =
      stringOrNull(phoneNumber) ??
      stringOrNull(account?.phoneNumber) ??
      stringOrNull(paperwork?.account?.phone) ??
      stringOrNull(booker?.phone) ??
      ''
    if (phone?.length > 0) {
      const results = await ModelBooking.findAll(phone)
      console.log('[API.fetchBookings] results:', results)
      const upcomingBookings = results.filter((booking) => {
        var today = new Date()
        today.setHours(today.getHours() - 2)
        const appointment = new Date(booking.appointment)
        return (
          appointment > new Date() || // If the appointment is in the future
          (booking.isWalkIn && appointment > today) // Or if it's a walk-in and the appointment is within the last 2 hours
        )
      })
      // Convert the appointment date to a readable format
      const withConvertedDates = upcomingBookings.map((booking) => {
        return {
          ...booking,
          appointment: Helper.convertAppointmentDate(booking.appointment),
        }
      })

      // If the booking summary is loaded, update it with the latest data
      const foundBooking = results.find(
        (booking) => booking.bookingId === ModelBooking.bookingId,
      )
      if (foundBooking) ModelBooking.setBookingSummary(foundBooking)
      return withConvertedDates
    }
  },
}

export default API
