//:::::::::::::::::::::::::
import css from './paperwork.module.css'
import {
  useEffect,
  useSearchParams,
  FirebaseAnalytics,
  ModelClinic,
  ModelBooking,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function PaperworkWrap(props: { children: React.ReactNode }) {
  const setClinic = async (clinicId: string) => {
    const clinic = await ModelClinic.getClinic(clinicId)
    ModelClinic.setSelected(clinic)
  }
  //Get params passed in from mobile apps (though only bookingID is required)
  //Example: http://localhost:3000/paperwork/1?bookingId=26xmXo&clinicId=GgbNNg
  //Another: http://localhost:3000/paperwork/1?bookingId=26xlRk&clinicId=0PRWLp
  const [params] = useSearchParams()

  //We need these values in the paperwork submission
  const bookingId = params.get('bookingId')
  if (bookingId) {
    ModelBooking.setBookingId(bookingId)
  }

  const sessionId = params.get('sessionId')
  if (sessionId) localStorage.setItem('sessionId', sessionId)

  //The only thing the clinic is used for is looking up legal documents on the paperwork summary screen
  const clinicId = params.get('clinicId')
  if (clinicId) {
    localStorage.setItem('clinicId', clinicId)
    if (!ModelClinic.getSelected()) {
      setClinic(clinicId)
    }
  }

  //==============================
  //#region Analytics
  //==============================
  useEffect(() => {
    //%%%%%
    FirebaseAnalytics.trigger('Paperwork')
  }, [])

  return (
    <>
      <div className={css.paperworkWrap}>{props.children}</div>
    </>
  )
}
