//:::::::::::::::::::::::::
import { useAccountStore } from 'src/_Shared/stores/account'
import css from './account.module.css'
import {
  Button,
  Icon,
  useState,
  useCallback,
  Input,
  Helper,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function AccountRecover() {
  //Email state
  const [email, setEmail] = useState('')
  const [emailReady, setEmailReady] = useState(false)
  const resetPassword = useAccountStore((state) => state.resetPassword)

  //Submit the form
  const clickSubmit = useCallback(async () => {
    if (emailReady && email?.length > 0) {
      await resetPassword(email)
      //Clear email field
      setEmail('')
    }
  }, [email, emailReady, resetPassword])

  return (
    <>
      <div className={css.accountLogin}>
        <div className={css.accountLoginStretch}>
          <div className={css.accountLoginLogo}>
            <Icon name='logoFull' width={110} />
          </div>
          <h2>Reset Password</h2>
          <p>
            Please enter the email address associated with your NextCare account
            to get a password reset email.
          </p>

          <form onSubmit={(event) => event.preventDefault()}>
            {/* === Email Address === */}
            <Input
              type='email'
              icon='email'
              value={email}
              change={(event) => setEmail(event.target.value)}
              blur={() => {
                setEmailReady(
                  Helper.validate(Helper.InputValidator.Email, email),
                )
              }}
              label='Email Address'
              enter={() => clickSubmit()}
            />

            <Button
              text='Submit'
              clicked={clickSubmit}
              disabled={!emailReady}
            />
          </form>
        </div>
      </div>
    </>
  )
}
