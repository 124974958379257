//:::::::::::::::::::::::::
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, Foundation } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function AccountLogin() {
  const { loginWithRedirect } = useAuth0()

  //Redirect to Auth0 login
  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: Foundation.auth0Redirect,
      },
    })
  }, [loginWithRedirect])

  return <></>
}
