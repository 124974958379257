//:::::::::::::::::::::::::
import css from './home.module.css'
import { Icon, Button } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function HomeLink(props: {
  icon: string
  title: string
  subtitle: string
  link?: string
  href?: string
}) {
  return (
    <Button
      link={props.link}
      title={props.title}
      block={true}
      clicked={() => {
        if (props.href) {
          // window.location.href = props.href
          window.open(props.href)
        }
      }}
    >
      <span className={css.homeLink}>
        <span>
          <Icon name={props.icon} width={48} />
        </span>
        <section>
          <h2>{props.title}</h2>
          <p>{props.subtitle}</p>
        </section>
        <em>
          <Icon name='arrowRight' width={16} />
        </em>
      </span>
    </Button>
  )
}
