//:::::::::::::::::::::::::
import css from './clinicDetailsStatus.module.css'
import { Icon, ModelBooking, Types, observer } from 'src/_Shared/global'
//:::::::::::::::::::::::::

function ClinicDetailsStatus(props: {
  clinic: Types.Clinic
  useIcons: boolean
  insurance?: string
}) {
  if (!props.clinic) {
    return <div className='no-clinic-selected'>No clinic selected</div>
  }

  return (
    <div className={css.clinicDetailsStatus}>
      {/* === Address === */}
      <p>
        {props.useIcons ? (
          <i>
            <Icon name='location' width={16} color='gray' />
          </i>
        ) : null}
        <span>{props.clinic.details.directions_address}</span>
      </p>
      {/* === Phone === */}
      <p>
        {props.useIcons ? (
          <i>
            <Icon name='phone' width={16} />
          </i>
        ) : null}
        <span>{props.clinic.details.phone_number}</span>
      </p>
      {/* === Patients in line === */}
      {!ModelBooking.primaryCare && (
        <p>
          {props.useIcons ? (
            <i>
              <Icon name='person' width={16} />
            </i>
          ) : null}
          <span>
            <b>{props.clinic.details.patient_count}</b> patient(s) in line
          </span>
        </p>
      )}
      {/* === Open status === */}
      <p>
        {props.useIcons ? (
          <i>
            <Icon name='clockBig' width={16} />
          </i>
        ) : null}
        <span>
          {props.clinic.isOpen ? (
            <>
              <em>Open</em> {props.clinic.openHours}
            </>
          ) : (
            <>
              <em className='closed'>Closed</em>. {props.clinic.openHours}
            </>
          )}
        </span>
      </p>
      {/* === Insurance Plans === */}
      {props.insurance && (
        <p>
          <i>
            <Icon name='person' width={16} />
          </i>
          <span>
            <strong>Accepted Insurance Plans</strong>
            {props.insurance}
          </span>
        </p>
      )}
    </div>
  )
}

export default observer(ClinicDetailsStatus)
