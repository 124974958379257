//:::::::::::::::::::::::::
import { useSearchParams, useNavigate, Path } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function Auth() {
  const [params] = useSearchParams('code')
  const navigate = useNavigate()
  const code = params.get('code')
  //If the code isn't getting passed in, don't do anything
  if (!code) {
    console.info('No code - redirecting to home')
    navigate(Path.Home)
  }

  return <></>
}
