//:::::::::::::::::::::::::
import css from './securityBlurb.module.css'
import { Icon } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function SecurityBlurb() {
  return (
    <p className={css.security}>
      You may change your personal information unless noted otherwise.{' '}
      <b>
        100% confidential and secure.{' '}
        <span>
          <Icon name='lockMini' width={14} />
        </span>
      </b>
    </p>
  )
}
