//:::::::::::::::::::::::::
import css from './bookPatientCss.module.css'
import { ModelBooking, observer, Types } from 'src/_Shared/global'
//:::::::::::::::::::::::::

function BookPatientList(props: {
  patients: Types.Patient[]
  selected: number
  select: (index: number) => void
}) {
  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.bookPatient}>
      <h4>Who is this visit for?</h4>
      <ol>
        {props.patients.map((patient, index) => {
          const patientName = ModelBooking.getPatientName(patient)
          return (
            <li
              className={`${props.selected === index ? css.selected : ''}`}
              key={patientName}
            >
              <button onClick={() => props.select(index)}>
                {patientName === ' ' ? 'Someone Else' : patientName}{' '}
                {index === 0 ? <em>(You)</em> : null}
              </button>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

export default observer(BookPatientList)
