import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dateToTextConvertor from 'src/toDelete/helpers/dateToTextConvertor'
import { TransactionDetailBO } from 'src/BOs/TransactionDetailBO'
import { ModelModal, ModelPay, Nav, Path, observer } from 'src/_Shared/global'
import css from './paymentReceipt.module.css'

let queryParams: URLSearchParams | null = null

const PaymentReceipt = () => {
  // initialization
  const navigate = useNavigate()
  const [transactionId, setTransactionId] = useState('')
  const [transactionReceipt, setTransactionReceipt] = useState<
    undefined | TransactionDetailBO
  >()
  const [paymentDate, setPaymentDate] = useState<string[]>(['', '', '', '', ''])
  const [paymenTime, setPaymentTime] = useState('')
  //get the transaction id from query param
  const location = useLocation()
  if (location.search !== '') {
    queryParams = new URLSearchParams(location.search)
  } else {
    queryParams = null
  }
  //#endregion initialization ends here

  useEffect(() => {
    const GetPaymentReceiptForTransaction = async () => {
      const transactionIdFromQuery = queryParams?.get('transactionId')
      if (!transactionIdFromQuery) return ModelModal.hide()
      setTransactionId(transactionIdFromQuery)
      const response = await ModelPay.getPaymentReceipt(transactionId)
      if (response.data !== null) {
        setTransactionReceipt(response.data)
        const convertedDateTime = convertLocalToTimezone(
          response.data.TransactionDate,
        )
        const convertedDate = convertedDateTime
          .split(' ')[0]
          .replaceAll('-', '/')
        const convertedTime = convertedDateTime.split(' ')[1]
        setPaymentDate(dateToTextConvertor(convertedDate))
        setPaymentTime(convertedTime)
      }
      ModelModal.hide()
    }
    ModelModal.showLoader()
    GetPaymentReceiptForTransaction()
  }, [])

  function convertLocalToTimezone(localDt: string) {
    var UTCTime = moment(localDt).local().format('YYYY-MM-DD hh:mm:ss')
    return UTCTime
  }

  return (
    <>
      <Nav
        title='Full Account Balance Payment'
        clicked={() =>
          ModelPay.patientDetails.isAdvantageProgram
            ? navigate(Path.PaymentAdvantageProgram)
            : navigate(Path.PaymentMakeAPayment)
        }
      />
      {!!transactionReceipt ? (
        <>
          <div className={css.paymentReceiptHeader}>
            <h1>
              Thank you!
              <br />
              We have received your payment for
            </h1>
                            
            <p className={css.paymentReceiptHeaderAmount}>
              ${transactionReceipt?.Amount?.toFixed(2)}
            </p>
            <span className={css.paymentReceiptHeaderLine} />
            <p className={css.paymentReceiptHeaderSubtitle}>
              Payments can take up to a few days to process.
            </p>
          </div>
          <div className={css.paymentReceipt}>
            <h2>Payment Receipt</h2>
            <table className={css.paymentReceiptTable}>
              <tbody>
                <tr>
                  <td className={css.paymentReceiptTableLabel}>PAYMENT DATE</td>
                  <td className={css.paymentReceiptTableValue}>{`${
                    paymentDate[1] + ' ' + paymentDate[2] + ' ' + paymentDate[4]
                  }`}</td>
                </tr>
                <tr>
                  <td className={css.paymentReceiptTableLabel}>PAYMENT TIME</td>
                  <td
                    className={css.paymentReceiptTableValue}
                  >{`${paymenTime}`}</td>
                </tr>
                <tr>
                  <td className={css.paymentReceiptTableLabel}>
                    AMOUNT SUBMITTED
                  </td>
                  <td className={css.paymentReceiptTableValue}>
                    ${transactionReceipt?.Amount}
                  </td>
                </tr>
                <tr>
                  <td className={css.paymentReceiptTableLabel}>
                    PAYMENT METHOD
                  </td>
                  <td className={css.paymentReceiptTableValue}>
                    {transactionReceipt?.CardResult.Type}
                  </td>
                </tr>
                <tr>
                  <td className={css.paymentReceiptTableLabel}>
                    CARD HOLDER'S NUMBER
                  </td>
                  <td className={css.paymentReceiptTableValue}>
                    XXXX-XXXX-XXXX-{transactionReceipt?.CardResult.LastFour}
                  </td>
                </tr>
                <tr>
                  <td className={css.paymentReceiptTableLabel}>
                    TRANSACTION ID
                  </td>
                  <td className={css.paymentReceiptTableValue}>
                    {transactionReceipt?.TransactionID}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className={css.paymentReceiptNullState}>
          <p>
            {`Transaction${transactionId && ` ${transactionId}`} not found`}
          </p>
        </div>
      )}
    </>
  )
}

export default observer(PaymentReceipt)
