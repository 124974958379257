//:::::::::::::::::::::::::
// import Notifications from './NotificationsButton/NotificationsButton'
import css from './primaryNav.module.css'
import { Button, Icon, Path } from 'src/_Shared/global'
import { useAuth0 } from '@auth0/auth0-react'
//:::::::::::::::::::::::::

export default function PrimaryNav(props: { backHome?: boolean }) {
  const { isAuthenticated } = useAuth0()

  return (
    <div className={css.primary}>
      {isAuthenticated && (
        <span>
          {props.backHome ? (
            <Button link={Path.Home}>
              <Icon name='backWhite' width={24} />
            </Button>
          ) : (
            <Button link={Path.Account}>
              <Icon name='account' width={24} />
            </Button>
          )}
        </span>
      )}
      <h2>
        <Icon name='logoFullWhite' width={110} />
      </h2>
      {/* {isAuthenticated && (
        <span>
          <Notifications />
        </span>
      )} */}
    </div>
  )
}
