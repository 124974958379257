//:::::::::::::::::::::::::
import css from './clinicServices.module.css'
import { ModelClinic, observer, Types } from 'src/_Shared/global'
//:::::::::::::::::::::::::

function ClinicServices(props: { clinic: Types.Clinic }) {
  if (ModelClinic.selected === undefined) {
    return <>Clinic not selected.</>
  }

  //The images are pulled in here via links provided by the API. This probably needs to work differently so that we can guarantee the icons are available in the app build and not just hope they stay on a server somewhere.

  return (
    <div className={css.clinicServices}>
      <h5>Available Services</h5>
      <ul>
        {/* === Covid Services === */}
        {props.clinic.details.covid_considerations.map((service, index) => (
          <li
            key={index}
            className='meds-icon d-flex align-items-center img-spacing'
          >
            <img src={service.image} alt={service.description} />
            <span>{service.heading}</span>
          </li>
        ))}
        {/* === Services Offered === */}
        {props.clinic.details.services_offered?.map((service, index) => (
          <li key={index}>
            <img src={service.image} alt={service.title} />
            <span dangerouslySetInnerHTML={{ __html: service.title }} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default observer(ClinicServices)
