//:::::::::::::::::::::::::
import {
  makeObservable,
  ModelModal,
  HttpClient,
  Helper,
  Types,
  action,
  runInAction,
  UploadPhotoIdBO,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

class ModelPaperwork {
  //==============================
  //#region Class Setup
  //==============================
  private static _instance: ModelPaperwork
  //Properties
  paperwork: Types.Paperwork
  backToSummary: boolean = false
  documents: Types.DocumentListItem[] = []
  signature: string = ''
  termsOfUse: boolean = false
  privacyPolicy: boolean = false
  formReady: boolean = false

  constructor() {
    //Set props to true to make reactive
    makeObservable(this, {
      //Enable properties
      paperwork: true,
      backToSummary: true,
      signature: true,
      documents: true,
      termsOfUse: true,
      privacyPolicy: true,
      formReady: true,
      //Methods
      setPaperwork: action,
      setBackToSummary: action,
      setDocuments: action,
      updateDocument: action,
      setSignature: action,
      setTermsOfUse: action,
      setPrivacyPolicy: action,
      setFormReady: action,
    })
    //Initialize paperwork...
    if (localStorage.getItem('paperwork')) {
      this.paperwork = JSON.parse(
        localStorage.getItem('paperwork')!,
      ) as Types.Paperwork
    } else {
      this.paperwork = this.newPaperWorkObject()
    }
  }

  //Initialize our singleton
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  getBooker(): any {
    if (localStorage.getItem('booker')) {
      return JSON.parse(localStorage.getItem('booker')!)
    } else {
      return undefined
    }
  }

  getPaperwork(): Types.Paperwork | undefined {
    if (localStorage.getItem('paperwork')) {
      return JSON.parse(localStorage.getItem('paperwork')!) as Types.Paperwork
    } else {
      return undefined
    }
  }

  //==============================
  //#region Setters
  //==============================
  setPaperwork(values: Types.Paperwork) {
    localStorage.setItem('paperwork', JSON.stringify(values))
    this.paperwork = values
  }
  setBackToSummary(value: boolean) {
    this.backToSummary = value
  }
  setDocuments(values: Types.DocumentListItem[]) {
    this.documents = values
  }
  updateDocument(document: Types.DocumentListItem, value: boolean) {
    //Find document to update
    const index = this.documents.findIndex((doc) => doc.id === document.id)
    //Toggle value
    runInAction(() => {
      this.documents[index].accepted = !document.accepted

      this.setDocuments(this.documents)
      this.setFormReady()
    })
  }
  setSignature(value: string) {
    this.signature = value
    this.setFormReady()
  }
  setTermsOfUse(value: boolean) {
    this.termsOfUse = value
    this.setFormReady()
  }
  setPrivacyPolicy(value: boolean) {
    console.log(value)
    this.privacyPolicy = value
    this.setFormReady()
  }
  resetAcceptanceFormFields() {
    this.signature = ''
    this.termsOfUse = false
    this.privacyPolicy = false
    this.documents.forEach((doc) => (doc.accepted = false))
    this.formReady = false
  }
  setFormReady() {
    const allDocsReady = this.documents.every((doc) => doc.accepted === true)

    this.formReady =
      this.signature.length > 0 &&
      allDocsReady &&
      this.termsOfUse &&
      this.privacyPolicy
  }

  //==============================
  //#region Submit Paperwork
  //==============================
  async submit() {
    ModelModal.showLoader('Please Wait', 'Submitting...')

    //Parameters passed in from native apps
    const bookingId = localStorage.getItem('bookingId')
    const clinicId = localStorage.getItem('clinicId')
    const sessionId = localStorage.getItem('sessionId')

    try {
      if (!bookingId) throw Error('Booking ID missing')
      if (!clinicId) throw Error('Clinic ID missing')

      console.log(this.paperwork.responses)

      //::: 1 ::: Save paperwork responses
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/solv/bookings/updatepaperworkfields',
        {
          status: 'complete',
          responses: {
            ...this.paperwork.responses,
            patientFirstName: this.paperwork.account.firstName ?? '',
            patientLastName: this.paperwork.account.lastName ?? '',
            birthDate: this.paperwork.account.dateOfBirth
              ? Helper.convertDateOfBirthSimpleToAPI(
                  this.paperwork.account.dateOfBirth,
                )
              : '',
            patientBirthSex: this.paperwork.account.birthSex ?? '',
            primaryPhone: this.paperwork.account.phone ?? '',
            email: this.paperwork.account.email ?? '',
            ethnicity:
              this.paperwork.account.ethnicity === 'Undisclosed'
                ? 'Unknown'
                : this.paperwork.account.ethnicity ?? 'Unknown',
            race:
              this.paperwork.account.race === 'Undisclosed'
                ? 'Unknown'
                : this.paperwork.account.race ?? 'Unknown',
            patientAddressStreet: this.paperwork.account.address ?? '',
            patientAddressStreetTwo: this.paperwork.account.apartment ?? '',
            patientAddressCity: this.paperwork.account.city ?? '',
            patientAddressState: this.paperwork.account.state ?? '',
            patientAddressZip: this.paperwork.account.zip ?? '',
          },
        },
        {
          headers: {
            bookingid: bookingId,
            sessionid: sessionId || '',
          },
        },
      )
      console.log('📋 Paperwork Response')
      console.log(response)

      //::: 2 ::: Save card insurance or ID card uploads
      //::::::::::::::::::::
      //ID Cards
      const frontIdCard = localStorage.getItem('idCardFront')
      const backIdCard = localStorage.getItem('idCardBack')
      if (frontIdCard && backIdCard) {
        await this.uploadCards(
          frontIdCard,
          backIdCard,
          bookingId,
          false, // Insurance = false
        )
      }
      //::::::::::::::::::::
      //Insurance Cards are updated on the booking
      const frontInsuranceCard = localStorage.getItem('insuranceCardFront')
      const backInsuranceCard = localStorage.getItem('insuranceCardBack')
      if (frontInsuranceCard && backInsuranceCard) {
        await this.uploadCards(
          frontInsuranceCard,
          backInsuranceCard,
          bookingId,
          true, // Insurance = true
        )
      }

      //:::
      ModelModal.hide()

      //Done
    } catch (error) {
      ModelModal.hide()
      //--- ! ---
      Helper.handleError('Submit Paperwork', error)
    }
  }

  //==============================
  //#region Get Status
  //==============================
  async getStatus(bookingId: string) {
    try {
      if (!bookingId) throw Error('Booking ID missing')

      //Lookup booking with phone number
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/solv/bookings/paperwork',
        null,
        {
          headers: {
            bookingid: bookingId,
          },
        },
      )
      //:::
      return response.data.data.paperworkStatus

      //Done
    } catch (error) {
      //--- ! ---
      Helper.handleError('Get Paperwork Status', error)
    }
  }

  //==============================
  //#region Get Prior Paperwork
  //==============================
  async getPriorPaperwork(bookingId: string) {
    try {
      if (!bookingId) throw Error('Booking ID missing')
      console.log('🔄 Getting Prior Paperwork...')

      //Lookup booking with phone number
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/solv/bookings/paperwork',
        null,
        {
          headers: {
            bookingid: bookingId,
          },
        },
      )
      //:::
      return response.data.data

      //Done
    } catch (error) {
      //--- ! ---
      Helper.handleError('Get Prior Paperwork', error)
    }
  }

  //==============================
  //#region Upload Cards
  //Insurance or Photo ID
  //==============================
  async uploadCards(
    front: string,
    back: string,
    bookingId: string,
    insurance: boolean,
  ) {
    try {
      console.log('🔄 Uploading ID cards...')
      const removePreamble = (image: string) => {
        console.info('Removing Preamble', image.slice(0, 100))
        const newImage = image.startsWith('data:image')
          ? image.split(',')[1]
          : image
        console.info('New Image', newImage.slice(0, 100))
        return newImage
      }
      const ready = await Helper.encryptCardImages(
        removePreamble(front),
        removePreamble(back),
      )

      const cardFront: UploadPhotoIdBO = {
        image: ready.cardFront,
        image_type: insurance ? 'insurance_card_front' : 'photo_id_front',
        publicKey: ready.publicKey,
        encryptedSymmetricKey: ready.symmetricKey,
        encryptedIv: ready.initializationVector,
      }
      console.log(cardFront)

      const cardBack: UploadPhotoIdBO = {
        image: ready.cardBack,
        image_type: insurance ? 'insurance_card_back' : 'photo_id_back',
        publicKey: ready.publicKey,
        encryptedSymmetricKey: ready.symmetricKey,
        encryptedIv: ready.initializationVector,
      }

      const httpClient = new HttpClient<any, any>()
      const responseFront = await httpClient.post(
        'v2/solv/bookings/uploaddocuments',
        cardFront,
        {
          headers: {
            bookingid: bookingId,
          },
        },
      )

      console.log('🪪 Card Front Response:')
      console.log(responseFront)

      const responseBack = await httpClient.post(
        'v2/solv/bookings/uploaddocuments',
        cardBack,
        {
          headers: {
            bookingid: bookingId,
          },
        },
      )

      console.log('💳 Card Back Response:')
      console.log(responseBack)

      //Done
    } catch (error) {
      //--- ! ---
      Helper.handleError('Get Paperwork Status', error)
    }
  }

  //==============================
  //#region Misc
  //==============================
  clear() {
    //When they start paperwork again, remove all the cached values
    localStorage.removeItem('paperwork')
    this.setPaperwork(this.newPaperWorkObject())
    localStorage.removeItem('insuranceCardBack')
    localStorage.removeItem('insuranceCardFront')
    localStorage.removeItem('idCardFront')
    localStorage.removeItem('idCardBack')
    localStorage.removeItem('clinicId')
  }
  patientIsOver18() {
    return Helper.isAtLeast18YearsOld(this.paperwork.account.dateOfBirth || '')
  }
  newPaperWorkObject() {
    return {
      workInjury: false,
      selfPay: false,
      guarantorSameAsAccountHolder: false,
      guarantorAddressSameAsPatient: false,
      secondaryInsurance: false,
      authorizedIndividual1: '',
      authorizedIndividual2: '',
      authorizedIndividual3: '',
      account: {
        auth0UserId: '',
        nextGenId: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        birthSex: '',
        phone: '',
        email: '',
        race: '',
        ethnicity: '',
        address: '',
        apartment: '',
        city: '',
        state: '',
        zip: '',
        country: 'USA',
        isMinor: false,
        guardianId: '',
      },
      status: '',
      responses: {
        areYouBeingTreatedAtTheRequestOfYourEmployer: '',
        employer: '',
        workInjuryEmployerContactName: '',
        workInjuryEmployerContactPhoneNumber: '',
        whatAreYouHereFor: '',
        whatTypeOfPhysical: '',
        whatTypeOfDrugScreenAlcoholTest: '',
        howDidYourInjuryOccur: '',
        whenDidYourInjuryOccur: '',
        toWhomReportedYourInjury: '',
        ethnicity: '',
        race: '',
        physician: '',
        pharmacy: '',
        preferredPharmacyCrossStreets: '',
        howHearAboutUs: '',
        emergencyContactFirstName: '',
        emergencyContactMiddleName: '',
        emergencyContactLastName: '',
        emergencyContactPhone: '',
        emergencyContactRelationshipToPatient: '',
        sameAsGuardian: '',
        GuarantorFirstName: '',
        GuarantorLastName: '',
        'GuarantorDateofBirth_2dc46f00-744f-11ed-bd09-51c5be6d07c3': '',
        'GuarantorRelationship_883a1660-744f-11ed-bd09-51c5be6d07c3': '',
        'GuarantorAddress_48e1f640-744f-11ed-bd09-51c5be6d07c3': '',
        GuarantorCity: '',
        GuarantorState: '',
        GuarantorZIP: '',
        GuarantorCountry: '',
        'GuarantorPhone_3cfac820-744f-11ed-bd09-51c5be6d07c3': '',
        'GuarantorEmailAddress_96866ff0-ac65-11ec-bd28-a3d3bf59450f': '',
        insuranceCompany: '',
        memberId: '',
        groupNumber: '',
        policyHolderFirstName: '',
        policyHolderMiddleName: '',
        policyHolderLastName: '',
        policyHolderBirthDate: '',
        patientsRelationshipToInsured: '',
        doYouHaveSecondInsuranceInformation: '',
        secondaryInsuranceCompany: '',
        secondaryMemberId: '',
        secondaryGroupNumber: '',
        secondaryPolicyHolderFirstName: '',
        secondaryPolicyHolderMiddleName: '',
        secondaryPolicyHolderLastName: '',
        secondaryPolicyHolderBirthDate: '',
        secondaryPatientsRelationshipToInsured: '',
        maritalStatus: '',
        ssn: '',
        height1: '',
        height2: '',
        'Whatisyourheightininches_a5a2dcf0-70bc-11ed-879c-8583350bb050': '',
        'Whatisyourweightinpounds_c0cc9930-70bc-11ed-879c-8583350bb050': '',
        Whatisyourtemperature: '',
        Ifyouhaveabloodpressurecuffpleasemeasureandenteryourbloodpressure: '',
        Pleaselistanyallergiestomedicationsorfoods: '',
        ThefollowingindividualsareauthorizedtoreceiveinformationregardingmymedicaltreatmentPleaseincludeNameandRelationshiptoPatientorNAExampleJaneDoeSpouse:
          '',
        WhatStatewillyoubelocatedinduringyourvirtualvideovisit: '',
      },
    }
  }
}

export default ModelPaperwork.Instance
