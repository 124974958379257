//:::::::::::::::::::::::::
import useAccountStore from 'src/_Shared/stores/account'
import css from './logOut.module.css'
import { Path, useCallback, useNavigate, Button } from 'src/_Shared/global'
import { useInsuranceStore } from 'src/_Shared/stores/insurance'
//:::::::::::::::::::::::::

export default function LogOut() {
  const navigate = useNavigate()
  const resetAccount = useAccountStore((state) => state.resetAccount)
  const resetInsurance = useInsuranceStore((state) => state.resetInsurance)

  //Logout
  const clickLogOut = useCallback(() => {
    resetAccount()
    resetInsurance()
    localStorage.clear()
    navigate(Path.LogOut)
  }, [navigate, resetAccount, resetInsurance])

  return (
    <div className={css.logOut}>
      <Button text='Log Out' clicked={clickLogOut} quaternary={true} />
    </div>
  )
}
