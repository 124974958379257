//:::::::::::::::::::::::::
import { Button, Foundation } from 'src/_Shared/global'
import { useAuth0 } from '@auth0/auth0-react'
import css from './home.module.css'
//:::::::::::::::::::::::::

export default function HomeAccount() {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  //Create Account
  const clickCreateAccount = () =>
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        scope:
          'openid profile email read:current_user update:current_user_metadata offline_access',
        redirect_uri:
          Foundation.auth0Redirect + '?destination=homeAccountCreated',
      },
    })

  //Login
  const clickLogIn = () => loginWithRedirect()

  return (
    <div className={css.homeAccount}>
      {/* === Not Authenticated === */}
      {!isAuthenticated ? (
        <>
          <Button text='Log In' clicked={clickLogIn} primary />
          <p>Don't Have an Account?</p>
          <Button text='Create Account' clicked={clickCreateAccount} tertiary />
        </>
      ) : null}
    </div>
  )
}
