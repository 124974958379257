//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import PaperworkWrap from '../PaperworkWrap'
import {
  Path,
  Nav,
  Button,
  useCallback,
  useNavigate,
  FieldText,
  observer,
  ModelPaperwork,
  useState,
  useEffect,
  ModelClinic,
  useSearchParams,
  Types,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

function PaperworkInfoRelease() {
  const navigate = useNavigate()

  const { responses } = ModelPaperwork.paperwork
  const [params] = useSearchParams()

  const [feet, setFeet] = useState<string>(() => {
    const stored =
      responses[
        'Whatisyourheightininches_a5a2dcf0-70bc-11ed-879c-8583350bb050'
      ] ?? ''
    return stored?.length
      ? (parseInt(stored) / 12).toString().split('.')[0]
      : ''
  })
  const [inches, setInches] = useState(() => {
    const stored =
      responses[
        'Whatisyourheightininches_a5a2dcf0-70bc-11ed-879c-8583350bb050'
      ] ?? ''
    return stored?.length ? (parseInt(stored) % 12).toString() : ''
  })

  const setResponseValue = (key: string, value: string) => {
    ModelPaperwork.setPaperwork({
      ...ModelPaperwork.paperwork,
      responses: {
        ...responses,
        [key]: value,
      },
    })
  }

  //==============================
  //#region Load Forms
  //==============================
  useEffect(() => {
    async function start(clinicId: string) {
      console.log('[Paperwork][SummaryForms][start()] clinicId:', clinicId)
      const docs = await ModelClinic.getReviewAndSignFormsForClinic(clinicId)
      console.log('[Paperwork][SummaryForms][start()] docs:', docs)

      //:::
      if (docs) {
        ModelPaperwork.setDocuments(docs)
      }

      ModelPaperwork.resetAcceptanceFormFields()
    }
    let clinicId
    //Grab clinic ID and set it
    const bookingSummaryRaw = localStorage.getItem('bookingSummary')
    let bookingSummary
    if (bookingSummaryRaw) {
      bookingSummary = JSON.parse(bookingSummaryRaw) as Types.BookingSummary
    }
    const clinic = localStorage.getItem('clinic')
    const selectedVirtual = ModelClinic.getSelectedVirtual()
    console.log('[Paperwork][Virtual] clinic:', clinic)
    if (selectedVirtual) {
      clinicId = selectedVirtual.practiceDetails?.id
      if (clinicId) localStorage.setItem('clinicId', clinicId)
      console.log('[Paperwork][Virtual] selectedVirtual:', selectedVirtual)
      console.log('[Paperwork][Virtual] clinicId:', clinicId)
    }
    console.log('[Paperwork][Virtual] clinicId:', clinicId)
    if (!clinicId && bookingSummary?.clinicId) {
      clinicId = bookingSummary.clinicId
    }
    if (clinicId) {
      localStorage.setItem('clinicId', clinicId)
    }
    //Get clinic from paperwork query param
    if (clinicId) start(clinicId)
  }, [params])

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(async () => {
    navigate(Path.PaperworkVirtual7)
  }, [navigate])

  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav
        back={Path.PaperworkVirtual4}
        title='Additional Paperwork'
        progress={6 / 7}
      />
      <div className={css.paperwork}>
        <div className={css.paperworkInner}>
          <h2>Additional Information - 1</h2>
          <h5 className={css.label}>What is your height in feet?</h5>
          <div className={css.heightFields}>
            <FieldText
              value={feet}
              label='Feet'
              change={(value) => {
                setFeet(value)
                const newHeight = (
                  Number(value) * 12 +
                  Number(inches)
                ).toString()
                console.log('newHeight', newHeight)
                setResponseValue(
                  'Whatisyourheightininches_a5a2dcf0-70bc-11ed-879c-8583350bb050',
                  newHeight,
                )
              }}
            />

            <FieldText
              value={inches}
              label='Inches'
              change={(value) => {
                setInches(value)
                const newHeight = (Number(feet) * 12 + Number(value)).toString()
                console.log('newHeight', newHeight)
                setResponseValue(
                  'Whatisyourheightininches_a5a2dcf0-70bc-11ed-879c-8583350bb050',
                  newHeight,
                )
              }}
            />
          </div>
          <h5 className={css.label}>What is your Weight in pounds?</h5>
          <FieldText
            value={
              responses[
                'Whatisyourweightinpounds_c0cc9930-70bc-11ed-879c-8583350bb050'
              ] ?? ''
            }
            label=''
            change={(value) => {
              setResponseValue(
                'Whatisyourweightinpounds_c0cc9930-70bc-11ed-879c-8583350bb050',
                value,
              )
            }}
          />
          <h5 className={css.label}>What is your Temperature?</h5>
          <FieldText
            value={responses.Whatisyourtemperature}
            label=''
            change={(value) => {
              setResponseValue('Whatisyourtemperature', value)
            }}
          />

          <h5 className={css.label}>
            If you have a blood pressure cuff, please measure and enter your
            blood pressure?
          </h5>
          <FieldText
            value={
              responses[
                'Ifyouhaveabloodpressurecuffpleasemeasureandenteryourbloodpressure'
              ]
            }
            label=''
            change={(value) => {
              setResponseValue(
                'Ifyouhaveabloodpressurecuffpleasemeasureandenteryourbloodpressure',
                value,
              )
            }}
          />
          <h5 className={css.label}>
            Please list any allergies to food and medication
          </h5>
          <FieldText
            value={responses.Pleaselistanyallergiestomedicationsorfoods}
            label=''
            change={(value) => {
              setResponseValue(
                'Pleaselistanyallergiestomedicationsorfoods',
                value,
              )
            }}
          />
          <h5 className={css.label}>
            If being seen virtually, what state are you located in at the time
            of your visit?
          </h5>
          <FieldText
            value={
              responses.WhatStatewillyoubelocatedinduringyourvirtualvideovisit
            }
            label=''
            change={(value) => {
              setResponseValue(
                'WhatStatewillyoubelocatedinduringyourvirtualvideovisit',
                value,
              )
            }}
          />
        </div>
      </div>

      {/* === Continue === */}
      <div className={css.buttonWrap}>
        <Button primary clicked={clickContinue} text={'Continue'} />
      </div>
    </PaperworkWrap>
  )
}

export default observer(PaperworkInfoRelease)
