//:::::::::::::::::::::::::
import {
  Button,
  useCallback,
  FirebaseAnalytics,
  Path,
  useNavigate,
  Types,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function ClinicSelectVisitTime(props: { clinic: Types.Clinic }) {
  const navigate = useNavigate()

  const clickSelectVisitTime = useCallback(() => {
    //%%%%%
    FirebaseAnalytics.trigger('cta_click_view_details', {
      clinic_name: props.clinic.details.title ?? 'No Clinic Title',
      visit_type: 'urgent care',
    })

    //>>>>>
    navigate(`${Path.BookTime}?clinic=${props.clinic.id}`)
  }, [navigate, props.clinic])
  return (
    <>
      <Button
        text='Select a Visit Time'
        clicked={clickSelectVisitTime}
        primary={true}
      />
    </>
  )
}
