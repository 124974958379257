//:::::::::::::::::::::::::
import css from './downloadAppCard.module.css'
import { Icon } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function DownloadAppCardItem(props: {
  icon: string
  text: string
}) {
  return (
    <div className={css.downloadAppCardItem}>
      <Icon name={props.icon} width={64} />
      <p>{props.text}</p>
    </div>
  )
}
