//:::::::::::::::::::::::::
import { useAuth0 } from '@auth0/auth0-react'
import css from '../Paperwork/paperwork.module.css'
import {
  Helper,
  ModelAccount,
  ModelModal,
  Nav,
  observer,
  Types,
  useEffect,
  useState,
} from 'src/_Shared/global'
import { AccountUtils, useAccountStore } from 'src/_Shared/stores/account'
import { Account } from 'src/_Shared/stores/account/store'
//:::::::::::::::::::::::::

const USA_ID = '13b3af53-f290-4f2b-b0ac-a81c64421d9a'
const Canada_ID = '3a2011eb-8425-47b4-add9-4b6cfe3021a1'

function showLoader() {
  ModelModal.hide()
  ModelModal.showLoader('Please Wait', 'Creating account...')
}

function countryFor(country: string) {
  switch (country) {
    case 'USA':
      return USA_ID
    case 'Canada':
      return Canada_ID
    default:
      return ''
  }
}

function PaperworkAccountCreated() {
  const [paperwork] = useState<Types.Paperwork | undefined>(
    ModelAccount.getPaperwork(),
  )

  const [
    account,
    lookupUser,
    createDependent,
    createUser,
    setIdToken,
    setAccessToken,
    linkAccounts,
    setAccount,
    fetchAccount,
  ] = useAccountStore((state) => [
    state.account,
    state.lookupUser,
    state.createDependent,
    state.createUser,
    state.setIdToken,
    state.setAccessToken,
    state.linkAccounts,
    state.setAccount,
    state.fetchAccount,
  ])
  const [timestamp, setTimestamp] = useState(new Date().getTime())
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0()
  useEffect(() => {
    const loginUser = async () => {
      // Get the token and claims and save them to local storage
      showLoader()
      const { access_token: token } = await getAccessTokenSilently({
        detailedResponse: true,
      })
      setAccessToken(token)
      const tokenClaims = await getIdTokenClaims()
      if (tokenClaims) setIdToken(tokenClaims.__raw)

      if (!paperwork) return
      // If the paperwork account has a birthday that's less than 18 years ago, save the patient as a dependent
      const booker = ModelAccount.getSavedObj('booker')
      const isBookedForDependent =
        !!booker?.dateOfBirth &&
        booker.dateOfBirth !== paperwork.account.dateOfBirth
      const isDependentMinor =
        isBookedForDependent &&
        !Helper.isAtLeast18YearsOld(paperwork.account.dateOfBirth)
      const paperworkDefaults: Account = {
        firstName: paperwork.account.firstName,
        lastName: paperwork.account.lastName,
        dateOfBirth: paperwork.account.dateOfBirth,
        email: paperwork.account.email,
        phoneNumber: paperwork.account.phone,
        ethnicityId: AccountUtils.getEthnicityIdByName(
          paperwork.account.ethnicity,
        ),
        ethnicityDescription: paperwork.account.ethnicity,
        raceId: AccountUtils.getRaceIdByName(paperwork.account.race),
        raceDescription: paperwork.account.race,
        sex: paperwork.account.birthSex,
        address1: paperwork.account.address,
        address2: paperwork.account.apartment,
        city: paperwork.account.city,
        state: paperwork.account.state,
        zipCode: paperwork.account.zip,
        countryId: countryFor(paperwork.account.country),
        nextGenPersonId: '',
        auth0Id: tokenClaims?.sub ?? '',
        favoriteClinics: [],
        insurances: [],
        country: paperwork.account.country,
        serverTime: new Date().getTime().toString(),
        dependents: [],
      }
      // If the patient is a minor, set the booker as the account holder
      const bookerAccountHolder = isBookedForDependent
        ? {
            firstName: booker?.firstName,
            lastName: booker?.lastName,
            dateOfBirth: booker?.dateOfBirth,
            sex: booker?.sex ?? 'O', // use 'O' as the default value
            email: booker?.email,
            phoneNumber: booker?.phone,
            ethnicityId: AccountUtils.getEthnicityIdByName('Undisclosed'), // We will only send "Unknown" as a string (where applicable) when the user selects this option
            ethnicityDescription: 'Unknown',
            raceId: AccountUtils.getRaceIdByName('Undisclosed'), // We will only send "Unknown" as a string (where applicable) when the user selects this option
            raceDescription: 'Unknown',
            dependents: isDependentMinor ? [paperworkDefaults] : [],
          }
        : {}

      if (paperwork) {
        const newAccount = {
          ...paperworkDefaults,
          ...bookerAccountHolder,
        }
        console.info('**** [PaperworkAccountCreated] newAccount', newAccount)
        setAccount(newAccount)
        setTimestamp(new Date().getTime())
      }
    }
    if (paperwork) loginUser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const createAuthUser = async () => {
      // Create the account and look it up after creation
      if (account) {
        await createUser()
        const result = await lookupUser()

        // If the lookup was successful, update the account with the person ID
        if (result) {
          const updatedAccount = {
            ...account,
            nextGenPersonId: result,
          }
          // localStorage.setItem('account', JSON.stringify(updatedAccount))
          setAccount(updatedAccount)

          // Update Auth0 with the person ID
          await linkAccounts()
          if ((updatedAccount.dependents?.length ?? 0) > 0) {
            // Create the dependents
            ModelModal.showLoader('Please Wait', 'Saving dependent ...')
            const dependent = updatedAccount.dependents?.[0]
            if (dependent) await createDependent(dependent)
            ModelModal.showLoader('Please Wait', 'Syncing account ...')
            await fetchAccount()
          }
        }
        ModelModal.hide()
        ModelModal.infoSaved()
      }
    }
    if (timestamp) createAuthUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp])

  //==============================
  //#region UI
  //==============================
  return (
    <>
      {/* === Nav === */}
      <Nav title='Save Your Information' />
      <div className={css.saveInfo}>
        <div className={css.saveInfoHeading}>
          <h1>Save Your Information for Faster Check-ins</h1>
          <p>
            Enjoy pre-filled paperwork on future visits, along with access to
            your health record summary, lab results, and the ability to pay your
            bills online.
          </p>
        </div>
      </div>
    </>
  )
}

export default observer(PaperworkAccountCreated)
