import { Helper, HttpClient } from 'src/_Shared/global'
import forge from 'node-forge'
import type { Insurance } from './store'
import { InsuranceBO } from 'src/BOs/InsuranceBO'

const API = {
  async deleteInsurance(cardId: string, idToken: string) {
    try {
      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/nextgen/insurance/delete',
        {},
        {
          headers: {
            idtoken: idToken,
            cardid: cardId,
          },
        },
      )

      //:::
      console.log(response)

      //Done
      return response
    } catch (error) {
      //--- ! ---
      Helper.handleError('Upload Insurance Card', error)
    }
  },
  async createInsurance(
    frontImage: string,
    backImage: string,
    cardName: string,
    idToken: string,
  ) {
    try {
      const removePreamble = (image: string) => {
        console.info('Before removing preamble:', image.slice(0, 10))
        const newImage = image.startsWith('data:image')
          ? image.split(',')[1]
          : image
        console.info('After removing preamble:', newImage.slice(0, 10))
        return newImage
      }
      const ready = await Helper.encryptCardImages(
        removePreamble(frontImage),
        removePreamble(backImage),
      )

      const insuranceToUpload = {
        publicKey: ready.publicKey,
        cardNickName: cardName,
        insuranceFrontImage: ready.cardFront,
        insuranceBackImage: ready.cardBack,
        encryptedSymmetricKey: ready.symmetricKey,
        encryptedIv: ready.initializationVector,
      }

      const httpClient = new HttpClient<any, any>()
      const response = await httpClient.post(
        'v2/nextgen/insurance/upload',
        insuranceToUpload,
        {
          headers: {
            idtoken: idToken,
          },
        },
      )

      //:::
      console.log(response)

      return response?.data
      //Done
    } catch (error) {
      //--- ! ---
      Helper.handleError('Upload Insurance Card', error)
    }
  },
  async fetchInsurances(idToken: string): Promise<Partial<Insurance>[]> {
    const httpClient = new HttpClient<any[], any>()
    const response = await httpClient.post(
      'v2/nextgen/insurance/all',
      {},
      {
        headers: {
          idtoken: idToken,
        },
      },
    )
    const insurances = response?.data ?? []
    return insurances?.map?.((insurance: InsuranceBO) => ({
      cardId: insurance.id,
      cardNickName: insurance.cardNickName,
    }))
  },
  async fetchInsuranceDetails(cardId: string, idToken: string) {
    const keyPair = await forge.pki.rsa.generateKeyPair({ bits: 2048 })
    let publicKeyPem = forge.pki.publicKeyToPem(keyPair.publicKey)
    let privateKeyPem = forge.pki.privateKeyToPem(keyPair.privateKey)

    const httpClient = new HttpClient<any, any>()
    const response = await httpClient.post(
      'v2/nextgen/insurance',
      {
        publicKey: Helper.encode64(publicKeyPem), // Ensure this is correctly handled on the server
      },
      {
        headers: {
          idtoken: idToken,
          cardid: cardId,
        },
      },
    )

    if (response?.data?.encryptedSymmetricKey && response?.data?.encryptedIv) {
      try {
        const symmetricKey = Helper.decryptRsa(
          response.data.encryptedSymmetricKey,
          privateKeyPem,
        )
        const symmetricIv = Helper.decryptRsa(
          response.data.encryptedIv,
          privateKeyPem,
        )

        const decryptedFrontImage = Helper.symmetricMessageDecryption(
          symmetricKey,
          symmetricIv,
          response.data.insuranceFrontImage,
        )
        const decryptedBackImage = Helper.symmetricMessageDecryption(
          symmetricKey,
          symmetricIv,
          response.data.insuranceBackImage,
        )

        return {
          ...response.data,
          insuranceFrontImage: decryptedFrontImage,
          insuranceBackImage: decryptedBackImage,
        }
      } catch (error) {
        console.error('Decryption failed:', error)
        throw new Error('Image decryption failed')
      }
    } else {
      console.error('Missing encrypted keys in response')
      throw new Error('Missing encrypted keys')
    }
  },
  async uploadCards(
    front: string,
    back: string,
    bookingId: string,
    idToken: string,
  ) {
    try {
      console.log('🔄 Uploading ID cards...')
      const ready = await Helper.encryptCardImages(front, back)

      const cardFront = {
        image: ready.cardFront,
        image_type: 'insurance_card_front',
        publicKey: ready.publicKey,
        encryptedSymmetricKey: ready.symmetricKey,
        encryptedIv: ready.initializationVector,
      }
      // console.log(cardFront)

      const cardBack = {
        image: ready.cardBack,
        image_type: 'insurance_card_back',
        publicKey: ready.publicKey,
        encryptedSymmetricKey: ready.symmetricKey,
        encryptedIv: ready.initializationVector,
      }

      const httpClient = new HttpClient<any, any>()
      const responseFront = await httpClient.post(
        'v2/solv/bookings/uploaddocuments',
        cardFront,
        {
          headers: {
            bookingid: bookingId,
            idtoken: idToken,
          },
        },
      )

      console.log('🪪 Card Front Response:')
      console.log(responseFront)

      const responseBack = await httpClient.post(
        'v2/solv/bookings/uploaddocuments',
        cardBack,
        {
          headers: {
            bookingid: bookingId,
            idtoken: idToken,
          },
        },
      )

      console.log('💳 Card Back Response:')
      console.log(responseBack)

      //Done
    } catch (error) {
      //--- ! ---
      Helper.handleError('Upload Insurance Paperwork', error)
    }
  },
}

export default API
