//:::::::::::::::::::::::::
import css from './MapSearch.module.css'
import {
  Icon,
  useCallback,
  ModelMap,
  useState,
  Button,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function MapSearch() {
  const [searchTerms, setSearchTerms] = useState('')

  const search = useCallback(async () => {
    let terms = searchTerms.trim()
    if (terms) {
      await ModelMap.search(terms)
    }
  }, [searchTerms])

  //==============================
  // Hit Enter to Search
  //==============================
  const hitEnter = (event: any) => {
    if (event.keyCode === 13) {
      search()
    }
  }
  return (
    <div className={css.mapSearch}>
      {/* === Locate === */}
      <Button clicked={() => ModelMap.getUserLocation()}>
        <span className={css.locationIcon}>
          <Icon name='locate' width={20} />
        </span>
      </Button>
      {/* === Input Field === */}
      <input
        type='text'
        value={searchTerms}
        placeholder='Address, City, State, or ZIP'
        onChange={(event) => setSearchTerms(event.target.value)}
        onKeyDownCapture={hitEnter}
      />
      {/* === Search === */}
      <Button clicked={search}>
        <div className={css.searchButton}>
          <Icon name='search' width={20} />
        </div>
      </Button>
    </div>
  )
}
