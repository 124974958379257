//:::::::::::::::::::::::::
import css from './loadingMarker.module.css'
import { Spinner } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function LoadingMarker() {
  return (
    <p className={css.loadingMarker}>
      <Spinner size={20} color='#FFF' />
    </p>
  )
}
