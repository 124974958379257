//:::::::::::::::::::::::::
import css from './documentList.module.css'
//:::::::::::::::::::::::::

export default function DocumentList(props: {
  children: React.ReactNode
  noBorders?: boolean
}) {
  var classReady = css.documentList
  if (props.noBorders) {
    classReady = `${css.documentList} ${css.documentListNoBorders}`
  }
  return <ul className={classReady}>{props.children}</ul>
}
