//:::::::::::::::::::::::::
import css from './bookPatientCss.module.css'
import {
  Types,
  FieldText,
  FieldDateOfBirth,
  FieldPhone,
  FieldEmail,
  Select,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

//==============================
//#region State
//==============================
export default function BookPatientForm(props: {
  values: Types.Patient
  change: (values: Types.Patient) => void
}) {
  //==============================
  //#region UI
  //==============================
  return (
    <form className={css.bookPatientForm}>
      <h4>Patient Information</h4>

      {/* === Patient First Name === */}
      <FieldText
        value={props.values.firstName}
        label='Patient First Name'
        error='First name is required'
        change={(value) => {
          props.change({
            ...props.values,
            firstName: value,
          })
        }}
      />

      {/* === Patient Last Name === */}
      <FieldText
        value={props.values.lastName}
        label='Patient Last Name'
        error='Last name is required'
        change={(value) => {
          props.change({
            ...props.values,
            lastName: value,
          })
        }}
      />

      {/* === Date of Birth === */}
      <FieldDateOfBirth
        value={props.values.dateOfBirth}
        change={(value) =>
          props.change({
            ...props.values,
            dateOfBirth: value,
          })
        }
      />

      {/* === Mobile Number === */}
      <FieldPhone
        value={props.values.phone}
        change={(value) =>
          props.change({
            ...props.values,
            phone: value,
          })
        }
      />

      {/* === Email Address === */}
      <FieldEmail
        value={props.values.email}
        change={(value) =>
          props.change({
            ...props.values,
            email: value,
          })
        }
      />

      {/* === Relationship === */}
      <div className={css.bookPatientFormSelect}>
        <Select
          label='Relationship'
          items={['Dependent Minor', 'Other']}
          value={props.values.relationship || ''}
          change={(value) =>
            props.change({
              ...props.values,
              relationship: value,
            })
          }
        />
        <p>
          {props.values.relationship === 'Other' &&
            'Other is anyone else and includes:  Dependent Adults, Spouse, Parents, Siblings, Other Relatives, and/or any other adult you are booking a visit on behalf of.'}
          {props.values.relationship === 'Dependent Minor' &&
            'A Dependent Minor means a person determined to be financially dependent upon a parent or guardian who has not reached the age of 18 or is not an emancipated minor.'}
        </p>
      </div>
    </form>
  )
}
