//:::::::::::::::::::::::::
import { observer } from 'mobx-react-lite'
import {
  Nav,
  Path,
  useNavigate,
  ModelBooking,
  ModelVirtualCare,
  ModelClinic,
  Selector,
  Button,
  useEffect,
  DatePicker,
  moment,
  useLocation,
  ModelModal,
  ModelPaperwork,
  useState,
  FieldText,
} from 'src/_Shared/global'
import BookTimeSlots from 'src/BookNow/2-BookTime/BookTimeSlots'
import VirtualCarePracticeInfo from './VirtualCarePracticeInfo'
import css from './virtualTime.module.css'
//:::::::::::::::::::::::::

function VirtualTime() {
  const navigate = useNavigate()
  const location = useLocation()
  const onDemandDate = ModelClinic.selectedVirtual?.times?.earliestAvailable
  const [defaultBookTimeDate, setDefaultBookTimeDate] = useState(
    moment().format('dddd, MMMM DD'),
  )

  //==============================
  //#region Get PracticeDetails
  //==============================
  useEffect(() => {
    //Reset clinic
    ModelClinic.setSelectedVirtual()
    ModelClinic.setSelected(undefined)

    //Clear booking
    ModelBooking.clear()
    ModelBooking.setVirtualCare(true)
    ModelBooking.setPrimaryCare(false)
    ModelBooking.setUrgentCare(false)

    //Clear paperwork
    ModelPaperwork.clear()

    // Redirect from marketing site
    const getPracticeDetails = async (locationId: string) => {
      const practiceDetails = await ModelClinic.getPracticeDetailsById(
        locationId,
      )
      ModelClinic.setPracticeDetails(practiceDetails)
      if (!practiceDetails) {
        ModelModal.showError('Clinic not found')
        return navigate(Path.VirtualCareLocation)
      }
      ModelVirtualCare.loadTimes(practiceDetails)
    }

    //Get the last path of the URL to acquire the locationId (same as practiceId)
    let locationId = ''
    if (location) {
      locationId = location.pathname.split('/').pop() ?? ''
    }

    //If not a 6-character clinic ID, send them to the home page
    if (locationId.length === 6) {
      getPracticeDetails(locationId)
      //Save to localStorage for use on VirtualSignForms
      localStorage.setItem('virtualClinicId', locationId)
    } else {
      ModelModal.showError('Clinic not found')
      return navigate(Path.VirtualCareLocation)
    }
  }, [navigate, location])

  //==============================
  //#region Continue
  //==============================
  const clickContinue = () => {
    navigate(Path.VirtualCareInfo)
  }

  const handleSelectTab = (value: string) => {
    switch (value) {
      case 'Today':
        setDefaultBookTimeDate(moment().format('dddd, MMMM DD'))
        ModelBooking.setSelectedTimeSlotPackage(
          ModelBooking.timeSlotPackages.find((p) => {
            return p.day === new Date().getDate()
          }),
        )
        break
      case 'Tomorrow':
        setDefaultBookTimeDate(moment().add(1, 'days').format('dddd, MMMM DD'))
        ModelBooking.setSelectedTimeSlotPackage(
          ModelBooking.timeSlotPackages.find((p) => {
            return p.day === new Date().getDate() + 1
          }),
        )
        break
      default:
        setDefaultBookTimeDate('')
        ModelBooking.setSelectedTimeSlotPackage(
          ModelBooking.timeSlotPackages[0],
        )
        break
    }
    ModelBooking.setSelectedTab(value)
  }

  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.virtualCareBook}>
      <Nav title='Select Date and Time' back={Path.VirtualCareLocation} />
      {ModelClinic.selectedVirtual?.practiceDetails && (
        <VirtualCarePracticeInfo
          practice={ModelClinic.selectedVirtual.practiceDetails}
        />
      )}

      {/* === Booking Times === */}
      <div className={css.bookTimesContainer}>
        {ModelBooking.virtualCare && (
          <>
            {/* === Virtual Care Tab Selector === */}
            <div className={css.virtualCareTabSelector}>
              <Selector
                items={['ON DEMAND', 'SCHEDULE A VISIT']}
                selected={ModelVirtualCare.selectedTab}
                clicked={(value) => {
                  if (value === 'ON DEMAND' && onDemandDate) {
                    ModelBooking.setAppointment(moment(onDemandDate).toDate())
                  } else {
                    ModelBooking.setAppointment(undefined)
                  }
                  ModelVirtualCare.setSelectedTab(value)
                }}
              />
            </div>
            <div className={css.bookTimes}>
              {ModelVirtualCare.selectedTab === 'ON DEMAND' ? (
                <div className={css.onDemandVisit}>
                  <div className={css.onDemandVisitDetails}>Visit Details</div>
                  {onDemandDate ? (
                    <>
                      <div className={css.onDemandVisitDate}>
                        Your estimated visit time will be{' '}
                        <span>{moment(onDemandDate).format('llll')}</span>
                      </div>
                    </>
                  ) : (
                    <div className={css.onDemandNullState}>
                      <p className={css.onDemandNullStateTitle}>
                        No on demand slots available
                      </p>
                      <p className={css.onDemandNullStateDescription}>
                        All online booking slots are full. You can still or
                        schedule a visit walk in to the clinic.
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className={css.selectAvailableTimeSlots}>
                    Select Available Slots
                  </div>

                  <Selector
                    items={['Today', 'Tomorrow', 'More Dates']}
                    selected={ModelBooking.selectedTab}
                    clicked={(value) => handleSelectTab(value)}
                    itemWidth={100}
                  />

                  {ModelBooking.selectedTab === 'More Dates' ? (
                    <>
                      {/* === Date Picker === */}
                      {ModelBooking.selectedTimeSlotPackage && (
                        <DatePicker
                          selectedDate={
                            ModelBooking.selectedTimeSlotPackage?.date ??
                            new Date()
                          }
                          minDate={
                            new Date(ModelBooking.earliestAppointmentDate)
                          }
                          maxDate={new Date(ModelBooking.latestAppointmentDate)}
                          change={(date: Date) => {
                            //Get the day they clicked and find the associated time slot package
                            const day = date.getDate()
                            const selected = ModelBooking.timeSlotPackages.find(
                              (item) => item.day === day,
                            )
                            if (selected) {
                              ModelBooking.setSelectedTimeSlotPackage(selected)
                            }
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {/* === Date Heading === */}
                      <h2 className={css.bookTimeDate}>
                        {ModelBooking.selectedTimeSlotPackage?.displayDate ||
                          defaultBookTimeDate}
                      </h2>
                    </>
                  )}

                  {/* === Time Slots === */}
                  <BookTimeSlots
                    slots={
                      (ModelBooking.selectedTimeSlotPackage &&
                        ModelBooking.selectedTimeSlotPackage?.slots) ||
                      []
                    }
                    selected={ModelBooking.appointment}
                    clicked={(slot) => {
                      ModelBooking.setAppointment(moment(slot).toDate())
                    }}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className={css.basement}>
        {/* === Reason === */}
        <FieldText
          value={ModelVirtualCare.reason}
          label='Reason for Visit'
          change={(value) => {
            ModelVirtualCare.setReason(value)
          }}
        />
        {/* === Continue === */}
        <Button
          primary
          text='Continue'
          clicked={clickContinue}
          disabled={
            !ModelBooking.appointment || ModelVirtualCare.reason.length === 0
          }
        />
      </div>

      <p className={css.emergency}>For emergencies, please call 911</p>
    </div>
  )
}

export default observer(VirtualTime)
