//:::::::::::::::::::::::::
import css from './textarea.module.css'
//:::::::::::::::::::::::::

export default function Textarea(props: {
  placeholder: string
  //Form validation
  error?: string
  valid?: boolean | undefined //Allow undefined initial state so all errors aren't visible on page load
  change?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}) {
  return (
    <div className={css.textareaWrap}>
      <div
        className={`${css.textarea} ${props.valid === false ? css.error : ''}`}
      >
        <textarea
          placeholder={props.placeholder}
          onChange={props.change}
        ></textarea>
        {/* === Error === */}
        <p
          className={`${css.textareaError} ${
            props.valid === false ? css.error : ''
          }`}
        >
          {props.error}
        </p>
      </div>
    </div>
  )
}
