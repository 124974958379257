//:::::::::::::::::::::::::
import { Icon } from 'src/_Shared/global'
import css from './validItem.module.css'
//:::::::::::::::::::::::::

export default function ValidItem(props: { text: string; valid: boolean }) {
  return (
    <div className={css.valid}>
      <Icon name={props.valid ? 'done' : 'ring'} width={24} />
      <p>{props.text}</p>
    </div>
  )
}
