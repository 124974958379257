//:::::::::::::::::::::::::
import css from './CreateNextGenAccount.module.css'
import { observer } from 'mobx-react-lite'
import PersonalInformation from '../PersonalInformation/PersonalInformation'
//:::::::::::::::::::::::::

function PersonalInfo() {
  return (
    <div className={css.accountCreate}>
      <PersonalInformation
        createAccount
        title='Patient Information'
        buttonText='Continue'
        message={
          <div className={css.accountCreateMessage}>
            <h3>Patient Information</h3>
            <p>
              Let’s setup your profile. This will help you pick up where you
              started and make it easier to serve you every time you log on.
              <span> 100% confidential and secure.</span>
            </p>
          </div>
        }
      />
    </div>
  )
}

export default observer(PersonalInfo)
