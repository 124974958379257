//:::::::::::::::::::::::::
import { AccountConstants } from 'src/_Shared/stores/account'
import css from '../paperwork.module.css'
import { Types, FieldText, Label, Selector, Select } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function PaperworkPatientAddress(props: {
  values: Types.Paperwork
  change: (values: Types.Paperwork) => void
}) {
  return (
    <div className={css.paperworkPatientAddress}>
      {/* === Street Address === */}
      <FieldText
        value={props.values.account.address}
        label='Street Address'
        error='Address is required'
        change={(value) =>
          props.change({
            ...props.values,
            account: {
              ...props.values.account,
              address: value,
            },
          })
        }
      />

      {/* === Apartment === */}
      <FieldText
        value={props.values.account.apartment}
        label='Apt, suite, etc. (optional)'
        change={(value) => {
          props.change({
            ...props.values,
            account: {
              ...props.values.account,
              apartment: value,
            },
          })
        }}
      />

      {/* === City === */}
      <FieldText
        label='City'
        value={props.values.account.city}
        error='City is required'
        change={(value) =>
          props.change({
            ...props.values,
            account: {
              ...props.values.account,
              city: value,
            },
          })
        }
      />

      {/* === Country === */}
      <div className={css.fieldWrap}>
        <Label text='Country' />
        <Selector
          items={['USA', 'Canada']}
          selected={props.values.account.country}
          clicked={(value) =>
            props.change({
              ...props.values,
              account: {
                ...props.values.account,
                country: value,
              },
            })
          }
        />
      </div>

      {/* === State === */}
      <div className={css.split}>
        <div className={css.stateWrap}>
          <Select
            label={
              props.values.account.country === 'USA' ? 'State' : 'Province'
            }
            value={props.values.account.state}
            items={
              props.values.account.country === 'USA'
                ? AccountConstants.STATES
                : AccountConstants.PROVINCES
            }
            change={(value) =>
              props.change({
                ...props.values,
                account: {
                  ...props.values.account,
                  state: value,
                },
              })
            }
          />
        </div>

        {/* === ZIP === */}
        <FieldText
          value={props.values.account.zip}
          label={
            props.values.account.country === 'USA' ? 'ZIP Code' : 'Postal Code'
          }
          error={
            props.values.account.country === 'USA'
              ? 'ZIP code is required'
              : 'Postal code is required'
          }
          change={(value) =>
            props.change({
              ...props.values,
              account: {
                ...props.values.account,
                zip: value,
              },
            })
          }
          mask={props.values.account.country === 'USA' ? '99999' : '*** ***'}
        />
      </div>
    </div>
  )
}
