//:::::::::::::::::::::::::
import css from './button.module.css'
import { useNavigate, useCallback } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function Button(props: {
  text?: string
  title?: string
  disabled?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  quaternary?: boolean
  inline?: boolean
  block?: boolean
  mini?: boolean
  //If a link is provided, use navigate to go to a page
  link?: string
  clicked?: (event: React.MouseEvent<HTMLElement>) => any
  //Optional children for clickable things
  children?: React.ReactNode
}) {
  const navigate = useNavigate()

  const clickedButton = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      //If a link is provided, navigate
      if (props.link) {
        //>>>>>
        navigate(props.link)
      }
      //Pass the click even to the parent if no link
      if (props.clicked) {
        props.clicked(event)
      }
    },
    [navigate, props],
  )

  return (
    <>
      <button
        title={props.title && props.text}
        type='button'
        onClick={(event) => clickedButton(event)}
        disabled={props.disabled}
        className={`${css.button} 
        ${props.disabled ? css.disabled : ''} 
        ${props.primary ? css.primary : ''} 
        ${props.secondary ? css.secondary : ''} 
        ${props.tertiary ? css.tertiary : ''}
        ${props.quaternary ? css.quaternary : ''}
        ${props.children ? css.children : ''}
        ${props.inline ? css.inline : ''}
        ${props.block ? css.block : ''}
        ${props.mini ? css.mini : ''}
        `}
      >
        {props.children ? props.children : props.text}
      </button>
    </>
  )
}
