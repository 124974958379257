//:::::::::::::::::::::::::
import { observer, ModelVirtualCare } from 'src/_Shared/global'
import css from './virtualInfo.module.css'
//:::::::::::::::::::::::::

function VirtualInfoSelfPayTotal() {
  return (
    <div className={css.selfPayTotal}>
      <h2>Your Cost: ${ModelVirtualCare.price}</h2>
      <small>
        Price includes only the cost of your visit. The cost of any medications
        or treatments prescribed as a result of your visit is not included.
      </small>
    </div>
  )
}

export default observer(VirtualInfoSelfPayTotal)
