//:::::::::::::::::::::::::
import { FieldText, Types, Divider, PickList } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function PaperworkEmployerReason(props: {
  values: Types.Paperwork
  change: (values: Types.Paperwork) => void
}) {
  return (
    <div>
      <Divider />
      <h2>Reason for Request</h2>
      <PickList
        items={['Work Injury', 'Physical', 'Drug Screening / Alcohol Test']}
        selected={props.values.responses.whatAreYouHereFor}
        clicked={(value) => {
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              whatAreYouHereFor: value,
            },
          })
        }}
      />
    </div>
  )
}
