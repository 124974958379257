//:::::::::::::::::::::::::
import css from './virtualSignForms.module.css'
import {
  useState,
  useEffect,
  Nav,
  Path,
  Button,
  useNavigate,
  useCallback,
  ModelClinic,
  ModelVirtualCare,
  DocumentList,
  DocumentListItem,
  Input,
  Spinner,
  observer,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

function VirtualSignForms() {
  const navigate = useNavigate()

  //==============================
  //#region State
  //==============================
  const [signature, setSignature] = useState('')
  const [legal, setLegal] = useState(false)
  const [formReady, setFormReady] = useState(false)

  useEffect(() => {
    setFormReady(legal && signature.length > 0)
  }, [legal, signature])

  //==============================
  //#region Load Forms
  //==============================
  useEffect(() => {
    async function start(clinicId: string) {
      const docs = await ModelClinic.getReviewAndSignFormsForClinic(clinicId)
      var docIds: string[] = []

      if (docs) {
        ModelVirtualCare.setDocuments(docs)
        //Save doc IDs for booking submission
        docIds = docs.map((doc) => doc.id)
        localStorage.setItem('virtualConsentIds', JSON.stringify(docIds))
      }
    }

    const virtualClinicId = localStorage.getItem('virtualClinicId')
    if (virtualClinicId) {
      start(virtualClinicId)
    }
  }, [])
  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(() => {
    //>>>>>
    navigate(Path.VirtualCareFinalize)
  }, [navigate])

  //==============================
  //#region UI
  //==============================

  return (
    <>
      <Nav title='Review & Sign Forms' back={Path.VirtualCareInfo} />
      <div className={css.signForms}>
        {/* === Form List === */}
        {ModelVirtualCare.documents.length === 0 ? (
          <div className={css.loading}>
            <Spinner size={30} />
          </div>
        ) : (
          <DocumentList>
            {ModelVirtualCare.documents.map((document) => (
              <DocumentListItem
                id={document.id}
                text={document.text}
                subtext={document.subtext}
                url={document.url}
                accepted={document.accepted}
              />
            ))}
          </DocumentList>
        )}

        {/* === Hard-coded legal agreement === */}
        <DocumentList noBorders>
          <DocumentListItem
            accepted={legal}
            toggle={() => {
              setLegal(!legal)
            }}
          >
            By continuing, I understand and agree to the terms and conditions
            contained in the forms above and agree to sign them electronically.
          </DocumentListItem>
        </DocumentList>

        {/* === Signature Field === */}
        <div className={css.fieldWrap}>
          <Input
            signature
            type='text'
            label='Tap to add signature'
            value={signature}
            icon='signHere'
            error='Your signature is required'
            change={(event) => {
              ModelVirtualCare.setSignature(event.target.value)
              setSignature(event.target.value)
            }}
          />
        </div>

        {/* === Continue Button === */}
        <div className={css.button}>
          <Button
            text='Continue'
            clicked={clickContinue}
            disabled={!formReady}
            primary
          />
        </div>
      </div>
    </>
  )
}

export default observer(VirtualSignForms)
