//:::::::::::::::::::::::::
import css from './virtualInfo.module.css'
import { Select, Types, Input } from 'src/_Shared/global'
import { AccountUtils, AccountConstants } from 'src/_Shared/stores/account'
//:::::::::::::::::::::::::

export default function VirtualPatientInfo(props: {
  values: Types.Patient
  change: (values: Types.Patient) => void
}) {
  return (
    <div>
      {/* === Patient First Name === */}
      <Input
        type='text'
        name='phone'
        label='Patient First Name'
        value={props.values.firstName ?? ''}
        disabled
      />
      {/* === Patient Last Name === */}
      <Input
        type='text'
        name='lastName'
        label='Patient Last Name'
        value={props.values.lastName ?? ''}
        disabled
      />

      {/* === Date of Birth === */}
      <Input
        type='text'
        name='dateOfBirth'
        label='Patient Date of Birth'
        value={props.values.dateOfBirth ?? ''}
        disabled
      />

      {/* === Birth Sex === */}

      <Select
        label='Patient Birth Sex'
        value={props.values.sex ?? ''}
        items={['Male', 'Female', 'Other']}
        change={(value) => {}}
        disabled
      />

      {/* === Mobile Number === */}
      <Input
        type='text'
        name='phone'
        label='Patient Mobile Number'
        value={props.values.phone ?? ''}
        disabled
      />

      {/* === Email Address === */}
      <Input
        type='text'
        name='email'
        label='Patient Email Address'
        value={props.values.email ?? ''}
        disabled
      />

      {/* === Race === */}

      <Select
        label='Race'
        value={props.values.race ?? ''}
        items={AccountUtils.getRaceNames()}
        change={(value) => {}}
        disabled
      />

      {/* === Ethnicity === */}

      <Select
        label='Ethnicity'
        value={props.values.ethnicity ?? ''}
        items={AccountUtils.getEthnicityNames()}
        change={() => {}}
        disabled
      />

      {/* === Street Address === */}
      <Input
        type='text'
        name='address'
        label='Street Address'
        value={props.values.address ?? ''}
        disabled
      />

      {/* === Apartment === */}
      <Input
        type='text'
        name='apt'
        label='Apt, suite, etc. (optional)'
        value={props.values.apartment ?? ''}
        disabled
      />

      {/* === City === */}
      <Input
        type='text'
        name='city'
        label='City'
        value={props.values.city ?? ''}
        disabled
      />

      {/* === Country === */}

      <Select
        label='Country'
        value={props.values.country ?? 'USA'}
        items={['USA', 'Canada']}
        change={(value) => {}}
        disabled
      />

      {/* === State === */}

      <Select
        label={props.values.country === 'USA' ? 'State' : 'Province'}
        value={props.values.state ?? ''}
        items={
          props.values.country === 'USA'
            ? AccountConstants.STATES
            : AccountConstants.PROVINCES
        }
        change={(value) => {}}
        disabled
      />

      {/* === ZIP === */}
      <Input
        type='text'
        name='zip'
        label={props.values.country === 'USA' ? 'ZIP Code' : 'Postal Code'}
        value={props.values.zip ?? ''}
        disabled
      />
    </div>
  )
}
