//:::::::::::::::::::::::::
//http://localhost:3000/qr/0PRWLp
import css from './checkIn.module.css'
import {
  ModelClinic,
  useEffect,
  useLocation,
  useNavigate,
  useState,
  observer,
  Path,
  Foundation,
} from 'src/_Shared/global'
import ImageHero from 'src/_Shared/images/ImageHero'
import * as qr from "@bitjson/qr-code";

//:::::::::::::::::::::::::
function ClinicQR() {
  const navigate = useNavigate()

  //==============================
  //#region State
  //==============================
  const [clinicId, setClinicId] = useState('')
  const [clinicName, setClinicName] = useState('')

  const checkin_url = Foundation.baseUrl.replace('http://','').replace('https://','') + 'check-in/' + clinicId;

  //==============================
  //#region Find Clinic
  //==============================
  // Test clinic IDs:  0PRWLp | 7gNmLp
  const location = useLocation()

  useEffect(() => {
    //Get the last path of the URL to acquire the practiceId
    var id = ''
    if (location) {
      console.log();
      id = location.pathname.split('/').pop() ?? ''
    }

    //If not a 6-character clinic ID, send them to the home page
    if (id.length === 6) {
      setClinicId(id);
      lookup(id).then((c) =>  
        setClinicName(c?.details.title)
      );
    } else {
      navigate(Path.Home)
    }

    async function lookup(id) {
      const clinic = await ModelClinic.getClinic(id)
      return clinic;
    }
  }, [navigate, location]) 

  //==============================
  //#region Set Walk-In Appointment Type
  //==============================
  useEffect(() => {
    localStorage.setItem('visitType', 'WI')
  }, [])

//==============================
  //#region QR
  //https://github.com/bitjson/qr-code
  //==============================
  useEffect(() => {qr.defineCustomElements(window)}, [])
  
  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.checkIn}>
      <ImageHero />
      <div className={css.checkInWrap}>
        <div className={css.qrheader}>CHECK-IN HERE</div>
        
        <div className={css.qr}>
          <QRStep number="1." instructions="OPEN THE CAMERA APP ON YOUR PHONE"/>
          <QRStep number="2." instructions="POINT IT AT THE QR CODE TO SCAN"/>
          <QRStep number="3." instructions="TAP ON THE MESSAGE ON THE SCREEN"/>

          {/* */}
          <div className={css.code +' ' + css.centered} >
            <qr-code
              id="qrClinic"
              contents={`${checkin_url}`}
              module-color="#008F9B"
              
              position-ring-color="#008F9B"
              position-center-color="#294B67"
              mask-x-to-y-ratio="1.0">
                <img src="/logo192.png" slot="icon" alt='qrcode-clinic' />
              </qr-code>
          </div>

          <QRStep number="" instructions="No camera? Type in this link into your" />
          <QRStep number="" instructions="phone’s internet browser to sign-in:" />

          <div className={css.url}>{`${checkin_url}`} </div>
          
          <div className='qr dotted-line'></div>

          <QRStep number="" instructions="As an added safety measure for our staff & patients, we" />
          <QRStep number="" instructions="have implemented a new contactless sign-in workflow." />
        </div>

        <small>{clinicName + ' (' + clinicId +')' ?? 'Loading...'}</small>
      </div>
    </div>
  )
}

function QRStep(props) {
  return (
    /*center steps without a number*/
    <div className={props.number==="" ? css.centered : css.numbered}>
      {props.number ?
        <div className={css.checkInStep}>{props.number}</div> : <></>
      }
      <div className={css.checkInInstructions}>{props.instructions}</div>
    </div>
  )
}

export default observer(ClinicQR)