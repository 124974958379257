//==============================
//#region App Paths
//==============================
export enum Path {
  //General
  Home = `/`,
  Notifications = `/notifications`,
  //Auth
  Auth = `/auth`,
  LogIn = `/auth/login`,
  LogOut = `/auth/logout`,
  //QR Code & Check-in
  CheckIn = `/check-in`,
  CreateAccount = `/account/create`,
  CreateAccountPatientInfo = `/account/patient-information`,
  CreateAccountReviewAndAccept = `/account/review-and-accept`,
  PaperworkAccountCreated = '/account/created',
  //Booking
  BookStart = `/book/start`,
  BookClinicDetails = `/book/clinic`,
  BookTime = `/book/time`,
  BookTimeChange = `/book/change-time`,
  BookInfo = `/book/info`,
  BookPatient = `/book/patient`,
  BookSign = `/book/sign`,
  BookFinish = `/book/finish`,
  //Paperwork
  Paperwork1 = `/paperwork/1`,
  Paperwork2 = `/paperwork/2`,
  Paperwork3 = `/paperwork/3`,
  Paperwork4 = `/paperwork/4`,
  Paperwork5 = `/paperwork/5`,
  PaperworkSummary = `/paperwork/summary`,
  PaperworkSaveInfo = '/paperwork/save-info',
  // Paperwork - Virtual Care
  PaperworkVirtual1 = `/paperwork-virtual/1`,
  PaperworkVirtual2 = `/paperwork-virtual/2`,
  PaperworkVirtual3 = `/paperwork-virtual/3`,
  PaperworkVirtual4 = `/paperwork-virtual/4`,
  PaperworkVirtual5 = `/paperwork-virtual/5`,
  PaperworkVirtual6 = `/paperwork-virtual/6`,
  PaperworkVirtual7 = `/paperwork-virtual/7`,
  //Virtual
  VirtualCareLocation = '/virtual-care/location',
  VirtualCareTime = '/virtual-care/time',
  VirtualCareInfo = '/virtual-care/info',
  VirtualCareInsurance = '/virtual-care/insurance',
  VirtualCareSelfPay = '/virtual-care/self-pay',
  VirtualCareSignForms = '/virtual-care/sign',
  VirtualCareFinalize = '/virtual-care/finalize',
  //Pay
  PaymentMakeAPayment = `payment/make-a-payment`,
  PaymentAdvantageProgram = `payment/advantage-program`,
  PaymentDetails = `payment/details`,
  PaymentReceipt = `payment/receipt`,
  //Account
  Account = `/account`,
  PersonalInformation = '/account/info',
  Insurance = '/account/insurance',
  Dependents = '/account/dependents',
  FavoriteClinics = '/account/favorite-clinics',
  Legal = '/account/legal',
  Contact = '/account/contact',
  AccountCreated = '/account/created',
}
