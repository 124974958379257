import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { FirebaseAnalytics, Foundation } from 'src/_Shared/global'
import accountStore from 'src/_Shared/stores/account'

import HttpResult from './HttpResult'
import { generateSessionId } from '../toDelete/helpers/sessionIdHelper'

//import { configureAppCheck } from '../firebaseConfig'
import { decryptLocalStorage } from '../toDelete/helpers/Cryptography/LocalStorageDecryptionHelper'
import URLs from '../toDelete/URLs.json'
// import { getAuthUserRefreshToken } from '../toDelete/services/AppServices'
// import HttpStatus from 'src/_API/HttpStatus'
import { UnAuthorizedErrorMessageEnum } from '../toDelete/models/enums/unAuthorizedErrorMessage'

// This class acts as a wrapper for axios http client
class HttpClient<R, B> {
  client: AxiosInstance
  //client2: AxiosInstance
  //client3: AxiosInstance
  //client4: AxiosInstance

  constructor(config?: any) {
    const sessionId = sessionStorage.getItem('sessionId')
    const { account, idToken } = accountStore.getState()

    let header = {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      platform: 'web',
      version: '2.0',
      loggedinuserid: account?.auth0Id ? account.auth0Id : 'guest',
      sessionid: sessionId !== null ? sessionId : generateSessionId(),
    }

    const appCheckRestrictors = ['config']
    if (!appCheckRestrictors.includes(config)) {
      if (localStorage.getItem('ENABLE_APP_CHECK') === 'true') {
        const appCheckToken = localStorage.getItem('appCheckToken')
        //const appCheckToken = decryptLocalStorage('AppcheckToken')
        header = {
          ...header,
          ...{
            appchecktoken: appCheckToken,
          },
        }
      } else {
        header = {
          ...header,
          ...{
            //apikey: Foundation.middleWareApiKey,
            apikey: process.env.REACT_APP_MIDDLEWARE_API_KEY!,
          },
        }
      }

      if (account?.nextGenPersonId) {
        const tokenDetails = decryptLocalStorage('CONTEXT_AUTH_DETAILS')

        if (tokenDetails) {
          const formattedTokenDetails = JSON.parse(tokenDetails)

          if (formattedTokenDetails.id_token) {
            header = {
              ...header,
              ...{
                //idtoken: formattedTokenDetails.id_token,
                idtoken: idToken,
              },
            }
          }
        }
      }
    } else {
      header = {
        ...header,
        ...{
          //apikey: Foundation.middleWareApiKey,
          apikey: process.env.REACT_APP_MIDDLEWARE_API_KEY!,
        },
      }
    }

    this.client = axios.create({
      //baseURL: Foundation.middlewareUrl,
      baseURL: process.env.REACT_APP_MIDDLEWARE_URL!,
      headers: header,
    })

    // this.client2 = axios.create({
    //   baseURL: auth0BaseURL,
    //   headers: {
    //     Accept: '*/*',
    //   },
    // })
    // this.client3 = axios.create({
    //   baseURL: baseURL,
    //   headers: {
    //     Accept: '*/*',
    //   },
    // })
    // this.client4 = axios.create({
    //   baseURL: 'https://nextcarepatientportal-devl.azurewebsites.net/api/',
    //   headers: {
    //     Accept: '*/*',
    //   },
    // })
  }

  get = async (
    URL: string,
    config: AxiosRequestConfig<any> = {},
    iteration = 0,
  ): Promise<HttpResult<R>> => {
    try {
      const response = await this.client.get<R, AxiosResponse<R, B>, B>(
        URL,
        config,
      )

      if (response.status !== 401) {
        return this.handleResponse(response)
      } else if (
        response.status === 401 &&
        localStorage.getItem('DEVICE') === 'webview'
      ) {
        return this.handleResponse(response)
      } else if (response.status === 401 && iteration > 2) {
        window.location.href =
          Foundation.baseUrl + URLs.UNEXPECTED_ERROR_OCCURED + '?value=error'
        return this.handleResponse(response)
      } else {
        const data = response.data as any
        const headerConfig = await this.unAuthorisedResponseHandler(
          data.message,
          config,
        )
        iteration = iteration + 1
        const recursiveResponse = await this.get(URL, headerConfig, iteration)
        return recursiveResponse
      }
    } catch (error: any) {
      if (error.response.status !== 401) {
        return this.handleError(error)
      } else if (
        error.response.status === 401 &&
        localStorage.getItem('DEVICE') === 'webview'
      ) {
        return this.handleError(error)
      } else if (error.response.status === 401 && iteration > 2) {
        window.location.href =
          Foundation.baseUrl + URLs.UNEXPECTED_ERROR_OCCURED + '?value=error'
        return this.handleError(error)
      } else {
        const headerConfig = await this.unAuthorisedResponseHandler(
          error.response.data.message,
          config,
        )

        iteration = iteration + 1
        const recursiveResponse = await this.get(URL, headerConfig, iteration)
        return recursiveResponse
      }
    }
  }

  post = async (
    URL: string,
    body: B,
    config: AxiosRequestConfig<any> = {},
    iteration = 0,
  ): Promise<HttpResult<R>> => {
    try {
      const response = await this.client.post<R, AxiosResponse<R, B>, B>(
        URL,
        body,
        config,
      )

      if (response.status !== 401) {
        return this.handleResponse(response)
      } else if (
        response.status === 401 &&
        localStorage.getItem('DEVICE') === 'webview'
      ) {
        return this.handleResponse(response)
      } else if (response.status === 401 && iteration > 2) {
        window.location.href =
          Foundation.baseUrl + URLs.UNEXPECTED_ERROR_OCCURED + '?value=error'
        return this.handleResponse(response)
      } else {
        const data = response.data as any
        const headerConfig = await this.unAuthorisedResponseHandler(
          data.message,
          config,
        )
        iteration = iteration + 1
        const recursiveResponse = await this.post(
          URL,
          body,
          headerConfig,
          iteration,
        )
        return recursiveResponse
      }
    } catch (error: any) {
      if (error.response.status !== 401) {
        return this.handleError(error)
      } else if (
        error.response.status === 401 &&
        localStorage.getItem('DEVICE') === 'webview'
      ) {
        return this.handleError(error)
      } else if (error.response.status === 401 && iteration > 2) {
        //window.location.href =
        //Foundation.baseUrl + URLs.UNEXPECTED_ERROR_OCCURED + '?value=error'
        return this.handleError(error)
      } else {
        console.log('Trying again...')
        const headerConfig = await this.unAuthorisedResponseHandler(
          error.response.data.message,
          config,
        )

        iteration = iteration + 1
        const recursiveResponse = await this.post(
          URL,
          body,
          headerConfig,
          iteration,
        )
        return recursiveResponse
      }
    }
  }
  // get2 = async (
  //   URL: string,
  //   config?: AxiosRequestConfig<any>,
  // ): Promise<HttpResult<R>> => {
  //   try {
  //     const response = await this.client2.get<R, AxiosResponse<R, B>, B>(
  //       URL,
  //       config,
  //     )
  //     return this.handleResponse(response)
  //   } catch (error) {
  //     return this.handleError(error)
  //   }
  // }
  // get3 = async (
  //   URL: string,
  //   config?: AxiosRequestConfig<any>,
  // ): Promise<HttpResult<R>> => {
  //   try {
  //     const response = await this.client3.get<R, AxiosResponse<R, B>, B>(
  //       URL,
  //       config,
  //     )
  //     return this.handleResponse(response)
  //   } catch (error) {
  //     return this.handleError(error)
  //   }
  // }
  // get4 = async (
  //   URL: string,
  //   config?: AxiosRequestConfig<any>,
  // ): Promise<HttpResult<R>> => {
  //   try {
  //     const response = await this.client4.get<R, AxiosResponse<R, B>, B>(
  //       URL,
  //       config,
  //     )
  //     return this.handleResponse(response)
  //   } catch (error) {
  //     return this.handleError(error)
  //   }
  // }
  // post2 = async (
  //   URL: string,
  //   body: B,
  //   config?: AxiosRequestConfig<any>,
  // ): Promise<HttpResult<R>> => {
  //   try {
  //     const response = await this.client2.post<R, AxiosResponse<R, B>, B>(
  //       URL,
  //       body,
  //       config,
  //     )

  //     return this.handleResponse(response)
  //   } catch (error) {
  //     return this.handleError(error)
  //   }
  // }

  private unAuthorisedResponseHandler = async (
    message: string,
    headerConfig: any,
  ) => {
    console.log(`unAuthorisedResponseHandler: ${message}`)

    switch (message) {
      case UnAuthorizedErrorMessageEnum.appcheckToken:
        await FirebaseAnalytics.configureAppCheck()
        if (localStorage.getItem('appCheckToken')) {
          const appCheckToken = localStorage.getItem('appCheckToken')!

          let newHeaderConfig = {
            ...headerConfig,
            headers: {
              ...headerConfig.headers,
              appchecktoken: appCheckToken,
            },
          }

          return newHeaderConfig
        } else {
          window.location.href =
            Foundation.baseUrl + URLs.UNEXPECTED_ERROR_OCCURED + '?value=error'
          return null
        }
      case UnAuthorizedErrorMessageEnum.idToken:
        // const tokenDetails = JSON.parse(
        //   decryptLocalStorage('CONTEXT_AUTH_DETAILS')!,
        // )
        //console.log(':A:')

        // const tokenResponse = await getAuthUserRefreshToken(
        //   tokenDetails.refresh_token,
        // )

        // if (tokenResponse.status !== HttpStatus.Success) {
        //   console.log('what about here?')
        //   window.location.href =
        //     Foundation.baseUrl +
        //     URLs.UNEXPECTED_ERROR_OCCURED +
        //     '?value=expired'
        //   return null
        // } else {

        // let newHeaderConfig = {
        //   ...headerConfig,
        //   headers: {
        //     ...headerConfig.headers,
        //     //idtoken: tokenResponse.data?.id_token,
        //     idtoken: localStorage.getItem('idToken'),
        //   },
        // }

        //return newHeaderConfig
        const ready = {
          ...headerConfig,
          headers: {
            ...headerConfig.headers,
            appchecktoken: localStorage.getItem('appCheckToken'),
            idtoken: localStorage.getItem('idToken'),
          },
        }
        console.log(ready)
        //:::
        return ready
      //}

      default:
        window.location.href =
          Foundation.baseUrl + URLs.UNEXPECTED_ERROR_OCCURED + '?value=error'
        return headerConfig
    }
  }

  private handleResponse = (response: any): HttpResult<R> => {
    if (response.status >= 200 && response.status <= 299) {
      return HttpResult.Success<R>(response.data)
    }
    if (response.status === 409) {
      return HttpResult.Conflict<R>('Conflict', response.data)
    }
    if (response.status === 401) {
      return HttpResult.UnAuthorised<R>('UnAuthorised Request', response.data)
    }
    if (response.status === 403) {
      return HttpResult.Forbidden<R>('Forbidden')
    }
    if (response.status === 404) {
      return HttpResult.NotFound<R>('Not Found')
    }

    return HttpResult.Failed<R>('Some error got occured')
  }

  public handleError = (error: any): HttpResult<R> => {
    if (error.response.status === 409) {
      return HttpResult.Conflict<R>('Conflict', error)
    }
    if (error.response.status === 404) {
      return HttpResult.NotFound<R>('Not Found')
    }
    if (error.response.status === 401) {
      return HttpResult.UnAuthorised<R>(
        'UnAuthorised Request',
        error.response.data,
      )
    }
    if (error.response.status === 403) {
      return HttpResult.Forbidden<R>('Forbidden')
    }
    if (error instanceof Error)
      return HttpResult.Failed<R>(String(error.message))
    return HttpResult.Failed<R>(String(error))
  }
}

export default HttpClient
