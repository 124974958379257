//:::::::::::::::::::::::::
import css from '../modal.module.css'
import {
  Icon,
  ModelModal,
  Button,
  useNavigate,
  Path,
  useCallback,
  ModelClinic,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function ModalAppointmentTaken() {
  const navigate = useNavigate()

  //==============================
  //#region Continue
  //==============================
  const clickGoToTimes = useCallback(() => {
    ModelModal.hide()
    navigate(
      `${Path.BookTime}?reselect=true&clinic=${ModelClinic.selected?.id}`,
    )
  }, [navigate])

  return (
    <div className={css.modalBookingFound}>
      <Icon name='modalAppointmentTaken' width={92} />
      <h5>Time Slot Unvailable</h5>
      <p>
        Unfortunately, your selected time is no longer available. Please choose
        a new time to complete your booking.
      </p>

      <Button
        text='Choose a New Time'
        clicked={clickGoToTimes}
        primary={true}
      />
    </div>
  )
}
