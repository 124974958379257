//:::::::::::::::::::::::::
import {
  useState,
  Nav,
  Path,
  Button,
  useNavigate,
  ModelModal,
} from 'src/_Shared/global'
import css from './CreateNextGenAccount.module.css'
import { DocumentList, DocumentListItem } from 'src/_Shared/global'
import { observer } from 'mobx-react-lite'
import { useAccountStore } from 'src/_Shared/stores/account'
//:::::::::::::::::::::::::

function ReviewAndAccept() {
  const [account, createUser, linkAccounts, lookupUser, resetAccount] =
    useAccountStore((state) => [
      state.account,
      state.createUser,
      state.linkAccounts,
      state.lookupUser,
      state.resetAccount,
    ])
  const [termsOfUse, setTermsOfUse] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  const navigate = useNavigate()
  const handleDecline = async () => {
    resetAccount()
    navigate(Path.LogOut)
  }

  const handleAccept = async () => {
    if (account) {
      ModelModal.showLoader('Please Wait', 'Creating account...')
      await createUser()
      await lookupUser()
      await linkAccounts()
      navigate(Path.Home)
      ModelModal.hide()
    } else {
      navigate(Path.Home)
    }
  }

  return (
    <>
      <Nav back={Path.CreateAccountPatientInfo} title={'Review and Accept'} />
      <div className={css.accountCreateReview}>
        <div className={css.accountCreateReviewMessage}>
          <h3>Review and accept terms</h3>
          <p>
            Please review and accept the attached documents to continue creating
            your account.
          </p>
        </div>

        <div className={css.formContainer}>
          <DocumentList>
            <DocumentListItem
              indent
              accepted={termsOfUse}
              toggle={() => setTermsOfUse(!termsOfUse)}
            >
              I have read, understand, and agree to NextCare’s{' '}
              <a
                href='https://nextcare.com/app-terms-of-use/'
                title='NextCare Terms of Use'
                target='_blank'
                rel='noreferrer'
              >
                Terms of Use
              </a>
            </DocumentListItem>
            <DocumentListItem
              indent
              accepted={privacyPolicy}
              toggle={() => setPrivacyPolicy(!privacyPolicy)}
            >
              I have read, understand, and agree to NextCare’s{' '}
              <a
                href='https://nextcare.com/app-privacy-policy/'
                title='NextCare Privacy Policy'
                target='_blank'
                rel='noreferrer'
              >
                Privacy Policy
              </a>
            </DocumentListItem>
          </DocumentList>

          <div className={css.buttonRow}>
            <Button text='Decline' clicked={handleDecline} secondary />
            <Button
              disabled={!termsOfUse || !privacyPolicy}
              text='Accept'
              clicked={handleAccept}
              primary
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(ReviewAndAccept)
