//:::::::::::::::::::::::::
import { SummaryItem } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function SummaryPhotoID() {
  return (
    <>
      <SummaryItem
        name='Front of Card'
        photo={localStorage.getItem('idCardFront') || ''}
      />
      <SummaryItem
        name='Back of Card'
        photo={localStorage.getItem('idCardBack') || ''}
      />
    </>
  )
}
