//:::::::::::::::::::::::::
import css from './nav.module.css'
//:::::::::::::::::::::::::

export default function Progress(props: {
  visible?: boolean
  percentage: number
}) {
  return (
    <div className={css.progress}>
      <span style={{ width: `${props.percentage * 100}%` }}>
        {props.percentage}
      </span>
    </div>
  )
}
