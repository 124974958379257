//:::::::::::::::::::::::::
import {
  makeObservable,
  PaperworkBO,
  HttpClient,
  Helper,
  UpdateInsuranceBO,
  CardIdentificationBO,
} from 'src/_Shared/global'

//:::::::::::::::::::::::::

class ModelInsurance {
  //==============================
  //#region Class Setup
  //==============================
  private static _instance: ModelInsurance
  //Properties
  paperwork: PaperworkBO | undefined

  constructor() {
    //Set props to true to make reactive
    makeObservable(this, {
      //Methods
      //Enable properties
    })
  }

  //Initialize our singleton
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  //==============================
  //#region Delete Insurance
  //==============================
  async deleteInsuranceCard(cardId: string, idToken: string) {
    try {
      const httpClient = new HttpClient<CardIdentificationBO, any>()
      const response = await httpClient.post('v2/nextgen/insurance/delete', {
        headers: {
          idtoken: idToken,
          cardid: cardId,
        },
      })

      //:::
      console.log(response)

      //Done
    } catch (error) {
      //--- ! ---
      Helper.handleError('Upload Insurance Card', error)
    }
  }

  //==============================
  //#region ,
  // Upload Insurance
  //==============================
  async uploadInsuranceCard(
    front: string,
    back: string,
    cardName: string,
    idToken: string,
  ) {
    try {
      const ready = await Helper.encryptCardImages(front, back)
      const bookingId = localStorage.getItem('bookingId')

      if (!bookingId) {
        return
      }
      const insuranceToUpload: UpdateInsuranceBO = {
        publicKey: ready.publicKey,
        cardNickName: cardName,
        insuranceFrontImage: ready.cardFront,
        insuranceBackImage: ready.cardBack,
        encryptedSymmetricKey: ready.symmetricKey,
        encryptedIv: ready.initializationVector,
      }

      const httpClient = new HttpClient<CardIdentificationBO, any>()
      const response = await httpClient.post(
        'v2/nextgen/insurance/upload',
        insuranceToUpload,
        {
          headers: {
            idtoken: idToken,
            bookingid: bookingId,
          },
        },
      )

      //:::
      console.log(response)

      if (response.status === 0) {
        localStorage.setItem(
          'insuranceCardId',
          String(response.data?.inserted_CardID),
        )
      }

      //Done
    } catch (error) {
      //--- ! ---
      Helper.handleError('Upload Insurance Card', error)
    }
  }
}

export default ModelInsurance.Instance
