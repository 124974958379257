//:::::::::::::::::::::::::
import useAccountStore from 'src/_Shared/stores/account'
import css from '../paperwork.module.css'
import PaperworkWrap from '../PaperworkWrap'
import {
  Path,
  Nav,
  Button,
  useCallback,
  useNavigate,
  FieldText,
  observer,
  ModelPaperwork,
  useState,
  ModelModal,
  useEffect,
  ModelClinic,
  useSearchParams,
  Types,
  Select,
} from 'src/_Shared/global'
import useInsuranceStore from 'src/_Shared/stores/insurance'
import moment from 'moment'
//:::::::::::::::::::::::::

function PaperworkAdditionalInfo2() {
  const navigate = useNavigate()

  const { responses } = ModelPaperwork.paperwork
  const [params] = useSearchParams()

  const [idToken] = useAccountStore((state) => [state.idToken])
  const [
    createInsurance,
    frontImage,
    backImage,
    cardName,
    uploadDocuments,
    insurances,
  ] = useInsuranceStore((state) => [
    state.createInsurance,
    state.frontImage,
    state.backImage,
    state.cardName,
    state.uploadDocuments,
    state.insurances,
  ])

  const setResponseValue = (key: string, value: string) => {
    ModelPaperwork.setPaperwork({
      ...ModelPaperwork.paperwork,
      responses: {
        ...responses,
        [key]: value,
      },
    })
  }

  //==============================
  //#region Send to Native Apps
  //==============================
  function sendDataToNativeApps(data: string) {
    //::::::::::::::::::
    //Send to iOS
    //::::::::::::::::::
    //@ts-ignore
    window?.webkit?.messageHandlers?.AccountBridge?.postMessage({
      message: data,
    })

    //::::::::::::::::::
    //Send to Android
    //::::::::::::::::::
    //@ts-ignore
    if (typeof AccountBridge !== 'undefined') {
      //@ts-ignore
      AccountBridge?.setAccount(data)
    }
  }

  //==============================
  //#region Load Forms
  //==============================
  useEffect(() => {
    async function start(clinicId: string) {
      console.log('[Paperwork][SummaryForms][start()] clinicId:', clinicId)
      const docs = await ModelClinic.getReviewAndSignFormsForClinic(clinicId)
      console.log('[Paperwork][SummaryForms][start()] docs:', docs)

      //:::
      if (docs) {
        ModelPaperwork.setDocuments(docs)
      }

      ModelPaperwork.resetAcceptanceFormFields()
    }
    let clinicId
    //Grab clinic ID and set it
    const bookingSummaryRaw = localStorage.getItem('bookingSummary')
    let bookingSummary
    if (bookingSummaryRaw) {
      bookingSummary = JSON.parse(bookingSummaryRaw) as Types.BookingSummary
    }
    const clinic = localStorage.getItem('clinic')
    const selectedVirtual = ModelClinic.getSelectedVirtual()
    console.log('[Paperwork][Virtual] clinic:', clinic)
    if (selectedVirtual) {
      clinicId = selectedVirtual.practiceDetails?.id
      if (clinicId) localStorage.setItem('clinicId', clinicId)
      console.log('[Paperwork][Virtual] selectedVirtual:', selectedVirtual)
      console.log('[Paperwork][Virtual] clinicId:', clinicId)
    }
    console.log('[Paperwork][Virtual] clinicId:', clinicId)
    if (!clinicId && bookingSummary?.clinicId) {
      clinicId = bookingSummary.clinicId
    }
    if (clinicId) {
      localStorage.setItem('clinicId', clinicId)
    }
    //Get clinic from paperwork query param
    if (clinicId) start(clinicId)
  }, [params])

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(async () => {
    ModelModal.showLoader('Please Wait', 'Submitting paperwork...')
    try {
      console.log('🔄 Submitting paperwork...')
      await ModelPaperwork.submit()

      //+++++
      if (localStorage.getItem('paperwork')) {
        console.log('🔄 Sending data to native app...')
        sendDataToNativeApps(localStorage.getItem('paperwork')!)
      }

      await uploadDocuments()
      const insuranceAlreadyExists = insurances?.find(
        (ins) => ins.backImage === backImage && ins.frontImage === frontImage,
      )

      if (frontImage && backImage && idToken && !insuranceAlreadyExists) {
        await createInsurance(
          frontImage,
          backImage,
          `${cardName} - ${moment().format('MM/DD/YYYY - h:mm A')}`,
          idToken,
        )
      }
      ModelModal.hide()
    } catch (error) {
      console.error('🚫 Error submitting paperwork:', error)
      ModelModal.hide()
    }

    console.log('✅ Paperwork submitted and data sent.')
    navigate(Path.BookFinish)
  }, [navigate])

  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav
        back={Path.PaperworkVirtual4}
        title='Additional Paperwork'
        progress={6 / 7}
      />
      <div className={css.paperwork}>
        <div className={css.paperworkInner}>
          <h2>Additional Information - 2</h2>
          <h5 className={css.label}>Marital Status</h5>
          <Select
            items={['Single', 'Married', 'Divorced', 'Widowed', 'Separated']}
            value={responses['maritalStatus'] ?? ''}
            change={(value: string) => {
              setResponseValue('maritalStatus', value)
            }}
          />
          <h5 className={css.label}>
            Social Security Number{' '}
            <span className={css.optional}>(Optional)</span>
          </h5>
          <FieldText
            value={responses.ssn}
            label=''
            change={(value) => {
              setResponseValue('ssn', value)
            }}
          />
          <h5 className={css.label}>
            The following individuals are authorized to receive information
            regarding my medical treatment (please include name and relationship
            to patient or N/A)
          </h5>
          <FieldText
            value={
              responses.ThefollowingindividualsareauthorizedtoreceiveinformationregardingmymedicaltreatmentPleaseincludeNameandRelationshiptoPatientorNAExampleJaneDoeSpouse
            }
            label=''
            change={(value) => {
              setResponseValue(
                'ThefollowingindividualsareauthorizedtoreceiveinformationregardingmymedicaltreatmentPleaseincludeNameandRelationshiptoPatientorNAExampleJaneDoeSpouse',
                value,
              )
            }}
          />
        </div>
      </div>

      {/* === Continue === */}
      <div className={css.buttonWrap}>
        <Button primary clicked={clickContinue} text={'Continue'} />
      </div>
    </PaperworkWrap>
  )
}

export default observer(PaperworkAdditionalInfo2)
