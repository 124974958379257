//:::::::::::::::::::::::::
import { Button, Icon, useCallback, useState } from 'src/_Shared/global'
import css from './input.module.css'
import Spinner from 'src/_Shared/components/Spinner/Spinner'
import InputMask from 'react-input-mask'
//:::::::::::::::::::::::::

export default function Input(props: {
  //Lots of options for maximum flexibility with this input component
  type: string
  value: string
  label: string
  name?: string
  optional?: string
  icon?: string
  loading?: boolean
  actionButtonIcon?: string
  disabled?: boolean
  mask?: string | (string | RegExp)[]
  stickyLabel?: boolean
  placeholder?: string

  //Form validation
  error?: string
  valid?: boolean | undefined //Allow undefined initial state so all errors aren't visible on page load
  minLength?: number
  maxLength?: number
  //Fancy font
  signature?: boolean

  //Function props
  actionButtonClicked?: (event: React.MouseEvent<HTMLElement>) => void
  change?: (event: React.ChangeEvent<HTMLInputElement>) => void
  blur?: (event: React.FocusEvent<HTMLInputElement>) => void
  keyUp?: () => void
  enter?: () => void
}) {
  //Enter key and other key presses
  const keyPressed = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      //Enter
      if (event.key === 'Enter') {
        props.enter && props.enter()
      }
      //Any key press
      props.keyUp && props.keyUp()
    },
    [props],
  )

  //Fancy animated label that animates via a CSS class and only hides if there's no value in the field
  const [focused, setFocused] = useState(false)

  return (
    <div className={css.wrap}>
      <label
        className={`${css.label} 
          ${props.stickyLabel ? css.focused : ''} 
          ${focused || props.disabled ? css.focused : ''} 
          ${props.disabled ? css.disabledFocused : ''}`}
      >
        {props.label}
        {props.optional && <em>{props.optional}</em>}
      </label>

      <div
        className={`${css.inputWrap} ${props.valid === false ? css.error : ''}`}
      >
        <div
          className={`
        ${css.input} 
        ${props.disabled ? css.disabled : ''}
        ${props.signature ? css.signature : ''}
        `}
        >
          {/* === Optional Icon === */}
          {props.icon && (
            <span className={css.icon}>
              {' '}
              <Icon name={`${props.icon}`} width={24} />
            </span>
          )}

          {/* === Input Field with Optional Mask === */}
          <InputMask
            name={props.name}
            value={props.value}
            type={props.type}
            mask={props.mask ? `${props?.mask}` : ''} //Must pass it a string no matter what
            placeholder={
              props.stickyLabel ? props.placeholder || '' : props.label
            }
            onChange={props.change}
            onKeyUp={keyPressed}
            disabled={props.disabled}
            minLength={props.minLength}
            maxLength={props.maxLength}
            onFocus={(event) => {
              setFocused(true)
            }}
            onBlur={(event) => {
              //Only remove focus class if the field is empty
              if (!props.value || props.value.length === 0) {
                setFocused(false)
              }
              //:::
              props.blur && props.blur(event)
            }}
          />

          {!props.loading && props.actionButtonIcon ? (
            <span className={css.sideWrap}>
              {/* === Loading Indicator === */}
              <b className={props.loading ? '' : css.hide}>
                <Spinner size={22} />
              </b>

              {/* === Auxiliary Button (like show/hide password) === */}
              <b
                className={
                  !props.loading && props.actionButtonIcon ? '' : css.hide
                }
              >
                <Button
                  clicked={(event) =>
                    props.actionButtonClicked &&
                    props.actionButtonClicked!(event)
                  }
                >
                  <Icon name={props.actionButtonIcon} width={24} />
                </Button>
              </b>
            </span>
          ) : null}
        </div>
        {/* === Error === */}
        <p
          className={`${css.inputError} ${
            props.valid === false ? css.error : ''
          }`}
        >
          {props.error}
        </p>
      </div>
    </div>
  )
}
