import { Navigate, Route, Routes } from 'react-router-dom'
import { Path } from 'src/_Shared/global'

//Booking flow
import BookStart from 'src/BookNow/1-BookStart/BookStart'
import BookTime from 'src/BookNow/2-BookTime/BookTime'
import BookInfo from 'src/BookNow/3-BookInfo/BookInfo'
import BookPatient from 'src/BookNow/4-BookPatient/BookPatient'
import BookFinish from 'src/BookNow/5-BookFinish/BookFinish'

//QR Code Check-in
import CheckInPage from 'src/CheckIn/CheckIn'
import CheckInQRPage from 'src/CheckIn/ClinicQR'

//Account flow
import Login from 'src/Auth/AuthLogin'
import Account from 'src/Account/Account'
import PersonalInformation from 'src/Account/PersonalInformation/PersonalInformation'
import Logout from 'src/Auth/AuthLogout'
import CreateAccount from 'src/Account/AccountCreate'
import CreateAccountPatientInformation from 'src/Account/CreateNextGenAccount/PatientInformation'
import CreateAccountReviewAndAccept from 'src/Account/CreateNextGenAccount/ReviewAndAccept'
import RecoverAccount from 'src/Account/AccountRecover'
import FavoriteClinics from './Account/FavoriteClinics/FavoriteClinics'
import Dependents from 'src/Account/Dependents/Dependents'
import Insurance from 'src/Account/Insurance/InsuranceList'

//Misc
import Dev from 'src/_Shared/Dev'
import Auth from 'src/Auth/Auth'
import Home from 'src/Home/Home'
import Notifications from 'src/Notifications/Notifications'
import PaperworkAccountCreated from 'src/Account/PaperworkAccountCreated'

//Paperwork
import PaperworkPatient from 'src/Paperwork/1-Patient/PaperworkPatient'
import PaperworkGuarantor from 'src/Paperwork/2-Guarantor/PaperworkGuarantor'
import PaperworkEmployer from 'src/Paperwork/3-Employer/PaperworkEmployer'
import PaperworkPayment from 'src/Paperwork/4-Payment/PaperworkPayment'
import PaperworkInfoRelease from 'src/Paperwork/5-InfoRelease/PaperworkInfoRelease'
import PaperworkSummary from 'src/Paperwork/6-Summary/PaperworkSummary'
import PaperworkSaveInfo from 'src/Paperwork/7-SaveInfo/PaperworkSaveInfo'

//Paperwork - Virtual
import PaperworkVirtualEmployer from 'src/PaperworkVirtual/1-Employer/PaperworkEmployer'
import PaperworkVirtualContacts from 'src/PaperworkVirtual/2-Contacts/PaperworkContacts'
import PaperworkVirtualEmergency from 'src/PaperworkVirtual/3-Emergency/PaperworkEmergency'
import PaperworkVirtualGuarantor from 'src/PaperworkVirtual/4-Guarantor/PaperworkGuarantor'
import PaperworkVirtualPayment from 'src/PaperworkVirtual/5-Payment/PaperworkPayment'
import PaperworkVirtualMeasurements from 'src/PaperworkVirtual/6-Measurements/PaperworkMeasurements'
import PaperworkVirtualAdditionalInfo2 from 'src/PaperworkVirtual/7-Additional-Info-2/PaperworkAdditionalInfo2'

//Payments
import PaymentMakeAPayment from './PayBill/1 - MakeAPayment/PaymentMakeAPayment'
import PaymentAdvantageProgram from './PayBill/1 - AdvantageProgram/PaymentAdvantageProgram'
import PaymentDetails from './PayBill/2 - Details/PaymentDetails'
import PaymentReceipt from './PayBill/3 - Receipt/PaymentReciept'

import Components from 'src/_Shared/components/Components'
import AccountLegal from 'src/Account/Legal'
import VirtualLocation from './VirtualCare/1-VirtualLocation/VirtualLocation'
import VirtualTime from './VirtualCare/2-VirtualTime/VirtualTime'
import VirtualInfo from './VirtualCare/3-VirtualInfo/VirtualInfo'
import VirtualInsurance from './VirtualCare/4-VirtualPayment/VirtualInsurance'
import VirtualSignForms from './VirtualCare/5-VirtualSignForms/VirtualSignForms'
import VirtualFinalize from './VirtualCare/6-VirtualFinalize/VirtualFinalize'

import BookTimeChange from './BookNow/2-BookTime/BookTimeChange'
import VirtualSelfPay from './VirtualCare/4-VirtualPayment/VirtualSelfPay'

export default function AppRouter() {
  return (
    <Routes>
      {/*
      //==============================
      // General
      //==============================
      */}
      <Route path='/' element={<Home />} />
      <Route path='notifications' element={<Notifications />} />
      {/* <Route path='onboarding' element={<OnBoardingPage />} /> */}
      {/*
      //==============================
      //#region Auth
      //==============================
      */}
      <Route path='auth' element={<Auth />} />
      <Route path='auth/login' element={<Login />} />
      <Route path='login/*' element={<Login />} />
      <Route path='auth/logout' element={<Logout />} />

      {/*
      //==============================
      //#region Paperwork
      //==============================
      */}
      <Route path='paperwork' element={<PaperworkPatient />} />
      <Route path='paperwork/1' element={<PaperworkPatient />} />
      <Route path='paperwork/2' element={<PaperworkGuarantor />} />
      <Route path='paperwork/3' element={<PaperworkEmployer />} />
      <Route path='paperwork/4' element={<PaperworkPayment />} />
      <Route path='paperwork/5' element={<PaperworkInfoRelease />} />

      {/* Paperwork Virtual */}
      <Route
        path='paperwork-virtual/1'
        element={<PaperworkVirtualEmployer />}
      />
      <Route
        path='paperwork-virtual/2'
        element={<PaperworkVirtualContacts />}
      />
      <Route
        path='paperwork-virtual/3'
        element={<PaperworkVirtualEmergency />}
      />
      <Route
        path='paperwork-virtual/4'
        element={<PaperworkVirtualGuarantor />}
      />
      <Route path='paperwork-virtual/5' element={<PaperworkVirtualPayment />} />
      <Route
        path='paperwork-virtual/6'
        element={<PaperworkVirtualMeasurements />}
      />
      <Route
        path='paperwork-virtual/7'
        element={<PaperworkVirtualAdditionalInfo2 />}
      />

      {/* Paperwork General */}
      <Route path='paperwork/summary' element={<PaperworkSummary />} />
      <Route path='paperwork/save-info' element={<PaperworkSaveInfo />} />

      {/* Post-paperwork account creation */}
      <Route path='account/created' element={<PaperworkAccountCreated />} />
      {/*
      //==============================
      //#region Pay bill
      //==============================
      */}
      <Route
        path={Path.PaymentMakeAPayment}
        element={<PaymentMakeAPayment />}
      />
      <Route
        path={Path.PaymentAdvantageProgram}
        element={<PaymentAdvantageProgram />}
      />
      <Route path={Path.PaymentDetails} element={<PaymentDetails />} />
      <Route path={Path.PaymentReceipt} element={<PaymentReceipt />} />

      {/*
      //==============================
      //#region Virtual Care
      //==============================
      */}
      <Route path={Path.VirtualCareLocation} element={<VirtualLocation />} />
      <Route path={`${Path.VirtualCareTime}/*`} element={<VirtualTime />} />
      <Route path={`${Path.VirtualCareInfo}/*`} element={<VirtualInfo />} />
      <Route
        path={`${Path.VirtualCareInsurance}/*`}
        element={<VirtualInsurance />}
      />
      <Route path={Path.VirtualCareSelfPay} element={<VirtualSelfPay />} />
      <Route
        path={`${Path.VirtualCareSignForms}/*`}
        element={<VirtualSignForms />}
      />
      <Route
        path={`${Path.VirtualCareFinalize}/*`}
        element={<VirtualFinalize />}
      />
      {/*
      {/*
      //==============================
      //#region Booking
      //==============================
      */}
      {/* === New URLs === */}
      <Route path='book/start' element={<BookStart />} />
      <Route path='book/time' element={<BookTime />} />
      <Route path='book/change-time' element={<BookTimeChange />} />
      <Route path='book/info' element={<BookInfo />} />
      <Route path='book/patient' element={<BookPatient />} />
      <Route path='book/finish' element={<BookFinish />} />
      {/*
      //==============================
      // Why is this the site root? WordPress deployment constraint? FIXED (?)
      //==============================
      */}
      <Route path='*' element={<Navigate to={'/'}></Navigate>} />
      {/*
      //==============================
      // QR Code Check-in
      //==============================
      */}
      <Route path='check-in/*' element={<CheckInPage />} />
      <Route path='qr/*' element={<CheckInQRPage />} />
      {/* === Redirect === */}
      <Route path='nxcareapp/check-in/*' element={<CheckInPage />} />
      {/*
      //==============================
      //#region Account Management
      //==============================
      */}
      <Route path={Path.Account} element={<Account />} />
      <Route
        path={Path.PersonalInformation}
        element={<PersonalInformation />}
      />
      <Route path={Path.Dependents} element={<Dependents />} />
      <Route path={Path.Insurance} element={<Insurance />} />
      <Route path={Path.FavoriteClinics} element={<FavoriteClinics />} />
      <Route path={Path.Legal} element={<AccountLegal />} />

      <Route path='account/create' element={<CreateAccount />} />
      <Route
        path={Path.CreateAccountPatientInfo}
        element={<CreateAccountPatientInformation />}
      />
      <Route
        path={Path.CreateAccountReviewAndAccept}
        element={<CreateAccountReviewAndAccept />}
      />
      <Route path='account/recover' element={<RecoverAccount />} />
      {/*Old URL Redirect just in case */}
      <Route path='account/recovery' element={<RecoverAccount />} />
      {/*
      //==============================
      //#region Dev
      //==============================
      */}
      <Route path='dev' element={<Dev />} />
      <Route path='components' element={<Components />} />
    </Routes>
  )
}
