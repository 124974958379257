//:::::::::::::::::::::::::
import { observer } from 'mobx-react-lite'
import css from './bookTime.module.css'
import {
  Nav,
  Path,
  useNavigate,
  ModelBooking,
  ModelClinic,
  Selector,
  Button,
  useEffect,
  useSearchParams,
  DatePicker,
  useCallback,
  Types,
  FirebaseAnalytics,
  moment,
  ModelModal,
  ModelVirtualCare,
} from 'src/_Shared/global'
import BookTimeSlots from './BookTimeSlots'
import useBookingStore from 'src/_Shared/stores/booking'
//:::::::::::::::::::::::::

function BookTimeChange() {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const isVirtualCare = ModelVirtualCare.isVirtualCare(
    params.get('clinic') ?? '',
  )

  const fetchBookings = useBookingStore((state) => state.fetchBookings)
  useEffect(() => {
    fetchBookings()
  }, [fetchBookings])

  useEffect(() => {
    const bookingSummary = ModelBooking.getBookingSummary()
    if (isVirtualCare && bookingSummary) {
      ModelClinic.getPracticeDetailsById(bookingSummary.clinicId).then(
        (practice) => {
          if (!practice) return
          ModelVirtualCare.loadTimes(practice)
        },
      )
    }
  }, [isVirtualCare])

  //==============================
  //#region Get Clinic ID
  //==============================
  useEffect(() => {
    //7gNmLp - sample
    async function loadClinic(id: string) {
      const appointment = localStorage.getItem('appointment')
      if (appointment) {
        ModelBooking.setSelectedTimeSlotPackage(
          ModelBooking.timeSlotPackages.find(
            (p) => p.day === new Date(appointment).getDate(),
          ),
        )
      }
      ModelModal.showLoader()
      const clinic = await ModelClinic.getClinic(id)
      if (!clinic) return

      console.log('isVirtualCare:', isVirtualCare, params.get('clinic'))
      if (isVirtualCare) {
        ModelModal.show()
        ModelBooking.setVirtualCare(true)
        const practiceDetails = await ModelClinic.getPracticeDetailsById(id)
        if (practiceDetails) ModelVirtualCare.loadTimes(practiceDetails)
        ModelModal.hide()
      } else {
        ModelClinic.setSelected(clinic)
        const times = await ModelBooking.getTimeSlotsForClinic(clinic.id)
        ModelBooking.setTimeSlotPackages(times?.timeSlots ?? [])
        localStorage.setItem(
          clinic.id,
          JSON.stringify({
            ...clinic,
            times,
          }),
        )
      }

      ModelModal.hide()
    }

    //Set appointment times from cache using query param
    const clinicId = params.get('clinic')

    if (!isVirtualCare && clinicId) {
      if (localStorage.getItem(clinicId)) {
        const clinic = JSON.parse(
          localStorage.getItem(clinicId)!,
        ) as Types.Clinic

        //Set cached clinic as selected so the view re-renders below
        ModelClinic.setSelected(clinic)

        if (clinic.times) {
          //Time slot packages
          ModelBooking.setTimeSlotPackages(clinic.times?.timeSlots)

          //Set appointment to null until they pick one
          ModelBooking.appointment = undefined
        } else {
          //Pull in clinic time slots
          loadClinic(clinicId)
        }
        //%%%%% Analytics
        FirebaseAnalytics.trigger('Book Now - Select Visit Time', {
          virtual_screen_name: 'Select Visit Time',
          clinic_name: clinic.details.title,
          visit_type: 'UC',
        })
      } else {
        //Load the clinic if we don't have it cached
        loadClinic(clinicId)
      }
    }
  }, [params])

  //==============================
  //#region Click Continue
  //==============================
  const clickConfirm = useCallback(async () => {
    ModelBooking.create().then(async () => {
      await fetchBookings()
      //>>>>>
      navigate(Path.BookFinish)
    })
  }, [navigate])

  const handleSelectTab = (value: string) => {
    switch (value) {
      case 'Today':
        ModelBooking.setSelectedTimeSlotPackage(
          ModelBooking.timeSlotPackages.find((p) => {
            return p.day === new Date().getDate()
          }),
        )
        break
      case 'Tomorrow':
        ModelBooking.setSelectedTimeSlotPackage(
          ModelBooking.timeSlotPackages.find((p) => {
            return p.day === new Date().getDate() + 1
          }),
        )
        break
      default:
        ModelBooking.setSelectedTimeSlotPackage(
          ModelBooking.timeSlotPackages[0],
        )
        break
    }
    ModelBooking.setSelectedTab(value)
  }

  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.bookTime}>
      <Nav title='Reschedule' back={Path.BookFinish} />
      {/* === Booking Times === */}
      <div className={css.bookTimes}>
        <div className={css.bookTimesLabel}>Select Available Slots</div>
        <Selector
          items={['Today', 'Tomorrow', 'More Dates']}
          selected={ModelBooking.selectedTab}
          clicked={(value) => handleSelectTab(value)}
          itemWidth={100}
        />

        {ModelBooking.selectedTab === 'More Dates' ? (
          <>
            {/* === Date Picker === */}
            {ModelBooking.selectedTimeSlotPackage && (
              <DatePicker
                selectedDate={
                  ModelBooking.selectedTimeSlotPackage?.date ?? new Date()
                }
                minDate={new Date(ModelBooking.earliestAppointmentDate)}
                maxDate={new Date(ModelBooking.latestAppointmentDate)}
                change={(date: Date) => {
                  //Get the day they clicked and find the associated time slot package
                  const day = date.getDate()
                  const selected = ModelBooking.timeSlotPackages.find(
                    (item) => item.day === day,
                  )
                  if (selected) {
                    ModelBooking.setSelectedTimeSlotPackage(selected)
                  }
                }}
              />
            )}
          </>
        ) : (
          <>
            {/* === Date Heading === */}
            <h2 className={css.bookTimeDate}>
              {ModelBooking.selectedTimeSlotPackage?.displayDate}
            </h2>
          </>
        )}

        {/* === Time Slots === */}
        <BookTimeSlots
          slots={
            (ModelBooking.selectedTimeSlotPackage &&
              ModelBooking.selectedTimeSlotPackage?.slots) ||
            []
          }
          selected={ModelBooking.appointment}
          clicked={(slot) => {
            ModelBooking.setAppointment(moment(slot).toDate())
          }}
        />
        {/* === Continue === */}
        <Button
          primary
          text='Confirm'
          clicked={clickConfirm}
          disabled={!ModelBooking.appointment}
        />
        <p className={css.emergency}>For emergencies, please call 911</p>
      </div>
    </div>
  )
}

export default observer(BookTimeChange)
