//:::::::::::::::::::::::::
import {
  FirebaseAnalytics,
  useEffect,
  observer,
  ModelClinic,
  ModelMap,
  ModelBooking,
  useState,
  Button,
  ModelAccount,
  Types,
} from 'src/_Shared/global'
import css from './clinicList.module.css'
import ClinicInfo from '../ClinicDetails/ClinicInfo/ClinicInfo'
import ClinicSelectVisitTime from '../ClinicDetails/ClinicSelectVisitTime'
import { InView } from 'react-intersection-observer'
import { useAccountStore } from 'src/_Shared/stores/account'
//:::::::::::::::::::::::::

function ClinicList() {
  //==============================
  //#region Analytics
  //==============================
  useEffect(() => {
    FirebaseAnalytics.trigger('Find a Location – List View')
  }, [])
  const [account] = useAccountStore((state) => [state.account])

  const [isFavorited, setIsFavorited] = useState(
    ModelClinic.selected
      ? ModelAccount.checkIfIsFavorited(ModelClinic.selected, account)
      : false,
  )

  //==============================
  //#region Favoriting
  //==============================
  const handleFavorite = async (clinic: Types.Clinic) => {
    const isFavorited = ModelAccount.checkIfIsFavorited(clinic, account)
    const updateIsFavorite =
      (await ModelAccount.setFavorite(clinic, isFavorited, account)) || false
    setIsFavorited(updateIsFavorite)
  }

  //==============================
  //#region UI
  //==============================
  var clinics: Types.Clinic[] = []

  if (ModelClinic.clinicsVisibleOnMap.length === 0) {
    //Show all clinics if none are visible on the map
    clinics = ModelClinic.clinics
  } else {
    //If clinics are visible on the map, we show the same clinics in the list
    clinics = ModelClinic.clinicsVisibleOnMap
  }

  //:::
  return (
    <div className={css.clinicList}>
      {clinics.map((clinic) => (
        <InView key={`inView-${clinic.id}`}>
          {({ inView, ref }) => {
            //When the clinic comes into view, load the next available time
            if (inView && !ModelMap.showMap) {
              ModelClinic.getNextAvailableTimeForClinic(clinic)
            }
            return (
              <div key={clinic.id} ref={ref}>
                <ClinicInfo
                  clinic={clinic}
                  isFavorited={isFavorited}
                  handleFavorite={handleFavorite}
                >
                  <div className={css.clinicListButtonWrap}>
                    {ModelMap.favoriteSearch ? (
                      <Button
                        text={
                          ModelAccount.checkIfIsFavorited(clinic, account)
                            ? 'Remove from Favorites'
                            : 'Add to Favorites'
                        }
                        clicked={() => handleFavorite(clinic)}
                        primary={true}
                      />
                    ) : (
                      <ClinicSelectVisitTime clinic={clinic} />
                    )}
                  </div>
                </ClinicInfo>
              </div>
            )
          }}
        </InView>
      ))}
    </div>
  )
}

export default observer(ClinicList)
