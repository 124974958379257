//:::::::::::::::::::::::::
import { observer } from 'mobx-react-lite'
import css from './bookTime.module.css'
import {
  Nav,
  Path,
  useNavigate,
  ModelBooking,
  ModelClinic,
  Selector,
  Button,
  useEffect,
  useSearchParams,
  DatePicker,
  useCallback,
  Types,
  FirebaseAnalytics,
  moment,
  ModelModal,
} from 'src/_Shared/global'
import BookTimeClinic from './BookTimeClinic'
import BookTimeSlots from './BookTimeSlots'
//:::::::::::::::::::::::::

function BookTime() {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const favoriteView = params.get('favoriteView')
  const reselect = params.get('reselect')

  //==============================
  //#region Get Clinic ID
  //==============================
  useEffect(() => {
    //7gNmLp - sample
    async function loadClinic(id: string) {
      ModelModal.showLoader('Please Wait', 'Finding Appointment Times')
      const clinic = await ModelClinic.getClinic(id)
      if (!clinic) return

      ModelClinic.setSelected(clinic)
      await ModelClinic.getNextAvailableTimeForClinic(clinic)
      ModelModal.hide()
    }

    //Set appointment times from cache using query param
    const clinicId = params.get('clinic')

    if (clinicId) {
      if (localStorage.getItem(clinicId)) {
        const clinic = JSON.parse(
          localStorage.getItem(clinicId)!,
        ) as Types.Clinic

        //Set cached clinic as selected so the view re-renders below
        ModelClinic.setSelected(clinic)

        if (clinic.times && !reselect) {
          //Time slot packages
          ModelBooking.setTimeSlotPackages(clinic.times?.timeSlots)

          //Set appointment to null until they pick one
          ModelBooking.appointment = undefined
        } else {
          //Pull in clinic time slots
          loadClinic(clinicId)
        }
        //%%%%% Analytics
        FirebaseAnalytics.trigger('Book Now - Select Visit Time', {
          virtual_screen_name: 'Select Visit Time',
          clinic_name: clinic.details.title,
          visit_type: 'UC',
        })
      } else {
        //Load the clinic if we don't have it cached

        loadClinic(clinicId)
      }
    }
  }, [params, reselect])

  //==============================
  //#region Click Continue
  //==============================
  const clickContinue = useCallback(async () => {
    //>>>>>
    navigate(Path.BookInfo)
  }, [navigate])

  //==============================
  //#region Click Finish Booking
  //==============================
  const clickFinishBooking = useCallback(async () => {
    //----------
    //Assemble booking summary for book finish screen
    const patient = JSON.parse(
      localStorage.getItem('patient') || '{}',
    ) as Types.Patient
    const bookingId = await ModelBooking.create()
    if (bookingId) {
      const practiceDetails = ModelClinic.getSelectedVirtual()?.practiceDetails
      const clinic = JSON.parse(localStorage.getItem('clinic')!) as Types.Clinic
      //Save booking summary
      const summary: Types.BookingSummary = {
        firstName: patient.firstName,
        lastName: patient.lastName,
        appointment: localStorage.getItem('appointment')!,
        bookingId: bookingId,
        clinicName:
          ModelBooking.virtualCare && practiceDetails
            ? practiceDetails.name
            : clinic.details.title,
        clinicId:
          ModelBooking.virtualCare && practiceDetails
            ? practiceDetails.id
            : clinic.id,
      }
      //Persist booking summary for next screen
      ModelBooking.setBookingSummary(summary)
      //Save booking ID for changing the booking
      ModelBooking.setBookingId(bookingId)
      ModelModal.hide()
      //>>>>>
      navigate(Path.BookFinish)
    }
  }, [navigate])

  const handleSelectTab = (value: string) => {
    switch (value) {
      case 'Today':
        ModelBooking.setSelectedTimeSlotPackage(
          ModelBooking.timeSlotPackages.find((p) => {
            return p.day === new Date().getDate()
          }),
        )
        break
      case 'Tomorrow':
        ModelBooking.setSelectedTimeSlotPackage(
          ModelBooking.timeSlotPackages.find((p) => {
            return p.day === new Date().getDate() + 1
          }),
        )
        break
      default:
        ModelBooking.setSelectedTimeSlotPackage(
          ModelBooking.timeSlotPackages[0],
        )
        break
    }
    ModelBooking.setSelectedTab(value)
  }

  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.bookTime}>
      <Nav
        title='Select Date and Time'
        back={favoriteView ? Path.FavoriteClinics : Path.BookStart}
      />
      {/* === Clinic Info === */}
      {ModelClinic.selected && <BookTimeClinic clinic={ModelClinic.selected} />}

      {/* === Booking Times === */}
      <div className={css.bookTimes}>
        <div className={css.bookTimesLabel}>Select Available Slots</div>
        <Selector
          items={['Today', 'Tomorrow', 'More Dates']}
          selected={ModelBooking.selectedTab}
          clicked={(value) => handleSelectTab(value)}
          itemWidth={100}
        />

        {ModelBooking.selectedTab === 'More Dates' ? (
          <>
            {/* === Date Picker === */}
            {ModelBooking.selectedTimeSlotPackage && (
              <DatePicker
                selectedDate={
                  ModelBooking.selectedTimeSlotPackage?.date ?? new Date()
                }
                minDate={new Date(ModelBooking.earliestAppointmentDate)}
                maxDate={new Date(ModelBooking.latestAppointmentDate)}
                change={(date: Date) => {
                  //Get the day they clicked and find the associated time slot package
                  const day = date.getDate()
                  const selected = ModelBooking.timeSlotPackages.find(
                    (item) => item.day === day,
                  )
                  if (selected) {
                    ModelBooking.setSelectedTimeSlotPackage(selected)
                  }
                }}
              />
            )}
          </>
        ) : (
          <>
            {/* === Date Heading === */}
            <h2 className={css.bookTimeDate}>
              {ModelBooking.selectedTimeSlotPackage?.displayDate}
            </h2>
          </>
        )}

        {/* === Time Slots === */}
        <BookTimeSlots
          slots={
            (ModelBooking.selectedTimeSlotPackage &&
              ModelBooking.selectedTimeSlotPackage?.slots) ||
            []
          }
          selected={ModelBooking.appointment}
          clicked={(slot) => {
            ModelBooking.setAppointment(moment(slot).toDate())
          }}
        />
        {/* === Continue === */}
        <Button
          primary
          text={reselect ? 'Finish Booking' : 'Continue'}
          clicked={reselect ? clickFinishBooking : clickContinue}
          disabled={!ModelBooking.appointment}
        />
        <p className={css.emergency}>For emergencies, please call 911</p>
      </div>
    </div>
  )
}

export default observer(BookTime)
