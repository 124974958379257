//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import PaperworkWrap from '../PaperworkWrap'
import {
  Path,
  Nav,
  ModelPaperwork,
  Button,
  useCallback,
  useNavigate,
  observer,
} from 'src/_Shared/global'
import PaperworkPaymentInsurance from './PaperworkPaymentInsurance'
//:::::::::::::::::::::::::

function PaperworkPayment() {
  const navigate = useNavigate()

  //==============================
  //#region Continue
  //==============================

  const clickContinue = useCallback(() => {
    //>>>>>
    navigate(Path.PaperworkVirtual6)
  }, [navigate])

  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav
        back={
          ModelPaperwork.patientIsOver18()
            ? Path.PaperworkVirtual3
            : Path.PaperworkVirtual4
        }
        title='Additional Paperwork'
        progress={5 / 7}
      />
      <div className={css.paperwork}>
        <div className={css.paperworkInner}>
          <PaperworkPaymentInsurance
            values={ModelPaperwork.paperwork}
            change={(values) => ModelPaperwork.setPaperwork(values)}
          />
        </div>
      </div>
      {/* === Continue === */}
      <div className={css.buttonWrap}>
        <Button primary clicked={clickContinue} text={'Continue'} />
      </div>
    </PaperworkWrap>
  )
}

export default observer(PaperworkPayment)
