import { observer } from 'mobx-react-lite'
import { ModelPay } from 'src/_Shared/global'
import css from '../modal.module.css'

const ModalAllBills = () => {
  const totalCost = ModelPay.paymentDetails
    .map(({ balance }) => balance)
    .reduce((a, b) => a + b, 0)

  return (
    <>
      <h5>All Bills</h5>
      <div className={css.allBillsContainer}>
        {ModelPay.paymentDetails.map((paymentDetail, index) => {
          return (
            <div key={index} className={css.allBillsItem}>
              <span>{paymentDetail.name}</span>
              <span>{`$${paymentDetail.balance}`}</span>
            </div>
          )
        })}
      </div>
      <div className={css.allBillsFooter}>Total ${totalCost}</div>
    </>
  )
}

export default observer(ModalAllBills)
