import forge from "node-forge";
import { decode64 } from "./decode64";

export const symmetricMessageDecryption = (
    symmetricKeyBase64: string,
    ivBase64: string,
    messageBase64: string
  ) => {
    const decipher = forge.cipher.createDecipher(
      "AES-CBC",
      decode64(symmetricKeyBase64)
    );
    decipher.start({ iv: decode64(ivBase64) });
    decipher.update(forge.util.createBuffer(decode64(messageBase64)));
    decipher.finish();
    return decipher.output.toString();
};