import css from './spinner.module.css'

//To use a different size spinner, just set the `size` property and it will change the width of the SVG file which scales the whole thing
export default function Spinner(props: { size?: number; color?: string }) {
  return (
    <svg
      className={css.spintacular}
      width={`${props.size || '100px'}`}
      //Weird bug... this needs to be here AND in the CSS file

      viewBox='0 0 100 100'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='50'
        cy='50'
        r='45'
        style={{ stroke: `${props.color || '#E5665D'}` }}
      />
    </svg>
  )
}
