//:::::::::::::::::::::::::
import { Icon, Types, observer, ModelBooking } from 'src/_Shared/global'
import css from './clinicInfo.module.css'
import ClinicWalkins from './ClinicWalkIns/ClinicWalkins'
import ClinicNextAvailable from './ClinicNextAvailable/ClinicNextAvailable'
import ClinicDetailsStatus from 'src/BookNow/Shared/ClinicDetailsStatus/ClinicDetailsStatus'
import ClinicPinDistance from 'src/BookNow/Shared/ClinicPinDistance/ClinicPinDistance'
import ClinicPrimaryCare from './ClinicPrimaryCare/ClinicPrimaryCare'
//:::::::::::::::::::::::::

function ClinicInfo(props: {
  clinic: Types.Clinic
  children?: React.ReactNode
  // isFavorited must come from parents to ensure full re-render after change
  isFavorited: boolean
  handleFavorite: (clinic: Types.Clinic) => Promise<void>
}) {
  //==============================
  //#region Favorite Clinic
  //==============================

  return (
    <div className={css.clinicInfo}>
      {/* === Distance in Miles === */}
      <ClinicPinDistance distance={props.clinic.distance} />

      {/* === Clinic Title === */}
      <div className={css.clinicTitle}>
        <div className={css.clinicTitleWrap}>
          <h5>{props.clinic.details.title ?? 'No Title'}</h5>
        </div>

        <ClinicDetailsStatus clinic={props.clinic} useIcons={false} />

        {/* === Loading / Next Available Appointment === */}
        {ModelBooking.primaryCare ? (
          <></>
        ) : (
          <>
            {props.clinic.loading ? (
              <ClinicNextAvailable time='loading...' />
            ) : (
              props.clinic.times?.nextAvailable && (
                <ClinicNextAvailable time={props.clinic.times?.nextAvailable} />
              )
            )}
          </>
        )}

        {ModelBooking.primaryCare ? <ClinicPrimaryCare /> : <ClinicWalkins />}

        {/* === Optional Button === */}
        {props.children && props.children}

        {/* === Favorite Star Button === */}
        <button
          className={css.favoriteStarButton}
          onClick={() => props.handleFavorite(props.clinic)}
        >
          <Icon name={props.isFavorited ? 'starFull' : 'star'} width={20} />
        </button>
      </div>
    </div>
  )
}

export default observer(ClinicInfo)
