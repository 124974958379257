//:::::::::::::::::::::::::
import moment from 'moment'
import {
  Types,
  makeAutoObservable,
  Helper,
  BookingResponseTypeBO,
  OverAllBookingBO,
  HttpClient,
  PracticeDetailsBO,
  ModelModal,
  ModelClinic,
  ModelBooking,
} from 'src/_Shared/global'

class ModelVirtualCare {
  //==============================
  //#region Class Setup
  //==============================
  private static _instance: ModelVirtualCare
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  constructor() {
    makeAutoObservable(this)
  }

  //==============================
  //#region Properties
  //==============================

  selectedTab: string = 'ON DEMAND' //ON DEMAND, SCHEDULE A VISIT
  reason: string = ''
  patient: Types.Patient | undefined
  patientName: string = ''
  price: number = 65 //Virtual care appointment price
  payInsurance: boolean = true
  employee: boolean = false
  employeeName: string | undefined
  advantageMember: boolean = false
  agreeTerms: boolean = false
  agreeFinalTerms: boolean = false
  documents: Types.DocumentListItem[] = []
  clinics: string[] = []
  signature: string = ''

  //==============================
  //#region Setters
  //==============================
  setPatient(value: Types.Patient) {
    this.patient = value
    localStorage.setItem('virtualPatient', JSON.stringify(value))
  }
  setPatientName(value: string) {
    this.patientName = value
  }
  setReason(value: string) {
    this.reason = value
    localStorage.setItem('virtualReason', value)
  }
  setEmployee(value: boolean) {
    this.employee = value
    //Update price
    this.setPrice()
  }
  setEmployeeName(value: string | undefined) {
    this.employeeName = value
    if (value) {
      localStorage.setItem('virtualEmployeeName', value)
    }
  }
  setAdvantageMember(value: boolean) {
    this.advantageMember = value
    //Update price
    this.setPrice()
  }
  setPayInsurance(value: boolean) {
    this.payInsurance = value
  }
  setPrice() {
    //Employee pricing
    if (this.employee) {
      this.price = 25
      return
    }
    //Advantage member pricing
    if (this.advantageMember) {
      this.price = 39
      return
    }
    //General pricing
    this.price = 65
  }
  setAgreeTerms(value: boolean) {
    this.agreeTerms = value
  }
  setAgreeFinalTerms(value: boolean) {
    this.agreeFinalTerms = value
  }
  setSelectedTab(value: string) {
    this.selectedTab = value
  }
  setDocuments(values: Types.DocumentListItem[]) {
    this.documents = values
  }
  setSignature(value: string) {
    this.signature = value
    localStorage.setItem('virtualSignature', value)
  }
  setClinics(value: string[]) {
    this.clinics = value
  }

  getVirtualClinics() {
    return {
      Arizona: 'p8RYD0',
      Colorado: 'A2m5y0',
      Kansas: 'pD3OZA',
      Michigan: 'goQDNV',
      Missouri: 'AaDmx0',
      Nebraska: '0mdM35',
      NewMexico: 'pYlMaA',
      NorthCarolina: 'pD3aKA',
      Oklahoma: '0EMPw0',
      Texas: '0rQDlg',
      Virginia: 'pjMNXA',
      Wyoming: '0EMOm0',
    }
  }

  //==============================
  //#region Get Times
  //==============================
  async loadTimes(practiceDetails: PracticeDetailsBO) {
    ModelModal.showLoader(undefined, 'Getting clinic times...')
    await ModelClinic.getNextAvailableTimeForPractice(practiceDetails)

    if (ModelClinic.selectedVirtual?.times) {
      //Time slot packages
      ModelBooking.setTimeSlotPackages(
        ModelClinic.selectedVirtual?.times.timeSlots,
      )

      //Set appointment to null until they pick one
      ModelBooking.appointment = undefined

      if (ModelClinic.selectedVirtual?.times.earliestAvailable) {
        // setAppointment if virtualCare and on demand date is available
        ModelBooking.setAppointment(
          moment(ModelClinic.selectedVirtual?.times.earliestAvailable).toDate(),
        )
      }
    }
    ModelModal.hide()
  }

  isVirtualCare(clinicId?: string): boolean {
    if (!clinicId) return false
    const virtualClinics = this.getVirtualClinics()
    return Object.values(virtualClinics).includes(clinicId)
  }

  //==============================
  //#region Create Virtual Booking
  //==============================
  async create() {
    const appointment = localStorage.getItem('appointment')!
    const virtualClinicId = localStorage.getItem('virtualClinicId')!
    const reason = localStorage.getItem('virtualReason')!
    const employee = localStorage.getItem('virtualEmployeeName')

    const booker = JSON.parse(
      localStorage.getItem('virtualBooker')!,
    ) as Types.Patient
    const patient = JSON.parse(
      localStorage.getItem('virtualPatient')!,
    ) as Types.Patient

    const consentIds = JSON.parse(
      localStorage.getItem('virtualConsentIds')!,
    ) as string[]

    const booking: OverAllBookingBO = {
      //::::::::::
      //Booking - patient details go in the booking
      bookingBO: {
        appointment_date: appointment, //Time offset required; don't format
        birth_date: patient.dateOfBirth
          ? Helper.convertDateOfBirthSimpleToAPI(patient.dateOfBirth)
          : '',
        email: patient.email,
        first_name: patient.firstName,
        last_name: patient.lastName,
        location_id: virtualClinicId,
        phone: Helper.convertPhoneToAPI(patient.phone),
        reason: reason,
      },
      //::::::::::
      //Paperwork - booker's details go into guardian
      paperworkBO: {
        responses: {
          patientFirstName: patient.firstName,
          patientLastName: patient.lastName,
          birthDate: patient.dateOfBirth
            ? Helper.convertDateOfBirthSimpleToAPI(patient.dateOfBirth)
            : '',
          patientAddressCity: '',
          patientAddressStreet: '',
          patientBirthSex: patient.sex
            ? Helper.convertBirthSexToAPI(patient.sex)
            : '',
          email: patient.email,
          patientAddressStreetTwo: '',
          primaryPhone: Helper.convertPhoneToAPI(patient.phone),
          patientAddressState: '',
          selfPayCheck: '',
          patientAddressZip: '',
          race: 'Unknown',
          ethnicity: 'Unknown',
          'AreyouandeligibledependentofaNextCareemployeelegalspouseorlegalchildundertheageof26IfYESpleasealsoprovidethenameoftheNextCareemployee_54970840-f5fc-11eb-8664-dd02b14340ab':
            employee ?? '',
          guardianBirthDate: Helper.convertDateOfBirthToAPI(booker.dateOfBirth),
          guardianFirstName: booker.firstName,
          guardianEmail: booker.email,
          guardianPhone: booker.phone,
          guardianLastName: booker.lastName,
          guardianCity: '',
          guardianAddressTwo: '',
          guardianZip: '',
          guardianState: '',
          guardianAddress: '',
        },
        status: 'in_progress',
      },
      consentBO: {
        location_consent_form_ids: consentIds,
        signer_signature: this.signature,
      },
    }

    //Create a booking
    console.log('🔄 Creating booking...')
    try {
      //Lookup booking with phone number
      const httpClient = new HttpClient<BookingResponseTypeBO, any>()
      const response = await httpClient.post(
        'v2/solv/bookings/schedulevisit',
        booking,
        {
          headers: {
            ondemand: this.selectedTab === 'ON DEMAND' ? 1 : 0,
          },
        },
      )

      if (response.data && response.data.Bookingid) {
        const bookingId = response.data.Bookingid
        console.log('✅ Virtual Appointment Booked: ', bookingId)
        this.clear()
        localStorage.setItem('virtualBookingId', bookingId)
        //:::
        return bookingId
      } else {
        console.log(response)
        console.log('Virtual booking error')
        return undefined
      }
    } catch (error) {
      //--- ! ---
      Helper.handleError('Create Virtual Booking', error)
    }
  }

  clear() {
    this.selectedTab = 'ON DEMAND' //ON DEMAND, SCHEDULE A VISIT
    this.reason = ''
    this.patient = undefined
    this.patientName = ''
    this.price = 65 //Virtual care appointment price
    this.payInsurance = true
    this.employee = false
    this.employeeName = undefined
    this.advantageMember = false
    this.agreeTerms = false
    this.agreeFinalTerms = false
    this.documents = []
    this.clinics = []
    this.signature = ''
    //Clear locally persisted virtual care data
    localStorage.removeItem('appointment')
    localStorage.removeItem('virtualClinicId')
    localStorage.removeItem('virtualReason')
    localStorage.removeItem('virtualEmployeeName')
    localStorage.removeItem('virtualBooker')
    localStorage.removeItem('virtualBookingId')
    localStorage.removeItem('virtualPatient')
    localStorage.removeItem('virtualConsentIds')
  }
}

export default ModelVirtualCare.Instance
