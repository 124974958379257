//:::::::::::::::::::::::::
import { Icon } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function CurrentLocationMarker() {
  return (
    <span>
      <Icon name='locationCurrent' width={30} />
    </span>
  )
}
