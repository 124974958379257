import {
  Foundation,
  ModelModal,
  ModelPay,
  Nav,
  Path,
  observer,
  useEffect,
  useNavigate,
} from 'src/_Shared/global'
import css from './paymentDetails.module.css'
import { PaymentDetailsBO } from 'src/BOs/PaymentDetailsBO'
import { PaymentSaleBodyBO } from 'src/BOs/PaymentSaleBodyBO'
import { useAccountStore } from 'src/_Shared/stores/account'

function PaymentDetails() {
  const navigate = useNavigate()
  const account = useAccountStore((state) => state.account)

  useEffect(() => {
    if (!account) return
    ModelModal.showLoader()

    let url = document
      ?.querySelector('#root')
      ?.shadowRoot?.querySelector('#payment_iframe')
    if (url !== null && url !== undefined) {
      url.addEventListener('load', async () => {
        debugger
        ModelModal.hide()

        let encodedUrl = (
          document
            ?.querySelector('#root')
            ?.shadowRoot?.querySelector('#payment_iframe') as HTMLIFrameElement
        )?.contentWindow?.location.href
        if (encodedUrl !== undefined) {
          const nextGenId = ModelPay.nextGenId
          const practiceId = ModelPay.practiceId
          let decodedURI = decodeURI(encodedUrl)
          if (encodedUrl.includes('cardDetails')) {
            let splitedValue = decodedURI.split('cardDetails=')
            let JsonValue = JSON.parse(splitedValue[1])
            let TotalCost = ModelPay.patientDetails.isAdvantageProgram ? 39 : 65
            var paymentDetails: PaymentDetailsBO = {
              CardNumber: JsonValue.token,
              Expiration: JsonValue.expDate,
              Amount: TotalCost,
            }
            //make a payment and navigate to the receipt page in pay bill
            let firstName = ''
            let lastName = ''
            if (nextGenId === account?.nextGenPersonId) {
              firstName = account.firstName ?? ''
              lastName = account.lastName ?? ''
            } else {
              account.dependents?.forEach((dependent) => {
                if (nextGenId === dependent.nextGenPersonId) {
                  firstName = dependent.firstName ?? ''
                  lastName = dependent.lastName ?? ''
                  return
                }
              })
            }
            let paymentSaleBody: PaymentSaleBodyBO = {
              CardNumber: paymentDetails.CardNumber,
              Expiration: paymentDetails.Expiration,
              Amount: ModelPay.amount,
              Memo: ModelPay.memo,
              FirstName: firstName,
              LastName: lastName,
            }
            const response = await ModelPay.makeAPayment(
              paymentSaleBody,
              practiceId,
              account.nextGenPersonId ?? '',
            )
            if (response.data?.TransactionStatus === 'C') {
              navigate(
                Path.PaymentReceipt +
                  '?transactionId=' +
                  response.data?.TransactionID,
              )
            } else {
              ModelModal.showError(response.message)
            }
          } else if (encodedUrl.includes('cardStatus')) {
            let splitedValue = decodedURI.split('cardStatus=')
            if (splitedValue[1].includes('canceled')) {
              return ModelPay.patientDetails.isAdvantageProgram
                ? navigate(Path.PaymentAdvantageProgram)
                : navigate(Path.PaymentMakeAPayment)
            }
          }
        }
      })
    }
    ModelModal.hide()
  }, [navigate])

  return (
    <>
      <Nav
        title='Payment Information'
        back={
          ModelPay.patientDetails.isAdvantageProgram
            ? Path.PaymentAdvantageProgram
            : Path.PaymentMakeAPayment
        }
      />
      <div className={css.payBillDetails}>
        <iframe
          id='payment_iframe'
          title='payment'
          src={`${Foundation.baseUrl}${'wp-content/instamedtrial.html'}`}
        />
      </div>
    </>
  )
}

export default observer(PaymentDetails)
