//:::::::::::::::::::::::::
import css from './bookStart.module.css'
import {
  ModelMap,
  useEffect,
  useSearchParams,
  Nav,
  Path,
  observer,
  ModelBooking,
  ModelClinic,
  ModelModal,
  ModelPaperwork,
  ModelVirtualCare,
} from 'src/_Shared/global'
import ClinicList from 'src/BookNow/1-BookStart/ClinicList/ClinicList'
import ClinicMap from './ClinicMap/ClinicMap'
import MapSearch from './MapSearch/MapSearch'
import MapOptions from './MapOptions/MapOptions'
import ClinicDetails from './ClinicDetails/ClinicDetails'
//:::::::::::::::::::::::::

function BookStart() {
  //==============================
  //#region Query String Search
  //==============================
  const [params] = useSearchParams()
  useEffect(() => {
    async function search(term?: string) {
      if (term) {
        await ModelMap.search(term)
      } else {
        //Get user location if not using search term
        //await ModelMap.getUserLocation()
      }
    }
    //Start
    const term = params.get('search') || undefined
    search(term)
  }, [params])

  //==============================
  //#region Get Clinic Markers
  //==============================
  useEffect(() => {
    async function start() {
      // Precaution to ensure that the virtual care data is cleared
      ModelVirtualCare.clear()
      localStorage.removeItem('virtualBookingId')

      ModelModal.showLoader('Please Wait', 'Loading clinics...')

      //Primary care query parameter
      const primaryCare = params.get('primaryCare') === 'true'
      ModelBooking.setPrimaryCare(primaryCare)

      //Favorite Search query parameter
      const favoriteSearch = params.get('favoriteSearch') === 'true'
      ModelMap.setFavoriteSearch(favoriteSearch)

      //Get clinics on load so they are ready when they search
      await ModelClinic.getAllClinics()

      //Get user location after the clinics are ready so we can calculate the distances correctly
      await ModelMap.getUserLocation()
      ModelModal.hide()
    }

    start()
  }, [params])

  //==============================
  //#region Clear Cache
  //==============================
  useEffect(() => {
    // reset any partially-finished virtual care data that may exist
    ModelBooking.setVirtualCare(false)
    ModelClinic.setSelectedVirtual()

    //Reset clinic
    ModelClinic.setSelected(undefined)
    //Clear booking
    ModelBooking.clear()
    //Clear paperwork
    ModelPaperwork.clear()
  }, [])

  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.start}>
      {/* === Nav with Search === */}
      <Nav back={ModelMap.favoriteSearch ? Path.FavoriteClinics : Path.Home}>
        <MapSearch />
      </Nav>

      <div className={css.wrap}>
        {/* === Map / List Toggle === */}
        <MapOptions />

        {/* === Clinic Map or List === */}
        <div className={ModelMap.showMap ? css.show : css.hide}>
          <ClinicMap />
        </div>
        <div className={ModelMap.showMap ? css.hide : css.show}>
          <ClinicList />
        </div>

        <ClinicDetails />
      </div>
    </div>
  )
}

export default observer(BookStart)
