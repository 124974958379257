//:::::::::::::::::::::::::
import { Icon } from 'src/_Shared/global'
import css from './clinicPrimaryCare.module.css'
//:::::::::::::::::::::::::

export default function ClinicPrimaryCare() {
  return (
    <>
      <div className={css.primaryCare}>
        <span>
          <Icon name='primaryCare' width={14} />
        </span>
        <div>
          <h6>Primary Care Office</h6>
        </div>
      </div>
      <div className={css.primaryCare}>
        <span>
          <Icon name='appointmentOnly' width={13} />
        </span>
        <div>
          <h6>Appointment Only, No Walk-ins</h6>
        </div>
      </div>
    </>
  )
}
