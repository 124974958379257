//:::::::::::::::::::::::::
import { observer } from 'mobx-react-lite'
import css from './bookTime.module.css'
import { Types } from 'src/_Shared/global'
import ClinicDetailsStatus from '../Shared/ClinicDetailsStatus/ClinicDetailsStatus'
//:::::::::::::::::::::::::

function BookTimeClinic(props: { clinic: Types.Clinic }) {
  //Prep the insurance list
  const insurances = props.clinic.details.insurance_list.map(
    (insurance) => insurance.name,
  )
  const insurancesReady = insurances.join(', ')

  return (
    <div className={css.bookClinicInfo}>
      <h1>{props.clinic.details.title}</h1>
      <h3>{props.clinic.details.brands.name}</h3>

      <ClinicDetailsStatus
        clinic={props.clinic}
        useIcons={true}
        insurance={insurancesReady}
      />
    </div>
  )
}

export default observer(BookTimeClinic)
