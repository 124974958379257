import { MouseEventHandler } from 'src/_Shared/global'
import css from './checkbox.module.css'

export default function Checkbox(props: {
  onClick: MouseEventHandler<HTMLInputElement>
  label?: string
}) {
  return (
    <label className={css.container}>
      <input className={css.checkbox} type='checkbox' onClick={props.onClick} />
      <span className={css.checkmark} />
      {props.label}
    </label>
  )
}
