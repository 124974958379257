//:::::::::::::::::::::::::
import css from './downloadBanner.module.css'
import { useEffect, useState } from 'react'
import {
  ModelApp,
  Icon,
  useLocation,
  useSearchParams,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function DownloadBanner(props: { force?: boolean }) {
  const [showBanner, setShowBanner] = useState(false)
  const [url, setUrl] = useState('')
  const [params] = useSearchParams('native')
  const location = useLocation()

  useEffect(() => {
    //Never show during paperwork
    if (location.pathname.includes('paperwork')) {
      setShowBanner(false)
      return
    }

    //Ignore all this if it's an <iframe> page in the native app which we set a query parameter for
    const native = params.get('native') || localStorage.getItem('native')
    if (native === 'yes') {
      setShowBanner(false)
      return
    }
    //Force it to show for testing
    const force = params.get('force')
    if (force === 'yes' || props.force) {
      setShowBanner(true)
      return
    }

    //We set the "Download" button's URL based on the type of device they have (iOS or Android)
    //If it's a desktop device, we hide the banner
    const agent = ModelApp.getUserAgent()

    switch (agent) {
      //:::::
      case 'Android':
        setUrl(
          'https://play.google.com/store/apps/details?id=com.nextcare.health&pcampaignid=web_share',
        )
        setShowBanner(true)
        break
      //:::::
      case 'Apple':
        setUrl('https://apps.apple.com/us/app/nextcare-anywhere/id1629290318')
        setShowBanner(true)
        break
      //:::::
      default:
        //Hide
        setShowBanner(false)
    }
  }, [setShowBanner, params, location.pathname, props.force])
  //++++++++++++++++++++++++++++++

  return showBanner ? (
    <div className={css.downloadBanner}>
      <Icon name='logoApp' width={28} />
      <div>
        <strong>NextCare Anywhere</strong>
        <p>Download the NextCare app</p>
      </div>
      <a href={url} title='download'>
        Download
      </a>
    </div>
  ) : null
}
