import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Start from './Start/Start'
//import reportWebVitals from './toDelete/reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <Start />
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()

//Book Now
//TODO Creating a dependent might be returning a null nextgenid

//Misc
//TODO build onboarding screens
//TODO app doesn't seem to refresh id token properly with Auth0 on HTTP requests (HttpHelper)
