//:::::::::::::::::::::::::
import css from './checklist.module.css'
import { Icon } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function Checklist(props: { items: string[] }) {
  return (
    <ul className={css.checklist}>
      {props.items.map((item: string) => (
        <li key={item}>
          <span>
            <Icon name='done' width={24} />
          </span>
          <p>{item}</p>
        </li>
      ))}
    </ul>
  )
}
