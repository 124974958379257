//:::::::::::::::::::::::::
import {
  observer,
  ModelMap,
  useCallback,
  useEffect,
  FirebaseAnalytics,
  ModelClinic,
  Types,
  Icon,
  Button,
  ModelModal,
} from 'src/_Shared/global'
import css from './clinicMap.module.css'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import NextAvailableMarker from './NextAvailableMarker/NextAvailableMarker'
import LoadingMarker from './LoadingMarker/LoadingMarker'
import MarkerCentered from './MarkerCentered'
import Marker from './Marker/Marker'
import CurrentLocationMarker from './CurrentLocationMarker'
//:::::::::::::::::::::::::

function ClinicMap() {
  //==============================
  //#region Analytics
  //==============================
  useEffect(() => {
    FirebaseAnalytics.trigger('Find a Location – Map View')
  }, [])

  //==============================
  //#region Initialize Map
  //==============================
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDBwsgaZD-4Euhh41HyGb3uBxk492AicnU',
  })

  const onMapLoad = useCallback((map: google.maps.Map) => {
    ModelMap.setMap(map)
  }, [])

  //==============================
  //#region Walkins Modal
  //==============================
  const clickWalkIns = useCallback(() => {
    ModelModal.showInfo(
      'Walk-ins accepted anytime.',
      'Don’t see a time that works for you? Walk-ins are always accepted at all clinics. Waiting time will vary depending on how many patients are in line. View clinic information page to see more information.',
    )
  }, [])

  //==============================
  //#region Build Markers
  //==============================
  useEffect(() => {
    ModelMap.updateMapMarkers()
  }, [])

  //==============================
  //#region Select Clinic
  //==============================
  const clickMarker = useCallback((clinic: Types.Clinic) => {
    //Select clinic and load data in our models
    ModelClinic.setSelected(clinic)
    //%%%%% Analytics
    FirebaseAnalytics.trigger('Book Now - Location - Map Details', {
      virtual_screen_name: 'Map Details',
      clinic_name: clinic.details.title,
    })
  }, [])

  return (
    <div className={css.map}>
      {isLoaded ? (
        <div className={css.mapWrap}>
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              //61px is the nav bar height
              height: 'calc(100dvh - 61px)',
              marginTop: 0,
              border: 'none',
            }}
            center={ModelMap.mapCenter}
            zoom={ModelMap.mapZoom}
            options={{ disableDefaultUI: true, zoomControl: true }}
            onLoad={onMapLoad}
            onZoomChanged={() => ModelMap.updateMapMarkers()}
            onDragEnd={() => ModelMap.updateMapMarkers()}
          >
            {/* === Current User's Location Pin === */}
            {ModelMap.userLatitude && ModelMap.userLongitude && (
              <MarkerCentered
                key='currentLocation'
                latitude={ModelMap.userLatitude}
                longitude={ModelMap.userLongitude}
                offset={0}
              >
                <CurrentLocationMarker />
              </MarkerCentered>
            )}

            {/* === Clinic Map Pins === */}
            {ModelClinic.clinics.map((clinic: Types.Clinic, index) => {
              if (
                clinic.loading &&
                ModelMap.mapZoom >= ModelMap.mapZoomThreshold
              ) {
                /* === Loading Pin === */
                return (
                  <MarkerCentered
                    key={clinic.id}
                    latitude={clinic.latitude}
                    longitude={clinic.longitude}
                    offset={44}
                  >
                    <LoadingMarker />
                  </MarkerCentered>
                )
              } else if (
                clinic.times?.nextAvailable &&
                ModelMap.mapZoom >= ModelMap.mapZoomThreshold
              ) {
                /* === Next Available Pin === */
                return (
                  <MarkerCentered
                    key={clinic.id}
                    latitude={clinic.latitude}
                    longitude={clinic.longitude}
                  >
                    <NextAvailableMarker
                      clinic={clinic}
                      clicked={clickMarker}
                    />
                  </MarkerCentered>
                )
              } else {
                /* === Regular Pins === */
                return (
                  <MarkerCentered
                    key={clinic.id}
                    latitude={clinic.latitude}
                    longitude={clinic.longitude}
                  >
                    <Marker clinic={clinic} clicked={clickMarker} />
                  </MarkerCentered>
                )
              }
            })}
          </GoogleMap>
          {/* === Walk-ins anytime banner === */}
          <div className={css.mapWalkIns}>
            <Button clicked={() => clickWalkIns()}>
              <p>
                <b>Walk-ins accepted anytime.</b>
                <span>
                  <Icon name='info' width={18} />
                </span>
              </p>
            </Button>
          </div>
        </div>
      ) : (
        <cite>Loading map...</cite>
      )}
    </div>
  )
}

export default observer(ClinicMap)
