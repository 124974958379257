//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import {
  FieldText,
  Types,
  FieldPhone,
  FieldRelationship,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function PaperworkPatientEmergency(props: {
  values: Types.Paperwork
  change: (values: Types.Paperwork) => void
}) {
  return (
    <div className={css.paperworkPatientEmergency}>
      {/* === Patient First Name === */}
      <FieldText
        value={props.values.responses.emergencyContactFirstName}
        label='First Name'
        change={(value) => {
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              emergencyContactFirstName: value,
            },
          })
        }}
      />

      {/* === Patient Last Name === */}
      <FieldText
        value={props.values.responses.emergencyContactLastName}
        label='Last Name'
        change={(value) => {
          props.change({
            ...props.values,
            responses: {
              ...props.values.responses,
              emergencyContactLastName: value,
            },
          })
        }}
      />

      {/* === Mobile Number === */}
      <div className={css.fieldWrap}>
        <FieldPhone
          value={props.values.responses.emergencyContactPhone}
          change={(value) =>
            props.change({
              ...props.values,
              responses: {
                ...props.values.responses,
                emergencyContactPhone: value,
              },
            })
          }
        />
      </div>

      {/* === Relationship to Patient === */}
      <div className={css.fieldWrap}>
        <FieldRelationship
          value={props.values.responses.emergencyContactRelationshipToPatient}
          change={(value: string) => {
            props.change({
              ...props.values,
              responses: {
                ...props.values.responses,
                emergencyContactRelationshipToPatient: value,
              },
            })
          }}
        />
      </div>
    </div>
  )
}
