//:::::::::::::::::::::::::
import { useAuth0 } from '@auth0/auth0-react'
import css from '../paperwork.module.css'
import {
  Nav,
  Button,
  useNavigate,
  useCallback,
  Path,
  Foundation,
  ModelPaperwork,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function PaperworkSaveInfo() {
  const navigate = useNavigate()

  //==============================
  //#region Skip
  //==============================
  const clickSkip = useCallback(() => {
    //>>>>>
    navigate(Path.BookFinish)
  }, [navigate])
  const { loginWithRedirect } = useAuth0()

  //==============================
  //#region UI
  //==============================
  return (
    <>
      {/* === Nav === */}
      <Nav title='Save Your Information' />
      <div className={css.saveInfo}>
        <div className={css.saveInfoHeading}>
          <h1>Save Your Information for Faster Check-ins</h1>
          <p>
            Enjoy pre-filled paperwork on future visits, along with access to
            your health record summary, lab results, and the ability to pay your
            bills online.
          </p>
        </div>

        <div className={css.saveInfoButtons}>
          <Button
            primary
            text='Create Account'
            clicked={() =>
              loginWithRedirect({
                authorizationParams: {
                  scope:
                    'openid profile email read:current_user update:current_user_metadata offline_access',
                  screen_hint: 'signup',
                  login_hint: ModelPaperwork.paperwork.account.email,
                  redirect_uri:
                    Foundation.auth0Redirect +
                    '?destination=paperworkAccountCreated',
                },
              })
            }
          />
          <Button secondary={true} text='Skip' clicked={clickSkip} />
          {/* <Button secondary text='Get All Profiles' clicked={getAllProfiles} />
          <Button
            secondary
            text='Get Current Profile'
            clicked={getCurrentProfile}
          /> */}
        </div>
      </div>
    </>
  )
}
