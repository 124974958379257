//:::::::::::::::::::::::::
import css from './nextAvailableMarker.module.css'
import { Types, ModelClinic, Button } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function NextAvailableMarker(props: {
  clinic: Types.Clinic
  clicked: (clinic: Types.Clinic) => void
}) {
  return (
    <div
      className={`${css.nextAvailableMarker} ${
        props.clinic.id === ModelClinic.selected?.id ? css.selected : ''
      }`}
    >
      <Button clicked={() => props.clicked(props.clinic)}>
        <p>
          <small>Next Available</small>
          <cite>{props.clinic.times?.nextAvailable ?? 'Not Available'}</cite>
        </p>
      </Button>
    </div>
  )
}
