//:::::::::::::::::::::::::
import css from './paymentMakePayment.module.css'
import HeroDog from 'src/_Shared/images/HeroDog.svg'
import {
  Select,
  ModelPay,
  useEffect,
  Button,
  useState,
  PaymentDetailBO,
  PrimaryNav,
  Input,
  Types,
  observer,
  Helper,
  ModelModal,
  Path,
  useNavigate,
} from 'src/_Shared/global'
import { useAuth0 } from '@auth0/auth0-react'
import instaMed from 'src/_Shared/images/poweredbyInstaMed.png'
import { useAccountStore } from 'src/_Shared/stores/account'

function PaymentMakeAPayment() {
  const navigate = useNavigate()
  const { isAuthenticated, loginWithPopup } = useAuth0()
  const [people, setPeople] = useState<Types.Person[]>([])
  const [selectedPerson, setSelectedPerson] = useState('Select...')
  const [selectedClinic, setSelectedClinic] = useState('Select...')

  const [balance, setBalance] = useState(0)
  const [balanceReady, setBalanceReady] = useState(false)
  const [amount, setAmount] = useState('')
  const [memo, setMemo] = useState('')
  const [allBills, setAllBills] = useState<PaymentDetailBO[]>([])
  const account = useAccountStore((state) => state.account)

  //==============================
  //#region Load Values
  //==============================
  useEffect(() => {
    if (account) {
      const peopleReady: Types.Person[] = []
      //Add self
      peopleReady.push({
        nxgenId: account.nextGenPersonId ?? '',
        name: `${account.firstName} ${account.lastName}`,
      })
      //Add dependents
      for (const person of account.dependents ?? []) {
        const ready: Types.Person = {
          nxgenId: person.nextGenPersonId ?? '',
          name: `${person.firstName} ${person.lastName}`,
        }
        peopleReady.push(ready)
      }
      setPeople(peopleReady)
    }
  }, [])

  //==============================
  //#region Load Balances
  //==============================
  useEffect(() => {
    setBalanceReady(
      selectedPerson !== 'Select...' && selectedClinic !== 'Select...',
    )
  }, [selectedPerson, selectedClinic])

  useEffect(() => {
    async function init() {
      await ModelPay.getBalance()
      setBalance(ModelPay.totalBalance)
      setAllBills(ModelPay.paymentDetails)
    }
    if (balanceReady) {
      init()
    }
  }, [balanceReady])

  //==============================
  //#region Form Validation
  //==============================
  useEffect(() => {
    setFormReady(Helper.isNotEmpty(amount))
  }, [amount])

  //==============================
  //#region Submit Form
  //==============================
  const [formReady, setFormReady] = useState(false)

  const handleContinue = () => {
    const nextGenId =
      people.find(({ name }) => name === selectedPerson)?.nxgenId || ''
    const practiceId =
      allBills.find(({ name }) => name === selectedClinic)?.practiceId || '1234'
    ModelPay.setNextGenId(nextGenId)
    ModelPay.setPracticeId(practiceId)
    ModelPay.setAmount(parseInt(amount))
    ModelPay.setMemo(memo)

    navigate(Path.PaymentDetails)
  }

  return (
    <div className={css.payBill}>
      <PrimaryNav backHome={true} />
      <img src={HeroDog} alt='Man on Phone' />
      <div className={css.payBillInner}>
        <h1>Pay Bill</h1>
        {isAuthenticated ? (
          <div className={css.pay}>
            {/* === Patient === */}
            {allBills.length > 1 && (
              <Button primary clicked={() => ModelModal.showAllBills()}>
                Check All Bills
              </Button>
            )}
            <Select
              items={people.map(({ name }) => name)}
              label='Who is this payment for?'
              value={selectedPerson}
              change={(value) => {
                setSelectedPerson(value)
              }}
            />
            {/* === Clinic === */}
            <Select
              items={['Practice 1', 'Practice 2']}
              label='Select Clinic'
              value={selectedClinic}
              change={(value) => {
                setSelectedClinic(value)
              }}
            />
            {/* === Amount Due === */}
            {balanceReady ? (
              <div className={css.amount}>
                <cite>Your Balance</cite>
                <h2>${balance}</h2>

                <Input
                  type='number'
                  value={amount}
                  label='Enter Dollar Amount to Pay'
                  stickyLabel={true}
                  placeholder='0.00'
                  change={(event) => {
                    setAmount(event.target.value)
                  }}
                />
                <Input
                  type='text'
                  value={memo}
                  label='Personal Memo'
                  error='A memo is required'
                  change={(event) => {
                    setMemo(event.target.value)
                  }}
                />
                <em>Payments can take a few days to process.</em>
                <img src={instaMed} alt='Powered by InstaMed' width={200} />
                <Button
                  text='Continue'
                  primary
                  clicked={handleContinue}
                  disabled={!formReady}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div className={css.payBillLogIn}>
            <p>You must be logged in to pay bills.</p>
            <Button
              text='Log In'
              secondary
              clicked={() => {
                loginWithPopup()
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(PaymentMakeAPayment)
