//:::::::::::::::::::::::::
import {
  ModelModal,
  Button,
  ModelBooking,
  Icon,
  Select,
  useState,
  useCallback,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function ModalCancelBooking() {
  const [reason, setReason] = useState('Select...')

  const clickConfirm = useCallback(async () => {
    if (ModelBooking.bookingId) {
      await ModelBooking.cancelBooking(ModelBooking.bookingId, reason)
    }
  }, [reason])

  return (
    <>
      <Icon name='modalCancel' width={80} />
      <h5>Cancel Visit</h5>
      <div className='noTouchy'>
        <Select
          items={ModelBooking.cancellationReasons}
          value={reason}
          change={(value) => setReason(value)}
        />
      </div>
      <Button
        text='Yes, Cancel'
        clicked={clickConfirm}
        disabled={reason.length === 0}
        primary={true}
      />

      <div className='noTouchy'>
        <Button
          text="No, Don't Cancel"
          tertiary={true}
          clicked={() => ModelModal.hide()}
        />
      </div>
    </>
  )
}
