import { icons, IconSVG } from './IconSVG'

export default function Icon(props: {
  name?: string
  icon?: IconSVG
  width: number
  color?: string
}) {
  //---
  if (!props.name) return <></>

  //Pull in the icon by name
  var icon: IconSVG = icons[props.name]

  //Use a CSS color name from global.css for the color
  let fill = props.color ? `var(--${props.color})` : 'none'

  return (
    <svg
      width={props.width}
      viewBox={icon.viewBox}
      dangerouslySetInnerHTML={{ __html: icon.svg }}
      style={{ background: 'none' }}
      fill={fill}
    />
  )
}

/*
==================================================
How to Make an Icon
==================================================
Step 1
Generate the SVG file in Figma and save it.

Step 2
Make note of the viewBox attribute

Step 3
Remove the <svg> tags so there is only the stuff in between

Step 4:
Import it in the IconSVG file 
*/
