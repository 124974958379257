//:::::::::::::::::::::::::
import {
  Divider,
  Types,
  Checklist,
  UploadCard,
  useState,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function PaperworkPhotoID(props: {
  values: Types.Paperwork
  change: (values: Types.Paperwork) => void
}) {
  //==============================
  //#region State
  //==============================
  const [cardFront, setCardFront] = useState<string | undefined>(
    localStorage.getItem('idCardFront') || undefined,
  )
  const [cardBack, setCardBack] = useState<string | undefined>(
    localStorage.getItem('idCardBack') || undefined,
  )

  //==============================
  //#region UI
  //==============================
  return (
    <div>
      <Divider margins={30} />
      <h2>Photo ID</h2>
      <p>
        Add photos of your ID card. We’ll send your ID to the clinic to verify.
      </p>
      <p>
        <b>When taking a photo, make sure the card...</b>
      </p>
      <Checklist
        items={[
          'Is lying flat and centered in the camera window.',
          'Is well lit with minimal glare on the surface.',
        ]}
      />
      {/* === Front of ID Card === */}
      <UploadCard
        title='Front of ID Card'
        id='FrontId'
        photo={cardFront}
        ready={(file: string) => {
          setCardFront(file)
          localStorage.setItem('idCardFront', file)
        }}
      />

      {/* === Back of ID Card === */}
      <UploadCard
        title='Back of ID Card'
        id='BackId'
        photo={cardBack}
        ready={(file: string) => {
          setCardBack(file)
          localStorage.setItem('idCardBack', file)
        }}
      />
    </div>
  )
}
