//:::::::::::::::::::::::::
import { ModelModal, Spinner } from 'src/_Shared/global'
import css from '../modal.module.css'
//:::::::::::::::::::::::::

export default function ModalLoading() {
  return (
    <div className={css.loading}>
      <Spinner size={40} />
      {ModelModal.title && <h5>{ModelModal.title}</h5>}
      <p>{ModelModal.message}</p>
      {ModelModal.subtext && <small>{ModelModal.subtext}</small>}
    </div>
  )
}
