enum HttpStatus {
  Success,
  Failed,
  UnAuthorised,
  Conflict,
  NotFound,
  Forbidden
}

export default HttpStatus;
