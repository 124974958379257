//http://localhost:3000/paperwork/1?bookingId=26xmXo&locationId=GgbNNg&isUrgentCare=true&device=web
//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import {
  Nav,
  Path,
  useNavigate,
  useCallback,
  Button,
  ModelPaperwork,
  observer,
} from 'src/_Shared/global'
import PaperworkWrap from '../PaperworkWrap'
import PaperworkPatientEmergency from './PaperworkPatientEmergency'
//:::::::::::::::::::::::::

function PaperworkPatient() {
  const navigate = useNavigate()

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(() => {
    if (ModelPaperwork.patientIsOver18()) {
      //>>>>>
      navigate(Path.PaperworkVirtual5)
    } else {
      //>>>>>
      navigate(Path.PaperworkVirtual4)
    }
  }, [navigate])

  //==============================
  //#region UI
  //==============================
  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav
        back={Path.PaperworkVirtual2}
        title='Additional Paperwork'
        progress={3 / 7}
      />

      <div className={css.paperwork}>
        <div className={css.paperworkInner}>
          {/* === Patient Information === */}
          <h2>Emergency Contact</h2>
          <PaperworkPatientEmergency
            values={ModelPaperwork.paperwork}
            change={(values) => ModelPaperwork.setPaperwork(values)}
          />
        </div>
      </div>
      {/* === Continue === */}
      <div className={css.buttonWrap}>
        <Button
          primary
          clicked={clickContinue}
          text={ModelPaperwork.backToSummary ? 'Save Changes' : 'Continue'}
        />
      </div>
    </PaperworkWrap>
  )
}

export default observer(PaperworkPatient)
