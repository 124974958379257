//:::::::::::::::::::::::::
import css from './bookFinish.module.css'
import {
  Path,
  useNavigate,
  useCallback,
  ModelBooking,
} from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function BookSomeoneElse() {
  const navigate = useNavigate()
  const clickedButton = useCallback(() => {
    ModelBooking.clear()
    //>>>>>
    if (ModelBooking.virtualCare) {
      return navigate(Path.VirtualCareLocation)
    }
    navigate(Path.BookStart)
  }, [navigate])

  return (
    <>
      <div className={css.someoneElse}>
        <h3>Need to book for someone else?</h3>
        <p>
          You can quickly and easily book another visit for your family at
          NextCare Urgent Care in just a few taps.
        </p>

        <button onClick={() => clickedButton()}>Book Another Visit</button>
      </div>
    </>
  )
}
