//:::::::::::::::::::::::::
import {
  Button,
  ClinicDistanceBO,
  Helper,
  HttpStatus,
  Icon,
  ModelAccount,
  ModelClinic,
  ModelMap,
  ModelModal,
  Path,
  PracticeListBO,
  UpdateUserInformationBO,
  observer,
  useEffect,
  useNavigate,
  useRef,
  useState,
} from 'src/_Shared/global'
import bluePhone from 'src/_Shared/images/blue-phone.png'
import rightChevron from 'src/_Shared/images/right-chevron.svg'
import activeInfoIcon from 'src/_Shared/images/info-icon-favorite.png'
import disabledInfoIcon from 'src/_Shared/images/info-disabled.png'
import css from './favoriteClinics.module.css'
import NavEdit from 'src/_Shared/components/NavEdit/NavEdit'
import { useAuth0 } from '@auth0/auth0-react'
import Checkbox from 'src/_Shared/components/Forms/Checkbox/Checkbox'
import useAccountStore from 'src/_Shared/stores/account'
const geodist = require('geodist')
//:::::::::::::::::::::::::

function FavoriteClinics() {
  const navigate = useNavigate()

  const { isAuthenticated } = useAuth0()

  const [isEditMode, setIsEditMode] = useState(false)
  const [clinicsToRemove, setClinicsToRemove] = useState<PracticeListBO[]>([])
  const [clinicsDistances, setClinicsDistances] = useState<
    ClinicDistanceBO[] | null
  >(null)

  const calculatedDistances = useRef<ClinicDistanceBO[]>([])
  const [favoriteClinics, setIsFavoriteClinics] = useState<PracticeListBO[]>([])
  const [account, setAccount] = useAccountStore((state) => [
    state.account,
    state.setAccount,
  ])

  const getLocationPermissionStatus =
    async (): Promise<PermissionState | null> => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: 'geolocation',
        })

        return permissionStatus.state
      } catch (error: any) {
        return null
      }
    }

  const getCurrentLocation = async (): Promise<GeolocationPosition | null> => {
    return new Promise(async (resolve) => {
      try {
        const permissionStatus = await getLocationPermissionStatus()
        if (permissionStatus === 'denied') {
          throw new Error('Location Permission Required')
        }

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            resolve(position)
          })
        }
      } catch (error: any) {
        resolve(null)
      }
    })
  }

  const init = async () => {
    ModelModal.showLoader()
    const result = await getCurrentLocation()
    if (result) {
      await ModelMap.setUserLongitude(result.coords.longitude)
      await ModelMap.setUserLatitude(result.coords.latitude)
    }
    if (account) {
      setIsFavoriteClinics(account.favoriteClinics || [])
      calculateDistance(account.favoriteClinics || [])
    }
    ModelModal.hide()
  }

  useEffect(() => {
    if (isAuthenticated) init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const calculateDistance = (clinics: PracticeListBO[]) => {
    let lat = ModelMap.userLatitude ?? 31.169621
    let long = ModelMap.userLongitude ?? -99.683617
    const distances: ClinicDistanceBO[] = clinics
      .map((clinic, index) => {
        return {
          id: clinic.ID,
          index: index,
          title: clinic.title,
          directionAddress: clinic.directions_address,
          phoneNumber: clinic.phone_number,
          distance: Number(
            geodist(
              { lat: lat, lon: long },
              { lat: clinic.geo.latitude, lng: clinic.geo.longitude },
            ),
          ),
        }
      })
      .sort((a, b) => a.distance - b.distance)

    calculatedDistances.current = distances
    setClinicsDistances(distances)
  }

  const checkBoxOnClickHandler = (
    event: any,
    clinicPassed: ClinicDistanceBO,
  ) => {
    if (event.target.checked === true) {
      const practiceList = favoriteClinics.find(
        (clinic) => clinic.ID === clinicPassed.id,
      )
      if (practiceList) setClinicsToRemove([...clinicsToRemove, practiceList])
    } else {
      setClinicsToRemove(
        favoriteClinics.filter((clinic) => clinic.ID !== clinicPassed.id) || [],
      )
    }
  }

  const cardOnClickHandler = async (clinicId: number) => {
    if (!isEditMode) {
      const clinic = await ModelClinic.getClinic(undefined, clinicId)
      if (!clinic) return
      navigate(`${Path.BookTime}?clinic=${clinic.id}&favoriteView=true`)
    }
  }

  const cancelOnClickHandler = () => {
    setClinicsToRemove([])
    setIsEditMode(false)
  }

  const editOnClickHandler = () => {
    setIsEditMode(true)
  }

  const removeOnClickHandler = async () => {
    ModelModal.showLoader(undefined, 'Removing favorites...')
    const clinicIdsToRemove = clinicsToRemove.map((clinic) => clinic.ID)
    const favClinics = favoriteClinics.filter(
      (clinic) => !clinicIdsToRemove.includes(clinic.ID),
    )
    if (!account) return
    let updateBody: UpdateUserInformationBO = {
      userprofile: {
        user_id: account.auth0Id,
        email_id: account.email ?? '',
        nxgen_person_id: account.nextGenPersonId ?? '',
        first_name: account.firstName ?? '',
        last_name: account.lastName ?? '',
        sex: account.sex ? Helper.convertBirthSexToAPI(account.sex) : '',
        dob: account.dateOfBirth ?? '',
        address_1: account.address1 ?? '',
        address_2: account.address2 ?? '',
        EthnicityId: account.ethnicityId ?? '',
        RaceId: account.raceId,
        city: account.city ?? '',
        state: account.state ?? '',
        zip: account.zipCode ?? '',
        phonenumber: account.phoneNumber ?? '',
        countryId: account.countryId ?? '',
      },
      fav_clinics: favClinics.map((clinic) => clinic.ID.toString()),
      isProfileUpdate: true,
      isClinicUpdate: true,
      isRemove: true,
    }
    // XXX: Allowing this for now to avoid upping the refactor complexity
    const response = await ModelAccount.update(updateBody)
    setAccount({ favoriteClinics: favClinics })
    if (response && response.status === HttpStatus.Success) {
      //exit edit mode and set clinicsToRemove to empty array.
      setIsEditMode(false)
      setClinicsToRemove([])
      init()
    } else {
      //Show error modal and return to edit mode
      ModelModal.showError()
      setClinicsToRemove([])
      setIsEditMode(false)
    }
  }

  return (
    <>
      <NavEdit
        title='Favorite Clinics'
        leftBtn={
          isEditMode
            ? { children: 'Cancel', onClick: cancelOnClickHandler }
            : { children: <Icon name='back' width={16} />, link: Path.Account }
        }
        rightBtn={
          !isEditMode && clinicsDistances && clinicsDistances.length > 0
            ? { children: 'Edit', onClick: editOnClickHandler }
            : { children: 'Remove', onClick: removeOnClickHandler }
        }
      />
      {clinicsDistances &&
        (clinicsDistances.length > 0 ? (
          clinicsDistances.map((clinicDistance: ClinicDistanceBO) => {
            return (
              <div
                onClick={() => cardOnClickHandler(clinicDistance.id)}
                key={`${clinicDistance.id}-card`}
                className={css.clinicDistance}
              >
                <div className={css.clinicDistanceCheckbox}>
                  {isEditMode && (
                    <Checkbox
                      onClick={(e) => checkBoxOnClickHandler(e, clinicDistance)}
                    />
                  )}
                </div>

                <div className={css.clinicDistanceInfo}>
                  <label className={css.clinicDistanceTitle}>
                    {clinicDistance.title}
                  </label>
                  <label className={css.clinicDistanceAddress}>
                    {clinicDistance.directionAddress}
                  </label>
                  {clinicDistance.phoneNumber && (
                    <label className={css.clinicDistancePhone}>
                      <img src={bluePhone} alt='phone' title='phone' />
                      {clinicDistance.phoneNumber}
                    </label>
                  )}
                </div>
                <div className={css.clinicDistanceMilesContainer}>
                  <p className={css.clinicDistanceMiles}>
                    {ModelMap.userLatitude && ModelMap.userLongitude
                      ? `${clinicDistance.distance} mi`
                      : ''}
                  </p>
                  {!isEditMode && (
                    <img
                      src={rightChevron}
                      alt='right chevron'
                      className={css.rightChevronIcon}
                    />
                  )}
                </div>
              </div>
            )
          })
        ) : (
          <div className={css.nullState}>
            To start adding favorite clinics, click the Find a Location button
            below.
          </div>
        ))}

      <div className={css.findALocationContainer}>
        <div className={css.info}>
          <img
            src={isEditMode ? disabledInfoIcon : activeInfoIcon}
            alt='info-icon'
            className={css.infoIcon}
          />
          Find a Location and add it to your favorites to easily find it on your
          next booking.
        </div>
        <div className={css.findALocation}>
          <Button
            secondary
            disabled={isEditMode}
            clicked={() => navigate(`${Path.BookStart}?favoriteSearch=true`)}
          >
            Find a Location
          </Button>
        </div>
      </div>
    </>
  )
}
export default observer(FavoriteClinics)
