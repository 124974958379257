//:::::::::::::::::::::::::
import css from './step.module.css'
import { Button } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function Step(props: {
  number: string
  title: string
  text: string
  selected?: boolean
  button?: string
  buttonClicked?: () => void
  link?: string
  disabled?: boolean
  hideBar?: boolean
}) {
  return (
    <div className={css.step}>
      <div className={css.number}>
        <em className={`${props.selected ? css.selected : ''}`}>
          {props.number}
        </em>
        {!props.hideBar && <span>|</span>}
      </div>
      <div className={css.content}>
        <h3>{props.title}</h3>
        <p>{props.text}</p>
        {props.button && (
          <Button
            text={props.button}
            secondary
            link={props.link}
            clicked={() => props.buttonClicked && props.buttonClicked()}
            disabled={props.disabled}
          />
        )}
      </div>
    </div>
  )
}
