//:::::::::::::::::::::::::
import { useAccountStore } from 'src/_Shared/stores/account'
import css from './bookInfo.module.css'
import {
  Input,
  Helper,
  Button,
  useState,
  useCallback,
  ModelBooking,
  useEffect,
  ModelModal,
  useNavigate,
  Types,
  Path,
  observer,
  LoginBanner,
  useSearchParams,
  FieldSex,
} from 'src/_Shared/global'
import useBookingStore from 'src/_Shared/stores/booking'
//:::::::::::::::::::::::::

function BookInfoForm() {
  const navigate = useNavigate()
  const [params] = useSearchParams('phone')

  //==============================
  //#region Phone Number
  //==============================
  //Check for a phone number entered during QR code check-in
  useEffect(() => {
    const phone = params.get('phone')
    if (phone) {
      setPhone(phone)
      setPhoneReady(true)
    }
  }, [params])

  //==============================
  //#region State
  //==============================
  // timeStamp is a way to update form data after logging in with LoginBanner
  // worth revisiting when time permits, but currently the only way to ensure page re-render
  const [timeStamp, setTimeStamp] = useState(new Date().getTime())
  const [firstName, firstNameSet] = useState('')
  const [firstNameReady, firstNameReadySet] = useState<boolean | undefined>()

  const [lastName, lastNameSet] = useState('')
  const [lastNameReady, lastNameReadySet] = useState<boolean | undefined>()

  const [dateOfBirth, dateOfBirthSet] = useState('')
  const [dateOfBirthReady, dateOfBirthReadySet] = useState<
    boolean | undefined
  >()

  const [sex, sexSet] = useState('')
  const [sexReady, sexReadySet] = useState<boolean | undefined>()

  const [phone, setPhone] = useState('')
  const [phoneReady, setPhoneReady] = useState<boolean | undefined>()

  const [email, emailSet] = useState('')
  const [emailReady, emailReadySet] = useState<boolean | undefined>()
  const account = useAccountStore((state) => state.account)

  //==============================
  //#region Load Values
  //==============================
  useEffect(() => {
    //First check for signed in user
    //Don't set fields as errors on load since some account values may be blank and we should wait for them to edit the field before we check for an error
    //Fields should only be set as ready if values supplied, but not set as errors if not. Otherwise, the page loads and is full of errors before the user does anything
    if (account) {
      //First Name
      firstNameSet(account.firstName ?? '')
      const validFirstName = Helper.validate(
        Helper.InputValidator.Alphabet,
        account.firstName ?? '',
      )
      //Only set as valid if the value passes
      if (validFirstName) {
        firstNameReadySet(true)
      }

      //Last Name
      lastNameSet(account.lastName ?? '')
      const validLastName = Helper.validate(
        Helper.InputValidator.Alphabet,
        account.lastName ?? '',
      )
      if (validLastName) {
        lastNameReadySet(true)
      }

      //Date of Birth
      dateOfBirthSet(
        Helper.convertDateOfBirthFromAPI(account.dateOfBirth ?? ''),
      )

      const validDateOfBirth =
        Helper.isAValidBirthDate(
          Helper.convertDateOfBirthFromAPI(account.dateOfBirth ?? ''),
        ) &&
        Helper.isAtLeast18YearsOld(
          Helper.convertDateOfBirthFromAPI(account.dateOfBirth ?? ''),
        )
      if (validDateOfBirth) {
        dateOfBirthReadySet(true)
      }

      //Birth Sex
      sexSet(account.sex ?? '')
      const validBirthSex = Helper.validateSex(account.sex ?? '')
      if (validBirthSex) {
        sexReadySet(true)
      }

      //Phone
      setPhone(account.phoneNumber ?? '')
      const validPhone =
        Helper.validate(
          Helper.InputValidator.PhoneWithoutDashes,
          account.phoneNumber ?? '',
        ) ||
        Helper.validate(
          Helper.InputValidator.PhoneWithDashes,
          account.phoneNumber ?? '',
        )
      if (validPhone) {
        setPhoneReady(true)
      }

      //Email
      emailSet(account.email ?? '')
      const validEmail = Helper.validate(
        Helper.InputValidator.Email,
        account.email ?? '',
      )
      if (validEmail) {
        emailReadySet(true)
      }
    } else {
      //Next check for previously saved data from this same guest session (like they came back to the screen from the next one)
      const booker = ModelBooking.getBooker()
      if (booker) {
        //Set for emails to have stored values
        firstNameSet(booker.firstName)
        firstNameReadySet(
          Helper.validate(Helper.InputValidator.Alphabet, booker.firstName),
        )

        lastNameSet(booker.lastName)
        lastNameReadySet(
          Helper.validate(Helper.InputValidator.Alphabet, booker.lastName),
        )

        dateOfBirthSet(Helper.convertDateOfBirthFromAPI(booker.dateOfBirth))
        dateOfBirthReadySet(
          Helper.isAValidBirthDate(
            Helper.convertDateOfBirthFromAPI(booker.dateOfBirth),
          ) &&
            Helper.isAtLeast18YearsOld(
              Helper.convertDateOfBirthFromAPI(booker.dateOfBirth),
            ),
        )

        sexSet(booker.sex ?? '')
        sexReadySet(Helper.validateSex(booker.sex ?? ''))

        setPhone(booker.phone)
        setPhoneReady(
          Helper.validate(
            Helper.InputValidator.PhoneWithoutDashes,
            booker.phone,
          ) ||
            Helper.validate(
              Helper.InputValidator.PhoneWithDashes,
              booker.phone,
            ),
        )

        emailSet(booker.email)
        emailReadySet(
          Helper.validate(Helper.InputValidator.Email, booker.email),
        )
      }
    }
  }, [timeStamp, account])

  //==============================
  //#region Form Validation
  //==============================
  useEffect(() => {
    const ready =
      !!firstNameReady &&
      !!lastNameReady &&
      !!dateOfBirthReady &&
      !!sexReady &&
      !!phoneReady &&
      !!emailReady
    //:::
    setFormReady(ready)
  }, [
    firstNameReady,
    lastNameReady,
    dateOfBirthReady,
    phoneReady,
    emailReady,
    sexReady,
  ])

  //==============================
  //#region Submit Form
  //==============================
  const [formReady, setFormReady] = useState(false)

  const [setBookingPhoneNumber] = useBookingStore((state) => [
    state.setBookingPhoneNumber,
  ])

  //Tell the parent to submit
  const clickSubmit = useCallback(() => {
    if (!ModelBooking.getAppointment()) {
      ModelModal.showError(
        'Oops',
        'There was an error with your booking and we will need you to try again.',
        'Start Over',
        Path.BookStart,
      )
      return
    }

    const phoneNumber = phone.replace(/[^0-9]/g, '')

    //Merge the new booking data
    const booker: Types.Patient = {
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dateOfBirth,
      sex: sex,
      phone: phoneNumber,
      email: email,
      nextGenId: account?.nextGenPersonId,
    }

    setBookingPhoneNumber(phoneNumber)
    ModelBooking.setBooker({ ...ModelBooking.booker, ...booker })

    //>>>>>
    navigate(Path.BookPatient)
  }, [firstName, lastName, dateOfBirth, phone, email, navigate])

  //++++++++++++++++++++
  return (
    <>
      <LoginBanner
        message='and Skip This Step'
        onUpdate={() => setTimeStamp(new Date().getTime())}
      />
      <div className={css.bookInfoForm}>
        <form>
          <h4>Your Information</h4>
          <p>
            Please provide your information below even if the appointment is not
            for you.
          </p>
          {/* === First Name === */}
          <Input
            type='text'
            label='Legal First Name'
            value={firstName}
            change={(event) => {
              firstNameSet(event.target.value)
              firstNameReadySet(
                Helper.validate(
                  Helper.InputValidator.Alphabet,
                  event.target.value,
                ),
              )
            }}
            error='First name is required'
            valid={firstNameReady}
          />

          {/* === Last Name === */}
          <Input
            type='text'
            label='Legal Last Name'
            value={lastName}
            change={(event) => {
              lastNameSet(event.target.value)
              lastNameReadySet(
                Helper.validate(
                  Helper.InputValidator.Alphabet,
                  event.target.value,
                ),
              )
            }}
            error='Last name is required'
            valid={lastNameReady}
          />

          {/* === Date of Birth === */}
          <Input
            type='text'
            value={dateOfBirth}
            label='Date of Birth'
            mask={'99/99/9999'}
            change={(event) => {
              dateOfBirthSet(event.target.value)
              dateOfBirthReadySet(
                Helper.isAValidBirthDate(event.target.value) &&
                  Helper.isAtLeast18YearsOld(event.target.value),
              )
            }}
            error='Please enter a date of birth (18 or older)'
            valid={dateOfBirthReady}
          />

          {/* booker birthSex is required for paperwork (even if booking for a dependent) */}
          {/* collect it here if it isn't set  */}
          {!account?.sex && (
            <FieldSex
              value={sex}
              change={(value) => {
                sexSet(value)
                sexReadySet(Helper.validateSex(value))
              }}
            />
          )}

          {/* === Mobile Number === */}
          <Input
            type='text'
            value={phone}
            mask={'***-***-****'}
            change={(event) => {
              setPhone(event.target.value)
            }}
            blur={() => {
              setPhoneReady(Helper.validatePhone(phone))
            }}
            label='Mobile Number'
            error='Please enter a valid phone number'
            valid={phoneReady}
          />

          {/* === Email Address === */}
          <Input
            type='text'
            value={email}
            change={(event) => {
              emailSet(event.target.value)
              emailReadySet(
                Helper.validate(
                  Helper.InputValidator.Email,
                  event.target.value,
                ),
              )
            }}
            blur={() => {
              // emailReadySet(Helper.validate(Helper.InputValidator.Email, email))
            }}
            label='Email Address'
            error='Please enter a valid email address'
            valid={emailReady}
          />

          {/* === Continue Button === */}
          <div className={css.bookInfoFormButton}>
            <Button
              text='Continue'
              disabled={!formReady}
              clicked={clickSubmit}
              primary={true}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default observer(BookInfoForm)
