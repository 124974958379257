//:::::::::::::::::::::::::
import css from './listLink.module.css'
import { Icon, Button } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function ListLink(props: {
  text: string
  link?: string
  icon?: string
  href?: string
  clicked?: () => void
}) {
  return (
    <li className={css.link}>
      <Button
        block
        link={props.link}
        clicked={() => {
          if (props.href) {
            window.open(props.href)
          }
          if (props.clicked) {
            props.clicked()
          }
        }}
      >
        <p>
          <cite>{props.text}</cite>
          <span>
            <Icon name={props.icon || 'arrowRight'} width={18} />
          </span>
        </p>
      </Button>
    </li>
  )
}
