//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import PaperworkWrap from '../PaperworkWrap'
import {
  Path,
  Nav,
  Divider,
  ModelPaperwork,
  Button,
  useCallback,
  useNavigate,
  Switch,
  observer,
  ModelBooking,
  Helper,
} from 'src/_Shared/global'
import PaperworkGuarantorInfo from './PaperworkGuarantorInfo'
import PaperworkGuarantorAddress from './PaperworkGuarantorAddress'
import accountStore from 'src/_Shared/stores/account'
//:::::::::::::::::::::::::

function PaperworkGuarantor() {
  const navigate = useNavigate()

  //==============================
  //#region Account Holder Info
  //==============================
  function setGuarantorAddress() {
    ModelPaperwork.setPaperwork({
      ...ModelPaperwork.paperwork,
      responses: {
        ...ModelPaperwork.paperwork.responses,
        ...(ModelPaperwork.paperwork.guarantorAddressSameAsPatient
          ? {
              'GuarantorAddress_48e1f640-744f-11ed-bd09-51c5be6d07c3':
                ModelPaperwork.paperwork.account?.address,
              GuarantorCity: ModelPaperwork.paperwork.account?.city,
              GuarantorCountry: ModelPaperwork.paperwork.account?.country,
              GuarantorState: ModelPaperwork.paperwork.account?.state,
              GuarantorZIP: ModelPaperwork.paperwork.account?.zip,
            }
          : {
              'GuarantorAddress_48e1f640-744f-11ed-bd09-51c5be6d07c3': '',
              GuarantorCity: '',
              GuarantorCountry: '',
              GuarantorState: '',
              GuarantorZIP: '',
            }),
      },
    })
  }
  function setGuarantorInfo() {
    const { account } = accountStore.getState()
    const booker = ModelBooking.getBooker()

    //Prepare additional fields (account for web, booker for native)
    const firstName = account?.firstName ?? booker?.firstName ?? ''
    const lastName = account?.lastName ?? booker?.lastName ?? ''
    const dateOfBirth = Helper.convertDateOfBirthSimpleFromAPI(
      account?.dateOfBirth ?? booker?.dateOfBirth ?? '',
    )
    const email = account?.email ?? booker?.email ?? ''
    const phoneNumber = Helper.convertPhoneFromAPI(
      account?.phoneNumber ?? booker?.phone ?? '',
    )
    const address = account?.address1 ?? booker?.address ?? ''
    const city = account?.city ?? booker?.city ?? ''
    const state = account?.state ?? booker?.state ?? ''
    const zip = account?.zipCode ?? booker?.zip ?? ''
    const country = account?.country ?? booker?.country ?? ''

    //Set paperwork object
    ModelPaperwork.setPaperwork({
      ...ModelPaperwork.paperwork,
      responses: {
        ...ModelPaperwork.paperwork.responses,
        ...(ModelPaperwork.paperwork.guarantorSameAsAccountHolder
          ? {
              GuarantorFirstName: firstName,
              GuarantorLastName: lastName,
              'GuarantorDateofBirth_2dc46f00-744f-11ed-bd09-51c5be6d07c3':
                dateOfBirth,
              'GuarantorEmailAddress_96866ff0-ac65-11ec-bd28-a3d3bf59450f':
                email,
              'GuarantorPhone_3cfac820-744f-11ed-bd09-51c5be6d07c3':
                phoneNumber,
              'GuarantorAddress_48e1f640-744f-11ed-bd09-51c5be6d07c3': address,
              GuarantorCity: city,
              GuarantorState: state,
              GuarantorZIP: zip,
              GuarantorCountry: country,
            }
          : {
              GuarantorFirstName: '',
              GuarantorLastName: '',
              'GuarantorDateofBirth_2dc46f00-744f-11ed-bd09-51c5be6d07c3': '',
              'GuarantorEmailAddress_96866ff0-ac65-11ec-bd28-a3d3bf59450f': '',
              'GuarantorPhone_3cfac820-744f-11ed-bd09-51c5be6d07c3': '',
              'GuarantorAddress_48e1f640-744f-11ed-bd09-51c5be6d07c3': '',
              GuarantorCity: '',
              GuarantorState: '',
              GuarantorZIP: '',
              GuarantorCountry: '',
            }),
      },
    })
  }

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(() => {
    const goBack = ModelPaperwork.backToSummary
    ModelPaperwork.setBackToSummary(false)
    if (goBack) {
      //>>>>>
      navigate(-1)
    } else {
      //>>>>>
      navigate(Path.Paperwork3)
    }
  }, [navigate])

  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav
        back={
          ModelPaperwork.backToSummary ? Path.PaperworkSummary : Path.Paperwork1
        }
        title='Guarantor Information'
        progress={2 / 6}
      />
      <div className={css.paperwork}>
        <div className={css.paperworkInner}>
          <h2>Guarantor Information</h2>
          <p>
            A guarantor is the individual responsible for the visit bill. If you
            are 18 or over, you are considered the responsible party. If you are
            not your own guarantor then the guarantor must be the patient’s
            legal guardian. That legal guardian must be present at the visit.
          </p>
          <Switch
            on={ModelPaperwork.paperwork.guarantorSameAsAccountHolder}
            changed={(value) => {
              ModelPaperwork.paperwork.guarantorSameAsAccountHolder = value
              //Set guarantor fields with account fields
              setGuarantorInfo()
            }}
            label='Same as Account Holder'
          />

          {/* === Guarantor Info === */}
          <PaperworkGuarantorInfo
            values={ModelPaperwork.paperwork}
            change={(values) => ModelPaperwork.setPaperwork(values)}
            sameAsAccountHolder={
              ModelPaperwork.paperwork.guarantorSameAsAccountHolder
            }
          />

          <Divider />

          {/* === Guarantor Address === */}
          <h2>Guarantor Address</h2>
          <Switch
            on={ModelPaperwork.paperwork.guarantorAddressSameAsPatient}
            changed={(value) => {
              ModelPaperwork.paperwork.guarantorAddressSameAsPatient = value
              ModelPaperwork.setPaperwork(ModelPaperwork.paperwork)
              setGuarantorAddress()
            }}
            label='Same as Patient'
          />
          <PaperworkGuarantorAddress
            values={ModelPaperwork.paperwork}
            change={(values) => ModelPaperwork.setPaperwork(values)}
          />
        </div>
      </div>
      {/* === Continue === */}
      <div className={css.buttonWrap}>
        <Button
          primary
          clicked={clickContinue}
          text={ModelPaperwork.backToSummary ? 'Save Changes' : 'Continue'}
        />
      </div>
    </PaperworkWrap>
  )
}

export default observer(PaperworkGuarantor)
