//:::::::::::::::::::::::::
import { SummaryItem } from 'src/_Shared/global'
//:::::::::::::::::::::::::

const addPreamble = (image: string) => {
  return image.startsWith('data:image')
    ? image
    : `data:image/jpeg;base64,${image}`
}

export default function SummaryPaymentInsurance() {
  const frontImage = localStorage.getItem('insuranceCardFront')
  const cardFront = frontImage?.length ? addPreamble(frontImage) : ''
  const backImage = localStorage.getItem('insuranceCardBack')
  const cardBack = backImage?.length ? addPreamble(backImage) : ''
  return (
    <>
      <SummaryItem name='Front of Card' photo={cardFront} />
      <SummaryItem name='Back of Card' photo={cardBack} />
    </>
  )
}
