//:::::::::::::::::::::::::
//This component exists as a lightweight abstraction over the Input component for text fields that cannot be blank. It's so that the logic of showing the error doesn't have to be repeated.
import { Input, useState, Helper } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function FieldText(props: {
  value: string
  label: string
  name?: string
  error?: string
  mask?: string
  change: (value: string) => void
  blur?: () => void
}) {
  const [ready, readySet] = useState(true)

  return (
    <Input
      type='text'
      name={props.name}
      label={props.label}
      value={props.value}
      change={(event) => {
        props.change(event.target.value)
        readySet(Helper.isNotEmpty(event.target.value))
      }}
      error={props.error}
      //Only show an error if you provide an error message
      valid={props.error ? ready : true}
      mask={props.mask}
    />
  )
}
