//:::::::::::::::::::::::::
import css from './navEdit.module.css'
import { Button, Icon, ReactNode } from 'src/_Shared/global'
//:::::::::::::::::::::::::

type NavButton = {
  children?: ReactNode
  link?: string
  onClick?: () => void
}

export default function NavEdit(props: {
  title?: string
  progress?: number
  leftBtn?: NavButton
  rightBtn?: NavButton
  children?: React.ReactNode
  clicked?: () => void
}) {
  return (
    <nav className={css.navEdit}>
      <div className={css.leftBtn}>
        {props.leftBtn && (
          <Button
            link={props.leftBtn.link}
            clicked={props.leftBtn.onClick}
            children={props.leftBtn.children ?? <Icon name='back' width={16} />}
          />
        )}
      </div>
      <h5>{props.title && props.title}</h5>
      <div className={css.rightBtn}>
        {props.rightBtn && (
          <Button
            link={props.rightBtn.link}
            clicked={props.rightBtn.onClick}
            children={props.rightBtn.children ?? 'Skip'}
          />
        )}
      </div>
    </nav>
  )
}
