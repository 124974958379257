//:::::::::::::::::::::::::
import { Selector, ModelVirtualCare, observer } from 'src/_Shared/global'

import css from './virtualInfo.module.css'
import VirtualInfoSelfPayTotal from './VirtualInfoSelfPayTotal'
import VirtualAdvantageMember from './VirtualAdvantageMember'
//:::::::::::::::::::::::::

function VirtualInfoPayment() {
  return (
    <div className={css.howToPay}>
      <p>How do you want to pay?</p>
      <div>
        <Selector
          items={['Insurance', 'Self-Pay']}
          buttonStyle={true}
          selected={ModelVirtualCare.payInsurance ? 'Insurance' : 'Self-Pay'}
          clicked={(value) => {
            ModelVirtualCare.setPayInsurance(value === 'Insurance')
          }}
        />
      </div>
      {ModelVirtualCare.payInsurance ? null : (
        <>
          {!ModelVirtualCare.employee && <VirtualAdvantageMember />}
          <VirtualInfoSelfPayTotal />
        </>
      )}
    </div>
  )
}

export default observer(VirtualInfoPayment)
