//:::::::::::::::::::::::::
import {
  List,
  ListLink,
  Path,
  Nav,
  Button,
  useState,
  Helper,
  useMemo,
} from 'src/_Shared/global'
import DependentsAddOrUpdate from './AddOrUpdateDependent/DependentsAddOrUpdate'
import css from './dependents.module.css'
import { useAccountStore } from 'src/_Shared/stores/account'
import type { Dependent } from '../../_Shared/stores/account/store'
//:::::::::::::::::::::::::

export default function Dependents() {
  //==============================
  //#region State
  //==============================
  const [showForm, setShowForm] = useState(false)
  const [selectedDependent, setSelectedDependent] = useState<Dependent>()
  const [account] = useAccountStore((state) => [state.account])

  //==============================
  //#region Load Dependents
  //==============================
  const dependents = useMemo<Dependent[]>(() => {
    if (account) {
      return account.dependents ?? []
    } else {
      return []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm, setShowForm])

  const selectDependent = (dependent: Dependent) => {
    setSelectedDependent(dependent)
    setShowForm(true)
  }

  return (
    <div className={css.dependents}>
      {showForm ? (
        <DependentsAddOrUpdate
          dependent={
            selectedDependent
              ? {
                  ...selectedDependent,
                  dateOfBirth: Helper.convertDateOfBirthFromAPI(
                    selectedDependent.dateOfBirth ?? '',
                  ),
                }
              : undefined
          }
          setShowForm={setShowForm}
          setSelectedDependent={setSelectedDependent}
        />
      ) : (
        <>
          <Nav back={Path.Account} title='Dependents' />
          <List>
            {dependents.map((dependent) => (
              <ListLink
                key={dependent.nextGenPersonId}
                text={`${dependent.firstName} ${dependent.lastName}`}
                clicked={() => selectDependent(dependent)}
              />
            ))}
          </List>
          <div className={css.addButton}>
            <Button
              tertiary
              text='Add a Dependent'
              clicked={() => {
                setSelectedDependent(undefined)
                setShowForm(true)
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}
