//:::::::::::::::::::::::::
import { makeObservable, action } from 'mobx'
//:::::::::::::::::::::::::

export enum ModalType {
  Loading,
  Info,
  InfoSaved,
  //These need to match the icon name used in Icon.tsx
  Error = 'modalError',
  Success = 'modalSuccess',
  CancelBooking = 'modalCancelBooking',
  Time = 'modalTime',
  BookingFound = 'modalBookingFound',
  NoAppointments = 'modalNoAppointments',
  AppointmentTaken = 'modalAppointmentTaken',
  AllBills = 'modalAllBills',
}

class ModelModal {
  private static _instance: ModelModal
  //Properties with their initial state
  showModal: boolean = false
  type: ModalType = ModalType.Loading
  title: string = 'Oops'
  message: string = 'Please wait...'
  button: string = 'Okay'
  subtext: string = ''
  link: string | undefined
  messageHtml: string | TrustedHTML = ''

  //==============================
  //#region Loader
  //==============================
  showLoader(message?: string, subtext?: string) {
    this.hide()
    this.type = ModalType.Loading
    this.title = ''
    this.message = message || 'Please wait'
    this.subtext = subtext ?? ''

    this.show()
  }

  //==============================
  //#region InfoSaved
  //==============================
  infoSaved() {
    this.hide()
    this.type = ModalType.InfoSaved
    this.show()
  }

  //==============================
  //#region Success
  //==============================
  showSuccess(
    title?: string,
    message?: string,
    button?: string,
    link?: string,
  ) {
    this.hide()
    this.type = ModalType.Success
    this.title = title || 'Success'
    this.message = message || '...'
    this.button = button || 'Okay'
    this.link = link || ''
    this.show()
  }
  //==============================
  //#region Info
  //==============================
  showInfo(title: string, message: string, button?: string, link?: string) {
    this.hide()
    this.type = ModalType.Info
    this.title = title
    this.message = message
    this.button = button || 'Close'
    this.link = link || ''
    this.show()
  }
  //==============================
  //#region Error
  //==============================
  showError(title?: string, message?: string, button?: string, link?: string) {
    this.hide()
    this.type = ModalType.Error
    this.title = title || 'Oops'
    this.message =
      message || 'There was an error, sorry. Please refresh and try again.'
    this.button = button || 'Okay'
    this.link = link
    this.show()
  }
  //==============================
  //#region Cancel Booking
  //==============================
  showCancelBooking() {
    this.hide()
    this.type = ModalType.CancelBooking
    this.show()
  }

  //==============================
  //#region Time Warning
  //==============================
  showTime() {
    this.hide()
    this.type = ModalType.Time
    this.show()
  }

  //==============================
  //#region Booking Found
  //==============================
  showBookingFound(message: string) {
    this.hide()
    this.type = ModalType.BookingFound
    this.messageHtml = message
    this.show()
  }

  //==============================
  //#region Booking Found
  //==============================
  showNoAppointments() {
    this.hide()
    this.type = ModalType.NoAppointments
    this.show()
  }

  //==============================
  //#region All Bills
  //==============================
  showAllBills() {
    this.hide()
    this.type = ModalType.AllBills
    this.show()
  }

  //==============================
  //#region Appointment Taken
  //==============================
  showAppointmentTaken() {
    this.hide()
    this.type = ModalType.AppointmentTaken
    this.show()
  }

  //==============================
  constructor() {
    //Set props to true to make reactive
    makeObservable(this, {
      //Methods
      show: action,
      hide: action,
      showError: action,
      showLoader: action,
      //Enable properties
      showModal: true,
    })
  }

  //Methods to change state
  show() {
    this.showModal = true
  }
  hide() {
    this.showModal = false
  }

  //Initialize our singleton
  public static get Instance() {
    return this._instance || (this._instance = new this())
  }
}

export default ModelModal.Instance
