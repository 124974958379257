//:::::::::::::::::::::::::
import css from './uploadCard.module.css'
import { Button, useRef, useCallback, ModelModal } from 'src/_Shared/global'
//:::::::::::::::::::::::::

export default function UploadFile(props: {
  text: string
  id: string
  ready: (file: string) => void
}) {
  //==============================
  //#region State
  //==============================
  const uploadRef = useRef<HTMLInputElement>(null)

  //==============================
  //#region Upload File
  //==============================
  const clickBrowse = useCallback((event: any) => {
    const file = event.target.files[0]

    //::::::::::::::::::::::::::
    // Check file type
    //::::::::::::::::::::::::::
    if (
      !file.type.includes('png') &&
      !file.type.includes('jpg') &&
      !file.type.includes('jpeg')
    ) {
      //Not a photo!
      ModelModal.showError(
        'Not a Photo',
        'Please select a photo file. You can choose either a PNG or a JPG.',
      )
      return
    }

    //::::::::::::::::::::::::::
    // Check file size
    //::::::::::::::::::::::::::
    const sizeInMB = Number((file.size / 1024 / 1024).toFixed(2))
    if (sizeInMB > 5) {
      //Too big!
      ModelModal.showError(
        'Too Big',
        'Please select a photo that is 5MB or less.',
      )
      return
    }

    //::::::::::::::::::::::::::
    // Load file
    //::::::::::::::::::::::::::
    const reader = new FileReader()
    reader.onloadend = function () {
      if (typeof reader.result === 'string') {
        //:::
        props.ready(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }, [])

  //==============================
  //#region UI
  //==============================
  return (
    <div className={css.uploadFile}>
      <Button>
        <label htmlFor={`inputFile${props.id}`}>
          <p className={css.link}>{props.text}</p>
        </label>
        <input
          id={`inputFile${props.id}`}
          name={`frontImage${props.id}`}
          onChange={clickBrowse}
          accept='.jpg,.png,.jpeg'
          type='file'
          ref={uploadRef}
        />
      </Button>
    </div>
  )
}
