import {
  AccountConstants,
  AccountUtils,
  useAccountStore,
} from 'src/_Shared/stores/account'
import {
  Nav,
  Path,
  ModelModal,
  useNavigate,
  Selector,
  useEffect,
  Input,
  useState,
  Button,
  Select,
  Label,
  FieldText,
  FieldDateOfBirth,
  SecurityBlurb,
  FieldSex,
} from 'src/_Shared/global'
import css from './personalInformation.module.css'
//:::::::::::::::::::::::::

function PersonalInformation({
  title = 'Personal Information',
  createAccount,
  message,
  buttonText,
}: {
  title?: string
  createAccount?: boolean
  message?: React.ReactNode
  buttonText?: string
}) {
  //==============================
  //#region Load Account
  //==============================
  const [account, resetAccount, setAccount, updateUser, fetchAccount] =
    useAccountStore((state) => [
      state.account,
      state.resetAccount,
      state.setAccount,
      state.updateUser,
      state.fetchAccount,
    ])

  const change = (key: string, value: string) =>
    setAccount({ ...account, [key]: value })

  const submitForm = async () => {
    if (!account) return
    ModelModal.showLoader('Please wait', 'Updating account...')
    await updateUser()
    await fetchAccount()
    ModelModal.hide()
  }
  const navigate = useNavigate()
  const [formNotReady, setFormNotReady] = useState(false)
  useEffect(() => {
    const getAccount = async () => {
      ModelModal.showLoader('Please wait', 'Getting account...')
      await fetchAccount()
      ModelModal.hide()
    }
    if (!createAccount) {
      getAccount()
    }
  }, [])
  useEffect(() => {
    const isPhoneReady =
      account?.phoneNumber &&
      account?.phoneNumber.replace(/[^0-9]/g, '').length === 10
    const notReady =
      !account?.firstName || !account?.lastName || !isPhoneReady || !account.sex
    setFormNotReady(notReady)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  return (
    <>
      <Nav
        title={title}
        back={createAccount ? Path.Home : Path.Account}
        clicked={() => {
          if (createAccount) {
            resetAccount() // logging out
            navigate(Path.Home)
          } else {
            navigate(Path.Account)
          }
        }}
      />

      <form className={css.account}>
        {message ? message : <SecurityBlurb />}
        {/* === First Name === */}
        <FieldText
          value={account?.firstName ?? ''}
          label='Legal First Name'
          error='First name is required'
          change={(value) => change('firstName', value)}
        />

        {/* === Last Name === */}
        <FieldText
          value={account?.lastName ?? ''}
          label='Legal Last Name'
          error='Last name is required'
          change={(value) => change('lastName', value)}
        />

        {/* === Date of Birth === */}
        <FieldDateOfBirth
          value={account?.dateOfBirth ?? ''}
          change={(value) => change('dateOfBirth', value)}
        />

        {/* === Mobile Number === */}
        <FieldText
          value={account?.phoneNumber || ''}
          label='Mobile Number'
          change={(value) => {
            console.log(value)
            change('phoneNumber', value)
          }}
          mask={'***-***-****'}
        />

        {/* === Email Address === */}
        <Input
          type='text'
          value={account?.email ?? ''}
          label='Email Address'
          disabled={true}
        />

        {/* === Birth Sex === */}
        <FieldSex
          value={account?.sex ?? ''}
          change={(value) => change('sex', value)}
        />

        {/* === Race === */}
        <div className={css.wrapFat}>
          <Select
            label='Race'
            value={AccountUtils.getRaceNameById(account?.raceId || '') ?? ''}
            items={AccountUtils.getRaceNames()}
            change={(value) =>
              change('raceId', AccountUtils.getRaceIdByName(value))
            }
          />
        </div>

        {/* === Ethnicity === */}
        <div className={css.wrapFat}>
          <Select
            label='Ethnicity'
            value={
              AccountUtils.getEthnicityNameById(account?.ethnicityId || '') ??
              ''
            }
            items={AccountUtils.getEthnicityNames()}
            change={(value) =>
              change('ethnicityId', AccountUtils.getEthnicityIdByName(value))
            }
          />
        </div>

        <hr />

        <h3>Patient Address</h3>

        {/* === Street Address === */}
        <FieldText
          value={account?.address1 ?? ''}
          label='Street Address'
          error='Address is required'
          change={(value) => change('address1', value)}
        />

        {/* === Apartment === */}
        <FieldText
          value={account?.address2 ?? ''}
          label='Apt, suite, etc. (optional)'
          change={(value) => change('address2', value)}
        />

        {/* === City === */}
        <FieldText
          label='City'
          value={account?.city ?? ''}
          error='City is required'
          change={(value) => change('city', value)}
        />

        {/* === Country === */}
        <div className={css.wrap}>
          <Label text='Country' />
          <Selector
            items={['USA', 'Canada']}
            selected={account?.country ?? ''}
            clicked={(value) => change('country', value)}
          />
        </div>

        {/* === State === */}
        <div className={css.wrap}>
          <Select
            label={account?.country === 'USA' ? 'State' : 'Province'}
            value={account?.state ?? ''}
            items={
              account?.country === 'USA'
                ? AccountConstants.STATES
                : AccountConstants.PROVINCES
            }
            change={(value) => change('state', value)}
          />
        </div>

        {/* === ZIP === */}
        <FieldText
          value={account?.zipCode ?? ''}
          label={account?.country === 'USA' ? 'ZIP Code' : 'Postal Code'}
          error={
            account?.country === 'USA'
              ? 'ZIP code is required'
              : 'Postal code is required'
          }
          change={(value) => change('zipCode', value)}
          mask={account?.country === 'USA' ? '*****' : '*** ***'}
        />

        {/* === Submit Button === */}
        <div className={css.buttonWrap}>
          <Button
            text={buttonText || 'Submit'}
            disabled={formNotReady}
            primary
            clicked={
              createAccount
                ? () => navigate(Path.CreateAccountReviewAndAccept)
                : submitForm
            }
          />
        </div>
      </form>
    </>
  )
}

export default PersonalInformation
