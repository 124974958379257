//:::::::::::::::::::::::::
import css from './loginBanner.module.css'
import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, Foundation, Button } from 'src/_Shared/global'
import { useAccountStore } from 'src/_Shared/stores/account'
//:::::::::::::::::::::::::

export default function LoginBanner(props: {
  message: string
  onUpdate?: () => any
}) {
  const { loginWithRedirect } = useAuth0()

  const [account] = useAccountStore((state) => [state.account])

  //==============================
  //#region Log In
  //==============================
  const clickLogIn = useCallback(() => {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${Foundation.auth0Redirect}?destination=bookInfo`,
      },
    })
  }, [loginWithRedirect])

  return (
    <>
      {!account ? (
        <div className={css.loginBanner}>
          <h2>Already Have an Account?</h2>
          <p>
            <Button clicked={clickLogIn}>
              <span>Log in</span>
            </Button>
            {props.message}
          </p>
        </div>
      ) : null}
    </>
  )
}
