//:::::::::::::::::::::::::
import {
  Nav,
  Path,
  Select,
  useState,
  useEffect,
  Divider,
  Checklist,
  UploadCard,
  Button,
  useNavigate,
  useCallback,
} from 'src/_Shared/global'
import css from './virtualPayment.module.css'
import { useInsuranceStore } from 'src/_Shared/stores/insurance'
import { Insurance } from 'src/_Shared/stores/insurance/store'
//:::::::::::::::::::::::::

const InsuranceCardImage = (props: { image?: string; alt: string }) => {
  return (
    <div className={css.dashedImageContainer}>
      <img
        src={
          props.image?.match(/data:image/)
            ? props.image
            : `data:image/jpeg;base64,${props.image}`
        }
        alt={props.alt}
      />
    </div>
  )
}

export default function VirtualInsurance() {
  const navigate = useNavigate()

  const [
    cardId,
    frontImage,
    backImage,
    insurances,
    setFrontImage,
    setBackImage,
    setCardId,
    setCardName,
  ] = useInsuranceStore((state) => [
    state.cardId,
    state.frontImage,
    state.backImage,
    state.insurances,
    state.setFrontImage,
    state.setBackImage,
    state.setCardId,
    state.setCardName,
  ])

  const [selectedCardId, setSelectedCardId] = useState<string | null>(cardId)
  const selectedInsurance =
    insurances?.find((i) => i.cardId === selectedCardId) ?? null

  const resetInsuranceCard = (card?: Insurance | null) => {
    if (card) {
      localStorage.setItem('insuranceCardFront', card.frontImage)
      localStorage.setItem('insuranceCardBack', card.backImage)
      setFrontImage(card.frontImage)
      setBackImage(card.backImage)
      setCardName(card.cardNickName)
    } else {
      localStorage.removeItem('insuranceCardFront')
      localStorage.removeItem('insuranceCardBack')
      setFrontImage(null)
      setBackImage(null)
      setCardId(null)
      setCardName('Insurance Card')
    }
  }
  useEffect(() => {
    resetInsuranceCard(selectedInsurance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //==============================
  //#region Continue
  //==============================
  const clickContinue = useCallback(() => {
    navigate(Path.VirtualCareSignForms)
  }, [navigate])

  //==============================
  //#region UI
  //==============================
  return (
    <>
      <Nav title='Insurance' back={Path.VirtualCareInfo} />
      <div className={css.insurance}>
        {insurances?.length ? (
          <Select
            label={'Select Insurance'}
            value={selectedInsurance?.cardNickName ?? ''}
            items={insurances
              .map((insurance) => insurance.cardNickName)
              .concat(['+ Add Insurance'])}
            change={(value: string) => {
              const newlySelectedInsurance =
                insurances.find((i) => i.cardNickName === value) ?? null
              setSelectedCardId(newlySelectedInsurance?.cardId ?? null)
              if (newlySelectedInsurance) {
                localStorage.setItem(
                  'insuranceCardFront',
                  newlySelectedInsurance.frontImage ?? '',
                )
                localStorage.setItem(
                  'insuranceCardBack',
                  newlySelectedInsurance.backImage ?? '',
                )
                setFrontImage(newlySelectedInsurance.frontImage)
                setBackImage(newlySelectedInsurance.backImage)
                setCardId(newlySelectedInsurance.cardId)
                setCardName(newlySelectedInsurance.cardNickName)
              } else {
                resetInsuranceCard()
              }
            }}
          />
        ) : (
          <></>
        )}
        {selectedInsurance === null ? (
          <>
            <Divider margins={30} />
            <h2>Insurance Information</h2>
            <p>
              Add photos of your insurance card. We’ll send your insurance to
              the clinic to verify.
            </p>
            <p>
              <b>When taking a photo, make sure the card...</b>
            </p>
            <Checklist
              items={[
                'Is lying flat and centered in the camera window.',
                'Is well lit with minimal glare on the surface.',
              ]}
            />
            {/* === Front of Insurance Card === */}
            <UploadCard
              title='Front of Insurance Card'
              id='Front'
              photo={frontImage ?? ''}
              ready={(file: string) => {
                setFrontImage(file)
                localStorage.setItem('insuranceCardFront', file)
              }}
            />

            {/* === Back of Insurance Card === */}
            <UploadCard
              title='Back of Insurance Card'
              id='Back'
              photo={backImage ?? ''}
              ready={(file: string) => {
                setBackImage(file)
                localStorage.setItem('insuranceCardBack', file)
              }}
            />
          </>
        ) : (
          <>
            <InsuranceCardImage
              image={selectedInsurance.frontImage}
              alt='Front of Insurance Card'
            />
            <InsuranceCardImage
              image={selectedInsurance.backImage}
              alt='Back of Insurance Card'
            />
          </>
        )}

        {/* === Continue Button === */}
        <Button text='Continue' clicked={clickContinue} primary />
      </div>
    </>
  )
}
