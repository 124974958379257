//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import PaperworkWrap from '../PaperworkWrap'
import {
  Path,
  Nav,
  ModelPaperwork,
  Button,
  useCallback,
  useNavigate,
  Selector,
  observer,
} from 'src/_Shared/global'
import PaperworkPaymentInsurance from './PaperworkPaymentInsurance'
import PaperworkPhotoID from './PaperworkPhotoID'
//:::::::::::::::::::::::::

function PaperworkPayment() {
  const navigate = useNavigate()

  //==============================
  //#region Continue
  //==============================

  const clickContinue = useCallback(() => {
    const goBack = ModelPaperwork.backToSummary
    ModelPaperwork.setBackToSummary(false)
    if (goBack) {
      //>>>>>
      navigate(-1)
    } else {
      //>>>>>
      navigate(Path.Paperwork5)
    }
  }, [navigate])

  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav
        back={
          ModelPaperwork.backToSummary ? Path.PaperworkSummary : Path.Paperwork3
        }
        title='Payment & Insurance'
        progress={4 / 6}
      />
      <div className={css.paperwork}>
        <div className={css.paperworkInner}>
          <h2>How do you want to pay?</h2>
          <div className={css.fieldWrapExtra}>
            <Selector
              items={['Insurance', 'Self-Pay']}
              selected={
                ModelPaperwork.paperwork.selfPay ? 'Self-Pay' : 'Insurance'
              }
              clicked={(value) => {
                ModelPaperwork.paperwork.selfPay = value === 'Self-Pay'
                ModelPaperwork.setPaperwork(ModelPaperwork.paperwork)
              }}
            />
          </div>

          <>
            {ModelPaperwork.paperwork.selfPay ? (
              <PaperworkPhotoID
                values={ModelPaperwork.paperwork}
                change={(values) => ModelPaperwork.setPaperwork(values)}
              />
            ) : (
              <PaperworkPaymentInsurance
                values={ModelPaperwork.paperwork}
                change={(values) => ModelPaperwork.setPaperwork(values)}
              />
            )}
          </>
        </div>
        <div className={css.buttonWrap}>
          <Button
            primary
            clicked={clickContinue}
            text={ModelPaperwork.backToSummary ? 'Save Changes' : 'Continue'}
          />
        </div>
      </div>
      {/* === Continue === */}
    </PaperworkWrap>
  )
}

export default observer(PaperworkPayment)
